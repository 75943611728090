import React, { Component } from 'react';
import './App.css';
import img_elN200 from './images/Picker_specifiques_elN200_1862196716.png';
import img_elN201 from './images/Picker_specifiques_elN201_2086322261.png';
import img_elN202 from './images/Picker_specifiques_elN202_1142767885.png';
import img_elN203 from './images/Picker_specifiques_elN203_300638107.png';
import img_elN204 from './images/Picker_specifiques_elN204_705946128.png';
import img_elN205 from './images/Picker_specifiques_elN205_347206647.png';
import img_elN206 from './images/Picker_specifiques_elN206_1565680216.png';
import img_elN207 from './images/Picker_specifiques_elN207_1567573579.png';
import img_elN208 from './images/Picker_specifiques_elN208_590111725.png';
import img_elN209 from './images/Picker_specifiques_elN209_655803815.png';
import img_elN210 from './images/Picker_specifiques_elN210_1773497076.png';
import img_elN211 from './images/Picker_specifiques_elN211_1167526193.png';
import img_elN212 from './images/Picker_specifiques_elN212_1757898433.png';
import img_elN213 from './images/Picker_specifiques_elN213_1692009191.png';
import img_elN214 from './images/Picker_specifiques_elN214_146108670.png';
import img_elN300 from './images/Picker_specifiques_elN300_1953398273.png';
import img_elN301 from './images/Picker_specifiques_elN301_776863078.png';
import img_elN302 from './images/Picker_specifiques_elN302_1987544321.png';
import img_elN303 from './images/Picker_specifiques_elN303_419408758.png';
import img_elN304 from './images/Picker_specifiques_elN304_1316536464.png';
import img_elN305 from './images/Picker_specifiques_elN305_157878831.png';
import img_elN306 from './images/Picker_specifiques_elN306_93986815.png';
import img_elN307 from './images/Picker_specifiques_elN307_2128063055.png';
import img_elN308 from './images/Picker_specifiques_elN308_25950915.png';
import img_elN309 from './images/Picker_specifiques_elN309_1498414555.png';
import img_elN310 from './images/Picker_specifiques_elN310_1915790896.png';
import img_elN311 from './images/Picker_specifiques_elN311_1141790305.png';
import img_elN312 from './images/Picker_specifiques_elN312_834466250.png';
import img_elN313 from './images/Picker_specifiques_elN313_1387004133.png';
import img_elN314 from './images/Picker_specifiques_elN314_2109013373.png';
import img_elN315 from './images/Picker_specifiques_elN315_1784287244.png';
import img_elN316 from './images/Picker_specifiques_elN316_1444734762.png';
import img_elN317 from './images/Picker_specifiques_elN317_841430707.png';
import img_elN318 from './images/Picker_specifiques_elN318_1395429857.png';
import img_elN319 from './images/Picker_specifiques_elN319_1601731449.png';
import img_elN320 from './images/Picker_specifiques_elN320_841113016.png';
import img_elN321 from './images/Picker_specifiques_elN321_1908264395.png';
import img_elN322 from './images/Picker_specifiques_elN322_1227292792.png';
import img_elN323 from './images/Picker_specifiques_elN323_655723893.png';
import img_elN324 from './images/Picker_specifiques_elN324_966885593.png';
import img_elN325 from './images/Picker_specifiques_elN325_1782162306.png';
import img_elN326 from './images/Picker_specifiques_elN326_852032141.png';
import img_elN327 from './images/Picker_specifiques_elN327_1792055494.png';
import img_elN328 from './images/Picker_specifiques_elN328_1123441646.png';
import img_elN329 from './images/Picker_specifiques_elN329_1531119697.png';
import img_elN330 from './images/Picker_specifiques_elN330_685285000.png';
import img_elN331 from './images/Picker_specifiques_elN331_1015599139.png';
import img_elN332 from './images/Picker_specifiques_elN332_175331120.png';
import img_elN333 from './images/Picker_specifiques_elN333_26253178.png';
import img_elN334 from './images/Picker_specifiques_elN334_92265793.png';
import img_elN335 from './images/Picker_specifiques_elN335_976968305.png';
import img_elN336 from './images/Picker_specifiques_elN336_841711626.png';
import img_elN337 from './images/Picker_specifiques_elN337_792756883.png';
import img_elN400 from './images/Picker_specifiques_elN400_1276573761.png';
import img_elN401 from './images/Picker_specifiques_elN401_273344906.png';
import img_elN402 from './images/Picker_specifiques_elN402_1265432327.png';
import img_elN403 from './images/Picker_specifiques_elN403_1975488458.png';
import img_elImage from './images/Picker_specifiques_elImage_140921196.png';
import btn_icon_563291437 from './images/btn_icon_563291437.png';
import btn_icon_284395175 from './images/btn_icon_284395175.png';

// UI framework component imports
import Input from 'muicss/lib/react/input';

export default class Picker_specifiques extends Component {

  // Properties used by this component:
  // label, state, flux, flux_id, id

  constructor(props) {
    super(props);
    
    this.state = {
      state: this.props.state || 0,
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  textInputChanged_elState = (event) => {
    this.setState({state: event.target.value});
    
    let mode = parseInt(this.props.appActions.dataSlots['ds_mode_point']);
    if (mode === 6) {
      // SPE
      let newState = Math.max(0, parseInt(event.target.value));
      let newDs_quai = {...this.props.appActions.dataSlots['ds_selected_dech_quai_view']};
      newDs_quai.spe[this.props.id].state = newState;
      this.props.appActions.updateDataSlot('ds_selected_dech_quai_view', newDs_quai);
      this.setState({state: newState});
    }  
  }
  transformPropValue_state = (input) => {
    return Math.max(0, parseInt(input || 0));
  }
  
  
  onClick_elButtonCopy = (ev) => {
    let mode = parseInt(this.props.appActions.dataSlots['ds_mode_point']);
    if (mode === 6) {
      // SPE
      let newState = Math.max(0, parseInt(this.state.state) - 1);
      let newDs_quai = {...this.props.appActions.dataSlots['ds_selected_dech_quai_view']};
      newDs_quai.spe[this.props.id].state = newState;
      this.props.appActions.updateDataSlot('ds_selected_dech_quai_view', newDs_quai);
      this.setState({state: newState});
    }
  
  }
  
  
  onClick_elButton = (ev) => {
    let mode = parseInt(this.props.appActions.dataSlots['ds_mode_point']);
    if (mode === 6) {
      // SPE
      let newState = Math.max(0, parseInt(this.state.state) + 1);
      let newDs_quai = {...this.props.appActions.dataSlots['ds_selected_dech_quai_view']};
      newDs_quai.spe[this.props.id].state = newState;
      this.props.appActions.updateDataSlot('ds_selected_dech_quai_view', newDs_quai);
      this.setState({state: newState});
    }
  
  }
  
  
  render() {
    const style_elN200 = {
      backgroundImage: 'url('+img_elN200+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN201 = {
      backgroundImage: 'url('+img_elN201+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN202 = {
      backgroundImage: 'url('+img_elN202+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN203 = {
      backgroundImage: 'url('+img_elN203+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN204 = {
      backgroundImage: 'url('+img_elN204+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN205 = {
      backgroundImage: 'url('+img_elN205+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN206 = {
      backgroundImage: 'url('+img_elN206+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN207 = {
      backgroundImage: 'url('+img_elN207+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN208 = {
      backgroundImage: 'url('+img_elN208+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN209 = {
      backgroundImage: 'url('+img_elN209+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN210 = {
      backgroundImage: 'url('+img_elN210+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN211 = {
      backgroundImage: 'url('+img_elN211+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN212 = {
      backgroundImage: 'url('+img_elN212+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN213 = {
      backgroundImage: 'url('+img_elN213+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN214 = {
      backgroundImage: 'url('+img_elN214+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN300 = {
      backgroundImage: 'url('+img_elN300+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN301 = {
      backgroundImage: 'url('+img_elN301+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN302 = {
      backgroundImage: 'url('+img_elN302+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN303 = {
      backgroundImage: 'url('+img_elN303+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN304 = {
      backgroundImage: 'url('+img_elN304+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN305 = {
      backgroundImage: 'url('+img_elN305+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN306 = {
      backgroundImage: 'url('+img_elN306+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN307 = {
      backgroundImage: 'url('+img_elN307+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN308 = {
      backgroundImage: 'url('+img_elN308+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN309 = {
      backgroundImage: 'url('+img_elN309+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN310 = {
      backgroundImage: 'url('+img_elN310+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN311 = {
      backgroundImage: 'url('+img_elN311+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN312 = {
      backgroundImage: 'url('+img_elN312+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN313 = {
      backgroundImage: 'url('+img_elN313+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN314 = {
      backgroundImage: 'url('+img_elN314+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN315 = {
      backgroundImage: 'url('+img_elN315+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN316 = {
      backgroundImage: 'url('+img_elN316+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN317 = {
      backgroundImage: 'url('+img_elN317+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN318 = {
      backgroundImage: 'url('+img_elN318+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN319 = {
      backgroundImage: 'url('+img_elN319+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN320 = {
      backgroundImage: 'url('+img_elN320+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN321 = {
      backgroundImage: 'url('+img_elN321+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN322 = {
      backgroundImage: 'url('+img_elN322+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN323 = {
      backgroundImage: 'url('+img_elN323+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN324 = {
      backgroundImage: 'url('+img_elN324+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN325 = {
      backgroundImage: 'url('+img_elN325+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN326 = {
      backgroundImage: 'url('+img_elN326+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN327 = {
      backgroundImage: 'url('+img_elN327+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN328 = {
      backgroundImage: 'url('+img_elN328+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN329 = {
      backgroundImage: 'url('+img_elN329+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN330 = {
      backgroundImage: 'url('+img_elN330+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN331 = {
      backgroundImage: 'url('+img_elN331+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN332 = {
      backgroundImage: 'url('+img_elN332+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN333 = {
      backgroundImage: 'url('+img_elN333+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN334 = {
      backgroundImage: 'url('+img_elN334+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN335 = {
      backgroundImage: 'url('+img_elN335+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN336 = {
      backgroundImage: 'url('+img_elN336+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN337 = {
      backgroundImage: 'url('+img_elN337+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN400 = {
      backgroundImage: 'url('+img_elN400+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN401 = {
      backgroundImage: 'url('+img_elN401+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN402 = {
      backgroundImage: 'url('+img_elN402+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN403 = {
      backgroundImage: 'url('+img_elN403+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    let transformPropValue_image = (input) => {
      let img = {
        "200": img_elN200,
        "201": img_elN201,
        "202": img_elN202,
        "203": img_elN203,
        "204": img_elN204,
        "205": img_elN205,
        "206": img_elN206,
        "207": img_elN207,
        "208": img_elN208,
        "209": img_elN209,
        "210": img_elN210,
        "211": img_elN211,
        "212": img_elN212,
        "213": img_elN213,
        "214": img_elN214,
        "300": img_elN300,
        "301": img_elN301,
        "302": img_elN302,
        "303": img_elN303,
        "304": img_elN304,
        "305": img_elN305,
        "306": img_elN306,
        "307": img_elN307,
        "308": img_elN308,
        "309": img_elN309,
        "310": img_elN310,
        "311": img_elN311,
        "312": img_elN312,
        "313": img_elN313,
        "314": img_elN314,
        "315": img_elN315,
        "316": img_elN316,
        "317": img_elN317,
        "318": img_elN318,
        "319": img_elN319,
        "320": img_elN320,
        "321": img_elN321,
        "322": img_elN322,
        "323": img_elN323,
        "324": img_elN324,
        "325": img_elN325,
        "326": img_elN326,
        "327": img_elN327,
        "328": img_elN328,
        "329": img_elN329,
        "330": img_elN330,
        "331": img_elN331,
        "332": img_elN332,
        "333": img_elN333,
        "334": img_elN334,
        "335": img_elN335,
        "336": img_elN336,
        "337": img_elN337,
        "400": img_elN400,
        "401": img_elN401,
        "402": img_elN402,
        "403": img_elN403,
      };
      
      return img[input.toString()];
    }
    const style_elImage = {
      backgroundImage: 'url('+(transformPropValue_image(this.props.flux_id) || img_elImage)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    
    const value_flux = this.props.flux;
    
    const style_elFlux = {
      fontSize: 10.4,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const value_state = this.state.state;
    
    const style_elState = {
      display: 'block',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      textAlign: 'center',
      pointerEvents: 'auto',
     };
    
    const style_elButtonCopy = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_563291437+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elButton = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_284395175+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Picker_specifiques cardBg">
        <div className="layoutFlow">
          <div className="flowRow flowRow_Picker_specifiques_elN200_1862196716">
          <div className="elN200">
            <div style={style_elN200} />
          </div>
          
          <div className="elN201">
            <div style={style_elN201} />
          </div>
          
          <div className="elN202">
            <div style={style_elN202} />
          </div>
          
          <div className="elN203">
            <div style={style_elN203} />
          </div>
          
          <div className="elN204">
            <div style={style_elN204} />
          </div>
          
          <div className="elN205">
            <div style={style_elN205} />
          </div>
          
          <div className="elN206">
            <div style={style_elN206} />
          </div>
          
          <div className="elN207">
            <div style={style_elN207} />
          </div>
          
          <div className="elN208">
            <div style={style_elN208} />
          </div>
          
          <div className="elN209">
            <div style={style_elN209} />
          </div>
          
          <div className="elN210">
            <div style={style_elN210} />
          </div>
          
          <div className="elN211">
            <div style={style_elN211} />
          </div>
          
          <div className="elN212">
            <div style={style_elN212} />
          </div>
          
          <div className="elN213">
            <div style={style_elN213} />
          </div>
          
          <div className="elN214">
            <div style={style_elN214} />
          </div>
          
          <div className="elN300">
            <div style={style_elN300} />
          </div>
          
          <div className="elN301">
            <div style={style_elN301} />
          </div>
          
          <div className="elN302">
            <div style={style_elN302} />
          </div>
          
          <div className="elN303">
            <div style={style_elN303} />
          </div>
          
          <div className="elN304">
            <div style={style_elN304} />
          </div>
          
          <div className="elN305">
            <div style={style_elN305} />
          </div>
          
          <div className="elN306">
            <div style={style_elN306} />
          </div>
          
          <div className="elN307">
            <div style={style_elN307} />
          </div>
          
          <div className="elN308">
            <div style={style_elN308} />
          </div>
          
          <div className="elN309">
            <div style={style_elN309} />
          </div>
          
          <div className="elN310">
            <div style={style_elN310} />
          </div>
          
          <div className="elN311">
            <div style={style_elN311} />
          </div>
          
          <div className="elN312">
            <div style={style_elN312} />
          </div>
          
          <div className="elN313">
            <div style={style_elN313} />
          </div>
          
          <div className="elN314">
            <div style={style_elN314} />
          </div>
          
          <div className="elN315">
            <div style={style_elN315} />
          </div>
          
          <div className="elN316">
            <div style={style_elN316} />
          </div>
          
          <div className="elN317">
            <div style={style_elN317} />
          </div>
          
          <div className="elN318">
            <div style={style_elN318} />
          </div>
          
          <div className="elN319">
            <div style={style_elN319} />
          </div>
          
          <div className="elN320">
            <div style={style_elN320} />
          </div>
          
          <div className="elN321">
            <div style={style_elN321} />
          </div>
          
          <div className="elN322">
            <div style={style_elN322} />
          </div>
          
          <div className="elN323">
            <div style={style_elN323} />
          </div>
          
          <div className="elN324">
            <div style={style_elN324} />
          </div>
          
          <div className="elN325">
            <div style={style_elN325} />
          </div>
          
          <div className="elN326">
            <div style={style_elN326} />
          </div>
          
          <div className="elN327">
            <div style={style_elN327} />
          </div>
          
          <div className="elN328">
            <div style={style_elN328} />
          </div>
          
          <div className="elN329">
            <div style={style_elN329} />
          </div>
          
          <div className="elN330">
            <div style={style_elN330} />
          </div>
          
          <div className="elN331">
            <div style={style_elN331} />
          </div>
          
          <div className="elN332">
            <div style={style_elN332} />
          </div>
          
          <div className="elN333">
            <div style={style_elN333} />
          </div>
          
          <div className="elN334">
            <div style={style_elN334} />
          </div>
          
          <div className="elN335">
            <div style={style_elN335} />
          </div>
          
          <div className="elN336">
            <div style={style_elN336} />
          </div>
          
          <div className="elN337">
            <div style={style_elN337} />
          </div>
          
          <div className="elN400">
            <div style={style_elN400} />
          </div>
          
          <div className="elN401">
            <div style={style_elN401} />
          </div>
          
          <div className="elN402">
            <div style={style_elN402} />
          </div>
          
          <div className="elN403">
            <div style={style_elN403} />
          </div>
          
          </div>
          <div className="elImage">
            <div style={style_elImage} />
          </div>
        </div>
        
        <div className="foreground">
          <div className="systemFontRegular  elFlux" style={style_elFlux}>
            <div>{value_flux !== undefined ? value_flux : (<span className="propValueMissing">{this.state.flux}</span>)}</div>
          </div>
          <Input className="baseFont elState" style={style_elState} type="number" hint="" onChange={this.textInputChanged_elState} value={value_state !== undefined ? value_state : ''}  />
          <button className="actionFont elButtonCopy" style={style_elButtonCopy} onClick={this.onClick_elButtonCopy}  />
          <button className="actionFont elButton" style={style_elButton} onClick={this.onClick_elButton}  />
        </div>
      </div>
    )
  }
  
}
