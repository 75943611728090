import React, { Component } from 'react';
import LocalizedStrings from 'react-localization';
// eslint-disable-next-line
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
// eslint-disable-next-line
import * as util from 'util';
import './App.css';
import AppareilPhotoScreen from './AppareilPhotoScreen.js';
import List_contenantsScreen from './List_contenantsScreen.js';
import ScannerAnomalieScreen from './ScannerAnomalieScreen.js';
import Quai_view_dechScreen from './Quai_view_dechScreen.js';
import MenuPrincipalScreen from './MenuPrincipalScreen.js';
import ConnexionScreen from './ConnexionScreen.js';
import TournéeDuJourScreen from './TournéeDuJourScreen.js';
import TournéeDemainScreen from './TournéeDemainScreen.js';
import ChuterUnOTScreen from './ChuterUnOTScreen.js';
import ScannerScreen from './ScannerScreen.js';
import DétailVidageScreen from './DétailVidageScreen.js';
import AnomalieScreen from './AnomalieScreen.js';
import ConfirmationAnomalieScreen from './ConfirmationAnomalieScreen.js';
import PriseEnChargeV3Screen from './PriseEnChargeV3Screen.js';
import OperationScreen from './OperationScreen.js';
import DepotV3Screen from './DepotV3Screen.js';
import Point_dechScreen from './Point_dechScreen.js';
import EchangeScreen from './EchangeScreen.js';
import Valider_simpleScreen from './Valider_simpleScreen.js';
import ChargeDuCamionScreen from './ChargeDuCamionScreen.js';
import DébutDeTournéeScreen from './DébutDeTournéeScreen.js';
import FinDeTournéeScreen from './FinDeTournéeScreen.js';
import DepotV4Screen from './DepotV4Screen.js';
import Vider_apres_rotationScreen from './Vider_apres_rotationScreen.js';
import Quai_view_dech_startScreen from './Quai_view_dech_startScreen.js';
import DataSheet_localizationSheet from './DataSheet_localizationSheet.js';
import DataSheet_auth_user from './DataSheet_auth_user.js';
import DataSheet_tournees_j from './DataSheet_tournees_j.js';
import DataSheet_tournees_j1 from './DataSheet_tournees_j1.js';
import DataSheet_id_tournee from './DataSheet_id_tournee.js';
import DataSheet_sites from './DataSheet_sites.js';
import DataSheet_matieres from './DataSheet_matieres.js';
import DataSheet_exutoires from './DataSheet_exutoires.js';
import DataSheet_operations from './DataSheet_operations.js';
import DataSheet_id_tournees_j from './DataSheet_id_tournees_j.js';
import DataSheet_static from './DataSheet_static.js';
import DataSheet_drivers from './DataSheet_drivers.js';
import DataSheet_clients from './DataSheet_clients.js';
import DataSheet_containers from './DataSheet_containers.js';
import DataSheet_trucks from './DataSheet_trucks.js';
import DataSheet_materiels from './DataSheet_materiels.js';
import DataSheet_reference_containers from './DataSheet_reference_containers.js';
import DataSheet_history_moves from './DataSheet_history_moves.js';
import DataSheet_quaiView from './DataSheet_quaiView.js';
import DataSheet_id_flux from './DataSheet_id_flux.js';
import DataSheet_quais_numbers from './DataSheet_quais_numbers.js';
import DataSheet_group_contenants from './DataSheet_group_contenants.js';
import DataSheet_short_list_quai_number from './DataSheet_short_list_quai_number.js';
import DataSheet_history_spe from './DataSheet_history_spe.js';
import DataSheet_reference_anomalie from './DataSheet_reference_anomalie.js';
import DataSheet_pictograms from './DataSheet_pictograms.js';
import DataSheet_dataBaseScans from './DataSheet_dataBaseScans.js';
import DataSheet_operations_other from './DataSheet_operations_other.js';
import DataSheet_clients_depots from './DataSheet_clients_depots.js';
import DataSheet_sectors from './DataSheet_sectors.js';
import DataSheet_quaiViewRights from './DataSheet_quaiViewRights.js';
import DataSheet_quaiViewProducers from './DataSheet_quaiViewProducers.js';
import DataSheet_entreprise from './DataSheet_entreprise.js';
import DataSheet_tournees_apercu from './DataSheet_tournees_apercu.js';
import DataSheet_apercu_modes from './DataSheet_apercu_modes.js';
import firebase from 'firebase';
import firestore from 'firebase/firestore';
import performance from 'firebase/performance';


class App extends Component {
  constructor(props) {
    super(props);

    this.dataSheets = {};
    this.dataSheets['localizationSheet'] = new DataSheet_localizationSheet('localizationSheet', this.dataSheetDidUpdate);
    this.dataSheets['auth_user'] = new DataSheet_auth_user('auth_user', this.dataSheetDidUpdate);
    this.dataSheets['tournees_j'] = new DataSheet_tournees_j('tournees_j', this.dataSheetDidUpdate);
    this.dataSheets['tournees_j1'] = new DataSheet_tournees_j1('tournees_j1', this.dataSheetDidUpdate);
    this.dataSheets['id_tournee'] = new DataSheet_id_tournee('id_tournee', this.dataSheetDidUpdate);
    this.dataSheets['sites'] = new DataSheet_sites('sites', this.dataSheetDidUpdate);
    this.dataSheets['matieres'] = new DataSheet_matieres('matieres', this.dataSheetDidUpdate);
    this.dataSheets['exutoires'] = new DataSheet_exutoires('exutoires', this.dataSheetDidUpdate);
    this.dataSheets['operations'] = new DataSheet_operations('operations', this.dataSheetDidUpdate);
    this.dataSheets['id_tournees_j'] = new DataSheet_id_tournees_j('id_tournees_j', this.dataSheetDidUpdate);
    this.dataSheets['static'] = new DataSheet_static('static', this.dataSheetDidUpdate);
    this.dataSheets['drivers'] = new DataSheet_drivers('drivers', this.dataSheetDidUpdate);
    this.dataSheets['clients'] = new DataSheet_clients('clients', this.dataSheetDidUpdate);
    this.dataSheets['containers'] = new DataSheet_containers('containers', this.dataSheetDidUpdate);
    this.dataSheets['trucks'] = new DataSheet_trucks('trucks', this.dataSheetDidUpdate);
    this.dataSheets['materiels'] = new DataSheet_materiels('materiels', this.dataSheetDidUpdate);
    this.dataSheets['reference_containers'] = new DataSheet_reference_containers('reference_containers', this.dataSheetDidUpdate);
    this.dataSheets['history_moves'] = new DataSheet_history_moves('history_moves', this.dataSheetDidUpdate);
    this.dataSheets['quaiView'] = new DataSheet_quaiView('quaiView', this.dataSheetDidUpdate);
    this.dataSheets['id_flux'] = new DataSheet_id_flux('id_flux', this.dataSheetDidUpdate);
    this.dataSheets['quais_numbers'] = new DataSheet_quais_numbers('quais_numbers', this.dataSheetDidUpdate);
    this.dataSheets['group_contenants'] = new DataSheet_group_contenants('group_contenants', this.dataSheetDidUpdate);
    this.dataSheets['short_list_quai_number'] = new DataSheet_short_list_quai_number('short_list_quai_number', this.dataSheetDidUpdate);
    this.dataSheets['history_spe'] = new DataSheet_history_spe('history_spe', this.dataSheetDidUpdate);
    this.dataSheets['reference_anomalie'] = new DataSheet_reference_anomalie('reference_anomalie', this.dataSheetDidUpdate);
    this.dataSheets['pictograms'] = new DataSheet_pictograms('pictograms', this.dataSheetDidUpdate);
    this.dataSheets['dataBaseScans'] = new DataSheet_dataBaseScans('dataBaseScans', this.dataSheetDidUpdate);
    this.dataSheets['operations_other'] = new DataSheet_operations_other('operations_other', this.dataSheetDidUpdate);
    this.dataSheets['clients_depots'] = new DataSheet_clients_depots('clients_depots', this.dataSheetDidUpdate);
    this.dataSheets['sectors'] = new DataSheet_sectors('sectors', this.dataSheetDidUpdate);
    this.dataSheets['quaiViewRights'] = new DataSheet_quaiViewRights('quaiViewRights', this.dataSheetDidUpdate);
    this.dataSheets['quaiViewProducers'] = new DataSheet_quaiViewProducers('quaiViewProducers', this.dataSheetDidUpdate);
    this.dataSheets['entreprise'] = new DataSheet_entreprise('entreprise', this.dataSheetDidUpdate);
    this.dataSheets['tournees_apercu'] = new DataSheet_tournees_apercu('tournees_apercu', this.dataSheetDidUpdate);
    this.dataSheets['apercu_modes'] = new DataSheet_apercu_modes('apercu_modes', this.dataSheetDidUpdate);
    this.dataSheetLoaded = {};

    this.dataSlots = {};
    this.dataSlots['ds_activeLang'] = "fr";
    this.dataSlots['ds_site_id'] = "null";
    this.dataSlots['ds_entreprise_id'] = "null";
    this.dataSlots['ds_username'] = "";
    this.dataSlots['ds_email'] = "";
    this.dataSlots['ds_image'] = "";
    this.dataSlots['ds_userid'] = "";
    this.dataSlots['ds_entreprise'] = "";
    this.dataSlots['ds_right_forecast'] = "";
    this.dataSlots['ds_admin'] = "";
    this.dataSlots['ds_login_success_screen'] = "connexion";
    this.dataSlots['ds_login_screen_state'] = "0";
    this.dataSlots['ds_demain_minuit_ts'] = "";
    this.dataSlots['ds_selected_tournee'] = "";
    this.dataSlots['ds_today_minuit_ts'] = "";
    this.dataSlots['ds_saved_picture'] = "";
    this.dataSlots['ds_saved_picture_document_key'] = "";
    this.dataSlots['ds_state_camera'] = "0";
    this.dataSlots['ds_site_enlevement'] = "";
    this.dataSlots['ds_matiere_enlevement'] = "";
    this.dataSlots['ds_exutoire_enlevement'] = "";
    this.dataSlots['ds_tonnage_enlevement'] = "";
    this.dataSlots['ds_commentaire'] = "";
    this.dataSlots['ds_action_enlevement'] = "";
    this.dataSlots['ds_scanned_id'] = "";
    this.dataSlots['ds_saved_picture_document_ref'] = "";
    this.dataSlots['ds_from_ot'] = "false";
    this.dataSlots['ds_current_position'] = "";
    this.dataSlots['ds_site_depot'] = "";
    this.dataSlots['ds_enable_valid'] = "true";
    this.dataSlots['ds_selected_tournee_driver'] = "";
    this.dataSlots['ds_selector_day'] = "0";
    this.dataSlots['ds_mode_di'] = "0";
    this.dataSlots['ds_client_id'] = "null";
    this.dataSlots['ds_contenant_id'] = "null";
    this.dataSlots['ds_mouvement'] = "";
    this.dataSlots['ds_selected_ot_row'] = "";
    this.dataSlots['ds_pause_page'] = "";
    this.dataSlots['ds_ref_prise_en_charge'] = "";
    this.dataSlots['ds_page_state'] = "0";
    this.dataSlots['ds_depot_hq'] = "0";
    this.dataSlots['ds_benne_vide'] = "0";
    this.dataSlots['ds_type_enlevement'] = "";
    this.dataSlots['ds_selected_command'] = "";
    this.dataSlots['ds_history_to_log'] = "";
    this.dataSlots['ds_selected_dech_client_id'] = "";
    this.dataSlots['ds_selected_dech_quai_view'] = "";
    this.dataSlots['ds_mode_point'] = "0";
    this.dataSlots['ds_selected_quai'] = "0";
    this.dataSlots['ds_selected_dech_site'] = "";
    this.dataSlots['ds_q_hq_view'] = "0";
    this.dataSlots['ds_vide_autre_view'] = "0";
    this.dataSlots['ds_tonnage'] = "";
    this.dataSlots['ds_source_anomalie'] = "";
    this.dataSlots['ds_volume'] = "";
    this.dataSlots['ds_flux'] = "";
    this.dataSlots['ds_km'] = "";
    this.dataSlots['ds_current_anomalies'] = "";
    this.dataSlots['ds_select_anomalies_visible'] = "0";
    this.dataSlots['ds_apercu_visible'] = "0";
    this.dataSlots['ds_mode_apercu'] = "0";
    this.dataSlots['ds_site_apercu'] = "";
    this.dataSlots['ds_facing_mode'] = "environment";

    this.updateLocalizationFromDataSheet(this.dataSheets['localizationSheet']);


    // Initialize web service plugin 'pointapp2'
    this.goToScreen("");
    firebase.initializeApp({
        apiKey: "AIzaSyCOmPscdkTQKyChJUcPJTDDtC56nv8Ar3k",
        authDomain: "neurowastepoint.firebaseapp.com",
        databaseURL: "https://neurowastepoint.firebaseio.com",
        projectId: "neurowastepoint",
        storageBucket: "neurowastepoint.appspot.com",
        messagingSenderId: "399083467032",
      appId: "1:399083467032:web:acca7690caac729c33ee6d",
      measurementId: "G-27S71L157N"
    });
    firebase.firestore().settings({});
    const perf = firebase.performance();
    firebase.firestore().enablePersistence()
             .catch((err) => {
                 if (err.code == 'failed-precondition') {
                     console.log('error persistence');
                 } else if (err.code == 'unimplemented') {
                     console.log('error persistence');
                 }
             });
    this.serviceOptions_auth_user = {
      dataSlots: this.dataSlots,
      servicePath: "users/",
      query: "where('uid', '==', '$slot('ds_userid')')",
    };
    this.dataSheets['auth_user'].appActions = this;
    this.dataSheets['auth_user'].firebase = firebase;
    
    this.serviceOptions_tournees_j = {
      dataSlots: this.dataSlots,
      servicePath: "entreprises/$slot('ds_entreprise_id')/tournees",
      query: "where('date', '==', $slot('ds_today_minuit_ts'))",
    };
    this.dataSheets['tournees_j'].appActions = this;
    this.dataSheets['tournees_j'].firebase = firebase;
    
    this.serviceOptions_tournees_j1 = {
      dataSlots: this.dataSlots,
      servicePath: "entreprises/$slot('ds_entreprise_id')/tournees",
      query: "where('date', '==', $slot('ds_demain_minuit_ts')).limit(0)",
    };
    this.dataSheets['tournees_j1'].appActions = this;
    this.dataSheets['tournees_j1'].firebase = firebase;
    
    this.serviceOptions_id_tournee = {
      dataSlots: this.dataSlots,
      servicePath: "entreprises/$slot('ds_entreprise_id')/id_tournees",
      query: "where('date', '==', $slot('ds_demain_minuit_ts')).orderBy('driver')",
    };
    this.dataSheets['id_tournee'].appActions = this;
    this.dataSheets['id_tournee'].firebase = firebase;
    
    this.serviceOptions_sites = {
      dataSlots: this.dataSlots,
      servicePath: "entreprises/$slot('ds_entreprise_id')/sites",
      query: "",
    };
    this.dataSheets['sites'].appActions = this;
    this.dataSheets['sites'].firebase = firebase;
    
    this.serviceOptions_matieres = {
      dataSlots: this.dataSlots,
      servicePath: "entreprises/$slot('ds_entreprise_id')/flux/",
      query: "",
    };
    this.dataSheets['matieres'].appActions = this;
    this.dataSheets['matieres'].firebase = firebase;
    
    this.serviceOptions_exutoires = {
      dataSlots: this.dataSlots,
      servicePath: "entreprises/$slot('ds_entreprise_id')/exutoires/",
      query: "",
    };
    this.dataSheets['exutoires'].appActions = this;
    this.dataSheets['exutoires'].firebase = firebase;
    
    this.serviceOptions_operations = {
      dataSlots: this.dataSlots,
      servicePath: "entreprises/$slot('ds_entreprise_id')/reference_operations",
      query: "orderBy('label')",
    };
    this.dataSheets['operations'].appActions = this;
    this.dataSheets['operations'].firebase = firebase;
    
    this.serviceOptions_id_tournees_j = {
      dataSlots: this.dataSlots,
      servicePath: "entreprises/$slot('ds_entreprise_id')/id_tournees",
      query: "where('date', '==', $slot('ds_today_minuit_ts')).orderBy('driver')",
    };
    this.dataSheets['id_tournees_j'].appActions = this;
    this.dataSheets['id_tournees_j'].firebase = firebase;
    
    this.serviceOptions_static = {
      dataSlots: this.dataSlots,
      servicePath: "entreprises/$slot('ds_entreprise_id')/static",
      query: "",
    };
    this.dataSheets['static'].appActions = this;
    this.dataSheets['static'].firebase = firebase;
    
    this.serviceOptions_drivers = {
      dataSlots: this.dataSlots,
      servicePath: "entreprises/$slot('ds_entreprise_id')/drivers",
      query: "orderBy('name')",
    };
    this.dataSheets['drivers'].appActions = this;
    this.dataSheets['drivers'].firebase = firebase;
    
    this.serviceOptions_clients = {
      dataSlots: this.dataSlots,
      servicePath: "entreprises/$slot('ds_entreprise_id')/clients",
      query: "orderBy('name')",
    };
    this.dataSheets['clients'].appActions = this;
    this.dataSheets['clients'].firebase = firebase;
    
    this.serviceOptions_containers = {
      dataSlots: this.dataSlots,
      servicePath: "entreprises/$slot('ds_entreprise_id')/clients/$slot('ds_client_id')/contenants",
      query: "orderBy('flux')",
    };
    this.dataSheets['containers'].appActions = this;
    this.dataSheets['containers'].firebase = firebase;
    
    this.serviceOptions_trucks = {
      dataSlots: this.dataSlots,
      servicePath: "entreprises/$slot('ds_entreprise_id')/trucks",
      query: "orderBy('imat')",
    };
    this.dataSheets['trucks'].appActions = this;
    this.dataSheets['trucks'].firebase = firebase;
    
    this.serviceOptions_materiels = {
      dataSlots: this.dataSlots,
      servicePath: "entreprises/$slot('ds_entreprise_id')/materiels",
      query: "orderBy('type')",
    };
    this.dataSheets['materiels'].appActions = this;
    this.dataSheets['materiels'].firebase = firebase;
    
    this.serviceOptions_reference_containers = {
      dataSlots: this.dataSlots,
      servicePath: "entreprises/$slot('ds_entreprise_id')/all_containers",
      query: "orderBy('id_scan')",
    };
    this.dataSheets['reference_containers'].appActions = this;
    this.dataSheets['reference_containers'].firebase = firebase;
    
    this.serviceOptions_history_moves = {
      dataSlots: this.dataSlots,
      servicePath: "entreprises/$slot('ds_entreprise_id')/history_moves",
      query: "limit(0)",
    };
    this.dataSheets['history_moves'].appActions = this;
    this.dataSheets['history_moves'].firebase = firebase;
    
    this.serviceOptions_quaiView = {
      dataSlots: this.dataSlots,
      servicePath: "entreprises/$slot('ds_entreprise_id')/quaiView",
      query: "where(\"place_type\", \"in\", [0,1,3]).orderBy('site')",
    };
    this.dataSheets['quaiView'].appActions = this;
    this.dataSheets['quaiView'].firebase = firebase;
    
    this.serviceOptions_id_flux = {
      dataSlots: this.dataSlots,
      servicePath: "id_flux",
      query: "orderBy('flux')",
    };
    this.dataSheets['id_flux'].appActions = this;
    this.dataSheets['id_flux'].firebase = firebase;
    
    this.serviceOptions_group_contenants = {
      dataSlots: this.dataSlots,
      servicePath: "group:contenants",
      query: "where('id_entreprise' , '==', '$slot('ds_entreprise_id')')",
    };
    this.dataSheets['group_contenants'].appActions = this;
    this.dataSheets['group_contenants'].firebase = firebase;
    
    this.serviceOptions_history_spe = {
      dataSlots: this.dataSlots,
      servicePath: "entreprises/$slot('ds_entreprise_id')/history_spe",
      query: "orderBy('ts', 'desc').limit(0)",
    };
    this.dataSheets['history_spe'].appActions = this;
    this.dataSheets['history_spe'].firebase = firebase;
    
    this.serviceOptions_reference_anomalie = {
      dataSlots: this.dataSlots,
      servicePath: "entreprises/$slot('ds_entreprise_id')/reference_anomalies",
      query: "orderBy('label')",
    };
    this.dataSheets['reference_anomalie'].appActions = this;
    this.dataSheets['reference_anomalie'].firebase = firebase;
    
    this.serviceOptions_dataBaseScans = {
      dataSlots: this.dataSlots,
      servicePath: "dataBaseScans",
      query: "limit(0)",
    };
    this.dataSheets['dataBaseScans'].appActions = this;
    this.dataSheets['dataBaseScans'].firebase = firebase;
    
    this.serviceOptions_operations_other = {
      dataSlots: this.dataSlots,
      servicePath: "entreprises/$slot('ds_entreprise_id')/reference_operations",
      query: "where('other_app', '==', true)",
    };
    this.dataSheets['operations_other'].appActions = this;
    this.dataSheets['operations_other'].firebase = firebase;
    
    this.serviceOptions_clients_depots = {
      dataSlots: this.dataSlots,
      servicePath: "entreprises/$slot('ds_entreprise_id')/clients",
      query: "where(\"place_type\", \"in\", [0,1, 3]).orderBy(\"name\")",
    };
    this.dataSheets['clients_depots'].appActions = this;
    this.dataSheets['clients_depots'].firebase = firebase;
    
    this.serviceOptions_sectors = {
      dataSlots: this.dataSlots,
      servicePath: "entreprises/$slot('ds_entreprise_id')/sectors",
      query: "where('id', '>', 0).orderBy(\"id\")",
    };
    this.dataSheets['sectors'].appActions = this;
    this.dataSheets['sectors'].firebase = firebase;
    
    this.serviceOptions_quaiViewRights = {
      dataSlots: this.dataSlots,
      servicePath: "entreprises/$slot('ds_entreprise_id')/quaiView",
      query: "where(\"place_type\", \"in\", [1,3]).orderBy('site')",
    };
    this.dataSheets['quaiViewRights'].appActions = this;
    this.dataSheets['quaiViewRights'].firebase = firebase;
    
    this.serviceOptions_quaiViewProducers = {
      dataSlots: this.dataSlots,
      servicePath: "entreprises/$slot('ds_entreprise_id')/quaiView",
      query: "where(\"place_type\", \"in\", [0]).orderBy('site')",
    };
    this.dataSheets['quaiViewProducers'].appActions = this;
    this.dataSheets['quaiViewProducers'].firebase = firebase;
    
    this.serviceOptions_entreprise = {
      dataSlots: this.dataSlots,
      servicePath: "entreprises",
      query: "doc('$slot('ds_entreprise_id')')",
    };
    this.dataSheets['entreprise'].appActions = this;
    this.dataSheets['entreprise'].firebase = firebase;
    
    this.serviceOptions_tournees_apercu = {
      dataSlots: this.dataSlots,
      servicePath: "entreprises/$slot('ds_entreprise_id')/tournees",
      query: "where('date', '==', $slot('ds_demain_minuit_ts'))",
    };
    this.dataSheets['tournees_apercu'].appActions = this;
    this.dataSheets['tournees_apercu'].firebase = firebase;
    

    this.state = {
      screenTransitionForward: true,
    }

  }

  windowDidResize = () => {
    let w = window.innerWidth;
    let formatId;
    if (w < 576) formatId = 'narrow-phone';
    else if (w < 768) formatId = 'wide-phone';
    else if (w < 1024) formatId = 'narrow-tablet';
    else formatId = 'wide-tablet';
    if (formatId !== this.state.screenFormatId) {
      this.setState({screenFormatId: formatId});
    }
  }

  componentDidMount() {
    this.windowDidResize();
    window.addEventListener('resize', this.windowDidResize);

    this.serviceOptions_id_flux.servicePath = this.dataSheets['id_flux'].expandSlotTemplateString("id_flux", this.dataSlots);
    this.loadData_pointapp2(this.dataSheets['id_flux'], this.serviceOptions_id_flux, true);
    
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowDidResize);
  }

  isLoading() {
    return this.state.loading;
  }

  goToScreen = (screenId, props) => {
    // This method is the default implementation and could be customized by a navigation plugin.
    this.props.history.push('/'+screenId, {...props, appActions: null, locStrings: null, dataSheets: null});
    window.scrollTo(0, 0);
  }

  goBack = () => {
    // This method is the default implementation and could be customized by a navigation plugin.
    this.props.history.goBack();
  }

  getDataSheet = (sheetId) => {
    // This method is the default implementation and could be customized by a state management plugin.
    return this.dataSheets[sheetId];
  }

  addToDataSheet = (sheetId, newRow, actionId) => {
    // This method is the default implementation and could be customized by a state management plugin.
    let sheet = this.dataSheets[sheetId];
    if (sheet && newRow) {
      sheet.addItem(newRow, this['serviceOptions_'+sheetId] || {});
    }
    this.setState({});
  }

  updateInDataSheet = (sheetId, row, actionId) => {
    // This method is the default implementation and could be customized by a state management plugin.
    let sheet = this.dataSheets[sheetId];
    if (sheet && row) {
      sheet.replaceItemByKey(row.key, row, this['serviceOptions_'+sheetId] || {});
      this.setState({});
    }
  }

  removeFromDataSheet = (sheetId, row) => {
    let sheet = this.dataSheets[sheetId];
    if (sheet && row) {
      sheet.removeItem(row, this['serviceOptions_'+sheetId] || {});
    }
    this.setState({});
  }

  updateDataSlot = (slotId, value, actionId) => {
    // This method is the default implementation and could be customized by a state management plugin.
    if (value === this.dataSlots[slotId])
      return;

    this.dataSlots[slotId] = value;

    if (slotId === 'ds_activeLang') {
      this.locStrings.setLanguage(value);
    }

    if (this.serviceOptions_auth_user.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['auth_user'].expandSlotTemplateString(this.serviceOptions_auth_user.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_pointapp2(this.dataSheets['auth_user'], this.serviceOptions_auth_user, true);
      }
    }
    
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['auth_user'].expandSlotTemplateString("users/", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_auth_user.servicePath = servicePath;
        this.loadData_pointapp2(this.dataSheets['auth_user'], this.serviceOptions_auth_user, true);
      }
    }
    if (this.serviceOptions_tournees_j.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['tournees_j'].expandSlotTemplateString(this.serviceOptions_tournees_j.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_pointapp2(this.dataSheets['tournees_j'], this.serviceOptions_tournees_j, true);
      }
    }
    
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['tournees_j'].expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/tournees", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_tournees_j.servicePath = servicePath;
        this.loadData_pointapp2(this.dataSheets['tournees_j'], this.serviceOptions_tournees_j, true);
      }
    }
    if (this.serviceOptions_tournees_j1.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['tournees_j1'].expandSlotTemplateString(this.serviceOptions_tournees_j1.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_pointapp2(this.dataSheets['tournees_j1'], this.serviceOptions_tournees_j1, true);
      }
    }
    
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['tournees_j1'].expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/tournees", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_tournees_j1.servicePath = servicePath;
        this.loadData_pointapp2(this.dataSheets['tournees_j1'], this.serviceOptions_tournees_j1, true);
      }
    }
    if (this.serviceOptions_id_tournee.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['id_tournee'].expandSlotTemplateString(this.serviceOptions_id_tournee.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_pointapp2(this.dataSheets['id_tournee'], this.serviceOptions_id_tournee, true);
      }
    }
    
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['id_tournee'].expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/id_tournees", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_id_tournee.servicePath = servicePath;
        this.loadData_pointapp2(this.dataSheets['id_tournee'], this.serviceOptions_id_tournee, true);
      }
    }
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['sites'].expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/sites", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_sites.servicePath = servicePath;
        this.loadData_pointapp2(this.dataSheets['sites'], this.serviceOptions_sites, true);
      }
    }
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['matieres'].expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/flux/", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_matieres.servicePath = servicePath;
        this.loadData_pointapp2(this.dataSheets['matieres'], this.serviceOptions_matieres, true);
      }
    }
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['exutoires'].expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/exutoires/", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_exutoires.servicePath = servicePath;
        this.loadData_pointapp2(this.dataSheets['exutoires'], this.serviceOptions_exutoires, true);
      }
    }
    if (this.serviceOptions_operations.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['operations'].expandSlotTemplateString(this.serviceOptions_operations.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_pointapp2(this.dataSheets['operations'], this.serviceOptions_operations, true);
      }
    }
    
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['operations'].expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/reference_operations", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_operations.servicePath = servicePath;
        this.loadData_pointapp2(this.dataSheets['operations'], this.serviceOptions_operations, true);
      }
    }
    if (this.serviceOptions_id_tournees_j.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['id_tournees_j'].expandSlotTemplateString(this.serviceOptions_id_tournees_j.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_pointapp2(this.dataSheets['id_tournees_j'], this.serviceOptions_id_tournees_j, true);
      }
    }
    
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['id_tournees_j'].expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/id_tournees", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_id_tournees_j.servicePath = servicePath;
        this.loadData_pointapp2(this.dataSheets['id_tournees_j'], this.serviceOptions_id_tournees_j, true);
      }
    }
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['static'].expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/static", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_static.servicePath = servicePath;
        this.loadData_pointapp2(this.dataSheets['static'], this.serviceOptions_static, true);
      }
    }
    if (this.serviceOptions_drivers.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['drivers'].expandSlotTemplateString(this.serviceOptions_drivers.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_pointapp2(this.dataSheets['drivers'], this.serviceOptions_drivers, true);
      }
    }
    
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['drivers'].expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/drivers", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_drivers.servicePath = servicePath;
        this.loadData_pointapp2(this.dataSheets['drivers'], this.serviceOptions_drivers, true);
      }
    }
    if (this.serviceOptions_clients.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['clients'].expandSlotTemplateString(this.serviceOptions_clients.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_pointapp2(this.dataSheets['clients'], this.serviceOptions_clients, true);
      }
    }
    
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['clients'].expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/clients", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_clients.servicePath = servicePath;
        this.loadData_pointapp2(this.dataSheets['clients'], this.serviceOptions_clients, true);
      }
    }
    if (this.serviceOptions_containers.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['containers'].expandSlotTemplateString(this.serviceOptions_containers.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_pointapp2(this.dataSheets['containers'], this.serviceOptions_containers, true);
      }
    }
    
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['containers'].expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/clients/$slot('ds_client_id')/contenants", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_containers.servicePath = servicePath;
        this.loadData_pointapp2(this.dataSheets['containers'], this.serviceOptions_containers, true);
      }
    }
    if (this.serviceOptions_trucks.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['trucks'].expandSlotTemplateString(this.serviceOptions_trucks.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_pointapp2(this.dataSheets['trucks'], this.serviceOptions_trucks, true);
      }
    }
    
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['trucks'].expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/trucks", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_trucks.servicePath = servicePath;
        this.loadData_pointapp2(this.dataSheets['trucks'], this.serviceOptions_trucks, true);
      }
    }
    if (this.serviceOptions_materiels.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['materiels'].expandSlotTemplateString(this.serviceOptions_materiels.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_pointapp2(this.dataSheets['materiels'], this.serviceOptions_materiels, true);
      }
    }
    
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['materiels'].expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/materiels", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_materiels.servicePath = servicePath;
        this.loadData_pointapp2(this.dataSheets['materiels'], this.serviceOptions_materiels, true);
      }
    }
    if (this.serviceOptions_reference_containers.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['reference_containers'].expandSlotTemplateString(this.serviceOptions_reference_containers.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_pointapp2(this.dataSheets['reference_containers'], this.serviceOptions_reference_containers, true);
      }
    }
    
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['reference_containers'].expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/all_containers", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_reference_containers.servicePath = servicePath;
        this.loadData_pointapp2(this.dataSheets['reference_containers'], this.serviceOptions_reference_containers, true);
      }
    }
    if (this.serviceOptions_history_moves.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['history_moves'].expandSlotTemplateString(this.serviceOptions_history_moves.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_pointapp2(this.dataSheets['history_moves'], this.serviceOptions_history_moves, true);
      }
    }
    
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['history_moves'].expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/history_moves", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_history_moves.servicePath = servicePath;
        this.loadData_pointapp2(this.dataSheets['history_moves'], this.serviceOptions_history_moves, true);
      }
    }
    if (this.serviceOptions_quaiView.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['quaiView'].expandSlotTemplateString(this.serviceOptions_quaiView.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_pointapp2(this.dataSheets['quaiView'], this.serviceOptions_quaiView, true);
      }
    }
    
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['quaiView'].expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/quaiView", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_quaiView.servicePath = servicePath;
        this.loadData_pointapp2(this.dataSheets['quaiView'], this.serviceOptions_quaiView, true);
      }
    }
    if (this.serviceOptions_id_flux.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['id_flux'].expandSlotTemplateString(this.serviceOptions_id_flux.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_pointapp2(this.dataSheets['id_flux'], this.serviceOptions_id_flux, true);
      }
    }
    
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['id_flux'].expandSlotTemplateString("id_flux", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_id_flux.servicePath = servicePath;
        this.loadData_pointapp2(this.dataSheets['id_flux'], this.serviceOptions_id_flux, true);
      }
    }
    if (this.serviceOptions_group_contenants.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['group_contenants'].expandSlotTemplateString(this.serviceOptions_group_contenants.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_pointapp2(this.dataSheets['group_contenants'], this.serviceOptions_group_contenants, true);
      }
    }
    
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['group_contenants'].expandSlotTemplateString("group:contenants", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_group_contenants.servicePath = servicePath;
        this.loadData_pointapp2(this.dataSheets['group_contenants'], this.serviceOptions_group_contenants, true);
      }
    }
    if (this.serviceOptions_history_spe.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['history_spe'].expandSlotTemplateString(this.serviceOptions_history_spe.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_pointapp2(this.dataSheets['history_spe'], this.serviceOptions_history_spe, true);
      }
    }
    
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['history_spe'].expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/history_spe", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_history_spe.servicePath = servicePath;
        this.loadData_pointapp2(this.dataSheets['history_spe'], this.serviceOptions_history_spe, true);
      }
    }
    if (this.serviceOptions_reference_anomalie.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['reference_anomalie'].expandSlotTemplateString(this.serviceOptions_reference_anomalie.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_pointapp2(this.dataSheets['reference_anomalie'], this.serviceOptions_reference_anomalie, true);
      }
    }
    
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['reference_anomalie'].expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/reference_anomalies", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_reference_anomalie.servicePath = servicePath;
        this.loadData_pointapp2(this.dataSheets['reference_anomalie'], this.serviceOptions_reference_anomalie, true);
      }
    }
    if (this.serviceOptions_dataBaseScans.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['dataBaseScans'].expandSlotTemplateString(this.serviceOptions_dataBaseScans.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_pointapp2(this.dataSheets['dataBaseScans'], this.serviceOptions_dataBaseScans, true);
      }
    }
    
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['dataBaseScans'].expandSlotTemplateString("dataBaseScans", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_dataBaseScans.servicePath = servicePath;
        this.loadData_pointapp2(this.dataSheets['dataBaseScans'], this.serviceOptions_dataBaseScans, true);
      }
    }
    if (this.serviceOptions_operations_other.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['operations_other'].expandSlotTemplateString(this.serviceOptions_operations_other.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_pointapp2(this.dataSheets['operations_other'], this.serviceOptions_operations_other, true);
      }
    }
    
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['operations_other'].expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/reference_operations", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_operations_other.servicePath = servicePath;
        this.loadData_pointapp2(this.dataSheets['operations_other'], this.serviceOptions_operations_other, true);
      }
    }
    if (this.serviceOptions_clients_depots.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['clients_depots'].expandSlotTemplateString(this.serviceOptions_clients_depots.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_pointapp2(this.dataSheets['clients_depots'], this.serviceOptions_clients_depots, true);
      }
    }
    
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['clients_depots'].expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/clients", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_clients_depots.servicePath = servicePath;
        this.loadData_pointapp2(this.dataSheets['clients_depots'], this.serviceOptions_clients_depots, true);
      }
    }
    if (this.serviceOptions_sectors.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['sectors'].expandSlotTemplateString(this.serviceOptions_sectors.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_pointapp2(this.dataSheets['sectors'], this.serviceOptions_sectors, true);
      }
    }
    
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['sectors'].expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/sectors", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_sectors.servicePath = servicePath;
        this.loadData_pointapp2(this.dataSheets['sectors'], this.serviceOptions_sectors, true);
      }
    }
    if (this.serviceOptions_quaiViewRights.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['quaiViewRights'].expandSlotTemplateString(this.serviceOptions_quaiViewRights.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_pointapp2(this.dataSheets['quaiViewRights'], this.serviceOptions_quaiViewRights, true);
      }
    }
    
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['quaiViewRights'].expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/quaiView", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_quaiViewRights.servicePath = servicePath;
        this.loadData_pointapp2(this.dataSheets['quaiViewRights'], this.serviceOptions_quaiViewRights, true);
      }
    }
    if (this.serviceOptions_quaiViewProducers.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['quaiViewProducers'].expandSlotTemplateString(this.serviceOptions_quaiViewProducers.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_pointapp2(this.dataSheets['quaiViewProducers'], this.serviceOptions_quaiViewProducers, true);
      }
    }
    
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['quaiViewProducers'].expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/quaiView", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_quaiViewProducers.servicePath = servicePath;
        this.loadData_pointapp2(this.dataSheets['quaiViewProducers'], this.serviceOptions_quaiViewProducers, true);
      }
    }
    if (this.serviceOptions_entreprise.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['entreprise'].expandSlotTemplateString(this.serviceOptions_entreprise.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_pointapp2(this.dataSheets['entreprise'], this.serviceOptions_entreprise, true);
      }
    }
    
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['entreprise'].expandSlotTemplateString("entreprises", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_entreprise.servicePath = servicePath;
        this.loadData_pointapp2(this.dataSheets['entreprise'], this.serviceOptions_entreprise, true);
      }
    }
    if (this.serviceOptions_tournees_apercu.query.length > 0) {
      let usedSlots = [];
      this.dataSheets['tournees_apercu'].expandSlotTemplateString(this.serviceOptions_tournees_apercu.query, {}, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.loadData_pointapp2(this.dataSheets['tournees_apercu'], this.serviceOptions_tournees_apercu, true);
      }
    }
    
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['tournees_apercu'].expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/tournees", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_tournees_apercu.servicePath = servicePath;
        this.loadData_pointapp2(this.dataSheets['tournees_apercu'], this.serviceOptions_tournees_apercu, true);
      }
    }
    this.setState({});
  }

  dataSheetDidUpdate = (dataSheet) => {
    // This method is the default implementation and could be customized by a state management plugin.
    this.setState({});
  }

  updateLocalizationFromDataSheet = (dataSheet) => {
    const stringsObj = dataSheet.getStringsByLanguage();
    if (stringsObj && Object.keys(stringsObj).length > 0) {
      this.locStrings = new LocalizedStrings(stringsObj);
    } else {
      this.locStrings = new LocalizedStrings({en: {}});
    }
    this.locStrings.setLanguage(this.dataSlots['ds_activeLang']);
  }

  loadData_pointapp2 = (dataSheet, options, firstLoad) => {
    // This method was written by data plugin 'Firebase_SMND_appli'.
    this.setState({loading: true});
    if (this.state.n_loading == undefined) { this.state.n_loading = new Set() }
    this.state.n_loading.add(dataSheet.id);
    // clear any placeholder data before load
    if (firstLoad) {
      dataSheet.items = [];
    }
    
    const fetchComplete = (err) => {
      if (err) {
        // This error handling comes from React Studio
        // and currently doesn't do anything useful.
        console.error('** Web service load failed: ', err);
      } else {
      }
      this.setState({loading: false});
    }
    
    let queryObj;
    try {
      const db = firebase.firestore();
      let isCollectionGroup = options.servicePath.startsWith("group:");
      const collection = (isCollectionGroup) ? db.collectionGroup(options.servicePath.substring(6)) : db.collection(options.servicePath);
      const query = dataSheet.expandSlotTemplateString(options.query, this.dataSlots);
    
      if (query.length < 1) {
        queryObj = collection;
      } else {
        console.log("loading firebase data for '%s' with query: %s", options.servicePath, query);
        try {
          queryObj = eval(`(function(c){ return c.${query}; })(collection)`);
        } catch (e) {
          console.log("** error creating firebase query object from '%s': ", query, e);
          this.state.n_loading.delete(dataSheet.id);
          return;
        }
      }
    }  catch (e) {
      console.log("** error creating firebase collection object from '%s': ", options.servicePath, e);
      this.state.n_loading.delete(dataSheet.id);
      return;
    }
    
    let refresh_data = (datasheet, data, options) => {
      dataSheet.loadFromJson(data);
      this.state.n_loading.delete(dataSheet.id);
      if (this.state.n_loading.size === 0) {
        fetchComplete(null, options);
      }
      if (datasheet.id == 'auth_user') {
        try {
          let newVal = datasheet.items[0].entreprise;
          this.updateDataSlot('ds_entreprise_id', newVal);
        } catch (error) {
          console.log(error);
        }
      }
    };
    
    if (dataSheet.listener) {
      dataSheet.listener();
    }
    
    const listenDs = () => queryObj.onSnapshot(
      (querySnapshot) => {
        let jsonArr = [];
    
        if (querySnapshot.docs) {
          querySnapshot.forEach((doc) => {
            const data = { ...doc.data(), document_key: doc.id, document_ref: doc.ref };
            // const data = { ...doc.data(), document_key: doc.id };
            jsonArr.push(data);
          });
        } else if (querySnapshot.data) {
          const doc = querySnapshot;
          const data = { ...doc.data(), document_key: doc.id, document_ref: doc.ref };
          // const data = { ...doc.data(), document_key: doc.id };
          jsonArr.push(data);
        }    
    
        if (dataSheet.id === 'containers') {
          jsonArr = this.dataSheets['group_contenants'].items
            .filter(it => it.id_client === this.dataSlots['ds_client_id'])
            .sort((a, b) => a.flux.localeCompare(b.flux))
        }
    
        if (dataSheet.id === 'auth_user') {
          console.log('auth_user_update', querySnapshot.metadata.fromCache, jsonArr);
          let auth_user = jsonArr[0];
          if (auth_user) {
            let profile = auth_user.profile;
            if (profile) {
              if ((!querySnapshot.metadata.fromCache && profile.app_access === false) || (querySnapshot.metadata.fromCache && profile.app_access === false && !window.navigator.onLine)) {
                alert("Utilisateur non autorisé sur cette application.")
                this.updateDataSlot('ds_entreprise_id', "null");
                this.updateDataSlot('ds_userid', "null"); 
                sessionStorage.setItem('loginStatus_connexion', 'inactive');
                firebase.auth().signOut().then(() => {
                  this.goToScreen('connexion'); 
                });
                jsonArr = [];
                dataSheet.items = [];
              }
            }
          }
        }
    
        if (dataSheet.id === 'id_tournees_j') {
          jsonArr.forEach(row => {
            if (row.ts_driver_started && row.ts_driver_stopped) {
              row.statut_display = row.driver + " -" + row.truck;
            } else if (row.ts_driver_started) {
              row.statut_display = row.driver + " - " + row.truck;
            } else if (row.disponible != false) {
              row.statut_display = row.driver + " - " + row.truck;
            } else {
              row.statut_display = row.driver + " - " + row.truck;
            }
          })
        }
    
        if (dataSheet.id === 'trucks') {
          jsonArr.forEach(row => {
            if ((row.abilities||[]).length > 0) {
              row.statut_display = row.imat + ' (' + (row.abilities||[]).join(', ') + ')';
            } else {
              row.statut_display = row.imat;
            }
          })
        }
    
        refresh_data(dataSheet, jsonArr, options);
        //dataSheet.loadFromJson(jsonArr);
        //fetchComplete(null, options);  
        console.log(dataSheet.id);
        //if (dataSheet.id == 'group_specifiques') { console.log('lalalalalalala'); console.log(dataSheet); }
      },
      (err) => {
        this.state.n_loading.delete(dataSheet.id);
        if (this.state.n_loading.size === 0) {
          fetchComplete(err, options);
        }
        setTimeout(() => {
          if (dataSheet.listener) {
            dataSheet.listener();
          }
          dataSheet.listener = listenDs();
        }, 5000);
      }); 
    
    dataSheet.listener = listenDs();
    /*
    dataSheet.listener = queryObj.onSnapshot(
      (querySnapshot) => {
        let jsonArr = [];
    
        if (querySnapshot.docs) {
          querySnapshot.forEach((doc) => {
            const data = { ...doc.data(), document_key: doc.id, document_ref: doc.ref };
            // const data = { ...doc.data(), document_key: doc.id };
            jsonArr.push(data);
          });
        } else if (querySnapshot.data) {
          const doc = querySnapshot;
          const data = { ...doc.data(), document_key: doc.id, document_ref: doc.ref };
          // const data = { ...doc.data(), document_key: doc.id };
          jsonArr.push(data);
        }    
    
        if (dataSheet.id === 'containers') {
          jsonArr = this.dataSheets['group_contenants'].items
            .filter(it => it.id_client === this.dataSlots['ds_client_id'])
            .sort((a, b) => a.flux.localeCompare(b.flux))
        }
    
        if (dataSheet.id === 'auth_user') {
          console.log('auth_user_update', querySnapshot.metadata.fromCache, jsonArr);
          let auth_user = jsonArr[0];
          if (auth_user) {
            let profile = auth_user.profile;
            if (profile) {
              if ((!querySnapshot.metadata.fromCache && profile.app_access === false) || (querySnapshot.metadata.fromCache && profile.app_access === false && !window.navigator.onLine)) {
                alert("Utilisateur non autorisé sur cette application.")
                this.updateDataSlot('ds_entreprise_id', "null");
                this.updateDataSlot('ds_userid', "null"); 
                sessionStorage.setItem('loginStatus_connexion', 'inactive');
                firebase.auth().signOut();
                jsonArr = [];
                dataSheet.items = [];
                this.goToScreen('connexion');
              }
            }
          }
        }
    
        if (dataSheet.id === 'id_tournees_j') {
          jsonArr.forEach(row => {
            if (row.ts_driver_started && row.ts_driver_stopped) {
              row.statut_display = row.driver + " -" + row.truck;
            } else if (row.ts_driver_started) {
              row.statut_display = row.driver + " - " + row.truck;
            } else if (row.disponible != false) {
              row.statut_display = row.driver + " - " + row.truck;
            } else {
              row.statut_display = row.driver + " - " + row.truck;
            }
          })
        }
    
        refresh_data(dataSheet, jsonArr, options);
        //dataSheet.loadFromJson(jsonArr);
        //fetchComplete(null, options);  
        console.log(dataSheet.id);
        //if (dataSheet.id == 'group_specifiques') { console.log('lalalalalalala'); console.log(dataSheet); }
      },
      (err) => {
        this.state.n_loading.delete(dataSheet.id);
        if (this.state.n_loading.size === 0) {
          fetchComplete(err, options);
        }
      });  
      */
    
    /*
    dbLoadingPromise.get().then((querySnapshot) => {
        let jsonArr = [];
    
        querySnapshot.forEach((doc) => {
          const data = { ...doc.data(), key: doc.id };
          jsonArr.push(data);
        });
    
        dataSheet.loadFromJson(jsonArr);
        fetchComplete(null, options);
      },
      (err) => {
        fetchComplete(err, options);
      });  
      */
    
  }

  render() {
    let makeElementForScreen = (screenId, baseProps, atTop, forward) => {
      let screenProps = {
        ...baseProps,
        atTopOfScreenStack: atTop,
        transitionForward: forward,
        appActions: this,
        dataSheets: this.dataSheets,
        locStrings: this.locStrings,
        deviceInfo: {
          screenFormatId: this.state.screenFormatId
        },
        'ds_activeLang': this.dataSlots['ds_activeLang'],
        'ds_site_id': this.dataSlots['ds_site_id'],
        'ds_entreprise_id': this.dataSlots['ds_entreprise_id'],
        'ds_username': this.dataSlots['ds_username'],
        'ds_email': this.dataSlots['ds_email'],
        'ds_image': this.dataSlots['ds_image'],
        'ds_userid': this.dataSlots['ds_userid'],
        'ds_entreprise': this.dataSlots['ds_entreprise'],
        'ds_right_forecast': this.dataSlots['ds_right_forecast'],
        'ds_admin': this.dataSlots['ds_admin'],
        'ds_login_success_screen': this.dataSlots['ds_login_success_screen'],
        'ds_login_screen_state': this.dataSlots['ds_login_screen_state'],
        'ds_demain_minuit_ts': this.dataSlots['ds_demain_minuit_ts'],
        'ds_selected_tournee': this.dataSlots['ds_selected_tournee'],
        'ds_today_minuit_ts': this.dataSlots['ds_today_minuit_ts'],
        'ds_camera': this.dataSlots['ds_camera'],
        'ds_saved_picture': this.dataSlots['ds_saved_picture'],
        'ds_saved_picture_document_key': this.dataSlots['ds_saved_picture_document_key'],
        'ds_state_camera': this.dataSlots['ds_state_camera'],
        'ds_site_enlevement': this.dataSlots['ds_site_enlevement'],
        'ds_matiere_enlevement': this.dataSlots['ds_matiere_enlevement'],
        'ds_exutoire_enlevement': this.dataSlots['ds_exutoire_enlevement'],
        'ds_tonnage_enlevement': this.dataSlots['ds_tonnage_enlevement'],
        'ds_commentaire': this.dataSlots['ds_commentaire'],
        'ds_action_enlevement': this.dataSlots['ds_action_enlevement'],
        'ds_scanned_id': this.dataSlots['ds_scanned_id'],
        'ds_saved_picture_document_ref': this.dataSlots['ds_saved_picture_document_ref'],
        'ds_from_ot': this.dataSlots['ds_from_ot'],
        'ds_current_position': this.dataSlots['ds_current_position'],
        'ds_site_depot': this.dataSlots['ds_site_depot'],
        'ds_enable_valid': this.dataSlots['ds_enable_valid'],
        'ds_selected_tournee_driver': this.dataSlots['ds_selected_tournee_driver'],
        'ds_selector_day': this.dataSlots['ds_selector_day'],
        'ds_mode_di': this.dataSlots['ds_mode_di'],
        'ds_client_id': this.dataSlots['ds_client_id'],
        'ds_contenant_id': this.dataSlots['ds_contenant_id'],
        'ds_mouvement': this.dataSlots['ds_mouvement'],
        'ds_selected_ot_row': this.dataSlots['ds_selected_ot_row'],
        'ds_pause_page': this.dataSlots['ds_pause_page'],
        'ds_ref_prise_en_charge': this.dataSlots['ds_ref_prise_en_charge'],
        'ds_page_state': this.dataSlots['ds_page_state'],
        'ds_depot_hq': this.dataSlots['ds_depot_hq'],
        'ds_benne_vide': this.dataSlots['ds_benne_vide'],
        'ds_type_enlevement': this.dataSlots['ds_type_enlevement'],
        'ds_selected_command': this.dataSlots['ds_selected_command'],
        'ds_history_to_log': this.dataSlots['ds_history_to_log'],
        'ds_selected_dech_client_id': this.dataSlots['ds_selected_dech_client_id'],
        'ds_selected_dech_quai_view': this.dataSlots['ds_selected_dech_quai_view'],
        'ds_mode_point': this.dataSlots['ds_mode_point'],
        'ds_selected_quai': this.dataSlots['ds_selected_quai'],
        'ds_selected_dech_site': this.dataSlots['ds_selected_dech_site'],
        'ds_q_hq_view': this.dataSlots['ds_q_hq_view'],
        'ds_vide_autre_view': this.dataSlots['ds_vide_autre_view'],
        'ds_tonnage': this.dataSlots['ds_tonnage'],
        'ds_source_anomalie': this.dataSlots['ds_source_anomalie'],
        'ds_volume': this.dataSlots['ds_volume'],
        'ds_flux': this.dataSlots['ds_flux'],
        'ds_km': this.dataSlots['ds_km'],
        'ds_current_anomalies': this.dataSlots['ds_current_anomalies'],
        'ds_select_anomalies_visible': this.dataSlots['ds_select_anomalies_visible'],
        'ds_apercu_visible': this.dataSlots['ds_apercu_visible'],
        'ds_mode_apercu': this.dataSlots['ds_mode_apercu'],
        'ds_site_apercu': this.dataSlots['ds_site_apercu'],
        'ds_facing_mode': this.dataSlots['ds_facing_mode'],
      };
      // A data sheet row was specified as the data source for this screen, so carry those props + 'dataSheetRow'.
      const dataSheetRow_ConnexionScreen = this.dataSheets['auth_user'].items[0];
      const screenData_ConnexionScreen = {
        ...dataSheetRow_ConnexionScreen,
        dataSheetRow: dataSheetRow_ConnexionScreen,
      }
      switch (screenId) {
        default:
          return null;
        case 'appareilPhoto':
          return (<AppareilPhotoScreen {...screenProps} />)
        case 'list_contenants':
          return (<List_contenantsScreen {...screenProps} />)
        case 'scannerAnomalie':
          return (<ScannerAnomalieScreen {...screenProps} />)
        case 'quai_view_dech':
          return (<Quai_view_dechScreen {...screenProps} />)
        case 'menuPrincipal':
          return (<MenuPrincipalScreen {...screenProps} />)
        case 'connexion':
          return (<ConnexionScreen {...screenProps} {...screenData_ConnexionScreen} />)
        case 'tournéeDuJour':
          return (<TournéeDuJourScreen {...screenProps} />)
        case 'tournéeDemain':
          return (<TournéeDemainScreen {...screenProps} />)
        case 'chuterUnOT':
          return (<ChuterUnOTScreen {...screenProps} />)
        case 'scanner':
          return (<ScannerScreen {...screenProps} />)
        case 'détailVidage':
          return (<DétailVidageScreen {...screenProps} />)
        case 'anomalie':
          return (<AnomalieScreen {...screenProps} />)
        case 'confirmationAnomalie':
          return (<ConfirmationAnomalieScreen {...screenProps} />)
        case 'priseEnChargeV3':
          return (<PriseEnChargeV3Screen {...screenProps} />)
        case 'operation':
          return (<OperationScreen {...screenProps} />)
        case 'depotV3':
          return (<DepotV3Screen {...screenProps} />)
        case 'point_dech':
          return (<Point_dechScreen {...screenProps} />)
        case 'echange':
          return (<EchangeScreen {...screenProps} />)
        case 'valider_simple':
          return (<Valider_simpleScreen {...screenProps} />)
        case 'chargeDuCamion':
          return (<ChargeDuCamionScreen {...screenProps} />)
        case 'débutDeTournée':
          return (<DébutDeTournéeScreen {...screenProps} />)
        case 'finDeTournée':
          return (<FinDeTournéeScreen {...screenProps} />)
        case 'depotV4':
          return (<DepotV4Screen {...screenProps} />)
        case 'vider_apres_rotation':
          return (<Vider_apres_rotationScreen {...screenProps} />)
        case 'quai_view_dech_start':
          return (<Quai_view_dech_startScreen {...screenProps} />)
      }
    }

    return (
      <div className="App">
        <Switch>
          <Route path="/" render={(props) => makeElementForScreen('connexion', props.location.state, true, true)} exact />
          <Route path="/appareilPhoto" render={(props) => {
            return makeElementForScreen('appareilPhoto', props.location.state, true, true);
          }} />
          <Route path="/list_contenants" render={(props) => {
            return makeElementForScreen('list_contenants', props.location.state, true, true);
          }} />
          <Route path="/scannerAnomalie" render={(props) => {
            return makeElementForScreen('scannerAnomalie', props.location.state, true, true);
          }} />
          <Route path="/quai_view_dech" render={(props) => {
            return makeElementForScreen('quai_view_dech', props.location.state, true, true);
          }} />
          <Route path="/menuPrincipal" render={(props) => {
            return makeElementForScreen('menuPrincipal', props.location.state, true, true);
          }} />
          <Route path="/connexion" render={(props) => {
            return makeElementForScreen('connexion', props.location.state, true, true);
          }} />
          <Route path="/tournéeDuJour" render={(props) => {
            return makeElementForScreen('tournéeDuJour', props.location.state, true, true);
          }} />
          <Route path="/tournéeDemain" render={(props) => {
            return makeElementForScreen('tournéeDemain', props.location.state, true, true);
          }} />
          <Route path="/chuterUnOT" render={(props) => {
            return makeElementForScreen('chuterUnOT', props.location.state, true, true);
          }} />
          <Route path="/scanner" render={(props) => {
            return makeElementForScreen('scanner', props.location.state, true, true);
          }} />
          <Route path="/détailVidage" render={(props) => {
            return makeElementForScreen('détailVidage', props.location.state, true, true);
          }} />
          <Route path="/anomalie" render={(props) => {
            return makeElementForScreen('anomalie', props.location.state, true, true);
          }} />
          <Route path="/confirmationAnomalie" render={(props) => {
            return makeElementForScreen('confirmationAnomalie', props.location.state, true, true);
          }} />
          <Route path="/priseEnChargeV3" render={(props) => {
            return makeElementForScreen('priseEnChargeV3', props.location.state, true, true);
          }} />
          <Route path="/operation" render={(props) => {
            return makeElementForScreen('operation', props.location.state, true, true);
          }} />
          <Route path="/depotV3" render={(props) => {
            return makeElementForScreen('depotV3', props.location.state, true, true);
          }} />
          <Route path="/point_dech" render={(props) => {
            return makeElementForScreen('point_dech', props.location.state, true, true);
          }} />
          <Route path="/echange" render={(props) => {
            return makeElementForScreen('echange', props.location.state, true, true);
          }} />
          <Route path="/valider_simple" render={(props) => {
            return makeElementForScreen('valider_simple', props.location.state, true, true);
          }} />
          <Route path="/chargeDuCamion" render={(props) => {
            return makeElementForScreen('chargeDuCamion', props.location.state, true, true);
          }} />
          <Route path="/débutDeTournée" render={(props) => {
            return makeElementForScreen('débutDeTournée', props.location.state, true, true);
          }} />
          <Route path="/finDeTournée" render={(props) => {
            return makeElementForScreen('finDeTournée', props.location.state, true, true);
          }} />
          <Route path="/depotV4" render={(props) => {
            return makeElementForScreen('depotV4', props.location.state, true, true);
          }} />
          <Route path="/vider_apres_rotation" render={(props) => {
            return makeElementForScreen('vider_apres_rotation', props.location.state, true, true);
          }} />
          <Route path="/quai_view_dech_start" render={(props) => {
            return makeElementForScreen('quai_view_dech_start', props.location.state, true, true);
          }} />
        </Switch>
      </div>
    );
  }
}
export default withRouter(App)
