import React, { Component } from 'react';
import './App.css';
import btn_icon_268247001 from './images/btn_icon_268247001.png';
import Apercu from './Apercu';

// UI framework component imports
import Checkbox from 'muicss/lib/react/checkbox';
import Select from 'muicss/lib/react/select';
import Option from 'muicss/lib/react/option';
import Button from 'muicss/lib/react/button';
import Appbar from 'muicss/lib/react/appbar';

export default class Point_dechScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);
    
    this.state = {
      title: (<div>Lieu de prise en charge</div>),
      title_plainText: "Lieu de prise en charge",
      checkbox_hors_quai: 'false',
      checkbox_quai: 'true',
      elPicker_dech_visible: true,
      picker_dech: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_client_id'] : ''),
      elPicker_producer_visible: false,
      picker_producer: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_client_id'] : ''),
      elOther_place_visible: true,
      notif: (<div> </div>),
      notif_plainText: " ",
    };
  }

  componentDidMount() {
    this.props.appActions.updateDataSlot('ds_selected_dech_quai_view', "");
    
    let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key == this.props.appActions.dataSlots['ds_selected_tournee']);
    if (tournee.abilities.includes("grue") && !this.state.elOther_place_visible) {
      this.setState({elOther_place_visible: true});
    } else if (!tournee.abilities.includes("grue") && this.state.elOther_place_visible) {
      this.setState({elOther_place_visible: false});
    }
    {
      let dataSheet = this.props.appActions.dataSheets['reference_containers'];
      let serviceOptions = this.props.appActions.serviceOptions_reference_containers;
      if ( !this.props.appActions.dataSheetLoaded['reference_containers']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/all_containers", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['reference_containers'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['quaiViewProducers'];
      let serviceOptions = this.props.appActions.serviceOptions_quaiViewProducers;
      if ( !this.props.appActions.dataSheetLoaded['quaiViewProducers']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/quaiView", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['quaiViewProducers'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['quaiViewRights'];
      let serviceOptions = this.props.appActions.serviceOptions_quaiViewRights;
      if ( !this.props.appActions.dataSheetLoaded['quaiViewRights']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/quaiView", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['quaiViewRights'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['quaiView'];
      let serviceOptions = this.props.appActions.serviceOptions_quaiView;
      if ( !this.props.appActions.dataSheetLoaded['quaiView']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/quaiView", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['quaiView'] = true;
      }
    }
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
    let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key == this.props.appActions.dataSlots['ds_selected_tournee']);
    if (tournee.abilities.includes("grue") && !this.state.elOther_place_visible) {
      this.setState({elOther_place_visible: true});
    } else if (!tournee.abilities.includes("grue") && this.state.elOther_place_visible) {
      this.setState({elOther_place_visible: false});
    }
  }

  checkboxChanged_elCheckbox_hors_quai = (event) => {
    this.setState({checkbox_hors_quai: (event.target.checked ? 'true' : 'false')});
    
    this.setState({
      elPicker_dech_visible: false,
      elPicker_producer_visible: true,
      picker_dech: null,
      picker_producer: null,
      
      checkbox_quai: 'false',
      checkbox_hors_quai: 'true',
    });  
    
    {
      let newVal = (event.target.checked ? 'true' : 'false');
      
      let transformValue = (input) => {
        return '';
      }
      newVal = transformValue(newVal);
      
      this.props.appActions.updateDataSlot("ds_client_id", newVal);
    }
  }
  
  checkboxChanged_elCheckbox_quai = (event) => {
    this.setState({checkbox_quai: (event.target.checked ? 'true' : 'false')});
    
    this.setState({
      elPicker_dech_visible: true,
      elPicker_producer_visible: false,
      picker_dech: null,
      picker_producer: null,
      
      checkbox_quai: 'true',
      checkbox_hors_quai: 'false',
    });  
    
    {
      let newVal = (event.target.checked ? 'true' : 'false');
      
      let transformValue = (input) => {
        return '';
      }
      newVal = transformValue(newVal);
      
      this.props.appActions.updateDataSlot("ds_client_id", newVal);
    }
  }
  
  pickerValueChanged_elPicker_dech = (event) => {
    this.setState({picker_dech: event.target.value});
    
    this.props.appActions.updateDataSlot("ds_client_id", event.target.value);
  }
  
  pickerValueChanged_elPicker_producer = (event) => {
    this.setState({picker_producer: event.target.value});
    
    this.props.appActions.updateDataSlot("ds_client_id", event.target.value);
  }
  
  onClick_elButton = (ev) => {
    let dech_quai_view = this.props.appActions.dataSheets['quaiView'].items.filter(it => {
      return it.id_client === this.props.appActions.dataSlots['ds_client_id']
    })[0];
    
    let dech_quai_view_simplified = {
      quais: dech_quai_view.quais,
      hors_quais: dech_quai_view.hors_quais,
      spe: dech_quai_view.spe,
      ts: dech_quai_view.ts,
      id_client: dech_quai_view.id_client,
      document_key: dech_quai_view.document_key,
    }
    
    this.props.appActions.updateDataSlot('ds_selected_dech_quai_view', JSON.parse(JSON.stringify(dech_quai_view_simplified)));
    this.props.appActions.updateDataSlot('ds_selected_dech_site', dech_quai_view.site);
    
    
    /// DEFAULT MODE POINT
    let rights_app = this.props.appActions.dataSheets['auth_user'].items[0].rights_app || {
      "READ": true,
      "STATES": true,
      "SCAN": true,
      "ORDER": true,
      "EMPTY": true,
      "PACKMAT": true,
      "SPE": true,
      "SHED": false,
      "OTHER": false,
      "ISSUE": false,
    };
    
    let id_right = ["READ", "STATES", "SCAN", "ORDER", "EMPTY", "PACKMAT", "SPE", "LOCKED", "LOCKED", "ISSUE"];
    let sequence = [4,5,0,1,2,3,6,7,8,9];
    let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key == this.props.appActions.dataSlots['ds_selected_tournee']);
    
    if (tournee.abilities.includes("grue")) {
      if (this.props.appActions.dataSlots['ds_selected_dech_site'] == 'Autre lieu') {
        id_right = ["LOCKED", "LOCKED", "LOCKED", "LOCKED", "LOCKED", "LOCKED", "LOCKED", "LOCKED", "OTHER", "LOCKED"];
        sequence = [8,0,1,2,3,4,5,6,7,9];
      } else {
        sequence = [7,8,4,5,0,1,2,3,6,9];
        id_right = ["READ", "STATES", "SCAN", "ORDER", "EMPTY", "PACKMAT", "SPE", "SHED", "OTHER", "ISSUE"];
      }
    }
    
    let next_mode = sequence[0];
    let cont = 0;
    while (rights_app[id_right[next_mode]] !== true && cont < 10) {
      next_mode = sequence[(sequence.findIndex(s => s==next_mode)+1)%10];
      //next_mode = (parseInt(next_mode)+1)%9;
      cont += 1;
    }
    if (cont < 10) {
      this.props.appActions.updateDataSlot('ds_mode_point', next_mode);
    } else {
      window.alert("Utilisateur non autorisé.");
      return;
    }
    
  
    let newVal = "0";
    this.props.appActions.updateDataSlot('ds_selected_quai', newVal);
  
    newVal = "";
    this.props.appActions.updateDataSlot('ds_scanned_id', newVal);
  
    newVal = "0";
    this.props.appActions.updateDataSlot('ds_q_hq_view', newVal);
  
    newVal = "tour";
    this.props.appActions.updateDataSlot('ds_source_anomalie', newVal);
  
    // Go to screen 'quai_view_dech'
    this.props.appActions.goToScreen('quai_view_dech', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elSwitch_producers = (ev) => {
    this.setState({elPicker_dech_visible: !this.state.elPicker_dech_visible});
  
    this.setState({elPicker_producer_visible: !this.state.elPicker_producer_visible});
  
  }
  
  
  onClick_elOther_place = (ev) => {
    let client_id = this.props.appActions.dataSheets['clients'].items.find(it => it.name == "Autre lieu");
    if (client_id == undefined) {
      window.alert("Le producteur 'Autre lieu' n'est pas paramétré");
      return;
    } else {
      this.props.appActions.updateDataSlot('ds_client_id', client_id.document_key);
    }
    
    let dech_quai_view_simplified = {
      quais: [],
      hors_quais: [],
      spe: [],
      ts: 0,
      id_client: client_id.document_key,
      document_key: client_id.document_key,
    }
    
    this.props.appActions.updateDataSlot('ds_selected_dech_quai_view', JSON.parse(JSON.stringify(dech_quai_view_simplified)));
    this.props.appActions.updateDataSlot('ds_selected_dech_site', "Autre lieu");
    
    /// DEFAULT MODE POINT
    let rights_app = this.props.appActions.dataSheets['auth_user'].items[0].rights_app || {
      "READ": true,
      "STATES": true,
      "SCAN": true,
      "ORDER": true,
      "EMPTY": true,
      "PACKMAT": true,
      "SPE": true,
      "SHED": false,
      "OTHER": false,
      "ISSUE": false,
    };
    
    let id_right = ["LOCKED", "LOCKED", "LOCKED", "LOCKED", "LOCKED", "LOCKED", "LOCKED", "LOCKED", "OTHER", "LOCKED"];
    let sequence = [8,0,1,2,3,4,5,6,7,9];
    
    let next_mode = sequence[0];
    let cont = 0;
    while (rights_app[id_right[next_mode]] !== true && cont < 10) {
      next_mode = sequence[(sequence.findIndex(s => s==next_mode)+1)%10];
      //next_mode = (parseInt(next_mode)+1)%9;
      cont += 1;
    }
    if (cont < 10) {
      this.props.appActions.updateDataSlot('ds_mode_point', next_mode);
    } else {
      window.alert("Utilisateur non autorisé.");
      return;
    }
  
    let newVal = "0";
    this.props.appActions.updateDataSlot('ds_selected_quai', newVal);
  
    newVal = "";
    this.props.appActions.updateDataSlot('ds_scanned_id', newVal);
  
    newVal = "0";
    this.props.appActions.updateDataSlot('ds_q_hq_view', newVal);
  
    newVal = "tour";
    this.props.appActions.updateDataSlot('ds_source_anomalie', newVal);
  
    // Go to screen 'quai_view_dech'
    this.props.appActions.goToScreen('quai_view_dech', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elAnnuler = (ev) => {
    // Go to screen 'Tournée du jour'
    this.props.appActions.goToScreen('tournéeDuJour', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elShow_apercu = (ev) => {
    let newVal = "1";
    this.props.appActions.updateDataSlot('ds_apercu_visible', newVal);
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };
    const style_elTitle = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const style_elRectangle = {
      background: 'rgba(213, 213, 213, 1.000)',
     };
    
    let checked_checkbox_hors_quai = this.state.checkbox_hors_quai;
    
    const style_elCheckbox_hors_quai = {
      pointerEvents: 'auto',
     };
    
    let checked_checkbox_quai = this.state.checkbox_quai;
    
    const style_elCheckbox_quai = {
      pointerEvents: 'auto',
     };
    
    let selection_picker_dech = this.state.picker_dech;
    // Source datasheet and selected data column for picker element 'picker_dech'
    const dataSource_picker_dech = this.props.appActions.getDataSheet('quaiViewRights');
    const valueColumnName_picker_dech = 'id_client';
    const labelColumnName_picker_dech = 'site';
    
    const style_elPicker_dech = {
      pointerEvents: 'auto',
     };
    const elPicker_dech = this.state.elPicker_dech_visible ? (
      <div className="elPicker_dech">
        <Select className="baseFont" style={style_elPicker_dech}  onChange={this.pickerValueChanged_elPicker_dech} value={selection_picker_dech} >
          {dataSource_picker_dech.items.every(item => {
            return item[valueColumnName_picker_dech] !== selection_picker_dech;
          }) ? <Option value=''/> : null}
          {dataSource_picker_dech.items.map(item => {
            const colValue = item[valueColumnName_picker_dech];
            const labelColValue = item[labelColumnName_picker_dech];
            return <Option key={item.key} value={colValue} label={labelColValue} />
          })}
        </Select>
      </div>
      
     ) : null;
    
    let selection_picker_producer = this.state.picker_producer;
    // Source datasheet and selected data column for picker element 'picker_producer'
    const dataSource_picker_producer = this.props.appActions.getDataSheet('quaiViewProducers');
    const valueColumnName_picker_producer = 'id_client';
    const labelColumnName_picker_producer = 'site';
    
    const style_elPicker_producer = {
      pointerEvents: 'auto',
     };
    const elPicker_producer = this.state.elPicker_producer_visible ? (
      <div className="elPicker_producer">
        <Select className="baseFont" style={style_elPicker_producer}  onChange={this.pickerValueChanged_elPicker_producer} value={selection_picker_producer} >
          {dataSource_picker_producer.items.every(item => {
            return item[valueColumnName_picker_producer] !== selection_picker_producer;
          }) ? <Option value=''/> : null}
          {dataSource_picker_producer.items.map(item => {
            const colValue = item[valueColumnName_picker_producer];
            const labelColValue = item[labelColumnName_picker_producer];
            return <Option key={item.key} value={colValue} label={labelColValue} />
          })}
        </Select>
      </div>
      
     ) : null;
    
    const style_elButton = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elSwitch_producers = {
      display: 'block',
      color: '#00e5d9',
      textAlign: 'center',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elSwitch_producers_outer = {
      display: 'none',
     };
    let transformPropValue_other_place = (input) => {
      let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key == input);
      return tournee.abilities.includes("grue") ? true : false;
    }
    
    const enabledValue_other_place = transformPropValue_other_place((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_tournee'] : ''));
    
    const style_elOther_place = {
      display: 'block',
      textTransform: 'none',
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#ffd478',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elOther_place = this.state.elOther_place_visible ? (
      <div className="elOther_place">
        <Button className="actionFont" style={style_elOther_place}  disabled={''+enabledValue_other_place !== 'true'} onClick={this.onClick_elOther_place} >
          Dépôt sauvage
        </Button>
      </div>
      
     ) : null;
    
    const style_elAnnuler = {
      display: 'block',
      textTransform: 'none',
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#ff7d78',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elShow_apercu = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_268247001+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_notif = (input) => {
      let entreprise = this.props.appActions.dataSheets['entreprise'].items[0]||{};
      let auth_user = this.props.appActions.dataSheets['auth_user'].items[0] || {};
      let profile = auth_user.profile || {}; 
      
      if (entreprise.pvd_can_add_to_tour && (profile.hdq === true ? !entreprise.hdq_cannot_add_to_tour : true)) {
        let commands = this.props.appActions.dataSheets['tournees_j'].items
        .filter(it => it.tournee == "" && it.statut === 0)
        .sort((a,b) => a.display_name.localeCompare(b.display_name));
        if (commands.length > 0) {
          return "<p style='color:white;border-radius:100%;background-color:#ff7e79;width:18px;height:18px;'>"+commands.length+"</p>";
        } else {
          return '';
        }
      } else {
        return '';
      }
    }
    
    const value_notif = transformPropValue_notif((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_today_minuit_ts'] : ''));
    
    const style_elNotif = {
      fontSize: 12.3,
      color: '#feffff',
      textAlign: 'center',
      pointerEvents: 'auto',
     };
    const elApercu = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_apercu_visible'] : '')) ? (
      <div className="hasNestedComps containerMinHeight elApercu">
        <Apercu visualStateIndex={(this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_mode_apercu'] : '')} ref={(el)=> this._elApercu = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    return (
      <div className="AppScreen Point_dechScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elSpacer">
            <div />
          </div>
          
          <div className="elTitle">
            <div className="headlineFont" style={style_elTitle}>
              <div>{this.state.title}</div>
            </div>
          </div>
          
          <div className="elRectangle">
            <div style={style_elRectangle} />
          </div>
          
          <div className="flowRow flowRow_Point_dechScreen_elCheckbox_hors_quai_1357987987">
          <div className="elCheckbox_hors_quai">
            <Checkbox className="baseFont" style={style_elCheckbox_hors_quai}  label="Autres lieux" onChange={this.checkboxChanged_elCheckbox_hors_quai} checked={checked_checkbox_hors_quai === 'true' || checked_checkbox_hors_quai === true || ''+checked_checkbox_hors_quai === '1'}  />
          </div>
          
          </div>
          <div className="elCheckbox_quai">
            <Checkbox className="baseFont" style={style_elCheckbox_quai}  defaultChecked label="Déchèteries" onChange={this.checkboxChanged_elCheckbox_quai} checked={checked_checkbox_quai === 'true' || checked_checkbox_quai === true || ''+checked_checkbox_quai === '1'}  />
          </div>
          
          { elPicker_dech }
          { elPicker_producer }
          <div className="elButton">
            <Button className="actionFont" style={style_elButton}  color="accent" onClick={this.onClick_elButton} >
              Valider
            </Button>
          </div>
          
          <div className="elSwitch_producers" style={style_elSwitch_producers_outer}>
            <button className="actionFont" style={style_elSwitch_producers} onClick={this.onClick_elSwitch_producers} >
              Autre lieu
            </button>
          </div>
          
          { elOther_place }
        </div>
        <Appbar className="navBar">
          <div className="title">Nouvelle prestation</div></Appbar>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <Button className="actionFont elAnnuler" style={style_elAnnuler} onClick={this.onClick_elAnnuler} >
              Annuler
            </Button>
            <button className="actionFont elShow_apercu" style={style_elShow_apercu} onClick={this.onClick_elShow_apercu}  />
            <div className="systemFontRegular  elNotif" style={style_elNotif}>
              <div><div dangerouslySetInnerHTML={{__html: value_notif}}></div></div>
            </div>
            { elApercu }
          </div>
        </div>
      </div>
    )
  }
  
}
