import React, { Component } from 'react';
import './App.css';
import request from 'request';
import btn_icon_363641683 from './images/btn_icon_363641683.png';
import Camera from './Camera';
import btn_icon_back_scanneranomalie from './images/btn_icon_back_scanneranomalie.png';

// UI framework component imports
import Button from 'muicss/lib/react/button';
import Appbar from 'muicss/lib/react/appbar';

export default class ScannerAnomalieScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);
    
    this.state = {
      elCapture_visible: true,
      elScanner_visible: true,
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elSearch_list = (ev) => {
    // Go to screen 'list_contenants'
    this.props.appActions.goToScreen('list_contenants', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elCapture = (ev) => {
    let containers_reference = this.props.appActions.dataSheets['reference_containers'].items;
    let ids_reference = containers_reference.map(c => c.id_scan.replace(/o/gi, '0').replace(/d/gi, '0').replace(/i/gi, '1').replace(/s/gi, '5').replace(/[^a-z\d]+/ig, ''))
    .sort((a, b) => b.length - a.length);
    
    let scan_one = (depth) => {
      if (depth < 5) {
        if (window.videoElement !== null) {
          let tracks = window.videoElement.srcObject.getTracks();
          tracks.forEach((track) => {
            track.applyConstraints({
              advanced: [{torch:  depth%2 === 0 ? false : true}]
            })
              .then(() => {
              return new Promise((resolve, reject) => {
                setTimeout(() => {resolve()}, 100)
              })
            })
              .catch(() => {
              return new Promise((resolve, reject) => {
                setTimeout(() => {resolve()}, 100)
              })
            })
              .finally(() => {
              console.log(depth);
    
              this._elScanner.savePicture()
              //let data = {image: this.props.appActions.dataSlots['ds_camera']};
    
              //let app = this.props.appActions.dataSheets['reference_containers'].firebase.app();
              //let functions = app.functions("europe-west1");
              //let ocr = functions.httpsCallable('getImageText');
    
              // temp
              let request_data = 
                  {
                    "requests": [
                      {
                        "image": {
                          "content": this.props.appActions.dataSlots['ds_camera'].split(',')[1],
                        },
                        "features": [
                          {
                            "type": "TEXT_DETECTION"
                          }
                        ]
                      }
                    ]
                  } 
              let query_OCR = new Promise( (resolve, reject) => {
                request.post({
                  url: "https://vision.googleapis.com/v1/images:annotate?key=AIzaSyDl_NCywJGXrCWzAeg12LrCVS6eSo0R13c",
                  json: request_data,
                }, (error, response, body) => {
                  if (error) {
                    console.log(error)
                    reject(error);
                  } else {
                    resolve(body.responses[0].textAnnotations);
                  }
                });
              })
    
              //
    
              //console.log('recognizing', new Date().getTime())
              //ocr(data).then(textAnnotations => {
              query_OCR.then(textAnnotations => {
                //console.log('Done', textAnnotations, new Date().getTime());
    
                // ocr sequence of characters
                let sequence = '';
                //textAnnotations.data.forEach(anotation => {
                textAnnotations.forEach(anotation => {
                  sequence += anotation.description;
                })
                sequence = sequence.replace(/o/gi, '0').replace(/d/gi, '0').replace(/i/gi, '1').replace(/s/gi, '5').replace(/[^a-z\d]+/ig, '');
    
                // id to recognize
                let index_found = ids_reference.findIndex(it => {
                  let exp = new RegExp(it, 'i');
                  return sequence.match(exp);
                });
                if (index_found >= 0) {
                  this.props.appActions.setState({loading: false});
                  // window.alert('Matricule reconnu : ' + ids_reference[index_found]);
    
                  let new_scan = {
                    ts: new Date().getTime(),
                    id_entreprise: this.props.appActions.dataSlots['ds_entreprise_id'],
                    image: this.props.appActions.dataSlots['ds_camera'],
                    id_scan: containers_reference[index_found]['id_scan']
                  }
                  this.props.appActions.dataSheets['dataBaseScans'].addItem(new_scan, this.props.appActions.serviceOptions_dataBaseScans);
    
                  this.props.appActions.updateDataSlot('ds_scanned_id', containers_reference[index_found]['id_scan']);
                  window.navigator.vibrate(200);
                  //this.props.appActions.goBack();
                  //this.props.appActions.updateDataSlot('ds_scanned_container', containers_reference[index_found]);
                  this.props.appActions.goToScreen('anomalie', { transitionId: 'fadeIn' });
                } else {
                  return scan_one(depth+1);
                  //this.props.appActions.setState({loading: false});
                  //window.alert('Matricule non reconnu : réessayer ou lancer une recherche manuelle.');
                }
    
              }).catch(error => {
                return scan_one(depth+1);
                //this.props.appActions.setState({loading: false});
                //window.alert("Une erreur s'est produite");
              })
            })
          })
        }
      } else {
        this.props.appActions.setState({loading: false});
        window.navigator.vibrate(200);
        // window.alert('Matricule non reconnu, recherche manuelle.');
        this.props.appActions.goToScreen('list_contenants', { transitionId: 'fadeIn' });
        return;
      }
    }
    
    try {
      this.props.appActions.setState({loading: true});
      scan_one(0);
    } catch (error) {
      this.props.appActions.setState({loading: false});
      window.navigator.vibrate(200);
      // window.alert("Une erreur s'est produite, recherche manuelle.");
      this.props.appActions.goToScreen('list_contenants', { transitionId: 'fadeIn' });
    }
    
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    let transformFillColorPropValue_background = (input) => {
      // This function modifies the value for property 'fillColor'.
      // There is a variable named 'input' that provides the property value.
      //
      return this.state.elScanner_visible ? "black" : "#f6f6f6";
    }
    const fillColorValue_background = transformFillColorPropValue_background((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_tournee_driver'] : ''));
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: (fillColorValue_background && fillColorValue_background.length > 0) ? fillColorValue_background : 'black',
     };
    
    const style_elSearch_list = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_363641683+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '98.150%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elCapture = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elCapture = this.state.elCapture_visible ? (
      <Button className="actionFont elCapture" style={style_elCapture}  color="primary" onClick={this.onClick_elCapture} >
        Scanner
      </Button>
     ) : null;
    const style_elScanner = {
      pointerEvents: 'auto',
     };
    const elScanner = this.state.elScanner_visible ? (
      <div className="elScanner" style={style_elScanner}>
        <Camera ref={(el)=> this._elScanner = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    let cssClass_progress = 'circularProgressIndicator';
    if (this.props.appActions.isLoading()) 
      cssClass_progress += ' circularProgressIndicator-active';
    
    
    return (
      <div className="AppScreen ScannerAnomalieScreen" style={baseStyle}>
        <div className="background">
          <div className="elBackground" style={style_elBackground_outer}>
            <div style={style_elBackground} />
          </div>
        </div>
        <Appbar className="navBar">
          <div className="title">Anomalie</div>  <div className="backBtn" onClick={ (ev)=>{ this.props.appActions.goBack() } }><img src={btn_icon_back_scanneranomalie} alt="" style={{width: '50%'}} /></div>
        </Appbar>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <button className="actionFont elSearch_list" style={style_elSearch_list} onClick={this.onClick_elSearch_list}  />
            { elCapture }
            { elScanner }
            <div className="elProgress">
              <svg className={cssClass_progress}>
                <circle className="circularProgress-animatedPath" style={{stroke: '#00e5d9'}} cx="25" cy="25" r="17" fill="none" strokeWidth="3" strokeMiterlimit="10" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    )
  }
  
}
