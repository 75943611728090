import React, { Component } from 'react';
import './App.css';
import img_elImage_back from './images/Image_fill_elImage_back_1322448678.png';
import img_elImage_front from './images/Image_fill_elImage_back_1322448678.png';

export default class Image_fill extends Component {

  // Properties used by this component:
  // state, type, color

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
    document.querySelectorAll(".embeddedContent.elFill>div").forEach(el => el.style.height="100%");
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  render() {
    let transformPropValue_image_back = (input) => {
      let getPictogramById = (id) => {
        return this.props.appActions.dataSheets['pictograms'].items.find(it => it.document_key == id);
      }
      
      if (this.props.state > 0) {
        return (getPictogramById(this.props.type + "_BACK")||getPictogramById("30_BACK")).base64;
      } else {
        return "";
      }
    }
    const style_elImage_back = {
      backgroundImage: 'url('+(transformPropValue_image_back(this.props.state) || img_elImage_back)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    let transformPropValue_fill = (input) => {
      let getPictogramById = (id) => {
        return this.props.appActions.dataSheets['pictograms'].items.find(it => it.document_key == id);
      }
      
      if (this.props.state > 1) {
        let pictogram = (getPictogramById(this.props.type + "_" + this.props.state + "_FILL") || getPictogramById("30_5_FILL")).base64;
        let pictograms = pictogram.split('style=""');
        return pictograms[0] + 'style="fill:' + (this.props.color||"#000000") + '"' + pictograms[1];
      }
    }
    
    // Embedded HTML content for element 'fill'
    const htmlContent_fill = transformPropValue_fill(this.props.color);
    
    const style_elFill = {
      pointerEvents: 'auto',
     };
    let transformPropValue_image_front = (input) => {
      let getPictogramById = (id) => {
        return this.props.appActions.dataSheets['pictograms'].items.find(it => it.document_key == id);
      }
      
      if (this.props.state > 0) {
        return (getPictogramById(this.props.type + "_FRONT") || getPictogramById("30_FRONT")).base64;
      } else {
        return getPictogramById("_0_").base64;
      }
    }
    const style_elImage_front = {
      backgroundImage: 'url('+(transformPropValue_image_front(this.props.type) || img_elImage_front)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    
    return (
      <div className="Image_fill">
        <div className="foreground">
          <div className="containerMinHeight elImage_back" style={style_elImage_back} />
          <div className="embeddedContent containerMinHeight elFill" style={style_elFill}>
            <div dangerouslySetInnerHTML={{__html: htmlContent_fill}}></div>
          </div>
          <div className="containerMinHeight elImage_front" style={style_elImage_front} />
        </div>
      </div>
    )
  }
  
}
