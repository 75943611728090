import React, { Component } from 'react';
import './App.css';

// UI framework component imports
import Checkbox from 'muicss/lib/react/checkbox';

export default class Anomalie_cat extends Component {

  // Properties used by this component:
  // label, checked, handleClickCat, isLeaf, display

  constructor(props) {
    super(props);
    
    this.state = {
      elLabel_visible: !this.props.isLeaf,
      elCheckbox_visible: this.props.isLeaf,
      checkbox: this.props.checked,
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
	if (this.props.isLeaf && this.state.elLabel_visible) {
      this.setState({
        elLabel_visible: false,
        elCheckbox_visible: true,
        checkbox: this.props.checked,
      });
    } else if (!this.props.isLeaf && !this.state.elLabel_visible) {
      this.setState({
        elLabel_visible: true,
        elCheckbox_visible: false,
        checkbox: this.props.checked,
      });
    }
  }

  onClick_elLabel = (ev) => {
    if (this.props.isLeaf) {
    } else {
      this.props.handleClickCat(this.props.label);
    }
  
  }
  
  
  checkboxChanged_elCheckbox = (event) => {
    this.setState({checkbox: (event.target.checked ? 'true' : 'false')});
  }
  
  render() {
    
    const style_elRectangle = {
      background: 'rgba(213, 213, 213, 1.000)',
     };
    let transformColorPropValue_label = (input) => {
      return "#00000080";
    }
    
    const value_label = this.props.display;
    
    const style_elLabel = {
      color: transformColorPropValue_label(this.props.isLeaf),
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elLabel = this.state.elLabel_visible ? (
      <div className="elLabel">
        <div className="baseFont" style={style_elLabel} onClick={this.onClick_elLabel} >
          <div>{value_label !== undefined ? value_label : (<span className="propValueMissing">{this.state.label}</span>)}</div>
        </div>
      </div>
      
     ) : null;
    
    let checked_checkbox = this.state.checkbox;
    
    const style_elCheckbox = {
      color: 'rgba(0, 0, 0, 0.5000)',
      pointerEvents: 'auto',
     };
    const elCheckbox = this.state.elCheckbox_visible ? (
      <div className="elCheckbox">
        <Checkbox className="baseFont" style={style_elCheckbox}  label={this.props.label} onChange={this.checkboxChanged_elCheckbox} checked={checked_checkbox === 'true' || checked_checkbox === true || ''+checked_checkbox === '1'}  />
      </div>
      
     ) : null;
    
    return (
      <div className="Anomalie_cat">
        <div className="background">
          <div className="elRectangle" style={style_elRectangle} />
        </div>
        
        <div className="layoutFlow">
          <div className="elSpacer">
            <div />
          </div>
          
          { elLabel }
          { elCheckbox }
          <div className="elSpacerCopy">
            <div />
          </div>
        </div>
        
      </div>
    )
  }
  
}
