import React, { Component } from 'react';
import './App.css';

export default class Item_immatriculation_anomalie extends Component {

  // Properties used by this component:
  // dataSheetRow

  constructor(props) {
    super(props);
    
    this.state = {
      text: (<div>undefined - undefined</div>),
      text_plainText: "undefined - undefined",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elText = (ev) => {
    this.props.appActions.updateDataSlot('ds_scanned_id', this.props.dataSheetRow.id_scan);
    //this.props.appActions.goBack();
    this.props.appActions.goToScreen('anomalie', { transitionId: 'fadeIn' });
  
  }
  
  
  render() {
    let transformPropValue_text = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return input.id_scan + " - " + input.type;
    }
    
    const value_text = transformPropValue_text(this.props.dataSheetRow);
    
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    // eslint-disable-next-line no-unused-vars
    const style_elText_inner = {
      textAlign: 'left',
     };
    
    return (
      <div className="Item_immatriculation_anomalie">
        <div className="foreground">
          <div className="baseFont containerMinHeight elText" style={style_elText} onClick={this.onClick_elText} >
            <div className="bottomAlignedContent">{value_text !== undefined ? value_text : (<span className="propValueMissing">{this.state.text}</span>)}</div>
          </div>
        </div>
      </div>
    )
  }
  
}
