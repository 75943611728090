import React, { Component } from 'react';
import './App.css';
import Image_fill from './Image_fill';

export default class Vider_apres_rotationScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);
    
    this.state = {
      text: (<div>Vider le contenant échangé?</div>),
      text_plainText: "Vider le contenant échangé?",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elYes_here = (ev) => {
    let attribute = true;
    
    let dech_quai_view = this.props.appActions.dataSheets['quaiView'].items.filter(it => {
      return it.id_client === this.props.appActions.dataSlots['ds_client_id'];
    })[0];
    
    let ot_row = this.props.appActions.dataSlots['ds_selected_command'];
    
    let destination_tour = this.props.appActions.dataSheets['tournees_j'].items.filter(it => it.tournee === ot_row.tournee);
    destination_tour.sort((a, b) => b.position - a.position);
    let max_position = (destination_tour[0]||{}).position||0;
    
    let date_ref = new Date(ot_row.date);
    
    let ts = new Date();
    ts =  ts.getTime();
    
    let keys_to_keep = [
      'abilities',
      'action',
      'adr',
      'client',
      'container_id',
      'coordinates',
      'default_action',
      'display_name',
      'duration',
      'exutoire',
      'flux',
      'heavy',
      'heure',
      'hh',
      'id_client',
      'id_scan',
      'ouverture',
      'packmat',
      'remark',
      'sector',
      'site',
      'sort_key',
      'state',
      'type',
      'zfe',      
    ];
    let modifications = {
      action: ot_row.default_action,
      default_action: ot_row.default_action,
      date: this.props.appActions.dataSlots['ds_today_minuit_ts'],
      display_name: dech_quai_view.site,
      driver: attribute ? ot_row.driver : "",
      driver_id: attribute ? ot_row.driver_id : "",
      etat: 'differe',
      hh: "HQ",
      photo: "",
      position: attribute ? (max_position+1) : 0,
      quai: 0,
      report: ot_row.report,
      reference_diff: ot_row.document_key + " (" + date_ref.toLocaleDateString("FR-fr") + ")",
      rotation_details: ts + "_" + this.props.appActions.dataSlots['ds_username'],
      site: dech_quai_view.id_client,
      statut : 0,
      tonnage: 0,
      tournee: attribute ? ot_row.tournee : "",
      truck: attribute ? ot_row.truck : "",
      truck_id: attribute ? ot_row.truck_id : "",
      ts: ts,
      user_id: this.props.appActions.dataSlots['ds_userid'],
      user_name: this.props.appActions.dataSlots['ds_username'],
    };
    
    let newOtPlanning = {...ot_row};
    Object.keys(newOtPlanning).forEach(k => {
      if (!keys_to_keep.includes(k)) {
        delete newOtPlanning[k];
      }
    })
    newOtPlanning = {...newOtPlanning, ...modifications};
    
    let tournees_collection = this.props.appActions.dataSheets['tournees_j'].firebase.firestore().collection("entreprises/"+this.props.appActions.dataSlots['ds_entreprise_id']+"/tournees/");
    tournees_collection.add(newOtPlanning).then(docRef => {
      console.log('Order success');
    })
      .catch(e => {
      console.log('ERROR : ', e);
    })
    
    
    window.alert("La commande de vidage a été créée et ajoutée à votre tournée.");
    
    // GOTO screen tournée
    this.props.appActions.goToScreen('tournéeDuJour', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elNo = (ev) => {
    let attribute = false;
    
    let dech_quai_view = this.props.appActions.dataSheets['quaiView'].items.filter(it => {
      return it.id_client === this.props.appActions.dataSlots['ds_client_id'];
    })[0];
    
    let ot_row = this.props.appActions.dataSlots['ds_selected_command'];
    
    let destination_tour = this.props.appActions.dataSheets['tournees_j'].items.filter(it => it.tournee === ot_row.tournee);
    destination_tour.sort((a, b) => b.position - a.position);
    let max_position = (destination_tour[0]||{}).position||0;
    
    let date_ref = new Date(ot_row.date);
    
    let ts = new Date();
    ts =  ts.getTime();
    
    let keys_to_keep = [
      'abilities',
      'action',
      'adr',
      'client',
      'container_id',
      'coordinates',
      'default_action',
      'display_name',
      'duration',
      'exutoire',
      'flux',
      'heavy',
      'heure',
      'hh',
      'id_client',
      'id_scan',
      'ouverture',
      'packmat',
      'remark',
      'sector',
      'site',
      'sort_key',
      'state',
      'type',
      'zfe',      
    ];
    let modifications = {
      action: ot_row.default_action,
      default_action: ot_row.default_action,
      date: this.props.appActions.dataSlots['ds_today_minuit_ts'],
      display_name: dech_quai_view.site,
      driver: attribute ? ot_row.driver : "",
      driver_id: attribute ? ot_row.driver_id : "",
      etat: 'differe',
      hh: "HQ",
      photo: "",
      position: attribute ? (max_position+1) : 0,
      quai: 0,
      report: ot_row.report,
      reference_diff: ot_row.document_key + " (" + date_ref.toLocaleDateString("FR-fr") + ")",
      rotation_details: ts + "_" + this.props.appActions.dataSlots['ds_username'],
      site: dech_quai_view.id_client,
      statut : 0,
      tonnage: 0,
      tournee: attribute ? ot_row.tournee : "",
      truck: attribute ? ot_row.truck : "",
      truck_id: attribute ? ot_row.truck_id : "",
      ts: ts,
      user_id: this.props.appActions.dataSlots['ds_userid'],
      user_name: this.props.appActions.dataSlots['ds_username'],
    };
    
    let newOtPlanning = {...ot_row};
    Object.keys(newOtPlanning).forEach(k => {
      if (!keys_to_keep.includes(k)) {
        delete newOtPlanning[k];
      }
    })
    newOtPlanning = {...newOtPlanning, ...modifications};
    
    let tournees_collection = this.props.appActions.dataSheets['tournees_j'].firebase.firestore().collection("entreprises/"+this.props.appActions.dataSlots['ds_entreprise_id']+"/tournees/");
    tournees_collection.add(newOtPlanning).then(docRef => {
      console.log('Order success');
    })
      .catch(e => {
      console.log('ERROR : ', e);
    })
    
    
    // window.alert("La commande de vidage a été créée et ajoutée à votre tournée.");
    
    // GOTO screen tournée
    this.props.appActions.goToScreen('tournéeDuJour', { transitionId: 'fadeIn' });
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const style_elRectangle = {
      background: 'rgba(213, 213, 213, 1.000)',
     };
    
    let transformValue_state_elImage_fill_full = (input) => {
      return input.state || 5;
    
    }
    
    let transformValue_type_elImage_fill_full = (input) => {
      let getContenantById = (id) => {
        return this.props.appActions.dataSheets['group_contenants'].items.find(it => it.document_key == id);
      }
      
      let getFluxByMatiere = (matiere) => {
        return this.props.appActions.dataSheets['matieres'].items.find(it => it.matiere == matiere);
      }
      
      let getMaterialByType = (type) => {
        return this.props.appActions.dataSheets['materiels'].items.find(it => it.type == type);
      }
      
      if (input.container_id == "null") {
        let type = (getMaterialByType(input.flux)||{}).code_shape||"30";
        return type;
      } else {
        let contenant = getContenantById(input.container_id) || {};
        let type = (getMaterialByType(contenant.type)||{}).code_shape||"30";
        return type;
      }
    }
    
    let transformValue_color_elImage_fill_full = (input) => {
      let getContenantById = (id) => {
        return this.props.appActions.dataSheets['group_contenants'].items.find(it => it.document_key == id);
      }
      
      let getFluxByMatiere = (matiere) => {
        return this.props.appActions.dataSheets['matieres'].items.find(it => it.matiere == matiere);
      }
      
      let getMaterialByType = (type) => {
        return this.props.appActions.dataSheets['materiels'].items.find(it => it.type == type);
      }
      
      return (getFluxByMatiere(input.flux)||{}).color || "#000000";
    }
    
    const style_elYes_here = {
      display: 'block',
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'center',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elNo = {
      display: 'block',
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'center',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen Vider_apres_rotationScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elSpacer">
            <div />
          </div>
          
          <div className="elText">
            <div className="headlineFont" style={style_elText}>
              <div>{this.state.text}</div>
            </div>
          </div>
          
          <div className="elRectangle">
            <div style={style_elRectangle} />
          </div>
          
          <div className="hasNestedComps elImage_fill_full">
            <div>
              <Image_fill state={transformValue_state_elImage_fill_full((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_command'] : ''))} type={transformValue_type_elImage_fill_full((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_command'] : ''))} color={transformValue_color_elImage_fill_full((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_command'] : ''))} ref={(el)=> this._elImage_fill_full = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
          
          <div className="elYes_here">
            <button className="actionFont" style={style_elYes_here} onClick={this.onClick_elYes_here} >
              OUI
            </button>
          </div>
          
          <div className="elNo">
            <button className="actionFont" style={style_elNo} onClick={this.onClick_elNo} >
              NON, STOCKAGE HORS QUAI
            </button>
          </div>
        </div>
        
      </div>
    )
  }
  
}
