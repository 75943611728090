import React, { Component } from 'react';
import './App.css';
import Picker_depot3 from './Picker_depot3';
import btn_icon_1298792923 from './images/btn_icon_1298792923.png';
import Apercu from './Apercu';
import btn_icon_back_depotv4 from './images/btn_icon_back_depotv4.png';

// UI framework component imports
import Button from 'muicss/lib/react/button';
import Checkbox from 'muicss/lib/react/checkbox';
import Select from 'muicss/lib/react/select';
import Option from 'muicss/lib/react/option';
import Appbar from 'muicss/lib/react/appbar';

export default class DepotV4Screen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);
    
    this.state = {
      elList_visible: false,
      elValider_visible: true,
      elCheckbox_quai_visible: true,
      checkbox_quai: 'false',
      elCheckbox_hors_quai_visible: true,
      checkbox_hors_quai: 'true',
      elPicker_producer_visible: false,
      picker_producer: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_client_id'] : ''),
      elPicker_dech_visible: true,
      picker_dech: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_client_id'] : ''),
      checkbox_dech: 'true',
      checkbox_autres: 'false',
      text: (<div>Lieu de dépot</div>),
      text_plainText: "Lieu de dépot",
    };
  }

  componentDidMount() {
    {
      let dataSheet = this.props.appActions.dataSheets['reference_containers'];
      let serviceOptions = this.props.appActions.serviceOptions_reference_containers;
      if ( !this.props.appActions.dataSheetLoaded['reference_containers']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/all_containers", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['reference_containers'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['quaiViewProducers'];
      let serviceOptions = this.props.appActions.serviceOptions_quaiViewProducers;
      if ( !this.props.appActions.dataSheetLoaded['quaiViewProducers']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/quaiView", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['quaiViewProducers'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['quaiView'];
      let serviceOptions = this.props.appActions.serviceOptions_quaiView;
      if ( !this.props.appActions.dataSheetLoaded['quaiView']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/quaiView", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['quaiView'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['quaiViewRights'];
      let serviceOptions = this.props.appActions.serviceOptions_quaiViewRights;
      if ( !this.props.appActions.dataSheetLoaded['quaiViewRights']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/quaiView", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['quaiViewRights'] = true;
      }
    }
    let new_state = {};
    // hors quai available
    let client = this.props.appActions.dataSheets['clients_depots'].items.find(it => it.document_key == (this.state.picker_dech || this.state.picker_producer))||{};
    if (client.place_type == 1 || client.place_type == 0) {
      new_state = {
        elCheckbox_hors_quai_visible: true,
        elCheckbox_quai_visible: true,
        checkbox_hors_quai: "true",
        checkbox_quai: "false",
        elList_visible: false,
        elValider_visible: true,
      }
    } else if (client.place_type == 3) {
      new_state = {
        elCheckbox_hors_quai_visible: true,
        checkbox_hors_quai: "true",
        elCheckbox_quai_visible: false,
        checkbox_quai: "false",
        elList_visible: false,
      	elValider_visible: true,
      }
    } else {
      new_state = {
        elCheckbox_hors_quai_visible: false,
        checkbox_hors_quai: "true",
        elCheckbox_quai_visible: false,
        checkbox_quai: "false",
        elList_visible: false,
      	elValider_visible: false,
      }
    }
    if (Object.keys(new_state).some(k => this.state[k] !== new_state[k])) {
      this.setState(new_state);
    };
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
    let new_state = {};
    // hors quai available
    let client = this.props.appActions.dataSheets['clients_depots'].items.find(it => it.document_key == (this.state.picker_dech || this.state.picker_producer))||{};
    if (client.place_type == 1 || client.place_type == 0) {
      new_state = {
        elCheckbox_hors_quai_visible: true,
        elCheckbox_quai_visible: true,
      }
      if (this.state.checkbox_quai == "true" || this.state.checkbox_quai == true) {
        new_state.elList_visible = true;
        new_state.elValider_visible = false;
      } else if (this.state.checkbox_hors_quai == "true" || this.state.checkbox_hors_quai == true) {
        new_state.elList_visible = false;
        new_state.elValider_visible = true;
      } 
    } else if (client.place_type == 3) {
      new_state = {
        elCheckbox_hors_quai_visible: true,
        checkbox_hors_quai: "true",
        elCheckbox_quai_visible: false,
        checkbox_quai: "false",
        elList_visible: false,
      	elValider_visible: true,
      }
    } else {
      new_state = {
        elCheckbox_hors_quai_visible: false,
        checkbox_hors_quai: "true",
        elCheckbox_quai_visible: false,
        checkbox_quai: "false",
        elList_visible: false,
        elValider_visible: false,
      }
    }
    if (Object.keys(new_state).some(k => this.state[k] !== new_state[k])) {
      this.setState(new_state);
    };
  }

  onClick_elValider = (ev) => {
    let a = window.confirm("Déposer hors quai?")
    if (!a) {
      return;
    }
    
    let getContenantById = (id) => {
      return this.props.appActions.dataSheets['group_contenants'].items.find(it => it.document_key == id);
    }
    
    let getFluxByMatiere = (matiere) => {
      return this.props.appActions.dataSheets['matieres'].items.find(it => it.matiere == matiere);
    }
    
    let getMaterialByType = (type) => {
      return this.props.appActions.dataSheets['materiels'].items.find(it => it.type == type);
    }
    
    let ts = new Date();
    ts =  ts.getTime();
    
    let client_depot = this.props.appActions.dataSheets['clients'].items.filter(it => it.document_key == (this.state.picker_dech || this.state.picker_producer))[0] || {};
    
    let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key == this.props.appActions.dataSlots['ds_selected_tournee']);
    if (tournee.loaded == true && tournee.loaded_is_full==false && tournee.load_detail) {
      // continue
    } else {
      return;
    }
    
    let logHistoryMove = () => {
      let update = {
        coordinates: {
          lat: window.previousPos.coords.latitude, 
          lng: window.previousPos.coords.longitude,
        },
        flux: tournee.load_detail.type,
        hq: true,
        id_scan: tournee.load_detail.id_scan,
        id_client: (this.state.picker_dech || this.state.picker_producer),
        id_contenant: "VIDE",
        move_type: "DEPOT",
        quai: 0,
        reference_ot: "",
        site: client_depot.name,
        tournee: this.props.appActions.dataSlots['ds_selected_tournee'],
        ts: ts,
        type: tournee.load_detail.type,
        user_id: this.props.appActions.dataSlots['ds_userid'],
        user_name: this.props.appActions.dataSlots['ds_username'],
        vide: true,
      };
      this.props.appActions.dataSheets['history_moves'].addItem(update, this.props.appActions.serviceOptions_history_moves);
    }
    
    logHistoryMove();
    
    /////////////////////////// UPDATE TRUCK LOAD /////////////////
    try {
      let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(t => t.document_key == this.props.appActions.dataSlots['ds_selected_tournee']);
      let update = {
        document_key: tournee.document_key,
        document_ref: tournee.document_ref,
        loaded: false,
        loaded_is_full: false,
        load_detail: {},
      };
      this.props.appActions.dataSheets['id_tournees_j'].replaceItemByRowIndex(tournee.document_key, update, this.props.appActions.serviceOptions_id_tournees_j);
    } catch (e) {
      console.log(e);
    }
    
    // UPDATE QUAI VIEW (POSE HORS QUAI)
    let dech_quai_view = this.props.appActions.dataSheets['quaiView'].items.find(it => {
      return it.id_client === this.props.appActions.dataSlots['ds_client_id'];
    });
    
    let new_hq = {
      id_scan: tournee.load_detail.id_scan,
      id_client: this.props.appActions.dataSlots['ds_client_id'],
      id_contenant: "VIDE",
      flux: tournee.load_detail.type,
      packmat: 0,
      state: 1,
      type: tournee.load_detail.type,
      id_tournee: this.props.appActions.dataSlots['ds_selected_tournee'],
    };
    dech_quai_view.hors_quais.push(new_hq);
    
    let update = {
      document_key: dech_quai_view.document_key,
      document_ref: dech_quai_view.document_ref,
      hors_quais: dech_quai_view.hors_quais,
      ts: new Date().getTime()
    };
    this.props.appActions.dataSheets['quaiView'].replaceItemByRowIndex(update.document_key, update, this.props.appActions.serviceOptions_quaiView);
    
  
    // Go to screen 'Tournée du jour'
    this.props.appActions.goToScreen('tournéeDuJour', { transitionId: 'fadeIn' });
  
  }
  
  
  checkboxChanged_elCheckbox_quai = (event) => {
    this.setState({checkbox_quai: (event.target.checked ? 'true' : 'false')});
    
    this.setState({
      //elList_visible: event.target.checked,
      //elValider_visible: !event.target.checked,
      
      checkbox_quai: "true",
      checkbox_hors_quai: "false",
    });  
  }
  
  checkboxChanged_elCheckbox_hors_quai = (event) => {
    this.setState({checkbox_hors_quai: (event.target.checked ? 'true' : 'false')});
    
    this.setState({
      //elList_visible: !event.target.checked,
      //elValider_visible: event.target.checked,
      
      checkbox_quai: "false",
      checkbox_hors_quai: "true",
    });  
  }
  
  pickerValueChanged_elPicker_producer = (event) => {
    this.setState({picker_producer: event.target.value});
    
    this.props.appActions.updateDataSlot("ds_client_id", event.target.value);
  }
  
  pickerValueChanged_elPicker_dech = (event) => {
    this.setState({picker_dech: event.target.value});
    
    this.props.appActions.updateDataSlot("ds_client_id", event.target.value);
  }
  
  checkboxChanged_elCheckbox_dech = (event) => {
    this.setState({checkbox_dech: (event.target.checked ? 'true' : 'false')});
    
    this.setState({
      elPicker_dech_visible: true,
      elPicker_producer_visible: false,
      picker_dech: null,
      picker_producer: null,
      
      checkbox_dech: 'true',
      checkbox_autres: 'false',
    });  
  }
  
  checkboxChanged_elCheckbox_autres = (event) => {
    this.setState({checkbox_autres: (event.target.checked ? 'true' : 'false')});
    
    this.setState({
      elPicker_dech_visible: false,
      elPicker_producer_visible: true,
      picker_dech: null,
      picker_producer: null,
      
      checkbox_dech: 'false',
      checkbox_autres: 'true',
    });  
  }
  
  onClick_elShow_apercu = (ev) => {
    let newVal = "1";
    this.props.appActions.updateDataSlot('ds_apercu_visible', newVal);
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };
    let transformPropValue_list = (input) => {
      // This function modifies the value for property 'itemsArray'.
      // There is a variable named 'input' that provides the property value.
      //
      let quaiView = this.props.appActions.dataSheets['quaiView'].items.filter(it => it.id_client === input)[0] || {};
      return (quaiView.quais || []).map((it, index) => {return {...it, quai: index, key: (quaiView.document_key + index)}}).slice(1);
    }
    
    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    if (Array.isArray(transformPropValue_list((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_client_id'] : '')))) {
        items_list = items_list.concat(transformPropValue_list((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_client_id'] : '')));
    } else if (transformPropValue_list((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_client_id'] : ''))) {
        items_list.push(transformPropValue_list((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_client_id'] : '')));
    }
    this._elList_items = [];
    
    const elList = this.state.elList_visible ? (
      <div className="hasNestedComps elList">
        <div>
          {items_list.map((row, index) => {
            let itemComp = (row._componentId)
                ? listComps_list[row._componentId]
                : <Picker_depot3 appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} {...{ 'flux': row['flux'], 'state': row['state'], 'id_scan': row['id_scan'], 'quai': row['quai'], 'id_client': row['id_client'], 'id_contenant': row['id_contenant'], 'packmat': row['packmat'], 'type': row['type'], 'color': row['color'], 'reserve': row['reserve'], }} ref={(el) => {if (el) this._elList_items.push(el)}} />;
            return (<div key={row.key}>
                {itemComp}
              </div>);
          })}
          <div className="marker" ref={(el)=> this._elList_endMarker = el} />
        </div>
      </div>
      
     ) : null;
    
    const style_elValider = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elValider = this.state.elValider_visible ? (
      <Button className="actionFont elValider" style={style_elValider}  color="primary" onClick={this.onClick_elValider} >
        Valider
      </Button>
     ) : null;
    
    let checked_checkbox_quai = this.state.checkbox_quai;
    
    const style_elCheckbox_quai = {
      pointerEvents: 'auto',
     };
    const elCheckbox_quai = this.state.elCheckbox_quai_visible ? (
      <Checkbox className="baseFont elCheckbox_quai" style={style_elCheckbox_quai}  label="A quai" onChange={this.checkboxChanged_elCheckbox_quai} checked={checked_checkbox_quai === 'true' || checked_checkbox_quai === true || ''+checked_checkbox_quai === '1'}  />
     ) : null;
    
    let checked_checkbox_hors_quai = this.state.checkbox_hors_quai;
    
    const style_elCheckbox_hors_quai = {
      pointerEvents: 'auto',
     };
    const elCheckbox_hors_quai = this.state.elCheckbox_hors_quai_visible ? (
      <Checkbox className="baseFont elCheckbox_hors_quai" style={style_elCheckbox_hors_quai}  defaultChecked label="Hors quai" onChange={this.checkboxChanged_elCheckbox_hors_quai} checked={checked_checkbox_hors_quai === 'true' || checked_checkbox_hors_quai === true || ''+checked_checkbox_hors_quai === '1'}  />
     ) : null;
    
    let selection_picker_producer = this.state.picker_producer;
    // Source datasheet and selected data column for picker element 'picker_producer'
    const dataSource_picker_producer = this.props.appActions.getDataSheet('quaiViewProducers');
    const valueColumnName_picker_producer = 'id_client';
    const labelColumnName_picker_producer = 'site';
    
    const style_elPicker_producer = {
      pointerEvents: 'auto',
     };
    const elPicker_producer = this.state.elPicker_producer_visible ? (
      <Select className="baseFont elPicker_producer" style={style_elPicker_producer}  onChange={this.pickerValueChanged_elPicker_producer} value={selection_picker_producer} >
        {dataSource_picker_producer.items.every(item => {
          return item[valueColumnName_picker_producer] !== selection_picker_producer;
        }) ? <Option value=''/> : null}
        {dataSource_picker_producer.items.map(item => {
          const colValue = item[valueColumnName_picker_producer];
          const labelColValue = item[labelColumnName_picker_producer];
          return <Option key={item.key} value={colValue} label={labelColValue} />
        })}
      </Select>
     ) : null;
    
    let selection_picker_dech = this.state.picker_dech;
    // Source datasheet and selected data column for picker element 'picker_dech'
    const dataSource_picker_dech = this.props.appActions.getDataSheet('quaiViewRights');
    const valueColumnName_picker_dech = 'id_client';
    const labelColumnName_picker_dech = 'site';
    
    const style_elPicker_dech = {
      pointerEvents: 'auto',
     };
    const elPicker_dech = this.state.elPicker_dech_visible ? (
      <Select className="baseFont elPicker_dech" style={style_elPicker_dech}  onChange={this.pickerValueChanged_elPicker_dech} value={selection_picker_dech} >
        {dataSource_picker_dech.items.every(item => {
          return item[valueColumnName_picker_dech] !== selection_picker_dech;
        }) ? <Option value=''/> : null}
        {dataSource_picker_dech.items.map(item => {
          const colValue = item[valueColumnName_picker_dech];
          const labelColValue = item[labelColumnName_picker_dech];
          return <Option key={item.key} value={colValue} label={labelColValue} />
        })}
      </Select>
     ) : null;
    
    let checked_checkbox_dech = this.state.checkbox_dech;
    
    const style_elCheckbox_dech = {
      pointerEvents: 'auto',
     };
    
    let checked_checkbox_autres = this.state.checkbox_autres;
    
    const style_elCheckbox_autres = {
      pointerEvents: 'auto',
     };
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const style_elRectangle = {
      background: 'rgba(213, 213, 213, 1.000)',
     };
    
    const style_elShow_apercu = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_1298792923+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elApercu = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_apercu_visible'] : '')) ? (
      <div className="hasNestedComps containerMinHeight elApercu">
        <Apercu visualStateIndex={(this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_mode_apercu'] : '')} ref={(el)=> this._elApercu = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    return (
      <div className="AppScreen DepotV4Screen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        <Appbar className="navBar">
          <div className="title">Déposer un contenant</div>  <div className="backBtn" onClick={ (ev)=>{ this.props.appActions.goBack() } }><img src={btn_icon_back_depotv4} alt="" style={{width: '50%'}} /></div>
        </Appbar>
        
        <div className="screenFgContainer">
          <div className="foreground">
            { elList }
            { elValider }
            { elCheckbox_quai }
            { elCheckbox_hors_quai }
            { elPicker_producer }
            { elPicker_dech }
            <Checkbox className="baseFont elCheckbox_dech" style={style_elCheckbox_dech}  defaultChecked label="Déchèteries" onChange={this.checkboxChanged_elCheckbox_dech} checked={checked_checkbox_dech === 'true' || checked_checkbox_dech === true || ''+checked_checkbox_dech === '1'}  />
            <Checkbox className="baseFont elCheckbox_autres" style={style_elCheckbox_autres}  label="Autres lieux" onChange={this.checkboxChanged_elCheckbox_autres} checked={checked_checkbox_autres === 'true' || checked_checkbox_autres === true || ''+checked_checkbox_autres === '1'}  />
            <div className="headlineFont elText" style={style_elText}>
              <div>{this.state.text}</div>
            </div>
            <div className="elRectangle" style={style_elRectangle} />
            <button className="actionFont elShow_apercu" style={style_elShow_apercu} onClick={this.onClick_elShow_apercu}  />
            { elApercu }
          </div>
        </div>
      </div>
    )
  }
  
}
