import React, { Component } from 'react';
import './App.css';
import Other_operation from './Other_operation';
import Tail_picker_plein from './Tail_picker_plein';
import Picker_plein2 from './Picker_plein2';
import Picker_niveau2 from './Picker_niveau2';
import Item_one_group_spe from './Item_one_group_spe';
import btn_icon_1188685410 from './images/btn_icon_1188685410.png';
import btn_icon_1953677896 from './images/btn_icon_1953677896.png';
import Done from './Done';
import btn_icon_648790592 from './images/btn_icon_648790592.png';
import Apercu from './Apercu';

// UI framework component imports
import Button from 'muicss/lib/react/button';
import Checkbox from 'muicss/lib/react/checkbox';
import Appbar from 'muicss/lib/react/appbar';

export default class Quai_view_dechScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);

    this.state = {    
      random_key: 0,
      elButton_hors_quai_visible: this.props.appActions.dataSlots['ds_q_hq_view'] == 0 ? false : true,
      elButton_quai_visible: this.props.appActions.dataSlots['ds_q_hq_view'] == 0 ? true : false,
      elButton_spe_visible: false,
      elList_hors_quai_visible: this.props.appActions.dataSlots['ds_q_hq_view'] == 0 ? false : true,
      elList_quai_visible: this.props.appActions.dataSlots['ds_q_hq_view'] == 0 ? true : false,
      elList_spe_visible: false,
      checkbox_hors_quai: this.props.appActions.dataSlots['ds_q_hq_view'] == 0 ? 'false' : 'true',
      checkbox_quai: this.props.appActions.dataSlots['ds_q_hq_view'] == 0 ? 'true' : 'false',
      elCheckbox_hors_quai_visible: true,
      elCheckbox_quai_visible: true,
      elHors_quai_visible: true,
      mode: (<div>Lecture état actuel</div>),
      mode_plainText: "Lecture état actuel",
      elDone_visible: false,
      elOther_operation_visible: false,
    };
  }

  componentDidMount() {
    {
      let dataSheet = this.props.appActions.dataSheets['reference_containers'];
      let serviceOptions = this.props.appActions.serviceOptions_reference_containers;
      if ( !this.props.appActions.dataSheetLoaded['reference_containers']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/all_containers", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['reference_containers'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['exutoires'];
      let serviceOptions = this.props.appActions.serviceOptions_exutoires;
      if ( !this.props.appActions.dataSheetLoaded['exutoires']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/exutoires/", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['exutoires'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['quaiView'];
      let serviceOptions = this.props.appActions.serviceOptions_quaiView;
      if ( !this.props.appActions.dataSheetLoaded['quaiView']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/quaiView", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['quaiView'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['materiels'];
      let serviceOptions = this.props.appActions.serviceOptions_materiels;
      if ( !this.props.appActions.dataSheetLoaded['materiels']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/materiels", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['materiels'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['containers'];
      let serviceOptions = this.props.appActions.serviceOptions_containers;
      if ( !this.props.appActions.dataSheetLoaded['containers']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/clients/$slot('ds_client_id')/contenants", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['containers'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['operations_other'];
      let serviceOptions = this.props.appActions.serviceOptions_operations_other;
      if ( !this.props.appActions.dataSheetLoaded['operations_other']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/reference_operations", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['operations_other'] = true;
      }
    }
 
    this._refresh = () => {
      try {
        console.log('refresh')

        let dech_quai_view = this.props.appActions.dataSheets['quaiView'].items.filter(it => {
          return it.id_client === this.props.appActions.dataSlots['ds_client_id']
        })[0];

        let dech_quai_view_simplified = {
          quais: dech_quai_view.quais,
          hors_quais: dech_quai_view.hors_quais,
          spe: dech_quai_view.spe,
          ts: dech_quai_view.ts,
          id_client: dech_quai_view.id_client,
          document_key: dech_quai_view.document_key,
        }

        this.props.appActions.updateDataSlot('ds_selected_dech_quai_view', JSON.parse(JSON.stringify(dech_quai_view_simplified)));
        this.props.appActions.updateDataSlot('ds_selected_dech_site', dech_quai_view.site);

        function getRandomInt(max) {
          return Math.floor(Math.random() * max);
        }

        this.setState({
          //elList_quai_visible: false,
          elButton_quai_visible: false,
          //elList_hors_quai_visible: false,
          elButton_hors_quai_visible: false,
          elList_spe_visible: false,
          elButton_spe_visible: false,
          random_key: getRandomInt(1000000)
        });
      } catch (e) {
        console.log("ERROR: refresh");
      }
    }
    this.intervalRefresh = setInterval(this._refresh, 15000)
    
    let client = this.props.appActions.dataSheets['clients'].items.find(it => it.document_key == this.props.appActions.dataSlots['ds_selected_dech_quai_view'].id_client)||{};
    let quaiActive = [0, 1].includes(client.place_type);
    let hQuaiActive = [0, 1, 3].includes(client.place_type);
    
    if (!quaiActive && this.props.appActions.dataSlots['ds_q_hq_view'] == 0) {
      this.props.appActions.updateDataSlot('ds_q_hq_view', 1); 
      this.setState({
        checkbox_quai: false,
        checkbox_hors_quai: true,
      })
    }
    if (!hQuaiActive && this.props.appActions.dataSlots['ds_q_hq_view'] == 1) {
      this.props.appActions.updateDataSlot('ds_q_hq_view', 0);
      this.setState({
        checkbox_quai: true,
        checkbox_hors_quai: false,
      })
    };
    
    if (["0", 0, 1, 2, 3, 5, 7].includes(this.props.appActions.dataSlots['ds_mode_point'])) {
      if (this.props.appActions.dataSlots['ds_q_hq_view'] == 0) {
        let new_state = {
          elList_quai_visible: true,
          elButton_quai_visible: true,
          elList_hors_quai_visible: false,
          elButton_hors_quai_visible: false,
          elList_spe_visible: false,
          elButton_spe_visible: false,
          elCheckbox_quai_visible: quaiActive,
          elCheckbox_hors_quai_visible: hQuaiActive,
          elHors_quai_visible: hQuaiActive,
          elOther_operation_visible: false
        };
        if (Object.keys(new_state).some(k => this.state[k] !== new_state[k])) {
          this.setState(new_state);
        };
      } else if (this.props.appActions.dataSlots['ds_q_hq_view'] == 1) {
        let new_state = {
          elList_quai_visible: false,
          elButton_quai_visible: false,
          elList_hors_quai_visible: true,
          elButton_hors_quai_visible: true,
          elList_spe_visible: false,
          elButton_spe_visible: false,
          elCheckbox_quai_visible: quaiActive,
          elCheckbox_hors_quai_visible: hQuaiActive,
          elHors_quai_visible: hQuaiActive,
          elOther_operation_visible: false
        };
        if (Object.keys(new_state).some(k => this.state[k] !== new_state[k])) {
          this.setState(new_state);
        };
      }
    } else if ([4,9].includes(this.props.appActions.dataSlots['ds_mode_point'])) {
      // Prise en charge
      if (this.props.appActions.dataSlots['ds_q_hq_view'] == 0) {
        let new_state = {
          elList_quai_visible: true,
          elButton_quai_visible: false,
          elList_hors_quai_visible: false,
          elButton_hors_quai_visible: false,
          elList_spe_visible: false,
          elButton_spe_visible: false,
          elCheckbox_quai_visible: quaiActive,
          elCheckbox_hors_quai_visible: hQuaiActive,
          elHors_quai_visible: hQuaiActive,
          elOther_operation_visible: false
        };
        if (Object.keys(new_state).some(k => this.state[k] !== new_state[k])) {
          this.setState(new_state);
        };
      } else if (this.props.appActions.dataSlots['ds_q_hq_view'] == 1) {
        let new_state = {
          elList_quai_visible: false,
          elButton_quai_visible: false,
          elList_hors_quai_visible: true,
          elButton_hors_quai_visible: false,
          elList_spe_visible: false,
          elButton_spe_visible: false,
          elCheckbox_quai_visible: quaiActive,
          elCheckbox_hors_quai_visible: hQuaiActive,
          elHors_quai_visible: hQuaiActive,
          elOther_operation_visible: false
        };
        if (Object.keys(new_state).some(k => this.state[k] !== new_state[k])) {
          this.setState(new_state);
        };
      }
    } else if (this.props.appActions.dataSlots['ds_mode_point'] === 6) {
      // Speciaux
      let new_state = {
        elList_quai_visible: false,
        elButton_quai_visible: false,
        elList_hors_quai_visible: false,
        elButton_hors_quai_visible: false,
        elList_spe_visible: true,
        elButton_spe_visible: true,
        elCheckbox_quai_visible: false,
        elCheckbox_hors_quai_visible: false,
        elHors_quai_visible: false,
        elOther_operation_visible: false
      };
      if (Object.keys(new_state).some(k => this.state[k] !== new_state[k])) {
        this.setState(new_state);
      };
    } else if (this.props.appActions.dataSlots['ds_mode_point'] === 8) {
      // Autres
      let new_state = {
        elList_quai_visible: false,
        elButton_quai_visible: false,
        elList_hors_quai_visible: false,
        elButton_hors_quai_visible: false,
        elList_spe_visible: false,
        elButton_spe_visible: false,
        elCheckbox_quai_visible: false,
        elCheckbox_hors_quai_visible: false,
        elHors_quai_visible: false,
        elOther_operation_visible: true
      };
      if (Object.keys(new_state).some(k => this.state[k] !== new_state[k])) {
        this.setState(new_state);
      };
    }
    
    this.props.appActions.updateDataSlot('ds_scanned_id', "");
  }

  componentWillUnmount() {
    clearInterval(this.intervalRefresh);
    // this.props.appActions.updateDataSlot('ds_scanned_id', "");
  }

  componentDidUpdate() {
    let client = this.props.appActions.dataSheets['clients'].items.find(it => it.document_key == this.props.appActions.dataSlots['ds_selected_dech_quai_view'].id_client)||{};
    let quaiActive = [0, 1].includes(client.place_type);
    let hQuaiActive = [0, 1, 3].includes(client.place_type);
    
    if (!quaiActive && this.props.appActions.dataSlots['ds_q_hq_view'] == 0) {
      this.props.appActions.updateDataSlot('ds_q_hq_view', 1); 
      this.setState({
        checkbox_quai: false,
        checkbox_hors_quai: true,
      })
    }
    if (!hQuaiActive && this.props.appActions.dataSlots['ds_q_hq_view'] == 1) {
      this.props.appActions.updateDataSlot('ds_q_hq_view', 0);
      this.setState({
        checkbox_quai: true,
        checkbox_hors_quai: false,
      })
    };
    
    if (["0", 0, 1, 2, 3, 5, 7].includes(this.props.appActions.dataSlots['ds_mode_point'])) {
      if (this.props.appActions.dataSlots['ds_q_hq_view'] == 0) {
        let new_state = {
          elList_quai_visible: true,
          elButton_quai_visible: true,
          elList_hors_quai_visible: false,
          elButton_hors_quai_visible: false,
          elList_spe_visible: false,
          elButton_spe_visible: false,
          elCheckbox_quai_visible: quaiActive,
          elCheckbox_hors_quai_visible: hQuaiActive,
          elHors_quai_visible: hQuaiActive,
          elOther_operation_visible: false
        };
        if (Object.keys(new_state).some(k => this.state[k] !== new_state[k])) {
          this.setState(new_state);
        };
      } else if (this.props.appActions.dataSlots['ds_q_hq_view'] == 1) {
        let new_state = {
          elList_quai_visible: false,
          elButton_quai_visible: false,
          elList_hors_quai_visible: true,
          elButton_hors_quai_visible: true,
          elList_spe_visible: false,
          elButton_spe_visible: false,
          elCheckbox_quai_visible: quaiActive,
          elCheckbox_hors_quai_visible: hQuaiActive,
          elHors_quai_visible: hQuaiActive,
          elOther_operation_visible: false
        };
        if (Object.keys(new_state).some(k => this.state[k] !== new_state[k])) {
          this.setState(new_state);
        };
      }
    } else if ([4,9].includes(this.props.appActions.dataSlots['ds_mode_point'])) {
      // Prise en charge
      if (this.props.appActions.dataSlots['ds_q_hq_view'] == 0) {
        let new_state = {
          elList_quai_visible: true,
          elButton_quai_visible: false,
          elList_hors_quai_visible: false,
          elButton_hors_quai_visible: false,
          elList_spe_visible: false,
          elButton_spe_visible: false,
          elCheckbox_quai_visible: quaiActive,
          elCheckbox_hors_quai_visible: hQuaiActive,
          elHors_quai_visible: hQuaiActive,
          elOther_operation_visible: false
        };
        if (Object.keys(new_state).some(k => this.state[k] !== new_state[k])) {
          this.setState(new_state);
        };
      } else if (this.props.appActions.dataSlots['ds_q_hq_view'] == 1) {
        let new_state = {
          elList_quai_visible: false,
          elButton_quai_visible: false,
          elList_hors_quai_visible: true,
          elButton_hors_quai_visible: false,
          elList_spe_visible: false,
          elButton_spe_visible: false,
          elCheckbox_quai_visible: quaiActive,
          elCheckbox_hors_quai_visible: hQuaiActive,
          elHors_quai_visible: hQuaiActive,
          elOther_operation_visible: false
        };
        if (Object.keys(new_state).some(k => this.state[k] !== new_state[k])) {
          this.setState(new_state);
        };
      }
    } else if (this.props.appActions.dataSlots['ds_mode_point'] === 6) {
      // Speciaux
      let new_state = {
        elList_quai_visible: false,
        elButton_quai_visible: false,
        elList_hors_quai_visible: false,
        elButton_hors_quai_visible: false,
        elList_spe_visible: true,
        elButton_spe_visible: true,
        elCheckbox_quai_visible: false,
        elCheckbox_hors_quai_visible: false,
        elHors_quai_visible: false,
        elOther_operation_visible: false
      };
      if (Object.keys(new_state).some(k => this.state[k] !== new_state[k])) {
        this.setState(new_state);
      };
    } else if (this.props.appActions.dataSlots['ds_mode_point'] === 8) {
      // Autres
      let new_state = {
        elList_quai_visible: false,
        elButton_quai_visible: false,
        elList_hors_quai_visible: false,
        elButton_hors_quai_visible: false,
        elList_spe_visible: false,
        elButton_spe_visible: false,
        elCheckbox_quai_visible: false,
        elCheckbox_hors_quai_visible: false,
        elHors_quai_visible: false,
        elOther_operation_visible: true
      };
      if (Object.keys(new_state).some(k => this.state[k] !== new_state[k])) {
        this.setState(new_state);
      };
    }
  }

  onClick_elCancel = (ev) => {
    // Go to screen based on value in data slot 'ds_source_anomalie'
    this.props.appActions.goToScreen(this.targetTable_switch_retour((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_source_anomalie'] : '')), { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elButton_spe = (ev) => {
    // update quai view
    
    let dech_quai_view = this.props.appActions.dataSheets['quaiView'].items.filter(it => {
      return it.document_key === this.props.appActions.dataSlots['ds_selected_dech_quai_view'].document_key
    })[0];
    
    let newState = this.props.appActions.dataSlots['ds_selected_dech_quai_view'];
    
    let update = {
      document_key: dech_quai_view.document_key,
      document_ref: dech_quai_view.document_ref,
      spe: newState.spe,
      ts_spe: new Date().getTime()
    };
    this.props.appActions.dataSheets['quaiView'].replaceItemByRowIndex(update.document_key, update, this.props.appActions.serviceOptions_quaiView);
    
    // update history
    
    let history_spe_update = {
      coordinates: {
        lat: window.previousPos.coords.latitude, 
        lng: window.previousPos.coords.longitude,
      },
      entreprise_id: this.props.appActions.dataSlots['ds_entreprise_id'],
      id_client: dech_quai_view.id_client,
      site: dech_quai_view.site,
      spe: newState.spe,
      ts: new Date().getTime(),
      user_id: this.props.appActions.dataSlots['ds_userid'],
      user_name: this.props.appActions.dataSlots['ds_username'],
    }
    this.props.appActions.dataSheets['history_spe'].addItem(history_spe_update, this.props.appActions.serviceOptions_history_spe);
    
    // confirmation
    this.setState({elDone_visible: true});
    setTimeout(() => {this.setState({elDone_visible: false}); this._refresh()}, 2000);
  
  }
  
  
  onClick_elButton_hors_quai = (ev) => {
    // place some orders
    
    let placeOrder = (pickerNiveau, validate_packmat=false, override_action=2, do_override=false) => {
      try {
        let getExutoire = (exut) => {
          let output = {};
          let exutoire = this.props.appActions.dataSheets['exutoires'].items.filter(it => it.document_key === exut)[0] || false;
          if (exutoire) {
            output['label'] = exutoire.label;
            output['document_key'] = exutoire.document_key;
            output['location_key'] = exutoire.location_key;
          }
          return output;
        }
    
        let getClientById = (id) => {
          return this.props.appActions.dataSheets['clients'].items.filter(it => it.document_key == id)[0];
        }
    
        let getContenantById = (id) => {
          return this.props.appActions.dataSheets['group_contenants'].items.filter(it => it.document_key == id)[0];
        }
    
        let getDriverById = (id) => {
          return this.props.appActions.dataSheets['drivers'].items.filter(it => it.document_key == id)[0];
        }
    
        let getTruckById = (id) => {
          return this.props.appActions.dataSheets['trucks'].items.filter(it => it.document_key == id)[0];
        }
    
        let getIdTourneeByDriverId = (driver_id) => {
          try {
            return this.props.appActions.dataSheets['id_tournees_history'].items.filter(it => it.driver_id == driver_id)[0].document_key;
          } catch (e) {
            return false;
          }
        }
    
        let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.filter(it => it.document_key == this.props.appActions.dataSlots['ds_selected_tournee'])[0];
    
        let date = new Date().setHours(0,0,0,0);
        let now = new Date();
    
        let client = getClientById(pickerNiveau.props.id_client);
        let contenant = getContenantById(pickerNiveau.props.id_contenant) || {};
        //let truck = getTruckById(tournee.truck_id) || {};
        //let driver = getDriverById(tournee.driver_id);
        let exutoire = contenant.exutoire;
    
        let item = {
          action: contenant.default_action || 1,
          adr: contenant.adr || false,
          client: client.name,
          container_id: contenant.document_key || pickerNiveau.props.id_contenant,
          coordinates: contenant.coordinates || {
            lat: window.previousPos.coords.latitude, 
            lng: window.previousPos.coords.longitude,
          },
          date: date,
          default_action: contenant.default_action || 1,
          display_name: dech_quai_view.site,
          driver: "",
          driver_id: "",
          duration: contenant.duration || null,
          etat: 'ajoute',
          exutoire: exutoire || pickerNiveau.props.exutoire || {'label': '', 'document_key': '', 'location_key': ''},
          flux: contenant.flux || (pickerNiveau.props.id_contenant.includes('VIDE') ? "VIDE" : pickerNiveau.props.flux),
          heavy: contenant.heavy || false,
          heure: "",
          hh: pickerNiveau.props.id_contenant.includes('VIDE') ? "VIDE" : "HQ",
          id_client: client.document_key,
          id_scan: pickerNiveau.state.id_scan || "",
          ouverture: contenant.ouverture || {},
          packmat: pickerNiveau.props.packmat || 0,
          photo: "",
          position: 0,
          quai: 0,
          remark: "",
          report: date,
          site: dech_quai_view.document_key,
          sort_key: client.name + "_" + date,
          statut : 0,
          state: pickerNiveau.props.state || 0,
          tournee: "",
          truck: "",
          truck_id: "",
          ts: now.getTime(),
          type: contenant.type || (pickerNiveau.props.id_contenant.includes('VIDE') ? pickerNiveau.props.flux : ""),
          user_id: this.props.appActions.dataSlots['ds_userid'],
          user_name: this.props.appActions.dataSlots['ds_username'],
          zfe: contenant.zfe || false,      
        }
    
        if (validate_packmat) {
          let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key == this.props.appActions.dataSlots['ds_selected_tournee']);
          let destination_tour = this.props.appActions.dataSheets['tournees_j'].items.filter(it => it.tournee === tournee.document_key);
          destination_tour.sort((a, b) => b.position - a.position);
          let max_position = (destination_tour[0]||{}).position||0;
          let truck = getTruckById(tournee.truck_id) || {};
          let driver = getDriverById(tournee.driver_id);
    
          item.action = override_action;
          item.action_realise = override_action;
          item.container_realise_id = item.container_id;
          item.coordinates_charge = {
            lat: window.previousPos.coords.latitude, 
            lng: window.previousPos.coords.longitude,
          };
          item.default_action = 2;
          item.driver = driver.name;
          item.driver_id = driver.document_key;
          item.final_state = pickerNiveau.state.state||0;
          item.flux_realise = item.flux;
          item.position = (max_position+1);
          item.statut = 1;
          item.tournee = tournee.document_key || "";
          item.truck = truck.imat || "";
          item.truck_id = truck.document_key || "";
          item.ts_charge = now.getTime();
          item.user_id_charge = this.props.appActions.dataSlots['ds_userid'];
          item.user_name_charge = this.props.appActions.dataSlots['ds_username'];
        }
        
        console.log(item);
    
        let tournees_collection = this.props.appActions.dataSheets['tournees_j'].firebase.firestore().collection("entreprises/"+this.props.appActions.dataSlots['ds_entreprise_id']+"/tournees/");
        tournees_collection.add(item).then(docRef => {
          console.log('Order success');
        })
      } catch (error) {
        console.log(error);
        window.alert("Impossible de créer cette commande.");
        return;
      }
    }
    
    // update quai View
    let dech_quai_view = this.props.appActions.dataSheets['quaiView'].items.find(it => {
      return it.document_key === this.props.appActions.dataSlots['ds_selected_dech_quai_view'].document_key
    });
    
    //let newState = {hors_quais: []};
    let newState = {hors_quais: JSON.parse(JSON.stringify(dech_quai_view.hors_quais))};
    //console.log('PARICI', newState.hors_quais, dech_quai_view.hors_quais);
    let new_state_out = {hors_quais: []};
    this._elList_hors_quai_items.forEach(item => {
      //console.log('PARICI', newState.hors_quais[item.props.quai], dech_quai_view.hors_quais[item.props.quai]);
      newState.hors_quais[item.props.quai].id_scan = item.state.id_scan;
      newState.hors_quais[item.props.quai].state = item.state.state;
      
      if (item.state.packmat_increment === true && this.props.appActions.dataSlots['ds_mode_point'] == 5) {
        newState.hors_quais[item.props.quai].packmat += 1;
        // order packmat and mark it as realized
        placeOrder(item, true, 2, true);
        
        let logHistoryMove = () => {
          let update = {
            coordinates: {
              lat: window.previousPos.coords.latitude, 
              lng: window.previousPos.coords.longitude,
            },
            final_state: item.state.state||0,
            flux: item.props.flux,
            hq: false,
            id_client: item.props.id_client,
            id_contenant: item.props.id_contenant,
            id_scan: item.state.id_scan,
            move_type: "COMPACTER",
            quai: item.props.quai,
            site: dech_quai_view.site,
            state: item.props.state || 0,
            ts: new Date().getTime(),
            user_id: this.props.appActions.dataSlots['ds_userid'],
            user_name: this.props.appActions.dataSlots['ds_username'],
          };
          this.props.appActions.dataSheets['history_moves'].addItem(update, this.props.appActions.serviceOptions_history_moves);
        }
    
        logHistoryMove();
    
      } else if (item.state.packmat_increment === 2 && this.props.appActions.dataSlots['ds_mode_point'] == 5) {
    
        item.state.packmat_increment = false;
        newState.hors_quais[item.props.quai]['packmat'] = 0;
    
      } else if (item.state.shedding_increment && this.props.appActions.dataSlots['ds_mode_point'] == 7) {
        // order shedding and mark it as realized
        placeOrder(item, true, 7);
    
        let logHistoryMove = () => {
          let update = {
            coordinates: {
              lat: window.previousPos.coords.latitude, 
              lng: window.previousPos.coords.longitude,
            },
            final_state: item.state.state||0,
            flux: item.props.flux,
            hq: false,
            id_client: item.props.id_client,
            id_contenant: item.props.id_contenant,
            id_scan: item.state.id_scan,
            move_type: "DELESTER",
            quai: 0,
            site: dech_quai_view.site,
            state: item.props.state||0,
            ts: new Date().getTime(),
            user_id: this.props.appActions.dataSlots['ds_userid'],
            user_name: this.props.appActions.dataSlots['ds_username'],
          };
          this.props.appActions.dataSheets['history_moves'].addItem(update, this.props.appActions.serviceOptions_history_moves);
        }
        
        item.state.shedding_increment = false;
        newState.hors_quais[item.props.quai]['state'] = item.state.state;
        logHistoryMove();
      } 
      
      if (item.state.order) {
        placeOrder(item);
        item.state.order = false;
      }
      if (item.state.to_delete === false) {
        new_state_out.hors_quais.push(newState.hors_quais[item.props.quai]);
        //newState.hors_quais.splice(item.props.quai, 1);
        if (item.state.id_scan) {
          let logHistoryMove = () => {
            let update = {
              coordinates: {
                lat: window.previousPos.coords.latitude, 
                lng: window.previousPos.coords.longitude,
              },
              flux: item.props.flux,
              hq: true,
              id_client: dech_quai_view.id_client,
              id_contenant: item.props.id_contenant,
              id_scan: item.state.id_scan,
              move_type: "NIVEAU",
              quai: 0,
              site: dech_quai_view.site,
              state: item.state.state,
              ts: new Date().getTime(),
              user_id: this.props.appActions.dataSlots['ds_userid'],
              user_name: this.props.appActions.dataSlots['ds_username'],
              vide: item.props.id_contenant == "VIDE",
            };
            this.props.appActions.dataSheets['history_moves'].addItem(update, this.props.appActions.serviceOptions_history_moves);
          }
          logHistoryMove();
        }
      }
    })
    
    let update = {
      document_key: dech_quai_view.document_key,
      document_ref: dech_quai_view.document_ref,
      hors_quais: new_state_out.hors_quais,
      ts: new Date().getTime()
    };
    this.props.appActions.dataSheets['quaiView'].replaceItemByRowIndex(update.document_key, update, this.props.appActions.serviceOptions_quaiView);
    
    // confirmation
    this.setState({elDone_visible: true});
    setTimeout(() => {this.setState({elDone_visible: false}); this._refresh()}, 2000);
    
  
    // SANITIZE id_scans
    try {
      // ids to remove from other places
      let used_ids = new Set();
      this._elList_hors_quai_items.forEach(item => {
        if (item.state.id_scan) {
          used_ids.add(item.state.id_scan);
        }
      })
    
      this.props.appActions.dataSheets['quaiView'].items.forEach(qv => {
        if (qv.document_key === this.props.appActions.dataSlots['ds_selected_dech_quai_view'].document_key) {
          let flag_q = false;
          let new_q = [];
          qv.quais.forEach(q => {
            if (used_ids.has(q.id_scan)) {
              q.id_scan = "";
              flag_q = true;
            }
            new_q.push(q);
          })
          if (flag_q) {
            let update = {
              document_key: qv.document_key,
              document_ref: qv.document_ref,
              quais: new_q,
            }
            this.props.appActions.dataSheets['quaiView'].replaceItemByRowIndex(qv.document_key, update, this.props.appActions.serviceOptions_quaiView)
          }
        } else {
          let flag_q = false;
          let flag_hq = false;
          let new_q = [];
          let new_hq = [];
          qv.quais.forEach(q => {
            if (used_ids.has(q.id_scan)) {
              q.id_scan = "";
              flag_q = true;
            }
            new_q.push(q);
          })
          qv.hors_quais.forEach(hq => {
            if (used_ids.has(hq.id_scan) && hq.id_contenant === "VIDE") {
              // skip
              flag_hq = true;
            } else if (used_ids.has(hq.id_scan)) {
              hq.id_scan = "";
              flag_hq = true;
              new_hq.push(hq);
            } else {
              new_hq.push(hq);
            }
          })
          if (flag_q) {qv.quais = new_q}
          if (flag_hq) {qv.hors_quais = new_hq}
          if (flag_q || flag_hq) {this.props.appActions.dataSheets['quaiView'].replaceItemByRowIndex(qv.document_key, qv, this.props.appActions.serviceOptions_quaiView)}
        }
      })
    } catch (e) {
      console.log(e);
    }
  
  }
  
  
  onClick_elButton_quai = (ev) => {
    let placeOrder = (pickerNiveau, validate_packmat=false, override_action=2, do_override=false) => {
      try {
        let getExutoire = (exut) => {
          let output = {};
          let exutoire = this.props.appActions.dataSheets['exutoires'].items.find(it => it.document_key === exut) || false;
          if (exutoire) {
            output['label'] = exutoire.label;
            output['document_key'] = exutoire.document_key;
            output['location_key'] = exutoire.location_key;
          }
          return output;
        }
    
        let getClientById = (id) => {
          return this.props.appActions.dataSheets['clients'].items.find(it => it.document_key == id);
        }
    
        let getContenantById = (id) => {
          return this.props.appActions.dataSheets['group_contenants'].items.find(it => it.document_key == id);
        }
    
        let getDriverById = (id) => {
          return this.props.appActions.dataSheets['drivers'].items.find(it => it.document_key == id);
        }
    
        let getTruckById = (id) => {
          return this.props.appActions.dataSheets['trucks'].items.find(it => it.document_key == id);
        }
    
        let getIdTourneeByDriverId = (driver_id) => {
          try {
            return this.props.appActions.dataSheets['id_tournees_history'].items.find(it => it.driver_id == driver_id).document_key;
          } catch (e) {
            return false;
          }
        }
    
        //let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key == this.props.appActions.dataSlots['ds_selected_tournee']);
        //let destination_tour = this.props.appActions.dataSheets['tournees_j'].items.filter(it => it.tournee === tournee.document_key);
        //destination_tour.sort((a, b) => b.position - a.position);
        //let max_position = (destination_tour[0]||{}).position||0;
    
        let date = new Date().setHours(0,0,0,0);
        let now = new Date();
    
        let client = getClientById(pickerNiveau.props.id_client);
        let contenant = getContenantById(pickerNiveau.props.id_contenant) || {};
        //let truck = getTruckById(tournee.truck_id) || {};
        //let driver = getDriverById(tournee.driver_id);
        let exutoire = contenant.exutoire;
    
        let item = {
          action: do_override ? override_action : (contenant.default_action || 1),
          adr: contenant.adr || false,
          client: client.name,
          container_id: contenant.document_key || pickerNiveau.props.id_contenant,
          coordinates: contenant.coordinates || {
            lat: window.previousPos.coords.latitude, 
            lng: window.previousPos.coords.longitude,
          },
          date: date,
          default_action: do_override ? override_action : (contenant.default_action || 1),
          display_name: dech_quai_view.site,
          driver: "",
          driver_id: "",
          duration: contenant.duration || null,
          etat: 'ajoute',
          exutoire: exutoire || pickerNiveau.props.exutoire || {'label': '', 'document_key': '', 'location_key': ''},
          flux: contenant.flux || pickerNiveau.props.flux,
          heavy: contenant.heavy || false,
          heure: "",
          hh: "PLEIN",
          id_client: client.document_key,
          id_scan: pickerNiveau.state.id_scan || "",
          ouverture: contenant.ouverture || {},
          packmat: pickerNiveau.state.packmat || 0,
          photo: "",
          position: 0,
          quai: pickerNiveau.props.quai || 0,
          remark: "",
          report: date,
          site: dech_quai_view.document_key,
          sort_key: client.name + "_" + date,
          state: pickerNiveau.props.state || 0,
          statut : 0,
          tournee: "",
          truck: "",
          truck_id: "",
          ts: now.getTime(),
          type: contenant.type || "",
          user_id: this.props.appActions.dataSlots['ds_userid'],
          user_name: this.props.appActions.dataSlots['ds_username'],
          zfe: contenant.zfe || false,      
        }
    
        if (validate_packmat) {
          let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key == this.props.appActions.dataSlots['ds_selected_tournee']);
          let destination_tour = this.props.appActions.dataSheets['tournees_j'].items.filter(it => it.tournee === tournee.document_key);
          destination_tour.sort((a, b) => b.position - a.position);
          let max_position = (destination_tour[0]||{}).position||0;
          let truck = getTruckById(tournee.truck_id) || {};
          let driver = getDriverById(tournee.driver_id);
    
          item.action = override_action;
          item.action_realise = override_action;
          item.container_realise_id = item.container_id;
          item.coordinates_charge = {
            lat: window.previousPos.coords.latitude, 
            lng: window.previousPos.coords.longitude,
          };
          item.default_action = 2;
          item.driver = driver.name;
          item.driver_id = driver.document_key;
          item.final_state = pickerNiveau.state.state||0;
          item.flux_realise = item.flux;
          item.position = (max_position+1);
          item.statut = 1;
          item.tournee = tournee.document_key || "";
          item.truck = truck.imat || "";
          item.truck_id = truck.document_key || "";
          item.ts_charge = now.getTime();
          item.user_id_charge = this.props.appActions.dataSlots['ds_userid'];
          item.user_name_charge = this.props.appActions.dataSlots['ds_username'];
        }
    
        console.log(item);
    
        let tournees_collection = this.props.appActions.dataSheets['tournees_j'].firebase.firestore().collection("entreprises/"+this.props.appActions.dataSlots['ds_entreprise_id']+"/tournees/");
        tournees_collection.add(item).then(docRef => {
          console.log('Order success');
        })
      } catch (error) {
        console.log(error);
        window.alert("Impossible de créer cette commande.");
        return;
      }
    }
    
    // update quai View
    let dech_quai_view = this.props.appActions.dataSheets['quaiView'].items.find(it => {
      return it.document_key === this.props.appActions.dataSlots['ds_selected_dech_quai_view'].document_key
    });
    
    //let newState = this.props.appActions.dataSlots['ds_selected_dech_quai_view'];
    
    // packmat stuff + log movement niveau
    let newState = {quais: JSON.parse(JSON.stringify(dech_quai_view.quais))};
    
    this._elList_quai_items.forEach(item => {
    
      newState.quais[item.props.quai].flux = item.state.flux;
      newState.quais[item.props.quai].id_contenant = item.state.id_contenant;
      newState.quais[item.props.quai].id_scan = item.state.id_scan;
      newState.quais[item.props.quai].packmat = item.state.packmat;
      newState.quais[item.props.quai].state = item.state.state;
    
      if (item.state.packmat_increment === true && this.props.appActions.dataSlots['ds_mode_point'] == 5) {
        
        // order packmat and mark it as realized
        let commands = this.props.appActions.dataSheets['tournees_j'].items
        .concat(this.props.appActions.dataSheets['tournees_j1'].items);
        let command = commands.find(it => it.document_key === item.props.reference_ot_pkt);
    
        if (command) {
          let getDriverById = (id) => {
            return this.props.appActions.dataSheets['drivers'].items.filter(it => it.document_key == id)[0];
          }
    
          let getTruckById = (id) => {
            return this.props.appActions.dataSheets['trucks'].items.filter(it => it.document_key == id)[0];
          }
    
          let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key == this.props.appActions.dataSlots['ds_selected_tournee']);
          let destination_tour = this.props.appActions.dataSheets['tournees_j'].items.filter(it => it.tournee === tournee.document_key);
          destination_tour.sort((a, b) => b.position - a.position);
          let max_position = (destination_tour[0]||{}).position||0;
    
          let now = new Date();
    
          let truck = getTruckById(tournee.truck_id) || {};
          let driver = getDriverById(tournee.driver_id);
    
          command.action_realise = 2;
          command.container_realise_id = command.container_id;
          command.coordinates_charge = {
            lat: window.previousPos.coords.latitude, 
            lng: window.previousPos.coords.longitude,
          };
          command.final_state = item.state.state||0;
          command.flux_realise = command.flux;
          command.state = item.props.state||0;
          command.statut = 1;
          command.ts_charge = now.getTime();
          command.user_id_charge = this.props.appActions.dataSlots['ds_userid'];
          command.user_name_charge = this.props.appActions.dataSlots['ds_username'];
          if (command.tournee != tournee.document_key) {
            command.driver = driver.name;
            command.driver_id = driver.document_key;
            command.tournee = tournee.document_key || "";
            command.truck = truck.imat || "";
            command.truck_id = truck.document_key || "";
            command.position = (max_position+1);
          }
          // update command
          this.props.appActions.dataSheets['tournees_j'].replaceItemByRowIndex(command.document_key, command, this.props.appActions.serviceOptions_tournees_j);
        } else {
          placeOrder(item, true, 2, true);
        }
    
        let logHistoryMove = () => {
          let update = {
            coordinates: {
              lat: window.previousPos.coords.latitude, 
              lng: window.previousPos.coords.longitude,
            },
            final_state: item.state.state,
            flux: item.props.flux,
            hq: false,
            id_client: item.props.id_client,
            id_contenant: item.props.id_contenant,
            id_scan: item.state.id_scan,
            move_type: "COMPACTER",
            quai: item.props.quai,
            site: dech_quai_view.site,
            state: item.props.state||0,
            ts: new Date().getTime(),
            user_id: this.props.appActions.dataSlots['ds_userid'],
            user_name: this.props.appActions.dataSlots['ds_username'],
          };
          this.props.appActions.dataSheets['history_moves'].addItem(update, this.props.appActions.serviceOptions_history_moves);
        }
    
        newState.quais[item.props.quai]['packmat'] = (newState.quais[item.props.quai].packmat || 0) + 1;
        newState.quais[item.props.quai]['state'] = item.state.state;
        item.state.packmat_increment = false;
        item.state.packmat = (newState.quais[item.props.quai].packmat);
        logHistoryMove();
    
      } else if (item.state.packmat_increment === true && this.props.appActions.dataSlots['ds_mode_point'] == 1) {
    
        // order packmat
        placeOrder(item, false, 2, true);
    
        item.state.packmat_increment = false;
    
      } else if (item.state.packmat_increment === 2 && this.props.appActions.dataSlots['ds_mode_point'] == 5) {
    
        item.state.packmat_increment = false;
        newState.quais[item.props.quai]['packmat'] = 0;
    
      } else if (item.state.shedding_increment) {
        // order shedding and mark it as realized
        
        placeOrder(item, true, 7);
    
        let logHistoryMove = () => {
          let update = {
            coordinates: {
              lat: window.previousPos.coords.latitude, 
              lng: window.previousPos.coords.longitude,
            },
            final_state: item.state.state||0,
            flux: item.props.flux,
            hq: false,
            id_client: item.props.id_client,
            id_contenant: item.props.id_contenant,
            id_scan: item.state.id_scan,
            move_type: "DELESTER",
            quai: item.props.quai,
            site: dech_quai_view.site,
            state: item.props.state||0,
            ts: new Date().getTime(),
            user_id: this.props.appActions.dataSlots['ds_userid'],
            user_name: this.props.appActions.dataSlots['ds_username'],
          };
          this.props.appActions.dataSheets['history_moves'].addItem(update, this.props.appActions.serviceOptions_history_moves);
        }
    
        newState.quais[item.props.quai]['state'] = item.state.state;
        item.state.shedding_increment = false;
        logHistoryMove();
      } else if (item.state.state == 0) {
        newState.quais[item.props.quai].packmat = 0;
        newState.quais[item.props.quai].id_scan = "";
        item.state.packmat_increment = false;
        item.state.packmat = 0;
        item.state.id_scan = "";
      }
    
      if ((newState.quais[item.props.quai].state !== (dech_quai_view.quais[item.props.quai] || {}).state) 
          || (newState.quais[item.props.quai].id_scan !== (dech_quai_view.quais[item.props.quai] || {}).id_scan)
          || (newState.quais[item.props.quai].flux !== (dech_quai_view.quais[item.props.quai] || {}).flux)) {
    
        let logHistoryMove = () => {
          let update = {
            coordinates: {
              lat: window.previousPos.coords.latitude, 
              lng: window.previousPos.coords.longitude,
            },
            flux: item.props.flux,
            hq: false,
            id_client: item.props.id_client,
            id_contenant: item.props.id_contenant,
            id_scan: item.state.id_scan,
            move_type: "NIVEAU",
            quai: item.props.quai,
            site: dech_quai_view.site,
            state: item.state.state,
            ts: new Date().getTime(),
            user_id: this.props.appActions.dataSlots['ds_userid'],
            user_name: this.props.appActions.dataSlots['ds_username'],
          };
          this.props.appActions.dataSheets['history_moves'].addItem(update, this.props.appActions.serviceOptions_history_moves);
        }
        logHistoryMove();
      }
      // reserve
      if (newState.quais[item.props.quai].reserve && newState.quais[item.props.quai].state<1) {
        newState.quais[item.props.quai].packmat = 0;
        newState.quais[item.props.quai].id_scan = "";
        newState.quais[item.props.quai].flux = "RESERVE";
        newState.quais[item.props.quai].id_contenant = "";
        newState.quais[item.props.quai].state = 0;
      }
    })
    // packmat stuff end
    
    // update quaiView
    let update = {
      document_key: dech_quai_view.document_key,
      document_ref: dech_quai_view.document_ref,
      quais: newState.quais,
      ts: new Date().getTime()
    };
    this.props.appActions.dataSheets['quaiView'].replaceItemByRowIndex(update.document_key, update, this.props.appActions.serviceOptions_quaiView);
    
    // place some orders
    this._elList_quai_items.forEach((item) => {
      if (item.state.order) {
        placeOrder(item);
        item.state.order = false;
      }
    })
    
    // confirmation
    this.setState({elDone_visible: true});
    setTimeout(() => {this.setState({elDone_visible: false}); this._refresh()}, 2000);
    
  
    // SANITIZE id_scans
    try {
      // ids to remove from other places
      let used_ids = new Set();
      this._elList_quai_items.forEach(item => {
        if (item.state.id_scan) {
          used_ids.add(item.state.id_scan);
        }
      })
    
      this.props.appActions.dataSheets['quaiView'].items.forEach(qv => {
        if (qv.document_key === this.props.appActions.dataSlots['ds_selected_dech_quai_view'].document_key) {
          let flag_hq = false;
          let new_hq = [];
          qv.hors_quais.forEach(hq => {
            if (used_ids.has(hq.id_scan) && hq.id_contenant === "VIDE") {
              // skip
              flag_hq = true;
            } else if (used_ids.has(hq.id_scan)) {
              hq.id_scan = "";
              flag_hq = true;
              new_hq.push(hq);
            } else {
              new_hq.push(hq);
            }
          })
          if (flag_hq) {
            let update = {
              document_key: qv.document_key,
              document_ref: qv.document_ref,
              hors_quais: new_hq,
            }
            this.props.appActions.dataSheets['quaiView'].replaceItemByRowIndex(qv.document_key, update, this.props.appActions.serviceOptions_quaiView)
          }
        } else {
          let flag_q = false;
          let flag_hq = false;
          let new_q = [];
          let new_hq = [];
          qv.quais.forEach(q => {
            if (used_ids.has(q.id_scan)) {
              q.id_scan = "";
              flag_q = true;
            }
            new_q.push(q);
          })
          qv.hors_quais.forEach(hq => {
            if (used_ids.has(hq.id_scan) && hq.id_contenant === "VIDE") {
              // skip
              flag_hq = true;
            } else if (used_ids.has(hq.id_scan)) {
              hq.id_scan = "";
              flag_hq = true;
              new_hq.push(hq);
            } else {
              new_hq.push(hq);
            }
          })
          if (flag_q) {qv.quais = new_q}
          if (flag_hq) {qv.hors_quais = new_hq}
          if (flag_q || flag_hq) {this.props.appActions.dataSheets['quaiView'].replaceItemByRowIndex(qv.document_key, qv, this.props.appActions.serviceOptions_quaiView)}
        }
      })
    } catch (e) {
      console.log(e);
    }
  
  }
  
  
  onClick_elList_hors_quai = (ev) => {
    window.clearInterval(this.intervalRefresh)
    window.clearTimeout(this.timeoutRefresh)
    console.log('locked for 30s')
    this.timeoutRefresh = setTimeout(() => {
      this.intervalRefresh = setInterval(this._refresh, 15000)
    }, 30000)
    
  
  }
  
  
  onClick_elList_quai = (ev) => {
    window.clearInterval(this.intervalRefresh)
    window.clearTimeout(this.timeoutRefresh)
    console.log('locked for 30s')
    this.timeoutRefresh = setTimeout(() => {
      this.intervalRefresh = setInterval(this._refresh, 15000)
    }, 30000)
    
  
  }
  
  
  checkboxChanged_elCheckbox_hors_quai = (event) => {
    this.setState({checkbox_hors_quai: (event.target.checked ? 'true' : 'false')});
    
    this.setState({
      elList_quai_visible: false,
      elList_hors_quai_visible: true,
      elButton_quai_visible: false,
      elButton_hors_quai_visible: true,
      
      checkbox_quai: false,
      checkbox_hors_quai: true,
    });
    
    this.props.appActions.updateDataSlot('ds_q_hq_view', 1);  
  }
  
  checkboxChanged_elCheckbox_quai = (event) => {
    this.setState({checkbox_quai: (event.target.checked ? 'true' : 'false')});
    
    this.setState({
      elList_quai_visible: true,
      elList_hors_quai_visible: false,
      elButton_quai_visible: true,
      elButton_hors_quai_visible: false,
      
      checkbox_quai: true,
      checkbox_hors_quai: false,
    });
    
    this.props.appActions.updateDataSlot('ds_q_hq_view', 0);  
  }
  
  onClick_elList_spe = (ev) => {
    window.clearInterval(this.intervalRefresh)
    window.clearTimeout(this.timeoutRefresh)
    console.log('locked for 30s')
    this.timeoutRefresh = setTimeout(() => {
      this.intervalRefresh = setInterval(this._refresh, 15000)
    }, 30000)
    
  
  }
  
  
  onClick_elMode_plus = (ev) => {
    /// DEFAULT MODE POINT
    let rights_app = this.props.appActions.dataSheets['auth_user'].items[0].rights_app || {
      "READ": true,
      "STATES": true,
      "SCAN": true,
      "ORDER": true,
      "EMPTY": true,
      "PACKMAT": true,
      "SPE": true,
      "SHED": false,
      "OTHER": false,
      "ISSUE": false,
    };
    
    let id_right = ["READ", "STATES", "SCAN", "ORDER", "EMPTY", "PACKMAT", "SPE", "LOCKED", "LOCKED", "ISSUE"];
    let sequence = [0,1,2,3,4,5,6,7,8,9];
    let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key == this.props.appActions.dataSlots['ds_selected_tournee'])||{abilities:[]};
    
    if (this.props.appActions.dataSlots['ds_source_anomalie'] == "menu") {
      id_right = ["READ", "STATES", "SCAN", "ORDER", "LOCKED", "LOCKED", "SPE", "LOCKED", "LOCKED", "ISSUE"];
    } else if (this.props.appActions.dataSlots['ds_selected_dech_site'] == "Autre lieu") {
      id_right = ["LOCKED", "LOCKED", "LOCKED", "LOCKED", "LOCKED", "LOCKED", "LOCKED", "LOCKED", "OTHER", "LOCKED"];
      sequence = [8,0,1,2,3,4,5,6,7,9];
    } else if (tournee.abilities.includes("grue")) {
      id_right = ["READ", "STATES", "SCAN", "ORDER", "EMPTY", "PACKMAT", "SPE", "SHED", "OTHER", "ISSUE"];
      sequence = [7,8,4,5,0,1,2,3,6,9];
    } else if (this.props.appActions.dataSlots['ds_source_anomalie'] == "tour") {
      sequence = [4,5,0,1,2,3,6,7,8,9];
    }
    
    let cont = 0;
    let next_mode = sequence[(sequence.findIndex(s => s==this.props.appActions.dataSlots['ds_mode_point'])+1)%10];
    //let next_mode = sequence[(parseInt(this.props.appActions.dataSlots['ds_mode_point'])+1)%9];
    while (rights_app[id_right[next_mode]] !== true && cont < 10) {
      next_mode = sequence[(sequence.findIndex(s => s==next_mode)+1)%10];
      // next_mode = (parseInt(next_mode)+1)%9;
      cont += 1;
    }
    if (cont < 10) {
      this.props.appActions.updateDataSlot('ds_mode_point', next_mode);
    } else {
      window.alert("Utilisateur non autorisé.");
      return;
    }
    
    this._refresh();
  
    // clear form
    this.props.appActions.updateDataSlot('ds_action_enlevement', "");
    this.props.appActions.updateDataSlot('ds_flux', "");
    this.props.appActions.updateDataSlot('ds_commentaire', "");
    this.props.appActions.updateDataSlot('ds_volume', "");
    this.props.appActions.updateDataSlot('ds_tonnage', "");
    this.props.appActions.updateDataSlot('ds_saved_picture', "");
    this.props.appActions.updateDataSlot('ds_exutoire_enlevement', "");
  
  }
  
  
  onClick_elMode_minus = (ev) => {
    /// DEFAULT MODE POINT
    let rights_app = this.props.appActions.dataSheets['auth_user'].items[0].rights_app || {
      "READ": true,
      "STATES": true,
      "SCAN": true,
      "ORDER": true,
      "EMPTY": true,
      "PACKMAT": true,
      "SPE": true,
      "SHED": false,
      "OTHER": false,
      "ISSUE": false,
    };
    
    let id_right = ["READ", "STATES", "SCAN", "ORDER", "EMPTY", "PACKMAT", "SPE", "LOCKED", "LOCKED", "ISSUE"];
    let sequence = [0,1,2,3,4,5,6,7,8,9];
    let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key == this.props.appActions.dataSlots['ds_selected_tournee'])||{abilities:[]};
    
    if (this.props.appActions.dataSlots['ds_source_anomalie'] == "menu") {
      id_right = ["READ", "STATES", "SCAN", "ORDER", "LOCKED", "LOCKED", "SPE", "LOCKED", "LOCKED","ISSUE"];
    } else if (this.props.appActions.dataSlots['ds_selected_dech_site'] == "Autre lieu") {
      id_right = ["LOCKED", "LOCKED", "LOCKED", "LOCKED", "LOCKED", "LOCKED", "LOCKED", "LOCKED", "OTHER", "LOCKED"];
      sequence = [8,0,1,2,3,4,5,6,7,9];
    } else if (tournee.abilities.includes("grue")) {
      id_right = ["READ", "STATES", "SCAN", "ORDER", "EMPTY", "PACKMAT", "SPE", "SHED", "OTHER", "ISSUE"];
      sequence = [7,8,4,5,0,1,2,3,6,9];
    } else if (this.props.appActions.dataSlots['ds_source_anomalie'] == "tour") {
      sequence = [4,5,0,1,2,3,6,7,8,9];
    }
    
    let cont = 0;
    let next_mode = sequence[(sequence.findIndex(s => s==this.props.appActions.dataSlots['ds_mode_point'])+9)%10];
    //let next_mode = sequence[(parseInt(this.props.appActions.dataSlots['ds_mode_point'])+1)%9];
    while (rights_app[id_right[next_mode]] !== true && cont < 10) {
      next_mode = sequence[(sequence.findIndex(s => s==next_mode)+9)%10];
      // next_mode = (parseInt(next_mode)+1)%9;
      cont += 1;
    }
    if (cont < 10) {
      this.props.appActions.updateDataSlot('ds_mode_point', next_mode);
    } else {
      window.alert("Utilisateur non autorisé.");
      return;
    }
    
    this._refresh();
  
    // clear form
    this.props.appActions.updateDataSlot('ds_action_enlevement', "");
    this.props.appActions.updateDataSlot('ds_flux', "");
    this.props.appActions.updateDataSlot('ds_commentaire', "");
    this.props.appActions.updateDataSlot('ds_volume', "");
    this.props.appActions.updateDataSlot('ds_tonnage', "");
    this.props.appActions.updateDataSlot('ds_saved_picture', "");
    this.props.appActions.updateDataSlot('ds_exutoire_enlevement', "");
  
  }
  
  
  onClick_elShow_apercu = (ev) => {
    let newVal = "1";
    this.props.appActions.updateDataSlot('ds_apercu_visible', newVal);
  
  }
  
  
  targetTable_switch_retour = (targetId) => {
    if (targetId === 'tour')
      return 'point_dech';
    else if (targetId === 'menu')
      return 'menuPrincipal';
    else if (targetId === 'solve')
      return 'tournéeDuJour';
  }
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };
    
    const style_elCancel = {
      display: 'block',
      textTransform: 'none',
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#ff7e78',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elButton_spe = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elButton_spe = this.state.elButton_spe_visible ? (
      <Button className="actionFont elButton_spe" style={style_elButton_spe}  color="accent" onClick={this.onClick_elButton_spe} >
        Enregistrer
      </Button>
     ) : null;
    
    const style_elButton_hors_quai = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elButton_hors_quai = this.state.elButton_hors_quai_visible ? (
      <Button className="actionFont elButton_hors_quai" style={style_elButton_hors_quai}  color="accent" onClick={this.onClick_elButton_hors_quai} >
        Enregistrer
      </Button>
     ) : null;
    
    const style_elButton_quai = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elButton_quai = this.state.elButton_quai_visible ? (
      <Button className="actionFont elButton_quai" style={style_elButton_quai}  color="accent" onClick={this.onClick_elButton_quai} >
        Enregistrer
      </Button>
     ) : null;
    const elOther_operation = this.state.elOther_operation_visible ? (
      <div className="hasNestedComps elOther_operation">
        <Other_operation ref={(el)=> this._elOther_operation = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    let transformPropValue_list_hors_quai = (input) => {
      try {
        let dech_quai_view = this.props.appActions.dataSheets['quaiView'].items.find(it => {
          return it.document_key === this.props.appActions.dataSlots['ds_selected_dech_quai_view'].document_key
        });
      
        let mode_point = this.props.appActions.dataSlots['ds_mode_point'];
        let actions;
        if (mode_point == 5) {
          actions = [2];
        } else {
          actions = [0, 1, 4];
        }
      
      
      
      
        let commands = this.props.appActions.dataSheets['tournees_j'].items
        .concat(this.props.appActions.dataSheets['tournees_j1'].items)
        .filter(it => it.container_id !== "" && it.site === input.id_client && it.statut === 0 && actions.includes(it.action))
        .sort((a,b) => a.date-b.date);
      
        let simplified_commands = commands.map(it => {return {
          container_id: it.container_id,
          date: it.date,
          document_key: it.document_key,
          flux: it.flux,
          hh: it.hh,
          id_client: it.id_client,
          id_scan: it.id_scan,
          quai: it.quai,
          site: it.site,
          statut: it.statut,
          tournee: it.tournee,
          type: it.type,
        }});
      
      
        let output = [];
        (dech_quai_view.hors_quais||[]).sort((a,b) => {b.id_scan.localeCompare(a.id_scan)});
        simplified_commands.sort((a,b) => {b.id_scan.localeCompare(a.id_scan)});
        for (let i=0; i < (dech_quai_view.hors_quais||[]).length; i++) {
          let item = dech_quai_view.hors_quais[i];
          let newItem = {...item, id_site: dech_quai_view.id_client, quai: i, key: this.state.random_key + i};
      
          // check stuff
          let index = -1;
          if (newItem.id_contenant.includes('VIDE')) {
            index = simplified_commands.findIndex(c => c.id_scan === newItem.id_scan && c.container_id === newItem.id_contenant && c.site === newItem.id_site && c.hh === "VIDE" && c.type == newItem.flux);
            if (index < 0) {
              index = simplified_commands.findIndex(c => c.id_scan === "" && c.container_id === newItem.id_contenant && c.site === newItem.id_site && c.hh === "VIDE" && c.type == newItem.flux);
            }
          } else {
            index = simplified_commands.findIndex(c => c.id_scan === newItem.id_scan && c.container_id === newItem.id_contenant && c.site === newItem.id_site && c.hh === "HQ");
            if (index < 0) {
              index = simplified_commands.findIndex(c => c.id_scan === "" && c.container_id === newItem.id_contenant && c.site === newItem.id_site && c.hh === "HQ");
            }
          }
          if (index >=0) {
            let command_index = simplified_commands[index];
            if (command_index.tournee) {
              if (command_index.date === this.props.appActions.dataSlots['ds_today_minuit_ts']) {
                newItem.checked = 3;
                newItem.reference_ot = command_index.document_key;
              } else {
                newItem.checked = 2;
                newItem.reference_ot = command_index.document_key;
              }
            } else {
              newItem.checked = 1;
              newItem.reference_ot = command_index.document_key;
            }
            simplified_commands.splice(index, 1);
          } else {
            newItem.checked = 0;
          }
      
          output.push(newItem);
        }
        return output;
      } catch (e) {
        console.log(e);
        return [];
      }
    }
    
    // Source items and any special components used for list/grid element 'list_hors_quai'.
    let items_list_hors_quai = [];
    let listComps_list_hors_quai = {};
    listComps_list_hors_quai['_tail'] = <Tail_picker_plein appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />;  // Tail item for this list
    
    if (Array.isArray(transformPropValue_list_hors_quai((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_dech_quai_view'] : '')))) {
        items_list_hors_quai = items_list_hors_quai.concat(transformPropValue_list_hors_quai((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_dech_quai_view'] : '')));
    } else if (transformPropValue_list_hors_quai((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_dech_quai_view'] : ''))) {
        items_list_hors_quai.push(transformPropValue_list_hors_quai((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_dech_quai_view'] : '')));
    }
    items_list_hors_quai.push({key: '_tail', _componentId: '_tail'});  // A data item to indicate the tail item
    this._elList_hors_quai_items = [];
    
    const style_elList_hors_quai = {
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elList_hors_quai = this.state.elList_hors_quai_visible ? (
      <div className="hasNestedComps elList_hors_quai">
        <div style={style_elList_hors_quai} onClick={this.onClick_elList_hors_quai} >
          {items_list_hors_quai.map((row, index) => {
            let itemComp = (row._componentId)
                ? listComps_list_hors_quai[row._componentId]
                : <Picker_plein2 appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} {...{ 'flux': row['flux'], 'state': row['state'], 'id_scan': row['id_scan'], 'quai': row['quai'], 'id_client': row['id_client'], 'id_contenant': row['id_contenant'], 'exutoire': row['exutoire'], 'checked': row['checked'], 'reference_ot': row['reference_ot'], 'type': row['type'], 'packmat': row['packmat'], 'color': row['color'], 'key': row['key'], }} ref={(el) => {if (el) this._elList_hors_quai_items.push(el)}} />;
            return (<div key={row.key}>
                {itemComp}
              </div>);
          })}
          <div className="marker" ref={(el)=> this._elList_hors_quai_endMarker = el} />
        </div>
      </div>
      
     ) : null;
    let transformPropValue_list_quai = (input) => {
      try {
        let dech_quai_view = this.props.appActions.dataSheets['quaiView'].items.find(it => {
          return it.document_key === this.props.appActions.dataSlots['ds_selected_dech_quai_view'].document_key
        });
      
        let mode_point = this.props.appActions.dataSlots['ds_mode_point'];
        let actions;
        /**
            if (mode_point == 5) {
              actions = [2];
            } else {
              actions = [0, 1, 4];
            }
            **/
        actions = [0, 1, 2, 4];
      
        let commands = this.props.appActions.dataSheets['tournees_j'].items
        .concat(this.props.appActions.dataSheets['tournees_j1'].items)
        .filter(it => it.container_id !== "" && it.site === input.id_client && it.statut === 0 && actions.includes(it.action))
        .sort((a,b) => a.date-b.date);
      
        let simplified_commands = commands.map(it => {return {
          action: it.action,
          container_id: it.container_id,
          date: it.date,
          document_key: it.document_key,
          flux: it.flux,
          hh: it.hh,
          id_client: it.id_client,
          id_scan: it.id_scan,
          quai: it.quai,
          site: it.site,
          statut: it.statut,
          tournee: it.tournee,
          type: it.type,
        }});
      
        let output = [];
        for (let i=1; i < dech_quai_view.quais.length; i++) {
          let item = dech_quai_view.quais[i];
          let newItem = {...item, id_site: dech_quai_view.id_client, quai: i, key: this.state.random_key + i};
      
          // check stuff vider
          let index = simplified_commands.findIndex(c => c.quai === i && c.container_id === newItem.id_contenant && c.site === newItem.id_site && c.hh === "PLEIN" && [0, 1, 4].includes(c.action));
          if (index >=0) {
            let command_index = simplified_commands[index];
            if (command_index.tournee) {
              if (command_index.date === this.props.appActions.dataSlots['ds_today_minuit_ts']) {
                newItem.checked = 3;
                newItem.reference_ot = command_index.document_key;
              } else {
                newItem.checked = 2;
                newItem.reference_ot = command_index.document_key;
              }
            } else {
              newItem.checked = 1;
              newItem.reference_ot = command_index.document_key;
            }
            simplified_commands.splice(index, 1);
          } else {
            newItem.checked = 0;
          }
          // check stuff pkt
          index = simplified_commands.findIndex(c => c.quai === i && c.container_id === newItem.id_contenant && c.site === newItem.id_site && c.hh === "PLEIN" && [2].includes(c.action));
          if (index >=0) {
            let command_index = simplified_commands[index];
            if (command_index.tournee) {
              if (command_index.date === this.props.appActions.dataSlots['ds_today_minuit_ts']) {
                newItem.checked_pkt = 3;
                newItem.reference_ot_pkt = command_index.document_key;
              } else {
                newItem.checked_pkt = 2;
                newItem.reference_ot_pkt = command_index.document_key;
              }
            } else {
              newItem.checked_pkt = 1;
              newItem.reference_ot_pkt = command_index.document_key;
            }
            simplified_commands.splice(index, 1);
          } else {
            newItem.checked_pkt = 0;
          }
      
          output.push(newItem);
        }
        return output;
      } catch (e) {
        console.log(e);
        return [];
      }
    }
    
    // Source items and any special components used for list/grid element 'list_quai'.
    let items_list_quai = [];
    let listComps_list_quai = {};
    if (Array.isArray(transformPropValue_list_quai((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_dech_quai_view'] : '')))) {
        items_list_quai = items_list_quai.concat(transformPropValue_list_quai((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_dech_quai_view'] : '')));
    } else if (transformPropValue_list_quai((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_dech_quai_view'] : ''))) {
        items_list_quai.push(transformPropValue_list_quai((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_dech_quai_view'] : '')));
    }
    this._elList_quai_items = [];
    
    const style_elList_quai = {
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elList_quai = this.state.elList_quai_visible ? (
      <div className="hasNestedComps elList_quai">
        <div style={style_elList_quai} onClick={this.onClick_elList_quai} >
          {items_list_quai.map((row, index) => {
            let itemComp = (row._componentId)
                ? listComps_list_quai[row._componentId]
                : <Picker_niveau2 appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} {...{ 'flux': row['flux'], 'state': row['state'], 'id_scan': row['id_scan'], 'quai': row['quai'], 'id_client': row['id_client'], 'id_contenant': row['id_contenant'], 'exutoire': row['exutoire'], 'packmat': row['packmat'], 'checked': row['checked'], 'reference_ot': row['reference_ot'], 'type': row['type'], 'color': row['color'], 'reserve': row['reserve'], 'checked_pkt': row['checked_pkt'], 'reference_ot_pkt': row['reference_ot_pkt'], }} ref={(el) => {if (el) this._elList_quai_items.push(el)}} />;
            return (<div key={row.key}>
                {itemComp}
              </div>);
          })}
          <div className="marker" ref={(el)=> this._elList_quai_endMarker = el} />
        </div>
      </div>
      
     ) : null;
    let transformPropValue_date = (input) => {
      let dech_quai_view = this.props.appActions.dataSheets['quaiView'].items.find(it => {
        return it.document_key === this.props.appActions.dataSlots['ds_selected_dech_quai_view'].document_key
      });
      if (dech_quai_view) {
        let date = new Date(dech_quai_view.ts);
        return date.toLocaleString('fr-FR', { month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' });
      } else {
        return ''
      }
    }
    
    const value_date = transformPropValue_date((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_dech_quai_view'] : ''));
    
    const style_elDate = {
      fontSize: 14.1,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'right',
     };
    let transformTextPropValue_hors_quai = (input) => {
      return "(" + input.hors_quais.filter(it => !it.id_contenant.includes("VIDE")).length + ")";
    }
    
    const textValue_hors_quai = transformTextPropValue_hors_quai((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_dech_quai_view'] : ''));
    
    const style_elHors_quai = {
      display: 'block',
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      backgroundColor: 'transparent',
     };
    const elHors_quai = this.state.elHors_quai_visible ? (
      <button className="actionFont elHors_quai" style={style_elHors_quai}>
        {textValue_hors_quai}
      </button>
     ) : null;
    
    let checked_checkbox_hors_quai = this.state.checkbox_hors_quai;
    
    const style_elCheckbox_hors_quai = {
      pointerEvents: 'auto',
     };
    const elCheckbox_hors_quai = this.state.elCheckbox_hors_quai_visible ? (
      <Checkbox className="baseFont elCheckbox_hors_quai" style={style_elCheckbox_hors_quai}  label="Hors quai" onChange={this.checkboxChanged_elCheckbox_hors_quai} checked={checked_checkbox_hors_quai === 'true' || checked_checkbox_hors_quai === true || ''+checked_checkbox_hors_quai === '1'}  />
     ) : null;
    
    let checked_checkbox_quai = this.state.checkbox_quai;
    
    const style_elCheckbox_quai = {
      pointerEvents: 'auto',
     };
    const elCheckbox_quai = this.state.elCheckbox_quai_visible ? (
      <Checkbox className="baseFont elCheckbox_quai" style={style_elCheckbox_quai}  defaultChecked label="A quai" onChange={this.checkboxChanged_elCheckbox_quai} checked={checked_checkbox_quai === 'true' || checked_checkbox_quai === true || ''+checked_checkbox_quai === '1'}  />
     ) : null;
    
    const style_elRectangle = {
      background: 'rgba(213, 213, 213, 1.000)',
     };
    let transformPropValue_list_spe = (input) => {
      let spe_groups = input.spe || [];
      
      let groups = spe_groups.reduce((acc, val) => {
        acc.add(val.group);
        return acc;
      }, new Set());
      
      groups = [...groups].sort();
      
      let items = [];
      groups.forEach(g => {
        let item = {
          group: g,
          items: spe_groups.filter(it => it.group === g).sort((a, b)=> a.flux.localeCompare(b.flux)),
        }
        items.push(item);
      })
      
      return items;
    }
    
    // Source items and any special components used for list/grid element 'list_spe'.
    let items_list_spe = [];
    let listComps_list_spe = {};
    if (Array.isArray(transformPropValue_list_spe((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_dech_quai_view'] : '')))) {
        items_list_spe = items_list_spe.concat(transformPropValue_list_spe((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_dech_quai_view'] : '')));
    } else if (transformPropValue_list_spe((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_dech_quai_view'] : ''))) {
        items_list_spe.push(transformPropValue_list_spe((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_dech_quai_view'] : '')));
    }
    this._elList_spe_items = [];
    
    const style_elList_spe = {
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elList_spe = this.state.elList_spe_visible ? (
      <div className="hasNestedComps elList_spe">
        <ul style={style_elList_spe} onClick={this.onClick_elList_spe} >
          {items_list_spe.map((row, index) => {
            let itemComp = (row._componentId)
                ? listComps_list_spe[row._componentId]
                : <Item_one_group_spe appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} {...{ 'group': row['group'], 'items': row['items'], }} ref={(el) => {if (el) this._elList_spe_items.push(el)}} />;
            return (<li key={row.key}>
                {itemComp}
              </li>);
          })}
          <div className="marker" ref={(el)=> this._elList_spe_endMarker = el} />
        </ul>
      </div>
      
     ) : null;
    
    const style_elMode_plus = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_1188685410+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '66.000%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elMode_minus = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_1953677896+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '66.000%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_mode = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      let modes = {
        0: 'Lire état actuel',
        1: 'Déclarer remplissage',
        2: 'Associer matricule',
        3: "Commander",
        4: "Vider / Echanger",
        5: "Compacter",
        6: "Déclarer flux spécifiques",
        7: "Délester",
        8: "Autre opération",
        9: "Déclarer anomalie",
      };
      return modes[input];
    }
    
    const value_mode = transformPropValue_mode((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_mode_point'] : ''));
    
    const style_elMode = {
      fontSize: 19.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const elDone = this.state.elDone_visible ? (
      <div className="hasNestedComps elDone">
        <Done ref={(el)=> this._elDone = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    const style_elShow_apercu = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_648790592+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_notif = (input) => {
      let entreprise = this.props.appActions.dataSheets['entreprise'].items[0]||{};
      let auth_user = this.props.appActions.dataSheets['auth_user'].items[0] || {};
      let profile = auth_user.profile || {}; 
      
      if (entreprise.pvd_can_add_to_tour && (profile.hdq === true ? !entreprise.hdq_cannot_add_to_tour : true)) {
        let commands = this.props.appActions.dataSheets['tournees_j'].items
        .filter(it => it.tournee == "" && it.statut === 0)
        .sort((a,b) => a.display_name.localeCompare(b.display_name));
        if (commands.length > 0) {
          return "<p style='color:white;border-radius:100%;background-color:#ff7e79;width:18px;height:18px;'>"+commands.length+"</p>";
        } else {
          return '';
        }
      } else {
        return '';
      }
    }
    
    const value_notif = transformPropValue_notif((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_today_minuit_ts'] : ''));
    
    const style_elNotif = {
      fontSize: 12.3,
      color: '#feffff',
      textAlign: 'center',
      pointerEvents: 'auto',
     };
    const elApercu = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_apercu_visible'] : '')) ? (
      <div className="hasNestedComps containerMinHeight elApercu">
        <Apercu visualStateIndex={(this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_mode_apercu'] : '')} ref={(el)=> this._elApercu = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    return (
      <div className="AppScreen Quai_view_dechScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elSpacer">
            <div />
          </div>
        </div>
        <Appbar className="navBar">
          <div className="title">{this.props.ds_selected_dech_site}</div></Appbar>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <Button className="actionFont elCancel" style={style_elCancel} onClick={this.onClick_elCancel} >
              Annuler
            </Button>
            { elButton_spe }
            { elButton_hors_quai }
            { elButton_quai }
            { elOther_operation }
            { elList_hors_quai }
            { elList_quai }
            <div className="systemFontItalic  elDate" style={style_elDate}>
              <div>{value_date !== undefined ? value_date : (<span className="propValueMissing">{this.state.date}</span>)}</div>
            </div>
            { elHors_quai }
            { elCheckbox_hors_quai }
            { elCheckbox_quai }
            <div className="elRectangle" style={style_elRectangle} />
            { elList_spe }
            <button className="actionFont elMode_plus" style={style_elMode_plus} onClick={this.onClick_elMode_plus}  />
            <button className="actionFont elMode_minus" style={style_elMode_minus} onClick={this.onClick_elMode_minus}  />
            <div className="systemFontBold  elMode" style={style_elMode}>
              <div>{value_mode !== undefined ? value_mode : (<span className="propValueMissing">{this.state.mode}</span>)}</div>
            </div>
            { elDone }
            <button className="actionFont elShow_apercu" style={style_elShow_apercu} onClick={this.onClick_elShow_apercu}  />
            <div className="systemFontRegular  elNotif" style={style_elNotif}>
              <div><div dangerouslySetInnerHTML={{__html: value_notif}}></div></div>
            </div>
            { elApercu }
          </div>
        </div>
      </div>
    )
  }
  
}
