import React, { Component } from 'react';
import './App.css';
import btn_icon_1181708088 from './images/btn_icon_1181708088.png';
import Apercu from './Apercu';
import btn_icon_back_débutdetournée from './images/btn_icon_back_débutdetournée.png';

// UI framework component imports
import Select from 'muicss/lib/react/select';
import Option from 'muicss/lib/react/option';
import Input from 'muicss/lib/react/input';
import Button from 'muicss/lib/react/button';
import Appbar from 'muicss/lib/react/appbar';

export default class DébutDeTournéeScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);
    
    let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key === this.props.appActions.dataSlots['ds_selected_tournee']);
    this.state = {
      title: (<div>Etat du camion</div>),
      title_plainText: "Etat du camion",
      name_txt: (<div>Nom</div>),
      name_txt_plainText: "Nom",
      name: (<div> </div>),
      name_plainText: " ",
      truck_txt: (<div>Camion</div>),
      truck_txt_plainText: "Camion",
      truck: (<div> </div>),
      truck_plainText: " ",
      site: (<div>Lieu de départ</div>),
      site_plainText: "Lieu de départ",
      picker_site: '',
      km_txt: (<div>Kilométrage du camion</div>),
      km_txt_plainText: "Kilométrage du camion",
      km: '',
      note_txt: (<div>Commentaire (facultatif)</div>),
      note_txt_plainText: "Commentaire (facultatif)",
      note: '',
      notif: (<div> </div>),
      notif_plainText: " ",
      //km: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_km'] : '') || '',
      km: tournee.km_start,
      picker_site: tournee.sector || 1,
      name: tournee.driver,
      truck: tournee.truck,
      note: tournee.comment
    };
  }

  componentDidMount() {
    {
      let dataSheet = this.props.appActions.dataSheets['reference_containers'];
      let serviceOptions = this.props.appActions.serviceOptions_reference_containers;
      if ( !this.props.appActions.dataSheetLoaded['reference_containers']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/all_containers", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['reference_containers'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['sectors'];
      let serviceOptions = this.props.appActions.serviceOptions_sectors;
      if ( !this.props.appActions.dataSheetLoaded['sectors']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/sectors", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['sectors'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['quaiView'];
      let serviceOptions = this.props.appActions.serviceOptions_quaiView;
      if ( !this.props.appActions.dataSheetLoaded['quaiView']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/quaiView", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['quaiView'] = true;
      }
    }
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  pickerValueChanged_elPicker_site = (event) => {
    this.setState({picker_site: event.target.value});
    
    this.props.appActions.updateDataSlot("ds_client_id", event.target.value);
  }
  
  textInputChanged_elKm = (event) => {
    this.setState({km: event.target.value});
  }
  
  textInputChanged_elNote = (event) => {
    this.setState({note: event.target.value});
  }
  
  onClick_elStart = (ev) => {
    try {
      let entreprise = this.props.appActions.dataSheets['entreprise'].items[0]||{};
      if (!this.state.picker_site || !this.state.name || !this.state.truck || (entreprise.is_km_mandatory && !this.state.km)) {
        window.alert("Formulaire incomplet.");
        return;
      } 
    
      let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key === this.props.appActions.dataSlots['ds_selected_tournee']);
    
      if (tournee && parseInt(this.state.picker_site)>0) {
        let id_client = this.props.appActions.dataSheets['sectors'].items.find(it => it.id == parseInt(this.state.picker_site));
        if (id_client) {
          this.props.appActions.updateDataSlot('ds_client_id', id_client.id_client_reference);
    
          tournee.driver_started = true;
          tournee.ts_driver_started = new Date().getTime();
          tournee.km_start = parseInt(this.state.km)||0;
          tournee.sector = parseInt(this.state.picker_site);
          tournee.comment = this.state.note || "";
    
          this.props.appActions.dataSheets['id_tournees_j'].replaceItemByRowIndex(tournee.document_key, tournee, this.props.appActions.serviceOptions_id_tournees_j);
    
          let dech_quai_view = this.props.appActions.dataSheets['quaiView'].items.filter(it => {
            return it.id_client === id_client.id_client_reference
          })[0];
    
          let dech_quai_view_simplified = {
            quais: dech_quai_view.quais,
            hors_quais: dech_quai_view.hors_quais,
            spe: dech_quai_view.spe,
            ts: dech_quai_view.ts,
            id_client: dech_quai_view.id_client,
            document_key: dech_quai_view.document_key,
          }
    
          this.props.appActions.updateDataSlot('ds_selected_dech_quai_view', JSON.parse(JSON.stringify(dech_quai_view_simplified)));
          this.props.appActions.updateDataSlot('ds_selected_dech_site', dech_quai_view.site);
    
        } else {
          window.alert("Sélectionner un lieu de départ.");
          return;
        }
      }
    } catch (error) {
      console.log(error)
      return;
    }
  
    let newVal = "4";
    
    let transformValue = (input) => {
      // This function modifies the value written to the target data slot.
      // There is a variable named 'input' that provides the input value.
      return parseInt(input);
    }
    newVal = transformValue(newVal);
    
    this.props.appActions.updateDataSlot('ds_mode_point', newVal);
  
    newVal = "0";
    this.props.appActions.updateDataSlot('ds_q_hq_view', newVal);
  
    newVal = "0";
    this.props.appActions.updateDataSlot('ds_selected_quai', newVal);
  
    newVal = "";
    this.props.appActions.updateDataSlot('ds_scanned_id', newVal);
  
    // Go to screen 'quai_view_dech_start'
    this.props.appActions.goToScreen('quai_view_dech_start', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elShow_apercu = (ev) => {
    let newVal = "1";
    this.props.appActions.updateDataSlot('ds_apercu_visible', newVal);
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };
    const style_elTitle = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const style_elRectangle = {
      background: 'rgba(213, 213, 213, 1.000)',
     };
    const style_elCard_outer = {
      backgroundColor: 'white',
      filter: 'drop-shadow(0.0px 2.3px 18px rgba(0, 0, 0, 0.1600))',
      overflow: 'visible',
     };
    const style_elName_txt = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    let transformPropValue_name = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return this.state.name;
    }
    
    const value_name = transformPropValue_name((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_tournee'] : ''));
    
    const style_elName = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elTruck_txt = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    let transformPropValue_truck = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return this.state.truck;
    }
    
    const value_truck = transformPropValue_truck((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_tournee'] : ''));
    
    const style_elTruck = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elSite = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    
    let selection_picker_site = this.state.picker_site;
    // Source datasheet and selected data column for picker element 'picker_site'
    const dataSource_picker_site = this.props.appActions.getDataSheet('sectors');
    const valueColumnName_picker_site = 'id';
    const labelColumnName_picker_site = 'label';
    
    const style_elPicker_site = {
      pointerEvents: 'auto',
     };
    const style_elKm_txt = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    
    const style_elKm = {
      display: 'block',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elNote_txt = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    
    const style_elNote = {
      display: 'block',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_elStart = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elShow_apercu = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_1181708088+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_notif = (input) => {
      let entreprise = this.props.appActions.dataSheets['entreprise'].items[0]||{};
      let auth_user = this.props.appActions.dataSheets['auth_user'].items[0] || {};
      let profile = auth_user.profile || {}; 
      
      if (entreprise.pvd_can_add_to_tour && (profile.hdq === true ? !entreprise.hdq_cannot_add_to_tour : true)) {
        let commands = this.props.appActions.dataSheets['tournees_j'].items
        .filter(it => it.tournee == "" && it.statut === 0)
        .sort((a,b) => a.display_name.localeCompare(b.display_name));
        if (commands.length > 0) {
          return "<p style='color:white;border-radius:100%;background-color:#ff7e79;width:18px;height:18px;'>"+commands.length+"</p>";
        } else {
          return '';
        }
      } else {
        return '';
      }
    }
    
    const value_notif = transformPropValue_notif((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_today_minuit_ts'] : ''));
    
    const style_elNotif = {
      fontSize: 12.3,
      color: '#feffff',
      textAlign: 'center',
      pointerEvents: 'auto',
     };
    const elApercu = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_apercu_visible'] : '')) ? (
      <div className="hasNestedComps containerMinHeight elApercu">
        <Apercu visualStateIndex={(this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_mode_apercu'] : '')} ref={(el)=> this._elApercu = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    return (
      <div className="AppScreen DébutDeTournéeScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elSpacer">
            <div />
          </div>
          
          <div className="elTitle">
            <div className="headlineFont" style={style_elTitle}>
              <div>{this.state.title}</div>
            </div>
          </div>
          
          <div className="elRectangle">
            <div style={style_elRectangle} />
          </div>
          
          <div className="flowRow flowRow_DébutDeTournéeScreen_elCard_2008976711">
          <div className="elCard" style={style_elCard_outer}>
            <div className="cardBg" />
          </div>
          
          <div className="elName_txt">
            <div className="baseFont" style={style_elName_txt}>
              <div>{this.state.name_txt}</div>
            </div>
          </div>
          
          <div className="elName">
            <div className="baseFont" style={style_elName}>
              <div>{value_name !== undefined ? value_name : (<span className="propValueMissing">{this.state.name}</span>)}</div>
            </div>
          </div>
          
          <div className="elTruck_txt">
            <div className="baseFont" style={style_elTruck_txt}>
              <div>{this.state.truck_txt}</div>
            </div>
          </div>
          
          <div className="elTruck">
            <div className="baseFont" style={style_elTruck}>
              <div>{value_truck !== undefined ? value_truck : (<span className="propValueMissing">{this.state.truck}</span>)}</div>
            </div>
          </div>
          
          <div className="elSite">
            <div className="baseFont" style={style_elSite}>
              <div>{this.state.site}</div>
            </div>
          </div>
          
          <div className="elPicker_site">
            <Select className="baseFont" style={style_elPicker_site}  onChange={this.pickerValueChanged_elPicker_site} value={selection_picker_site} >
              {dataSource_picker_site.items.every(item => {
                return item[valueColumnName_picker_site] !== selection_picker_site;
              }) ? <Option value=''/> : null}
              {dataSource_picker_site.items.map(item => {
                const colValue = item[valueColumnName_picker_site];
                const labelColValue = item[labelColumnName_picker_site];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="elKm_txt">
            <div className="baseFont" style={style_elKm_txt}>
              <div>{this.state.km_txt}</div>
            </div>
          </div>
          
          <div className="elKm">
            <Input className="baseFont" style={style_elKm} type="number" hint="Kilométrage" onChange={this.textInputChanged_elKm} value={this.state.km}  />
          </div>
          
          <div className="elNote_txt">
            <div className="baseFont" style={style_elNote_txt}>
              <div>{this.state.note_txt}</div>
            </div>
          </div>
          
          <div className="elNote">
            <Input className="baseFont" style={style_elNote} type="text" hint="Commentaire" onChange={this.textInputChanged_elNote} value={this.state.note}  />
          </div>
          
          </div>
          <div className="elSpacer2">
            <div />
          </div>
          
          <div className="elStart">
            <Button className="actionFont" style={style_elStart}  color="accent" onClick={this.onClick_elStart} >
              Valider
            </Button>
          </div>
          
          <div className="elSpacerCopy">
            <div />
          </div>
        </div>
        <Appbar className="navBar">
          <div className="title">Début de tournée</div>  <div className="backBtn" onClick={ (ev)=>{ this.props.appActions.goBack() } }><img src={btn_icon_back_débutdetournée} alt="" style={{width: '50%'}} /></div>
        </Appbar>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <button className="actionFont elShow_apercu" style={style_elShow_apercu} onClick={this.onClick_elShow_apercu}  />
            <div className="systemFontRegular  elNotif" style={style_elNotif}>
              <div><div dangerouslySetInnerHTML={{__html: value_notif}}></div></div>
            </div>
            { elApercu }
          </div>
        </div>
      </div>
    )
  }
  
}
