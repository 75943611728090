// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_quais_numbers extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['numbers'] = "1";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['numbers'] = "2";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['numbers'] = "3";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['numbers'] = "4";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['numbers'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['numbers'] = "6";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['numbers'] = "7";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['numbers'] = "8";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['numbers'] = "9";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['numbers'] = "10";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['numbers'] = "11";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['numbers'] = "12";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['numbers'] = "13";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['numbers'] = "14";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['numbers'] = "15";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['numbers'] = "16";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['numbers'] = "17";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['numbers'] = "18";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['numbers'] = "19";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['numbers'] = "20";
    item.key = key++;
  }

}
