import React, { Component } from 'react';
import './App.css';
import Done_fin from './Done_fin';
import btn_icon_472823535 from './images/btn_icon_472823535.png';
import Apercu from './Apercu';
import btn_icon_back_findetournée from './images/btn_icon_back_findetournée.png';

// UI framework component imports
import Select from 'muicss/lib/react/select';
import Option from 'muicss/lib/react/option';
import Input from 'muicss/lib/react/input';
import Button from 'muicss/lib/react/button';
import Appbar from 'muicss/lib/react/appbar';

export default class FinDeTournéeScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);
    
    let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key === this.props.appActions.dataSlots['ds_selected_tournee']);
    this.state = {
      title: (<div>Etat du camion</div>),
      title_plainText: "Etat du camion",
      site: (<div>Lieu d’arrivée</div>),
      site_plainText: "Lieu d’arrivée",
      picker_site: '',
      km_txt: (<div>Kilométrage du camion</div>),
      km_txt_plainText: "Kilométrage du camion",
      km: '',
      fuel_txt: (<div>Carburant en Litres (facultatif)</div>),
      fuel_txt_plainText: "Carburant en Litres (facultatif)",
      fuel: '',
      note_txt: (<div>Commentaire (facultatif)</div>),
      note_txt_plainText: "Commentaire (facultatif)",
      note: '',
      elDone_fin_visible: false,
      //km: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_km'] : '') || '',
      km: tournee.km_stop,
      picker_site: tournee.sector_stop || tournee.sector || 1,
      note: tournee.comment_stop,
      fuel: tournee.fuel_stop,
    };
  }

  componentDidMount() {
    {
      let dataSheet = this.props.appActions.dataSheets['reference_containers'];
      let serviceOptions = this.props.appActions.serviceOptions_reference_containers;
      if ( !this.props.appActions.dataSheetLoaded['reference_containers']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/all_containers", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['reference_containers'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['sectors'];
      let serviceOptions = this.props.appActions.serviceOptions_sectors;
      if ( !this.props.appActions.dataSheetLoaded['sectors']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/sectors", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['sectors'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['quaiView'];
      let serviceOptions = this.props.appActions.serviceOptions_quaiView;
      if ( !this.props.appActions.dataSheetLoaded['quaiView']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/quaiView", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['quaiView'] = true;
      }
    }
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  pickerValueChanged_elPicker_site = (event) => {
    this.setState({picker_site: event.target.value});
  }
  
  textInputChanged_elKm = (event) => {
    this.setState({km: event.target.value});
  }
  
  textInputChanged_elFuel = (event) => {
    this.setState({fuel: event.target.value});
  }
  
  textInputChanged_elNote = (event) => {
    this.setState({note: event.target.value});
  }
  
  onClick_elStart = (ev) => {
    let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key === this.props.appActions.dataSlots['ds_selected_tournee']);
    let entreprise = this.props.appActions.dataSheets['entreprise'].items[0]||{};
    
    if (tournee && parseInt(this.state.picker_site)>0 && (entreprise.is_km_mandatory==false || parseInt(this.state.km)>0)) {
    
      tournee.driver_stopped = true;
      tournee.ts_driver_stopped = new Date().getTime();
      tournee.km_stop = parseInt(this.state.km)||0;
      tournee.sector_stop = parseInt(this.state.picker_site);
      tournee.comment_stop = this.state.note || "";
      tournee.fuel_stop = parseInt(this.state.fuel) || 0
      
      /////////////////////////// UPDATE TRUCK LOAD /////////////////
      // tournee.loaded = false;
      // tournee.loaded_is_full = false;
      // tournee.load_detail = {};
    
      this.props.appActions.dataSheets['id_tournees_j'].replaceItemByRowIndex(tournee.document_key, tournee, this.props.appActions.serviceOptions_id_tournees_j);
    
      try {
        let ots_tour = this.props.appActions.dataSheets['tournees_j'].items.filter(it => it.tournee === tournee.document_key);
        ots_tour.forEach(ot => {
          try {
            let update = {
              document_key: ot.document_key,
              document_ref: ot.document_ref,
            }
            if (tournee.km_start) {
              update.km_start = tournee.km_start;
            }
            if (tournee.comment) {
              update.comment_start = tournee.comment;
            }
            if (tournee.km_stop) {
              update.km_stop = tournee.km_stop;
            }
            if (tournee.comment_stop) {
              update.comment_stop = tournee.comment_stop;
            }
            if (tournee.fuel_stop) {
              update.fuel_stop = tournee.fuel_stop;
            }
            this.props.appActions.dataSheets['tournees_j'].replaceItemByRowIndex(update.document_key, update, this.props.appActions.serviceOptions_tournees_j);
          } catch (e) {
            console.log(e)
          };
        })
      } catch (error) {
        console.log(error);
      }
      
      // get quaiView of the starting site
      let id_client_reference_sector = (this.props.appActions.dataSheets['sectors'].items.find(it => it.id == tournee.sector_stop)||{}).id_client_reference;
      let quaiView = this.props.appActions.dataSheets['quaiView'].items.find(it => it.id_client == id_client_reference_sector);
      
      if (quaiView && tournee.loaded && !tournee.loaded_is_full && tournee.load_detail && tournee.load_detail.type) {
        // truck has empty container
        let new_hq = {
          id_scan: tournee.load_detail.id_scan || "",
          id_client: id_client_reference_sector,
          id_contenant: "VIDE",
          flux: tournee.load_detail.type,
          packmat: 0,
          state: 1,
          type: tournee.load_detail.type,
        };
        quaiView.hors_quais.push(new_hq);
        
        let update = {
          document_key: quaiView.document_key,
          document_ref: quaiView.document_ref,
          hors_quais: quaiView.hors_quais,
          //ts: new Date().getTime()
        };
        this.props.appActions.dataSheets['quaiView'].replaceItemByRowIndex(update.document_key, update, this.props.appActions.serviceOptions_quaiView);
        
        /////////////////////////// UPDATE TRUCK LOAD /////////////////
        try {
          let updateT = {
            document_key: tournee.document_key,
            document_ref: tournee.document_ref,
            loaded: false,
            loaded_is_full: false,
            load_detail: {},
          };
          this.props.appActions.dataSheets['id_tournees_j'].replaceItemByRowIndex(tournee.document_key, updateT, this.props.appActions.serviceOptions_id_tournees_j);
        } catch (e) {
          console.log(e);
        }
    
    
      }
      
    } else {
      window.alert("Formulaire incomplet.");
      return;
    }
    
  
    this.setState({elDone_fin_visible: true});
  
  }
  
  
  onClick_elDone_fin = (ev) => {
    // Go to screen 'Menu principal'
    this.props.appActions.goToScreen('menuPrincipal', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elShow_apercu = (ev) => {
    let newVal = "1";
    this.props.appActions.updateDataSlot('ds_apercu_visible', newVal);
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };
    const style_elTitle = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const style_elRectangle = {
      background: 'rgba(213, 213, 213, 1.000)',
     };
    const style_elCard_outer = {
      backgroundColor: 'white',
      filter: 'drop-shadow(0.0px 2.3px 18px rgba(0, 0, 0, 0.1600))',
      overflow: 'visible',
     };
    const style_elSite = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    
    let selection_picker_site = this.state.picker_site;
    // Source datasheet and selected data column for picker element 'picker_site'
    const dataSource_picker_site = this.props.appActions.getDataSheet('sectors');
    const valueColumnName_picker_site = 'id';
    const labelColumnName_picker_site = 'label';
    
    const style_elPicker_site = {
      pointerEvents: 'auto',
     };
    const style_elKm_txt = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    
    const style_elKm = {
      display: 'block',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elFuel_txt = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    
    const style_elFuel = {
      display: 'block',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elNote_txt = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    
    const style_elNote = {
      display: 'block',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_elStart = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elDone_fin = {
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elDone_fin = this.state.elDone_fin_visible ? (
      <div className="hasNestedComps elDone_fin" style={style_elDone_fin} onClick={this.onClick_elDone_fin} >
        <Done_fin ref={(el)=> this._elDone_fin = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    const style_elShow_apercu = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_472823535+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elApercu = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_apercu_visible'] : '')) ? (
      <div className="hasNestedComps containerMinHeight elApercu">
        <Apercu visualStateIndex={(this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_mode_apercu'] : '')} ref={(el)=> this._elApercu = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    return (
      <div className="AppScreen FinDeTournéeScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elSpacer">
            <div />
          </div>
          
          <div className="elTitle">
            <div className="headlineFont" style={style_elTitle}>
              <div>{this.state.title}</div>
            </div>
          </div>
          
          <div className="elRectangle">
            <div style={style_elRectangle} />
          </div>
          
          <div className="flowRow flowRow_FinDeTournéeScreen_elCard_1340743110">
          <div className="elCard" style={style_elCard_outer}>
            <div className="cardBg" />
          </div>
          
          <div className="elSite">
            <div className="baseFont" style={style_elSite}>
              <div>{this.state.site}</div>
            </div>
          </div>
          
          <div className="elPicker_site">
            <Select className="baseFont" style={style_elPicker_site}  onChange={this.pickerValueChanged_elPicker_site} value={selection_picker_site} >
              {dataSource_picker_site.items.every(item => {
                return item[valueColumnName_picker_site] !== selection_picker_site;
              }) ? <Option value=''/> : null}
              {dataSource_picker_site.items.map(item => {
                const colValue = item[valueColumnName_picker_site];
                const labelColValue = item[labelColumnName_picker_site];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="elKm_txt">
            <div className="baseFont" style={style_elKm_txt}>
              <div>{this.state.km_txt}</div>
            </div>
          </div>
          
          <div className="elKm">
            <Input className="baseFont" style={style_elKm} type="number" hint="Kilométrage" onChange={this.textInputChanged_elKm} value={this.state.km}  />
          </div>
          
          <div className="elFuel_txt">
            <div className="baseFont" style={style_elFuel_txt}>
              <div>{this.state.fuel_txt}</div>
            </div>
          </div>
          
          <div className="elFuel">
            <Input className="baseFont" style={style_elFuel} type="number" hint="Litres" onChange={this.textInputChanged_elFuel} value={this.state.fuel}  />
          </div>
          
          <div className="elNote_txt">
            <div className="baseFont" style={style_elNote_txt}>
              <div>{this.state.note_txt}</div>
            </div>
          </div>
          
          <div className="elNote">
            <Input className="baseFont" style={style_elNote} type="text" hint="Commentaire" onChange={this.textInputChanged_elNote} value={this.state.note}  />
          </div>
          
          </div>
          <div className="elSpacer2">
            <div />
          </div>
          
          <div className="elStart">
            <Button className="actionFont" style={style_elStart}  color="accent" onClick={this.onClick_elStart} >
              Valider
            </Button>
          </div>
          
          <div className="elSpacerCopy">
            <div />
          </div>
        </div>
        <Appbar className="navBar">
          <div className="title">Fin de tournée</div>  <div className="backBtn" onClick={ (ev)=>{ this.props.appActions.goBack() } }><img src={btn_icon_back_findetournée} alt="" style={{width: '50%'}} /></div>
        </Appbar>
        
        <div className="screenFgContainer">
          <div className="foreground">
            { elDone_fin }
            <button className="actionFont elShow_apercu" style={style_elShow_apercu} onClick={this.onClick_elShow_apercu}  />
            { elApercu }
          </div>
        </div>
      </div>
    )
  }
  
}
