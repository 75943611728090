import React, { Component } from 'react';
import './App.css';
import btn_icon_1779270817 from './images/btn_icon_1779270817.png';
import img_state1_elEllipse171166065 from './images/Play_state1_elEllipse171166065.png';
import img_state2_elEllipse171166065 from './images/Play_state2_elEllipse171166065.png';
import img_state3_elEllipse171166065 from './images/Play_state3_elEllipse171166065.png';

export default class Play extends Component {

  // Properties used by this component:
  // statut, dataSheetRow, visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  // --- Functions for component state index 0 (1 of 4) --- 
  
  renderState0() {
    let transformPropValue_charge = (input) => {
      if (input == 0) {
        return true;
      } else {
        return false
      }
    }
    
    const enabledValue_charge = transformPropValue_charge(this.props.statut);
    
    const style_state0_elCharge = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_1779270817+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
     };
    
    return (
      <div className="Play">
        <div className="foreground">
          <button className="actionFont state0_elCharge" style={style_state0_elCharge}  disabled={''+enabledValue_charge !== 'true'}  />
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 4) --- 
  
  renderState1() {
    
    const style_state1_elEllipse171166065 = {
      backgroundImage: 'url('+img_state1_elEllipse171166065+')',
      backgroundSize: '100% 100%',
     };
    
    const style_state1_elV751851499633 = {
      background: 'rgba(235, 235, 235, 1.000)',
     };
    
    const style_state1_elV501345751315 = {
      background: 'rgba(235, 235, 235, 1.000)',
     };
    
    const style_state1_elV25 = {
      background: 'rgba(235, 235, 235, 1.000)',
     };
    
    return (
      <div className="Play">
        <div className="foreground">
          <div className="state1_elEllipse171166065" style={style_state1_elEllipse171166065} />
          <div className="state1_elV751851499633" style={style_state1_elV751851499633} />
          <div className="state1_elV501345751315" style={style_state1_elV501345751315} />
          <div className="state1_elV25" style={style_state1_elV25} />
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 2 (3 of 4) --- 
  
  renderState2() {
    
    const style_state2_elEllipse171166065 = {
      backgroundImage: 'url('+img_state2_elEllipse171166065+')',
      backgroundSize: '100% 100%',
     };
    
    const style_state2_elV751851499633 = {
      background: 'rgba(235, 235, 235, 1.000)',
     };
    
    const style_state2_elV501345751315 = {
      background: 'rgba(235, 235, 235, 1.000)',
     };
    
    return (
      <div className="Play">
        <div className="foreground">
          <div className="state2_elEllipse171166065" style={style_state2_elEllipse171166065} />
          <div className="state2_elV751851499633" style={style_state2_elV751851499633} />
          <div className="state2_elV501345751315" style={style_state2_elV501345751315} />
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 3 (4 of 4) --- 
  
  renderState3() {
    
    const style_state3_elEllipse171166065 = {
      backgroundImage: 'url('+img_state3_elEllipse171166065+')',
      backgroundSize: '100% 100%',
     };
    
    const style_state3_elV751851499633 = {
      background: 'rgba(235, 235, 235, 1.000)',
     };
    
    return (
      <div className="Play">
        <div className="foreground">
          <div className="state3_elEllipse171166065" style={style_state3_elEllipse171166065} />
          <div className="state3_elV751851499633" style={style_state3_elV751851499633} />
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
      case 2:
        return this.renderState2();
      case 3:
        return this.renderState3();
    }
  }
  
}
