import React, { Component } from 'react';
import './App.css';
import btn_icon_207956291 from './images/btn_icon_207956291.png';
import Play from './Play';
import Prevent_interraction from './Prevent_interraction';

export default class Ot_tournee_j extends Component {

  // Properties used by this component:
  // flux, document_key, statut, action, exutoire, remark, dataSheetRow, hh

  constructor(props) {
    super(props);
    
    this.state = {
      site: (<div>&lt;no value from script&gt;</div>),
      site_plainText: "<no value from script>",
      action: (<div> </div>),
      action_plainText: " ",
      flux: (<div> </div>),
      flux_plainText: " ",
      type: (<div> </div>),
      type_plainText: " ",
      exutoire: (<div> </div>),
      exutoire_plainText: " ",
      remark: (<div> </div>),
      remark_plainText: " ",
      elChuter_visible: true,
    };
  }

  componentDidMount() {
    if (this.props.statut == 0) {
      this.setState({elChuter_visible: true});
    } else {
      this.setState({elChuter_visible: false});
    }
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
    if (this.props.statut == 0 && this.state.elChuter_visible === false) {
      this.setState({elChuter_visible: true});
    } else if (this.props.statut != 0 && this.state.elChuter_visible === true) {
      this.setState({elChuter_visible: false});
    }
  }

  onClick_elChuter = (ev) => {
    let getClientByName = (name) => {
      try {
      	return this.props.appActions.dataSheets['clients'].items.filter(it => it.name == name)[0];
      } catch (e) {
        return false;
      }
    }
    
    let id_client = this.props.dataSheetRow.id_client ? this.props.dataSheetRow.id_client : (getClientByName(this.props.dataSheetRow.client).document_key || '');
    
    this.props.appActions.updateDataSlot('ds_saved_picture_document_key', this.props.dataSheetRow.document_key);
    this.props.appActions.updateDataSlot('ds_saved_picture_document_ref', this.props.dataSheetRow.document_ref);
    this.props.appActions.updateDataSlot('ds_client_id', id_client);
    this.props.appActions.updateDataSlot('ds_contenant_id', this.props.dataSheetRow.container_id);
    this.props.appActions.updateDataSlot('ds_selected_command', this.props.dataSheetRow);
    this.props.appActions.updateDataSlot('ds_site_depot', this.props.dataSheetRow.client);
    this.props.appActions.updateDataSlot('ds_matiere_enlevement', this.props.dataSheetRow.flux);
  
    let newVal = "";
    this.props.appActions.updateDataSlot('ds_scanned_id', newVal);
  
    this.props.appActions.updateDataSlot('ds_comentaire', "");
    this.props.appActions.updateDataSlot('ds_volume', "");
    this.props.appActions.updateDataSlot('ds_tonnage', "");
    this.props.appActions.updateDataSlot('ds_saved_picture', "");
  
    // Go to screen 'Chuter un OT'
    this.props.appActions.goToScreen('chuterUnOT', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elPlay = (ev) => {
    let newVal = this.props.dataSheetRow;
    this.props.appActions.updateDataSlot('ds_selected_command', newVal);
  
    this.props.appActions.updateDataSlot('ds_client_id', this.props.dataSheetRow.id_client);
    
    // only for non started ots or on going
    if (this.props.statut >= 9 || this.props.statut === 0) {
      let a = window.confirm("Prendre en charge l'opération?");
      if (a) {
        let rights_app = this.props.appActions.dataSheets['auth_user'].items[0].rights_app || {
          "READ": true,
          "STATES": true,
          "SCAN": true,
          "ORDER": true,
          "EMPTY": true,
          "PACKMAT": true,
          "SPE": true,
          "SHED": true,
          "OTHER": false,
          "ISSUE": false,
        };
        if (this.props.action == 2 && !rights_app['PACKMAT']) {
          window.alert("Utilisateur non autorisé à compacter.");
          return;
        }
      } else {
        return
      }
    } else {
      return;
    }
  
    newVal = "0";
    this.props.appActions.updateDataSlot('ds_vide_autre_view', newVal);
  
    this.props.appActions.updateDataSlot('ds_comentaire', "");
    this.props.appActions.updateDataSlot('ds_volume', "");
    this.props.appActions.updateDataSlot('ds_tonnage', "");
    this.props.appActions.updateDataSlot('ds_saved_picture', "");
    this.props.appActions.updateDataSlot('ds_scanned_id', this.props.dataSheetRow.id_scan || "");
    this.props.appActions.updateDataSlot('ds_source_anomalie', "solve");
    this.props.appActions.updateDataSlot('ds_current_anomalies', []);
  
    // Go to screen based on value in data slot 'ds_selected_command'
    this.props.appActions.goToScreen(this.targetTable_switch1(this.transformTargetScreenValue_switch1((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_command'] : ''))), { transitionId: 'fadeIn' });
  
  }
  
  
  transformTargetScreenValue_switch1 = (input) => {
    let action = this.props.appActions.dataSheets['operations'].items.find(it => it.id == input.action);
    
    if (action.id == 10) {
      return "solve";
    } else if (action.id != 0 && action.action==0) {
      // mouvement autre que mvt de benne
      return "1";
    } else if (action.action==2) {
      // packmat
      this.props.appActions.updateDataSlot("ds_client_id", this.props.appActions.dataSlots['ds_selected_command'].id_client);
      this.props.appActions.updateDataSlot("ds_mode_point", 5);
      this.props.appActions.updateDataSlot("ds_selected_quai", 0);
      this.props.appActions.updateDataSlot("ds_scanned_id", "");
      this.props.appActions.updateDataSlot("ds_q_hq_view", 0);
      this.props.appActions.updateDataSlot("ds_source_anomalie", "tour");
    
      let dech_quai_view = this.props.appActions.dataSheets['quaiView'].items.find(it => {
        return it.id_client === this.props.appActions.dataSlots['ds_client_id']
      });
    
      let dech_quai_view_simplified = {
        quais: dech_quai_view.quais,
        hors_quais: dech_quai_view.hors_quais,
        spe: dech_quai_view.spe,
        ts: dech_quai_view.ts,
        id_client: dech_quai_view.id_client,
        document_key: dech_quai_view.document_key,
      }
    
      this.props.appActions.updateDataSlot('ds_selected_dech_quai_view', JSON.parse(JSON.stringify(dech_quai_view_simplified)));
      this.props.appActions.updateDataSlot('ds_selected_dech_site', dech_quai_view.site);
    
      return "2";
    } else {
      return input.statut.toString() || "0";
    }
  }
  
  
  targetTable_switch1 = (targetId) => {
    if (targetId === '0')
      return 'priseEnChargeV3';
    else if (targetId === '9')
      return 'echange';
    else if (targetId === '10')
      return 'détailVidage';
    else if (targetId === '11')
      return 'depotV3';
    else if (targetId === '1')
      return 'valider_simple';
    else if (targetId === '2')
      return 'quai_view_dech';
    else if (targetId === 'solve')
      return 'anomalie';
  }
  
  render() {
    let transformFillColorPropValue_background = (input) => {
      if (input == 1 || input == 3 || input == 5) {
        return "#00e2d033";
      } else if (input == 2 || input == 4  || input == 6) {
        return "#FF7E79";
      } else if (input == 7 || input == 8 || input >= 9) {
        return "#ebebeb";
      } else {
        return "#FFFFFF";
      }
    }
    const fillColorValue_background = transformFillColorPropValue_background(this.props.statut);
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: (fillColorValue_background && fillColorValue_background.length > 0) ? fillColorValue_background : 'white',
     };
    
    const style_elRectangle = {
      background: 'rgba(213, 213, 213, 1.000)',
     };
    let transformPropValue_site = (input) => {
      /**
      if (this.props.dataSheetRow.action == 3 && this.props.dataSheetRow.hh != 'DI') {
        return input.exutoire.label;
      } else {
        return input.display_name;
      }
      **/
      return input.display_name;
    }
    
    const value_site = transformPropValue_site(this.props.dataSheetRow);
    
    const style_elSite = {
      fontSize: 14.1,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_action = (input) => {
      let action = input.action_realise != undefined ? input.action_realise : input.action;
      return (this.props.appActions.dataSheets['operations'].items.find(it => it.id == action)||{}).label || "";
    }
    
    const value_action = transformPropValue_action(this.props.dataSheetRow);
    
    const style_elAction = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };
    let transformPropValue_flux = (input) => {
      return input + (this.props.dataSheetRow.id_scan ? (" - " + this.props.dataSheetRow.id_scan) : "");
    }
    
    const value_flux = transformPropValue_flux(this.props.flux);
    
    const style_elFlux = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_type = (input) => {
      return input.type + (this.props.dataSheetRow.quai ? (" - quai " + this.props.dataSheetRow.quai) : " - Hors quai");
    }
    
    const value_type = transformPropValue_type(this.props.dataSheetRow);
    
    const style_elType = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_exutoire = (input) => {
      if (this.props.action == 1 || this.props.action == 4) {
        if (this.props.dataSheetRow.exutoire_realise && this.props.dataSheetRow.exutoire_realise.label) {
          return this.props.dataSheetRow.exutoire_realise.label;
        } else {
          return input ? input.label : '';
        }
      } else {
        return '';
      }
    }
    
    const value_exutoire = transformPropValue_exutoire(this.props.exutoire);
    
    const style_elExutoire = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_remark = (input) => {
      if (this.props.dataSheetRow.action == 3 && this.props.dataSheetRow.hh != 'DI') {
        return "Prendre la benne à " + this.props.dataSheetRow.display_name;
      } else {
        return "-" + (input : '');
      }
    }
    
    const value_remark = transformPropValue_remark(this.props.remark);
    
    const style_elRemark = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_chuter = (input) => {
      if (input == 0) {
        return true;
      } else {
        return false
      }
    }
    
    const enabledValue_chuter = transformPropValue_chuter(this.props.statut);
    
    const style_elChuter = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_207956291+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elChuter = this.state.elChuter_visible ? (
      <button className="actionFont elChuter" style={style_elChuter}  disabled={''+enabledValue_chuter !== 'true'} onClick={this.onClick_elChuter}  />
     ) : null;
    let transformStateValue_elPlay = (input) => {
      if (input == 9) {
        return 1;
      } else if (input == 10) {
        return 2;
      } else if (input == 11) {
        return 3;
      } else {
        return 0;
      }
    }
    const style_elPlay = {
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformVisiblePropValue_play = (input) => {
      if (input == 1 || input == 3 || input == 5) {
        return false;
      } else if (input == 2 || input == 4  || input == 6) {
        return false;
      } else {
        return true;
      }
    }
    const elPlay = ((val) => { return val === "true" || val == true || val == 1 })(transformVisiblePropValue_play(this.props.statut)) ? (
      <div className="hasNestedComps elPlay" style={style_elPlay} onClick={this.onClick_elPlay} >
        <Play statut={this.props.statut} dataSheetRow={this.props.dataSheetRow} visualStateIndex={transformStateValue_elPlay(this.props.statut)} ref={(el)=> this._elPlay = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    let transformVisiblePropValue_prevent_interraction = (input) => {
      if (input == 9 || input == 10 || input == 11) {
        return false;
      } else {
        let filtered_items = this.props.appActions.dataSheets['tournees_j'].items.filter(it => it.tournee == this.props.appActions.dataSlots['ds_selected_tournee'])
      
        if (filtered_items.some(it => it.statut == 9 || it.statut == 10 || it.statut == 11)) {
          return true;
        } else {
          return false
        }
      }
    }
    const elPrevent_interraction = ((val) => { return val === "true" || val == true || val == 1 })(transformVisiblePropValue_prevent_interraction(this.props.statut)) ? (
      <div className="hasNestedComps containerMinHeight elPrevent_interraction">
        <Prevent_interraction ref={(el)=> this._elPrevent_interraction = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    return (
      <div className="Ot_tournee_j">
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div style={style_elBackground} />
          </div>
          
          <div className="elRectangle" style={style_elRectangle} />
        </div>
        
        <div className="layoutFlow">
          <div className="elSite">
            <div className="systemFontBold" style={style_elSite}>
              <div>{value_site !== undefined ? value_site : (<span className="propValueMissing">{this.state.site}</span>)}</div>
            </div>
          </div>
          
          <div className="flowRow flowRow_Ot_tournee_j_elAction_2061235096">
          <div className="elAction">
            <div className="baseFont" style={style_elAction}>
              <div>{value_action !== undefined ? value_action : (<span className="propValueMissing">{this.state.action}</span>)}</div>
            </div>
          </div>
          
          </div>
          <div className="elFlux">
            <div className="baseFont" style={style_elFlux}>
              <div>{value_flux !== undefined ? value_flux : (<span className="propValueMissing">{this.state.flux}</span>)}</div>
            </div>
          </div>
          
          <div className="elType">
            <div className="baseFont" style={style_elType}>
              <div>{value_type !== undefined ? value_type : (<span className="propValueMissing">{this.state.type}</span>)}</div>
            </div>
          </div>
          
          <div className="elExutoire">
            <div className="baseFont" style={style_elExutoire}>
              <div>{value_exutoire !== undefined ? value_exutoire : (<span className="propValueMissing">{this.state.exutoire}</span>)}</div>
            </div>
          </div>
          
          <div className="elRemark">
            <div className="baseFont" style={style_elRemark}>
              <div>{value_remark !== undefined ? value_remark : (<span className="propValueMissing">{this.state.remark}</span>)}</div>
            </div>
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
        </div>
        
        <div className="foreground">
          { elChuter }
          { elPlay }
          { elPrevent_interraction }
        </div>
      </div>
    )
  }
  
}
