import React, { Component } from 'react';
import './App.css';
import img_elN0_0 from './images/Picker_specifiques_elImage_140921196.png';
import img_elN0_2 from './images/Picker_niveau2_elN0_2_732410671.png';
import img_elN0_3 from './images/Picker_niveau2_elN0_3_997716689.png';
import img_elN0_4 from './images/Picker_niveau2_elN0_4_1296709412.png';
import Image_fill from './Image_fill';
import Packmat from './Packmat';
import Client_diff from './Client_diff';

export default class Picker_echange2 extends Component {

  // Properties used by this component:
  // flux, state, id_scan, quai, id_client, id_contenant, exutoire, checked, reference_ot, type, color, id_tournee, packmat, reserve

  constructor(props) {
    super(props);
    
    this.state = {
      flux: (<div>BOIS</div>),
      flux_plainText: "BOIS",
      id_scan: (<div> </div>),
      id_scan_plainText: " ",
      quai: (<div> </div>),
      quai_plainText: " ",
      state: (<div> </div>),
      state_plainText: " ",
  		state: this.props.state,
        id_scan: this.props.id_scan,
        order: false,
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elImage_fill = (ev) => {
    let a = window.confirm("Confirmer la rotation?");
    if (!a) {
      return;
    }
    
    let current_command = this.props.appActions.dataSlots['ds_selected_command'];
    
    // CLOTURER L'OT et passer une nouvelle commande
    // demander s'il veut se l'attribuer
    let checkOperation = (command) => {
      if (!command.ts_vidage && (command.action === 1 || command.action === 4 || command.action === 5)) {
        return command.hh=='VIDE' ? 6 : 0;
      } else {
        return command.action;
      }
    }
    
    let ts = new Date();
    ts =  ts.getTime();
    
    let client_depot = this.props.appActions.dataSheets['clients'].items.filter(it => it.document_key == this.props.appActions.dataSlots['ds_selected_command'].site)[0] || {};
    
    /*
    let successDepot = () => {
      let update_ot = {
        document_ref: this.props.appActions.dataSlots['ds_selected_command'].document_ref,
        document_key: this.props.appActions.dataSlots['ds_selected_command'].document_key,
        ts_depot: ts,
        coordinates_depot: {
          lat: window.previousPos.coords.latitude, 
          lng: window.previousPos.coords.longitude,
        },
        user_id_depot: this.props.appActions.dataSlots['ds_userid'],
        user_name_depot: this.props.appActions.dataSlots['ds_username'],
        statut: 1, // ot validé et en attente de validation
        site_depot_name: client_depot.name,
        site_depot: client_depot.document_key,
        site_depot_quai: 0,
        site_depot_hq: true,
        action_realise: checkOperation(this.props.appActions.dataSlots['ds_selected_command']),
      }
      this.props.appActions.updateDataSlot('ds_selected_command', {...this.props.appActions.dataSlots['ds_selected_command'], ...update_ot});
      this.props.appActions.dataSheets['tournees_j'].replaceItemByRowIndex(update_ot.document_key, update_ot, this.props.appActions.serviceOptions_tournees_j);
    }
    
    let logHistoryMove = () => {
      let otRow = this.props.appActions.dataSlots['ds_selected_command'];
      let update = {
        coordinates: {
          lat: window.previousPos.coords.latitude, 
          lng: window.previousPos.coords.longitude,
        },
        flux: otRow.flux_realise,
        hq: true,
        id_scan: otRow.id_scan,
        id_client: client_depot.document_key,
        id_contenant: otRow.container_id || "",
        move_type: "DEPOT",
        quai: 0,
        reference_ot: otRow.document_key || "",
        site: client_depot.name,
        tournee: otRow.tournee,
        ts: ts,
        user_id: this.props.appActions.dataSlots['ds_userid'],
        user_name: this.props.appActions.dataSlots['ds_username'],
        vide: otRow.ts_vidage > 0 || otRow.flux_realise.includes("VIDE") ? true : false,
      };
      this.props.appActions.dataSheets['history_moves'].addItem(update, this.props.appActions.serviceOptions_history_moves);
    }
    */
    
    let logHistoryMoveMiseQuai = () => {
      let otRow = this.props.appActions.dataSlots['ds_selected_command'];
      let update = {
        coordinates: {
          lat: window.previousPos.coords.latitude, 
          lng: window.previousPos.coords.longitude,
        },
        flux: otRow.flux_realise,
        hq: false,
        id_scan: this.props.id_scan,
        id_client: client_depot.document_key,
        id_contenant: otRow.container_id || "",
        move_type: "MISE A QUAI",
        quai: otRow.quai,
        reference_ot: otRow.document_key || "",
        site: client_depot.name,
        tournee: otRow.tournee,
        ts: ts,
        user_id: this.props.appActions.dataSlots['ds_userid'],
        user_name: this.props.appActions.dataSlots['ds_username'],
        vide: true,
      };
      this.props.appActions.dataSheets['history_moves'].addItem(update, this.props.appActions.serviceOptions_history_moves);
    }
    
    // successDepot();
    // logHistoryMove();
    logHistoryMoveMiseQuai();
    
    /////////////////////////// UPDATE TRUCK LOAD /////////////////
    /*
    try {
      let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(t => t.document_key == this.props.appActions.dataSlots['ds_selected_command'].tournee);
      let update = {
        document_key: tournee.document_key,
        document_ref: tournee.document_ref,
        loaded: false,
        loaded_is_full: false,
        load_detail: {},
      };
      this.props.appActions.dataSheets['id_tournees_j'].replaceItemByRowIndex(tournee.document_key, update, this.props.appActions.serviceOptions_id_tournees_j);
    } catch (e) {
      console.log(e);
    }
    */
    
    // UPDATE QUAI VIEW (POSE HORS QUAI)
    let dech_quai_view = this.props.appActions.dataSheets['quaiView'].items.filter(it => {
      return it.id_client === this.props.appActions.dataSlots['ds_client_id'];
    })[0];
    
    // commander le vdiff hq ?
    let placeNewOrder = true;
    
    
    if (dech_quai_view) {
      if (current_command.quai > 0) {
        //update quai
        if (dech_quai_view.quais[current_command.quai].reserve === true) {
          dech_quai_view.quais[current_command.quai].id_scan = this.props.id_scan|| "";
          dech_quai_view.quais[current_command.quai].state = this.props.state || 1;
          dech_quai_view.quais[current_command.quai].packmat = this.props.packmat || 0;
          dech_quai_view.quais[current_command.quai].id_contenant = this.props.id_contenant || "";
          dech_quai_view.quais[current_command.quai].flux = this.props.flux || "";
        } else {
          dech_quai_view.quais[current_command.quai].id_scan = this.props.id_scan|| "";
          dech_quai_view.quais[current_command.quai].state = this.props.state || 1;
          dech_quai_view.quais[current_command.quai].packmat = this.props.packmat || 0;
        }
      }
      //update hors quai
    
      dech_quai_view.hors_quais.splice(this.props.quai, 1);
      /*
      let new_container = {
        id_scan: this.props.appActions.dataSlots['ds_selected_command'].id_scan,
        id_client: this.props.appActions.dataSlots['ds_selected_command'].id_client,
        id_contenant: this.props.appActions.dataSlots['ds_selected_command'].container_id,
        flux: this.props.appActions.dataSlots['ds_selected_command'].flux_realise,
        packmat: this.props.appActions.dataSlots['ds_selected_command'].packmat || 0,
        state: this.props.appActions.dataSlots['ds_selected_command'].state || 5,
        type: this.props.appActions.dataSlots['ds_selected_command'].type,
        exutoire: this.props.appActions.dataSlots['ds_selected_command'].exutoire,
      }
      let placeNewOrder = new_container;
    
      dech_quai_view.hors_quais.push(new_container);
      */
    
      let update = {
        document_key: dech_quai_view.document_key,
        document_ref: dech_quai_view.document_ref,
        quais: dech_quai_view.quais,
        hors_quais: dech_quai_view.hors_quais,
        ts: new Date().getTime()
      };
      this.props.appActions.dataSheets['quaiView'].replaceItemByRowIndex(update.document_key, update, this.props.appActions.serviceOptions_quaiView);
    }
    
    try {
      //update statut
      let update_ot = {
        document_ref: this.props.appActions.dataSlots['ds_selected_command'].document_ref,
        document_key: this.props.appActions.dataSlots['ds_selected_command'].document_key,
        statut: 10,
      }
      
      this.props.appActions.updateDataSlot('ds_selected_command', {...this.props.appActions.dataSlots['ds_selected_command'], ...update_ot});
      this.props.appActions.dataSheets['tournees_j'].replaceItemByRowIndex(update_ot.document_key, update_ot, this.props.appActions.serviceOptions_tournees_j);
    } catch (error) {
      console.log(error);
    }
    
    // PLACE ORDER si pose benne plein HQ (vidage différé)
    ///////////// CASE REPORTER //////////////
    if (placeNewOrder) {
      // this.props.appActions.goToScreen('vider_apres_rotation', { transitionId: 'fadeIn' });
      this.props.appActions.goToScreen('détailVidage', { transitionId: 'fadeIn' });
    } else {
      this.props.appActions.goToScreen('tournéeDuJour', { transitionId: 'fadeIn' });
    }
    
  
  }
  
  
  render() {
    let transformFillColorPropValue_card = (input) => {
      return input===1 ? "#00E2D0": "white";
    }
    const fillColorValue_card = transformFillColorPropValue_card(this.props.checked);
    const style_elCard = {
      width: '100%',
      height: '100%',
     };
    const style_elCard_outer = {
      backgroundColor: (fillColorValue_card && fillColorValue_card.length > 0) ? fillColorValue_card : 'white',
     };
    const style_elN0_0 = {
      backgroundImage: 'url('+img_elN0_0+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN0_2 = {
      backgroundImage: 'url('+img_elN0_2+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN0_3 = {
      backgroundImage: 'url('+img_elN0_3+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN0_4 = {
      backgroundImage: 'url('+img_elN0_4+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    
    const value_flux = this.props.flux;
    
    const style_elFlux = {
      fontSize: 14.1,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    let transformValue_state_elImage_fill = (input) => {
      if (this.props.id_contenant == "VIDE") {
        return 1;
      } else {
        return this.state.state || 5;
        //return 5;
      }
    }
    
    let transformValue_type_elImage_fill = (input) => {
      let getContenantById = (id) => {
        return this.props.appActions.dataSheets['group_contenants'].items.find(it => it.document_key == id);
      }
      
      let getFluxByMatiere = (matiere) => {
        return this.props.appActions.dataSheets['matieres'].items.find(it => it.matiere == matiere);
      }
      
      let getMaterialByType = (type) => {
        return this.props.appActions.dataSheets['materiels'].items.find(it => it.type == type);
      }
      
      if (this.props.id_contenant == "VIDE") {
        let type = (getMaterialByType(this.props.flux)||{}).code_shape||"30";
        return type;
      } else {
        let contenant = getContenantById(this.props.id_contenant) || {};
        let type = (getMaterialByType(contenant.type)||{}).code_shape||"30";
        return type;
      }
    }
    
    let transformValue_color_elImage_fill = (input) => {
      let getContenantById = (id) => {
        return this.props.appActions.dataSheets['group_contenants'].items.find(it => it.document_key == id);
      }
      
      let getFluxByMatiere = (matiere) => {
        return this.props.appActions.dataSheets['matieres'].items.find(it => it.matiere == matiere);
      }
      
      let getMaterialByType = (type) => {
        return this.props.appActions.dataSheets['materiels'].items.find(it => it.type == type);
      }
      
      return (getFluxByMatiere(this.props.flux)||{}).color || "#000000";
    }
    const style_elImage_fill = {
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_id_scan = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return this.state.id_scan;
    }
    let transformCustomCSSPropValue_id_scan = (input) => {
      let style = '"borderRadius": "5px"';
      try {
        let container = this.props.appActions.dataSheets['reference_containers'].items.find(it => it.id_scan === input);
        if (container.unresolved_anomalies_labels.length) {
          style += ',"backgroundColor": "#FFD479"';
        } else {
          if (input) {
            style += ',"backgroundColor": "#EBEBEB"';
          }
        } 
      } catch (e) {
        if (input) {
          style += ',"backgroundColor": "#EBEBEB"';
        }
      }
      
      return style;
    }
    
    const value_id_scan = transformPropValue_id_scan(this.props.id_scan);
    
    const style_elId_scan = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'center',
      ...(() => {  // append customCSS property of 'id_scan'
        let val = transformCustomCSSPropValue_id_scan(this.props.id_scan);
        if (val == null || val.length == 0) return null;
        try {
          return JSON.parse('{'+val+'}');
        } catch (error) {
          console.log(error);
        }
        return null;
      })(),
     };
    let transformPropValue_quai = (input) => {
      return input;
    }
    
    const value_quai = transformPropValue_quai(this.props.quai);
    
    const style_elQuai = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
      display: 'none',
     };
    let transformPropValue_state = (input) => {
      let state = "";
      if (this.props.state == 0 || this.props.id_contenant === 'VIDE') {
        state = "-";
      } else {
        state = (this.props.state-1)*25 + "%";
      }
      if (this.props.reserve) {state += " (R)"};
      return state;
    }
    
    const value_state = transformPropValue_state(this.props.state);
    
    const style_elState = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'center',
     };
    
    let transformValue_packmat_elPackmat_display = (input) => {
      return input;
    
    }
    let transformVisiblePropValue_packmat_display = (input) => {
      return (input > 0) ? true : false;
    }
    const elPackmat_display = ((val) => { return val === "true" || val == true || val == 1 })(transformVisiblePropValue_packmat_display(this.props.packmat)) ? (
      <div className="hasNestedComps elPackmat_display">
        <Packmat packmat={transformValue_packmat_elPackmat_display(this.props.packmat)} visualStateIndex={2} ref={(el)=> this._elPackmat_display = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    let transformVisiblePropValue_client_diff = (input) => {
      return input && (this.props.appActions.dataSlots['ds_selected_tournee'] == input);
    }
    const elClient_diff = ((val) => { return val === "true" || val == true || val == 1 })(transformVisiblePropValue_client_diff(this.props.id_tournee)) ? (
      <div className="hasNestedComps elClient_diff">
        <Client_diff visualStateIndex={2} ref={(el)=> this._elClient_diff = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    return (
      <div className="Picker_echange2">
        <div className="background">
          <div className="containerMinHeight elCard" style={style_elCard_outer}>
            <div style={style_elCard} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="flowRow flowRow_Picker_echange2_elN0_0_1191061661">
          <div className="elN0_0">
            <div style={style_elN0_0} />
          </div>
          
          <div className="elN0_2">
            <div style={style_elN0_2} />
          </div>
          
          <div className="elN0_3">
            <div style={style_elN0_3} />
          </div>
          
          <div className="elN0_4">
            <div style={style_elN0_4} />
          </div>
          
          </div>
        </div>
        
        <div className="foreground">
          <div className="systemFontBold  elFlux" style={style_elFlux}>
            <div>{value_flux !== undefined ? value_flux : (<span className="propValueMissing">{this.state.flux}</span>)}</div>
          </div>
          <div className="hasNestedComps elImage_fill" style={style_elImage_fill} onClick={this.onClick_elImage_fill} >
            <Image_fill state={transformValue_state_elImage_fill(this.props.state)} type={transformValue_type_elImage_fill(this.props.id_contenant)} color={transformValue_color_elImage_fill(this.props.flux) || "#000000"} ref={(el)=> this._elImage_fill = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
          </div>
          <div className="baseFont elId_scan" style={style_elId_scan}>
            <div>{value_id_scan !== undefined ? value_id_scan : (<span className="propValueMissing">{this.state.id_scan}</span>)}</div>
          </div>
          <div className="headlineFont elQuai" style={style_elQuai}>
            <div>{value_quai !== undefined ? value_quai : (<span className="propValueMissing">{this.state.quai}</span>)}</div>
          </div>
          <div className="baseFont elState" style={style_elState}>
            <div>{value_state !== undefined ? value_state : (<span className="propValueMissing">{this.state.state}</span>)}</div>
          </div>
          { elPackmat_display }
          { elClient_diff }
        </div>
      </div>
    )
  }
  
}
