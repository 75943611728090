import React, { Component } from 'react';
import './App.css';
import btn_icon_464519933 from './images/btn_icon_464519933.png';
import btn_icon_1776291884 from './images/btn_icon_1776291884.png';
import Image_fill from './Image_fill';
import btn_icon_521157686 from './images/btn_icon_521157686.png';
import Apercu from './Apercu';
import Tail_picker_echange from './Tail_picker_echange';
import Picker_echange2 from './Picker_echange2';
import btn_icon_1078959539 from './images/btn_icon_1078959539.png';

// UI framework component imports
import Button from 'muicss/lib/react/button';
import Appbar from 'muicss/lib/react/appbar';

export default class EchangeScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo, state, type, color

  constructor(props) {
    super(props);
    
    this.state = {
      state0_elId_scan: (<div> </div>),
      state0_elId_scan_plainText: " ",
      state0_elText: (<div>Rotation avec un contenant?</div>),
      state0_elText_plainText: "Rotation avec un contenant?",
      state0_elNotif776150942: (<div> </div>),
      state0_elNotif776150942_plainText: " ",
      state1_elList_visible: true,
      state1_elSite: (<div>Stock de contenants sur place</div>),
      state1_elSite_plainText: "Stock de contenants sur place",
      state1_elText: (<div>Rotation avec quel contenant?</div>),
      state1_elText_plainText: "Rotation avec quel contenant?",
      state1_elNotif776150942: (<div> </div>),
      state1_elNotif776150942_plainText: " ",
    };
  }

  componentDidMount() {
    /**
    let selected_command = this.props.appActions.dataSlots['ds_selected_command'];
    if (Object.keys(selected_command).length) {
      this.setState({
        picker_vide: selected_command.type,
      })
    }
    // this.props.appActions.updateDataSlot('ds_scanned_id', "");
    if (this.props.appActions.dataSlots['ds_vide_autre_view']==0) {
      document.querySelectorAll("button.elYes_here").forEach(el => {el.style['background-color'] = "gray"});
      document.querySelectorAll("button.elYes_there").forEach(el => {el.style['background-color'] = "transparent"});
      document.querySelectorAll("button.elNo").forEach(el => {el.style['background-color'] = "transparent"});
    } else if (this.props.appActions.dataSlots['ds_vide_autre_view']==1) {
      document.querySelectorAll("button.elYes_here").forEach(el => {el.style['background-color'] = "transparent"});
      document.querySelectorAll("button.elYes_there").forEach(el => {el.style['background-color'] = "gray"});
      document.querySelectorAll("button.elNo").forEach(el => {el.style['background-color'] = "transparent"});
    } else if (this.props.appActions.dataSlots['ds_vide_autre_view']==2) {
      document.querySelectorAll("button.elYes_here").forEach(el => {el.style['background-color'] = "transparent"});
      document.querySelectorAll("button.elYes_there").forEach(el => {el.style['background-color'] = "transparent"});
      document.querySelectorAll("button.elNo").forEach(el => {el.style['background-color'] = "gray"});
    }
    **/
    {
      let dataSheet = this.props.appActions.dataSheets['reference_containers'];
      let serviceOptions = this.props.appActions.serviceOptions_reference_containers;
      if ( !this.props.appActions.dataSheetLoaded['reference_containers']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/all_containers", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['reference_containers'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['quaiView'];
      let serviceOptions = this.props.appActions.serviceOptions_quaiView;
      if ( !this.props.appActions.dataSheetLoaded['quaiView']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/quaiView", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['quaiView'] = true;
      }
    }
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
    /**
    if (this.props.appActions.dataSlots['ds_vide_autre_view']==0) {
      document.querySelectorAll("button.elYes_here").forEach(el => {el.style['background-color'] = "gray"});
      document.querySelectorAll("button.elYes_there").forEach(el => {el.style['background-color'] = "transparent"});
      document.querySelectorAll("button.elNo").forEach(el => {el.style['background-color'] = "transparent"});
    } else if (this.props.appActions.dataSlots['ds_vide_autre_view']==1) {
      document.querySelectorAll("button.elYes_here").forEach(el => {el.style['background-color'] = "transparent"});
      document.querySelectorAll("button.elYes_there").forEach(el => {el.style['background-color'] = "gray"});
      document.querySelectorAll("button.elNo").forEach(el => {el.style['background-color'] = "transparent"});
    } else if (this.props.appActions.dataSlots['ds_vide_autre_view']==2) {
      document.querySelectorAll("button.elYes_here").forEach(el => {el.style['background-color'] = "transparent"});
      document.querySelectorAll("button.elYes_there").forEach(el => {el.style['background-color'] = "transparent"});
      document.querySelectorAll("button.elNo").forEach(el => {el.style['background-color'] = "gray"});
    }
    **/
  }

  // --- Functions for component state index 0 (1 of 2) --- 
  
  onClick_state0_elCancel464519933 = (ev) => {
    let a = window.confirm("Interrompre l'OT en cours?");
    if (!a) {
      return;
    }
  
    // Go to screen 'Tournée du jour'
    this.props.appActions.goToScreen('tournéeDuJour', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_state0_elNo = (ev) => {
    //update statut
    let update_ot = {
      document_ref: this.props.appActions.dataSlots['ds_selected_command'].document_ref,
      document_key: this.props.appActions.dataSlots['ds_selected_command'].document_key,
      statut: 10,
    }
    
    if (this.props.appActions.dataSlots['ds_selected_command'].action == 1) {
      update_ot.action = 4;
    }
    this.props.appActions.updateDataSlot('ds_selected_command', {...this.props.appActions.dataSlots['ds_selected_command'], ...update_ot});
    this.props.appActions.dataSheets['tournees_j'].replaceItemByRowIndex(update_ot.document_key, update_ot, this.props.appActions.serviceOptions_tournees_j);
  
    // Go to screen 'Détail vidage'
    this.props.appActions.goToScreen('détailVidage', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_state0_elYes_here = (ev) => {
    let client_depot = this.props.appActions.dataSheets['clients'].items.filter(it => it.document_key == this.props.appActions.dataSlots['ds_selected_command'].site)[0] || {};
    
    if ([].includes(client_depot.place_type)) {
      let a = window.confirm("Echanger le contenant?");
      if (!a) {
        return;
      }
    
      let current_command = this.props.appActions.dataSlots['ds_selected_command'];
    
      // UPDATE QUAI VIEW
      let dech_quai_view = this.props.appActions.dataSheets['quaiView'].items.filter(it => {
        return it.id_client === current_command.site;
      })[0];
    
      if (dech_quai_view) {
        if (current_command.hh != 'HQ' && current_command.hh != 'VIDE') {
          if (current_command.quai > 0) {
            //update quai
            dech_quai_view.quais[current_command.quai].id_scan = "";
            dech_quai_view.quais[current_command.quai].state = 1;
            dech_quai_view.quais[current_command.quai].packmat = 0;
          }
          //update hors quai
          dech_quai_view.hors_quais.splice(this.props.quai, 1);
        } 
    
        let update = {
          document_key: dech_quai_view.document_key,
          document_ref: dech_quai_view.document_ref,
          quais: dech_quai_view.quais,
          ts: new Date().getTime()
        };
        this.props.appActions.dataSheets['quaiView'].replaceItemByRowIndex(update.document_key, update, this.props.appActions.serviceOptions_quaiView);
        ///////////////////////////
      }
    
      //update statut
      let update_ot = {
        document_ref: this.props.appActions.dataSlots['ds_selected_command'].document_ref,
        document_key: this.props.appActions.dataSlots['ds_selected_command'].document_key,
        statut: 10,
      }
      this.props.appActions.updateDataSlot('ds_selected_command', {...this.props.appActions.dataSlots['ds_selected_command'], ...update_ot});
      this.props.appActions.dataSheets['tournees_j'].replaceItemByRowIndex(update_ot.document_key, update_ot, this.props.appActions.serviceOptions_tournees_j);
    
    
      this.props.appActions.goToScreen('détailVidage', { transitionId: 'fadeIn' });
    
    } else {
      this.props.appActions.updateDataSlot('ds_vide_autre_view', 1);
    }
  
  }
  
  
  onClick_state0_elShow_apercu521157686 = (ev) => {
    let newVal = "1";
    this.props.appActions.updateDataSlot('ds_apercu_visible', newVal);
  
  }
  
  
  renderState0() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_state0_elBackground321605362 = {
      width: '100%',
      height: '100%',
     };
    const style_state0_elBackground321605362_outer = {
      backgroundColor: '#f6f6f6',
     };
    
    const style_state0_elRectangle440755512 = {
      background: 'rgba(213, 213, 213, 1.000)',
     };
    
    const style_state0_elCancel464519933 = {
      display: 'block',
      textTransform: 'none',
      color: '#fff',
      textAlign: 'left',
      backgroundColor: '#ff7d78',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elNo = {
      display: 'block',
      color: '#00e5d9',
      textAlign: 'center',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elYes_here = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elIconButton = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_1776291884+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
     };
    
    let transformValue_state_state0_elImage_fill_empty = (input) => {
      return 1;
    
    }
    
    let transformValue_type_state0_elImage_fill_empty = (input) => {
      let getContenantById = (id) => {
        return this.props.appActions.dataSheets['group_contenants'].items.find(it => it.document_key == id);
      }
      
      let getFluxByMatiere = (matiere) => {
        return this.props.appActions.dataSheets['matieres'].items.find(it => it.matiere == matiere);
      }
      
      let getMaterialByType = (type) => {
        return this.props.appActions.dataSheets['materiels'].items.find(it => it.type == type);
      }
      
      if (input.container_id == "null") {
        let type = (getMaterialByType(input.flux)||{}).code_shape||"30";
        return type;
      } else {
        let contenant = getContenantById(input.container_id) || {};
        let type = (getMaterialByType(contenant.type)||{}).code_shape||"30";
        return type;
      }
    }
    
    let transformValue_color_state0_elImage_fill_empty = (input) => {
      let getContenantById = (id) => {
        return this.props.appActions.dataSheets['group_contenants'].items.find(it => it.document_key == id);
      }
      
      let getFluxByMatiere = (matiere) => {
        return this.props.appActions.dataSheets['matieres'].items.find(it => it.matiere == matiere);
      }
      
      let getMaterialByType = (type) => {
        return this.props.appActions.dataSheets['materiels'].items.find(it => it.type == type);
      }
      
      return (getFluxByMatiere(input.flux)||{}).color || "#000000";
    }
    
    let transformValue_state_state0_elImage_fill_full = (input) => {
      return input.state || 5;
    
    }
    
    let transformValue_type_state0_elImage_fill_full = (input) => {
      let getContenantById = (id) => {
        return this.props.appActions.dataSheets['group_contenants'].items.find(it => it.document_key == id);
      }
      
      let getFluxByMatiere = (matiere) => {
        return this.props.appActions.dataSheets['matieres'].items.find(it => it.matiere == matiere);
      }
      
      let getMaterialByType = (type) => {
        return this.props.appActions.dataSheets['materiels'].items.find(it => it.type == type);
      }
      
      if (input.container_id == "null") {
        let type = (getMaterialByType(input.flux)||{}).code_shape||"30";
        return type;
      } else {
        let contenant = getContenantById(input.container_id) || {};
        let type = (getMaterialByType(contenant.type)||{}).code_shape||"30";
        return type;
      }
    }
    
    let transformValue_color_state0_elImage_fill_full = (input) => {
      let getContenantById = (id) => {
        return this.props.appActions.dataSheets['group_contenants'].items.find(it => it.document_key == id);
      }
      
      let getFluxByMatiere = (matiere) => {
        return this.props.appActions.dataSheets['matieres'].items.find(it => it.matiere == matiere);
      }
      
      let getMaterialByType = (type) => {
        return this.props.appActions.dataSheets['materiels'].items.find(it => it.type == type);
      }
      
      return (getFluxByMatiere(input.flux)||{}).color || "#000000";
    }
    let transformPropValue_id_scan = (input) => {
      return input.id_scan||"";
    }
    let transformCustomCSSPropValue_id_scan = (input) => {
      let style = '"borderRadius": "5px"';
      try {
        let container = this.props.appActions.dataSheets['reference_containers'].items.find(it => it.id_scan === input.id_scan);
        if (container.unresolved_anomalies_labels.length) {
          style += ',"backgroundColor": "#FFD479"';
        } else {
          if (input.id_scan) {
            style += ',"backgroundColor": "#EBEBEB"';
          }
        } 
      } catch (e) {
        if (input.id_scan) {
          style += ',"backgroundColor": "#EBEBEB"';
        }
      }
      
      return style;
    }
    
    const value_id_scan = transformPropValue_id_scan((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_command'] : ''));
    
    const style_state0_elId_scan = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'center',
      ...(() => {  // append customCSS property of 'id_scan'
        let val = transformCustomCSSPropValue_id_scan((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_command'] : ''));
        if (val == null || val.length == 0) return null;
        try {
          return JSON.parse('{'+val+'}');
        } catch (error) {
          console.log(error);
        }
        return null;
      })(),
     };
    const style_state0_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const style_state0_elShow_apercu521157686 = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_521157686+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_notif = (input) => {
      let entreprise = this.props.appActions.dataSheets['entreprise'].items[0]||{};
      let auth_user = this.props.appActions.dataSheets['auth_user'].items[0] || {};
      let profile = auth_user.profile || {}; 
      
      if (entreprise.pvd_can_add_to_tour && (profile.hdq === true ? !entreprise.hdq_cannot_add_to_tour : true)) {
        let commands = this.props.appActions.dataSheets['tournees_j'].items
        .filter(it => it.tournee == "" && it.statut === 0)
        .sort((a,b) => a.display_name.localeCompare(b.display_name));
        if (commands.length > 0) {
          return "<p style='color:white;border-radius:100%;background-color:#ff7e79;width:18px;height:18px;'>"+commands.length+"</p>";
        } else {
          return '';
        }
      } else {
        return '';
      }
    }
    
    const value_notif = transformPropValue_notif((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_today_minuit_ts'] : ''));
    
    const style_state0_elNotif776150942 = {
      fontSize: 12.3,
      color: '#feffff',
      textAlign: 'center',
      pointerEvents: 'auto',
     };
    const state0_elApercu65562753 = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_apercu_visible'] : '')) ? (
      <div className="hasNestedComps containerMinHeight state0_elApercu65562753">
        <Apercu visualStateIndex={(this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_mode_apercu'] : '')} ref={(el)=> this._state0_elApercu65562753 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    return (
      <div className="AppScreen EchangeScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight state0_elBackground321605362" style={style_state0_elBackground321605362_outer}>
            <div className="appBg" style={style_state0_elBackground321605362} />
          </div>
          
          <div className="state0_elRectangle440755512" style={style_state0_elRectangle440755512} />
        </div>
        <Appbar className="navBar">
          <div className="title">Rotation (Etape 2/4)</div></Appbar>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <Button className="actionFont state0_elCancel464519933" style={style_state0_elCancel464519933}  variant="fab" onClick={this.onClick_state0_elCancel464519933} >
              <img src={btn_icon_464519933} alt="" style={{width: '100%', marginTop: '4%'}} />
            </Button>
            <button className="actionFont state0_elNo" style={style_state0_elNo} onClick={this.onClick_state0_elNo} >
              NON, Vider remettre
            </button>
            <Button className="actionFont state0_elYes_here" style={style_state0_elYes_here}  color="accent" onClick={this.onClick_state0_elYes_here} >
              OUI
            </Button>
            <button className="actionFont state0_elIconButton" style={style_state0_elIconButton} />
            <div className="hasNestedComps state0_elImage_fill_empty">
              <Image_fill state={transformValue_state_state0_elImage_fill_empty((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_command'] : ''))} type={transformValue_type_state0_elImage_fill_empty((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_command'] : ''))} color={transformValue_color_state0_elImage_fill_empty((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_command'] : ''))} ref={(el)=> this._state0_elImage_fill_empty = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            <div className="hasNestedComps state0_elImage_fill_full">
              <Image_fill state={transformValue_state_state0_elImage_fill_full((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_command'] : ''))} type={transformValue_type_state0_elImage_fill_full((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_command'] : ''))} color={transformValue_color_state0_elImage_fill_full((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_command'] : ''))} ref={(el)=> this._state0_elImage_fill_full = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
            <div className="baseFont state0_elId_scan" style={style_state0_elId_scan}>
              <div>{value_id_scan !== undefined ? value_id_scan : (<span className="propValueMissing">{this.state.state0_elId_scan}</span>)}</div>
            </div>
            <div className="headlineFont state0_elText" style={style_state0_elText}>
              <div>{this.state.state0_elText}</div>
            </div>
            <button className="actionFont state0_elShow_apercu521157686" style={style_state0_elShow_apercu521157686} onClick={this.onClick_state0_elShow_apercu521157686}  />
            <div className="systemFontRegular  state0_elNotif776150942" style={style_state0_elNotif776150942}>
              <div><div dangerouslySetInnerHTML={{__html: value_notif}}></div></div>
            </div>
            { state0_elApercu65562753 }
          </div>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 2) --- 
  
  onClick_state1_elCancel464519933 = (ev) => {
    let a = window.confirm("Interrompre l'OT en cours?");
    if (!a) {
      return;
    }
  
    // Go to screen 'Tournée du jour'
    this.props.appActions.goToScreen('tournéeDuJour', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_state1_elReturn = (ev) => {
    let newVal = "0";
    this.props.appActions.updateDataSlot('ds_vide_autre_view', newVal);
  
  }
  
  
  onClick_state1_elShow_apercu521157686 = (ev) => {
    let newVal = "1";
    this.props.appActions.updateDataSlot('ds_apercu_visible', newVal);
  
  }
  
  
  renderState1() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_state1_elBackground321605362 = {
      width: '100%',
      height: '100%',
     };
    const style_state1_elBackground321605362_outer = {
      backgroundColor: '#f6f6f6',
     };
    
    const style_state1_elRectangle440755512 = {
      background: 'rgba(213, 213, 213, 1.000)',
     };
    
    const style_state1_elCancel464519933 = {
      display: 'block',
      textTransform: 'none',
      color: '#fff',
      textAlign: 'left',
      backgroundColor: '#ff7d78',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_list = (input) => {
      let entreprise = this.props.appActions.dataSheets['entreprise'].items[0]||{};
      
      if (entreprise.replace_hq_on_rotation) {
        let quaiView = this.props.appActions.dataSheets['quaiView'].items.filter(it => it.id_client === input.site)[0] || {};
        return (quaiView.hors_quais || [])
          .map((it, index) => {return {...it, quai: index, key: index}})
          .filter(it => it.id_contenant.includes('VIDE') || it.id_contenant === this.props.appActions.dataSlots['ds_selected_command'].container_id);
      } else {
        let quaiView = this.props.appActions.dataSheets['quaiView'].items.filter(it => it.id_client === input.site)[0] || {};
        return (quaiView.hors_quais || [])
          .map((it, index) => {return {...it, quai: index, key: index}})
          .filter(it => it.id_contenant.includes('VIDE'));
      }
    }
    
    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    listComps_list['_tail'] = <Tail_picker_echange appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />;  // Tail item for this list
    
    if (Array.isArray(transformPropValue_list((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_command'] : '')))) {
        items_list = items_list.concat(transformPropValue_list((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_command'] : '')));
    } else if (transformPropValue_list((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_command'] : ''))) {
        items_list.push(transformPropValue_list((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_command'] : '')));
    }
    items_list.push({key: '_tail', _componentId: '_tail'});  // A data item to indicate the tail item
    this._state1_elList_items = [];
    
    const state1_elList = this.state.state1_elList_visible ? (
      <div className="hasNestedComps state1_elList">
        <div>
          {items_list.map((row, index) => {
            let itemComp = (row._componentId)
                ? listComps_list[row._componentId]
                : <Picker_echange2 appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} {...{ 'flux': row['flux'], 'state': row['state'], 'id_scan': row['id_scan'], 'quai': row['quai'], 'id_client': row['id_client'], 'id_contenant': row['id_contenant'], 'exutoire': row['exutoire'], 'checked': row['checked'], 'reference_ot': row['reference_ot'], 'type': row['type'], 'color': row['color'], 'id_tournee': row['id_tournee'], 'packmat': row['packmat'], 'reserve': row['reserve'], }} ref={(el) => {if (el) this._state1_elList_items.push(el)}} />;
            return (<div key={row.key}>
                {itemComp}
              </div>);
          })}
          <div className="marker" ref={(el)=> this._state1_elList_endMarker = el} />
        </div>
      </div>
      
     ) : null;
    const style_state1_elSite = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_state1_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const style_state1_elReturn = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_1078959539+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state1_elShow_apercu521157686 = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_521157686+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_notif = (input) => {
      let entreprise = this.props.appActions.dataSheets['entreprise'].items[0]||{};
      let auth_user = this.props.appActions.dataSheets['auth_user'].items[0] || {};
      let profile = auth_user.profile || {}; 
      
      if (entreprise.pvd_can_add_to_tour && (profile.hdq === true ? !entreprise.hdq_cannot_add_to_tour : true)) {
        let commands = this.props.appActions.dataSheets['tournees_j'].items
        .filter(it => it.tournee == "" && it.statut === 0)
        .sort((a,b) => a.display_name.localeCompare(b.display_name));
        if (commands.length > 0) {
          return "<p style='color:white;border-radius:100%;background-color:#ff7e79;width:18px;height:18px;'>"+commands.length+"</p>";
        } else {
          return '';
        }
      } else {
        return '';
      }
    }
    
    const value_notif = transformPropValue_notif((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_today_minuit_ts'] : ''));
    
    const style_state1_elNotif776150942 = {
      fontSize: 12.3,
      color: '#feffff',
      textAlign: 'center',
      pointerEvents: 'auto',
     };
    const state1_elApercu65562753 = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_apercu_visible'] : '')) ? (
      <div className="hasNestedComps containerMinHeight state1_elApercu65562753">
        <Apercu visualStateIndex={(this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_mode_apercu'] : '')} ref={(el)=> this._state1_elApercu65562753 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    return (
      <div className="AppScreen EchangeScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight state1_elBackground321605362" style={style_state1_elBackground321605362_outer}>
            <div className="appBg" style={style_state1_elBackground321605362} />
          </div>
          
          <div className="state1_elRectangle440755512" style={style_state1_elRectangle440755512} />
        </div>
        <Appbar className="navBar">
          <div className="title">Rotation (Etape 2/4)</div></Appbar>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <Button className="actionFont state1_elCancel464519933" style={style_state1_elCancel464519933}  variant="fab" onClick={this.onClick_state1_elCancel464519933} >
              <img src={btn_icon_464519933} alt="" style={{width: '100%', marginTop: '4%'}} />
            </Button>
            { state1_elList }
            <div className="baseFont state1_elSite" style={style_state1_elSite}>
              <div>{this.state.state1_elSite}</div>
            </div>
            <div className="headlineFont state1_elText" style={style_state1_elText}>
              <div>{this.state.state1_elText}</div>
            </div>
            <button className="actionFont state1_elReturn" style={style_state1_elReturn} onClick={this.onClick_state1_elReturn}  />
            <button className="actionFont state1_elShow_apercu521157686" style={style_state1_elShow_apercu521157686} onClick={this.onClick_state1_elShow_apercu521157686}  />
            <div className="systemFontRegular  state1_elNotif776150942" style={style_state1_elNotif776150942}>
              <div><div dangerouslySetInnerHTML={{__html: value_notif}}></div></div>
            </div>
            { state1_elApercu65562753 }
          </div>
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.props.ds_vide_autre_view !== undefined) ? this.props.ds_vide_autre_view : 0, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
    }
  }
  
}
