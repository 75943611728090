import React, { Component } from 'react';
import './App.css';
import btn_icon_962503623 from './images/btn_icon_962503623.png';
import btn_icon_2089340750 from './images/btn_icon_2089340750.png';
import btn_icon_819729806 from './images/btn_icon_819729806.png';
import btn_icon_238105142 from './images/btn_icon_238105142.png';
import btn_icon_804705354 from './images/btn_icon_804705354.png';
import btn_icon_1128553880 from './images/btn_icon_1128553880.png';
import img_state6_elImage from './images/Packmat_state6_elImage_639093612.png';

export default class Packmat extends Component {

  // Properties used by this component:
  // packmat, visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  // --- Functions for component state index 0 (1 of 7) --- 
  
  renderState0() {
    
    return (
      <div className="Packmat">
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 7) --- 
  
  renderState1() {
    
    const style_state1_elIconButtonCopy = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_962503623+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
     };
    
    return (
      <div className="Packmat">
        <div className="foreground">
          <button className="actionFont containerMinHeight state1_elIconButtonCopy" style={style_state1_elIconButtonCopy} />
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 2 (3 of 7) --- 
  
  renderState2() {
    
    const style_state2_elIconButton = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_2089340750+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
     };
    let transformTextPropValue_iconButtonCopy = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return input;
    }
    
    const textValue_iconButtonCopy = transformTextPropValue_iconButtonCopy(this.props.packmat);
    
    const style_state2_elIconButtonCopy = {
      display: 'block',
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
      backgroundColor: 'transparent',
     };
    
    return (
      <div className="Packmat">
        <div className="foreground">
          <button className="actionFont state2_elIconButton" style={style_state2_elIconButton} />
          <button className="actionFont containerMinHeight state2_elIconButtonCopy" style={style_state2_elIconButtonCopy}>
            {textValue_iconButtonCopy}
          </button>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 3 (4 of 7) --- 
  
  renderState3() {
    
    const style_state3_elIconButton819729806 = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_819729806+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
     };
    
    const style_state3_elIconButtonCopy = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_238105142+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
     };
    
    return (
      <div className="Packmat">
        <div className="foreground">
          <button className="actionFont state3_elIconButton819729806" style={style_state3_elIconButton819729806} />
          <button className="actionFont containerMinHeight state3_elIconButtonCopy" style={style_state3_elIconButtonCopy} />
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 4 (5 of 7) --- 
  
  renderState4() {
    
    const style_state4_elIconButton819729806 = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_819729806+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
     };
    
    const style_state4_elIconButtonCopy = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_804705354+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
     };
    
    return (
      <div className="Packmat">
        <div className="foreground">
          <button className="actionFont state4_elIconButton819729806" style={style_state4_elIconButton819729806} />
          <button className="actionFont containerMinHeight state4_elIconButtonCopy" style={style_state4_elIconButtonCopy} />
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 5 (6 of 7) --- 
  
  renderState5() {
    
    const style_state5_elIconButton819729806 = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_819729806+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
     };
    
    const style_state5_elIconButtonCopy = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_1128553880+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
     };
    
    return (
      <div className="Packmat">
        <div className="foreground">
          <button className="actionFont state5_elIconButton819729806" style={style_state5_elIconButton819729806} />
          <button className="actionFont containerMinHeight state5_elIconButtonCopy" style={style_state5_elIconButtonCopy} />
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 6 (7 of 7) --- 
  
  renderState6() {
    const style_state6_elImage = {
      backgroundImage: 'url('+img_state6_elImage+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    
    return (
      <div className="Packmat">
        <div className="foreground">
          <div className="containerMinHeight state6_elImage" style={style_state6_elImage} />
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
      case 2:
        return this.renderState2();
      case 3:
        return this.renderState3();
      case 4:
        return this.renderState4();
      case 5:
        return this.renderState5();
      case 6:
        return this.renderState6();
    }
  }
  
}
