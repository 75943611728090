import React, { Component } from 'react';
import './App.css';
import img_elVide from './images/Picker_niveau2_elVide_1373600465.png';
import Image_fill from './Image_fill';

export default class Picker_depot3 extends Component {

  // Properties used by this component:
  // flux, state, id_scan, quai, id_client, id_contenant, packmat, type, color, reserve

  constructor(props) {
    super(props);
    
    this.state = {
      flux: (<div>BOIS</div>),
      flux_plainText: "BOIS",
      state: (<div> </div>),
      state_plainText: " ",
      id_scan: (<div> </div>),
      id_scan_plainText: " ",
      quai: (<div> </div>),
      quai_plainText: " ",
  		state: this.props.state,
        id_scan: this.props.id_scan,
        packmat: this.props.packmat || 0
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elImage_fill = (ev) => {
    if (this.props.state == 0 || this.props.state === 1 ) {
      let a = window.confirm("Déposer sur le quai " + this.props.quai + " ?")
      if (!a) {
        return;
      }
    } else {
      alert("Impossible de déposer le contenant, quai occupé");
      return;
    }
    
    let ts = new Date();
    ts =  ts.getTime();
    
    let client_depot = this.props.appActions.dataSheets['clients'].items.find(it => it.document_key == this.props.appActions.dataSlots['ds_client_id']) || {};
    
    let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key == this.props.appActions.dataSlots['ds_selected_tournee']);
    if (tournee.loaded == true && tournee.loaded_is_full==false && tournee.load_detail) {
      // continue
    } else {
      return;
    }
    
    let logHistoryMove = () => {
      let update = {
        coordinates: {
          lat: window.previousPos.coords.latitude, 
          lng: window.previousPos.coords.longitude,
        },
        flux: tournee.load_detail.type,
        hq: true,
        id_scan: tournee.load_detail.id_scan,
        id_client: client_depot.document_key,
        id_contenant: "VIDE",
        move_type: "DEPOT",
        quai: this.props.quai,
        reference_ot: "",
        site: client_depot.name,
        tournee: this.props.appActions.dataSlots['ds_selected_tournee'],
        ts: ts,
        type: tournee.load_detail.type,
        user_id: this.props.appActions.dataSlots['ds_userid'],
        user_name: this.props.appActions.dataSlots['ds_username'],
        vide: true,
      };
      this.props.appActions.dataSheets['history_moves'].addItem(update, this.props.appActions.serviceOptions_history_moves);
    }
    
    logHistoryMove();
    
    /////////////////////////// UPDATE TRUCK LOAD /////////////////
    try {
      let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(t => t.document_key == this.props.appActions.dataSlots['ds_selected_tournee']);
      let update = {
        document_key: tournee.document_key,
        document_ref: tournee.document_ref,
        loaded: false,
        loaded_is_full: false,
        load_detail: {},
      };
      this.props.appActions.dataSheets['id_tournees_j'].replaceItemByRowIndex(tournee.document_key, update, this.props.appActions.serviceOptions_id_tournees_j);
    } catch (e) {
      console.log(e);
    }
    
    // UPDATE QUAI VIEW
    let dech_quai_view = this.props.appActions.dataSheets['quaiView'].items.filter(it => {
      return it.id_client === this.props.appActions.dataSlots['ds_client_id'];
    })[0];
    
    
    try {
      if (dech_quai_view) {
    
        let contenant;
        if (this.props.reserve && this.props.id_scan) {
          contenant = this.props.appActions.dataSheets['reference_containers'].items.find(it => it.id_scan === this.props.id_scan)||{};
        } else {
          contenant = this.props.appActions.dataSheets['group_contenants'].items.find(it => it.document_key === this.props.id_contenant)||{};
        }
    
        if (contenant && contenant.type && this.props.state === 1) {
          dech_quai_view.hors_quais.push({
            id_scan: this.props.id_scan || "",
            id_client: this.props.appActions.dataSlots['ds_client_id'],
            id_contenant: "VIDE",
            flux: contenant.type,
            packmat: 0,
            state: 1,
            type: contenant.type,
          })
        }
    
        dech_quai_view.quais[this.props.quai].id_scan = tournee.load_detail.id_scan;
        dech_quai_view.quais[this.props.quai].state = 1;
        dech_quai_view.quais[this.props.quai].packmat = 0;
    
    
        let update = {
          document_key: dech_quai_view.document_key,
          document_ref: dech_quai_view.document_ref,
          quais: dech_quai_view.quais,
          hors_quais: dech_quai_view.hors_quais,
          ts: new Date().getTime()
        };
        this.props.appActions.dataSheets['quaiView'].replaceItemByRowIndex(update.document_key, update, this.props.appActions.serviceOptions_quaiView);
    
      }
    } catch (error) {
      console.log(error);
    }
    
    ///////////////////////////
    
    this.props.appActions.goToScreen('tournéeDuJour', { transitionId: 'fadeIn' });
    
  
  }
  
  
  render() {
    
    const style_elRectangle = {
      background: 'rgba(254, 255, 255, 1.000)',
      border: '0.9px solid rgba(254, 255, 255, 0.9000)',
     };
    const style_elVide = {
      backgroundImage: 'url('+img_elVide+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    
    const value_flux = this.props.flux;
    
    const style_elFlux = {
      fontSize: 14.1,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    let transformPropValue_state = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      if (this.props.state == 0) {
        return "-";
      } else {
        return (this.props.state-1)*25 + "%";
      }
    }
    
    const value_state = transformPropValue_state(this.props.state);
    
    const style_elState = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'center',
     };
    
    let transformValue_type_elImage_fill = (input) => {
      let getContenantById = (id) => {
        return this.props.appActions.dataSheets['group_contenants'].items.find(it => it.document_key == id);
      }
      
      let getFluxByMatiere = (matiere) => {
        return this.props.appActions.dataSheets['matieres'].items.find(it => it.matiere == matiere);
      }
      
      let getMaterialByType = (type) => {
        return this.props.appActions.dataSheets['materiels'].items.find(it => it.type == type);
      }
      
      if (this.props.id_contenant == "VIDE") {
        let type = (getMaterialByType(this.props.flux)||{}).code_shape||"30";
        return type;
      } else {
        let contenant = getContenantById(this.props.id_contenant) || {};
        let type = (getMaterialByType(contenant.type)||{}).code_shape||"30";
        return type;
      }
    }
    
    let transformValue_color_elImage_fill = (input) => {
      let getContenantById = (id) => {
        return this.props.appActions.dataSheets['group_contenants'].items.find(it => it.document_key == id);
      }
      
      let getFluxByMatiere = (matiere) => {
        return this.props.appActions.dataSheets['matieres'].items.find(it => it.matiere == matiere);
      }
      
      let getMaterialByType = (type) => {
        return this.props.appActions.dataSheets['materiels'].items.find(it => it.type == type);
      }
      
      return (getFluxByMatiere(this.props.flux)||{}).color || "#000000";
    }
    const style_elImage_fill = {
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_id_scan = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return this.props.id_scan;
    }
    
    const value_id_scan = transformPropValue_id_scan(this.props.id_scan);
    
    const style_elId_scan = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'right',
     };
    let transformPropValue_quai = (input) => {
      return input;
    }
    
    const value_quai = transformPropValue_quai(this.props.quai);
    
    const style_elQuai = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    
    return (
      <div className="Picker_depot3">
        <div className="background">
          <div className="containerMinHeight elRectangle" style={style_elRectangle} />
        </div>
        
        <div className="layoutFlow">
          <div className="flowRow flowRow_Picker_depot3_elVide_1000640606">
          <div className="elVide">
            <div style={style_elVide} />
          </div>
          
          </div>
        </div>
        
        <div className="foreground">
          <div className="systemFontBold  elFlux" style={style_elFlux}>
            <div>{value_flux !== undefined ? value_flux : (<span className="propValueMissing">{this.state.flux}</span>)}</div>
          </div>
          <div className="baseFont elState" style={style_elState}>
            <div>{value_state !== undefined ? value_state : (<span className="propValueMissing">{this.state.state}</span>)}</div>
          </div>
          <div className="hasNestedComps elImage_fill" style={style_elImage_fill} onClick={this.onClick_elImage_fill} >
            <Image_fill state={this.props.state} type={transformValue_type_elImage_fill(this.props.id_contenant)} color={transformValue_color_elImage_fill(this.props.flux) || "#000000"} ref={(el)=> this._elImage_fill = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
          </div>
          <div className="baseFont elId_scan" style={style_elId_scan}>
            <div>{value_id_scan !== undefined ? value_id_scan : (<span className="propValueMissing">{this.state.id_scan}</span>)}</div>
          </div>
          <div className="headlineFont elQuai" style={style_elQuai}>
            <div>{value_quai !== undefined ? value_quai : (<span className="propValueMissing">{this.state.quai}</span>)}</div>
          </div>
        </div>
      </div>
    )
  }
  
}
