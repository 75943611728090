// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_localizationSheet extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['key'] = "start_button_922055";
    item['fr'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "button1_title_971988";
    item['fr'] = "Part X";
    
    item = {};
    this.items.push(item);
    item['key'] = "button2_title_152132";
    item['fr'] = "Part X";
    
    item = {};
    this.items.push(item);
    item['key'] = "mainpage_button_684083";
    item['fr'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "main_text_509729";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "main_text2_801476";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "main_button_262185";
    item['fr'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "main_text3_597359";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_text_947931";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen3_button_786296";
    item['fr'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_text_909366";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_text_566393";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_textcopy_471427";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_textcopy_533421";
    item['fr'] = "_test";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_n_pleinescopy_856645";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "component1_average_ncopy_423177";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_total_pcopy_801538";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_text_727026";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_textcopy_315093";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_text_592033";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_textcopy_629092";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_deacopy_957701";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_dea_pointcopy_781277";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_dea_pointcopy2_299680";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_dea_pointcopy3_682406";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_dea_pointcopy4_534736";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_dea_pointcopy5_612423";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_dea_pointcopy6_390271";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_dea_pointcopy7_455842";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_dea_pointcopy8_524785";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_dea_pointcopy9_624921";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_dib_pointcopy_310901";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_papier_pointcopy_97745";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_platre_pointcopy_176349";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_dea_pointcopy_87895";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_bois_pointcopy_142347";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_carton_pointcopy_998540";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_ferraille_pointcopy_118119";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_gravats_pointcopy_644252";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_gravats_sales_pointcopy_959189";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_dib_pointcopy_814699";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_papier_pointcopy_646394";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_platre_pointcopy_251423";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item_vegetaux_pointcopy_768025";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_checkbox_197872";
    item['fr'] = "Pleines";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_heaader_text_227657";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_heaader_text_280084";
    item['fr'] = "DEA";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_heaader_textcopy_733261";
    item['fr'] = "DEA";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_heaader_textcopy_808232";
    item['fr'] = "BOIS";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_heaader_textcopy2_684869";
    item['fr'] = "CARTON";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_heaader_textcopy3_382968";
    item['fr'] = "FERRAILLE";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_heaader_textcopy4_328170";
    item['fr'] = "GRAVATS";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_heaader_textcopy5_866598";
    item['fr'] = "GRAVATS SALES";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_heaader_textcopy6_52945";
    item['fr'] = "DIB";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_heaader_textcopy7_902919";
    item['fr'] = "PAPIER";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_heaader_textcopy8_476748";
    item['fr'] = "PLATRE";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_heaader_textcopy9_733636";
    item['fr'] = "VEGETAUX";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_heaader_text2_247145";
    item['fr'] = "DEA";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_text_78807";
    item['fr'] = "DEA";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_site_832659";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_dea_point_991424";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_dea_pointcopy_375201";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_bois_point_70089";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_bois_pointcopy_207673";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_carton_point_981323";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_carton_pointcopy_51589";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_ferraille_point_294383";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_ferraille_pointcopy_996888";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_gravats_point_597190";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_gravats_pointcopy_456981";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_gravats_sales_point_222745";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_gravats_sales_pointcopy_836296";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_dib_point_67725";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_dib_pointcopy_189917";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_papier_point_770739";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_papier_pointcopy_399812";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_platre_point_898626";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_platre_pointcopy_70395";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_vegetaux_point_265492";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "releve_item2_vegetaux_pointcopy_372522";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_text_973973";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_text_985940";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_sitecopy_128567";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_text_639179";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_sup_seuilcopy_452819";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_tot_videcopy_699658";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_tot_pleincopy_196426";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_tps_ecoulecopy_608492";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_f1_nvocopy_38594";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_f1_nvocopy_363608";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_f1_futurecopy_633773";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_f1_pleincopy_633789";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_f1_nvocopy_230438";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_checkbox_746681";
    item['fr'] = "Prévision 1h";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_text_12307";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_checkboxcopy_658281";
    item['fr'] = "Pleines";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_field_32189";
    item['fr'] = "seuil";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_f1_pleincopy_772029";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_f2_pleincopy_135142";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_f3_pleincopy_75765";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_f4_pleincopy_958437";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item2_f1_nvo_319024";
    item['fr'] = "MEUBLES";
    
    item = {};
    this.items.push(item);
    item['key'] = "item2_site_26091";
    item['fr'] = "Site";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_f1copy_434781";
    item['fr'] = "CARTON";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_f1copy2_257775";
    item['fr'] = "BOIS";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_f1copy3_32941";
    item['fr'] = "FERRAILLE";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_f1copy4_987910";
    item['fr'] = "GRAVATS";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_f1copy5_394126";
    item['fr'] = "GRAVATS NV";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_f1copy6_812628";
    item['fr'] = "DIB";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_f1copy7_447582";
    item['fr'] = "PAPIER";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_f1copy8_541154";
    item['fr'] = "PLATRE";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_f1copy9_900294";
    item['fr'] = "VEGETAUX";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_text_529563";
    item['fr'] = "Site";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_text_953776";
    item['fr'] = "Site";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_f1copy9_526532";
    item['fr'] = "VEGETAUX";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_f1copy8_298608";
    item['fr'] = "PLATRE";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_f1copy7_886869";
    item['fr'] = "PAPIER";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_f1copy6_1036145";
    item['fr'] = "DIB";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_f1copy5_732587";
    item['fr'] = "GRAVATS NV";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_f1copy4_403083";
    item['fr'] = "GRAVATS";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_f1copy3_515312";
    item['fr'] = "FERRAILLE";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_f1copy2_1020659";
    item['fr'] = "BOIS";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_f1copy_397751";
    item['fr'] = "CARTON";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_f1_640902";
    item['fr'] = "MEUBLES";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_text_311415";
    item['fr'] = "Site";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_text_171742";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item2_tot_videcopy_142636";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item2_sup_seuilcopy_357161";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_text_296897";
    item['fr'] = "BOIS";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy_11386";
    item['fr'] = "MEUBLES";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy_1028163";
    item['fr'] = "CARTON";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy2_676170";
    item['fr'] = "CARTON";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy_642565";
    item['fr'] = "CARTON";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy2_616126";
    item['fr'] = "FERRAILLE";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy3_501952";
    item['fr'] = "GRAV";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy4_877196";
    item['fr'] = "GRAV NV";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy5_279603";
    item['fr'] = "PAPIER";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy6_147729";
    item['fr'] = "PLATRE";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy7_380240";
    item['fr'] = "DIB";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy8_23991";
    item['fr'] = "VEGET";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy9_105431";
    item['fr'] = "PRES DIB";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_f1copy_589090";
    item['fr'] = "GRAVATS NV";
    
    item = {};
    this.items.push(item);
    item['key'] = "item4_f6_plein_408475";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item4_f6_nvo_259108";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "item4_f6_future_498501";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "item4_f10_plein_32046";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item4_f10_nvo_259640";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "item4_f10_future_208634";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "item4_f9_plein_336354";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item4_f9_future_309244";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "item4_f9_nvo_755165";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "item4_f8_plein_292171";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item4_f8_nvo_601916";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "item4_f8_future_410552";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "item4_f7_plein_479867";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item4_f7_future_42180";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "item4_f7_nvo_726443";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "vides_f10_nvocopy_933086";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "vides_f10_nvocopy2_171734";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy10_751048";
    item['fr'] = "m3";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy11_40942";
    item['fr'] = "30 m3";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy12_654787";
    item['fr'] = "VIDES";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy13_204406";
    item['fr'] = "MATIERES";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_text2_336463";
    item['fr'] = "Pilotage du parc";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_textcopy_306997";
    item['fr'] = "Pilotage du parc";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_textcopy2_461497";
    item['fr'] = "Connecté en tant que";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_text3_707603";
    item['fr'] = "Seuil critique";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_textcopy3_964119";
    item['fr'] = "%";
    
    item = {};
    this.items.push(item);
    item['key'] = "outildepilotage_text_747430";
    item['fr'] = "Bienvenue";
    
    item = {};
    this.items.push(item);
    item['key'] = "outildepilotage_textcopy_665386";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "outildepilotage_textcopy2_157778";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "outildepilotage_button_576162";
    item['fr'] = "Commencer";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_textcopy4_403941";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_text_1034631";
    item['fr'] = "Confidentiel Neurowaste";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen9_text2_258286";
    item['fr'] = "Pilotage du parc";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen9_textcopy_455082";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen9_textcopy4_1040480";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen9_textcopy2_419023";
    item['fr'] = "Connecté en tant que";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen9_text_534236";
    item['fr'] = "Confidentiel Neurowaste";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen9_checkboxcopy_621024";
    item['fr'] = "Pleines";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen9_field_489190";
    item['fr'] = "seuil";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen9_textcopy3_103325";
    item['fr'] = "%";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen9_text3_702453";
    item['fr'] = "Seuil critique";
    
    item = {};
    this.items.push(item);
    item['key'] = "gauche_f1_pleincopy_210175";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "gauche_f1_futurecopy_288848";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "gauche_f1_nvocopy_903750";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "gauche_f5_pleincopy_187093";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "gauche_f5_futurecopy_273025";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "gauche_f5_nvocopy_422382";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "gauche_f4_pleincopy_41193";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "gauche_f4_nvocopy_245405";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "gauche_f4_futurecopy_890050";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "gauche_f3_pleincopy_1020683";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "gauche_f3_futurecopy_719565";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "gauche_f3_nvocopy_659395";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "gauche_f2_pleincopy_404154";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "gauche_f2_nvocopy_886807";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "gauche_f2_futurecopy_215022";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f6_plein_157580";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f6_nvo_1018809";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f6_future_19636";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f10_plein_262651";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f10_nvo_883821";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f10_future_977647";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f9_plein_512305";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f9_future_555024";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f9_nvo_661293";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f8_plein_537803";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f8_nvo_39076";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f8_future_527255";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f7_plein_898771";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f7_future_1029799";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f7_nvo_147430";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_f6_pleincopy_438498";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_f6_nvocopy_340390";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_f6_futurecopy_280310";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_f10_pleincopy_462262";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_f10_nvocopy_544582";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_f10_futurecopy_656154";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_f9_pleincopy_994997";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_f9_futurecopy_695666";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_f9_nvocopy_799065";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_f8_pleincopy_669619";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_f8_nvocopy_1040629";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_f8_futurecopy_329613";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_f7_pleincopy_449776";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_f7_futurecopy_1032782";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_f7_nvocopy_298249";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future_f6_futurecopy_975032";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future_f10_futurecopy_562029";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future_f9_futurecopy_1038683";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future_f8_futurecopy_824122";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "future_f7_futurecopy_492044";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future_f6_futurecopy2_258430";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_f6_nvocopy2_483315";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "synthese_text_148946";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_hotspot_753190";
    item['fr'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "plein_site_263000";
    item['fr'] = "Site";
    
    item = {};
    this.items.push(item);
    item['key'] = "plein_tot_plein_129944";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "vides_tot_vide_659065";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_tps_ecoule_168274";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "button1_title_377072";
    item['fr'] = "Part X";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_textcopy2_458308";
    item['fr'] = "Connecté en tant que";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_textcopy4_708368";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_textcopy_562953";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_text3_474323";
    item['fr'] = "Seuil critique";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_textcopy3_70586";
    item['fr'] = "%";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_field_578660";
    item['fr'] = "seuil";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_checkboxcopy_155495";
    item['fr'] = "Prévisions";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_checkbox_608856";
    item['fr'] = "Pleines";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_text_728825";
    item['fr'] = "Confidentiel Neurowaste";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_long_term2_textcopy2_670938";
    item['fr'] = "Connecté en tant que";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_long_term2_textcopy4_334237";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_long_term2_textcopy_878102";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_long_term2_text_835376";
    item['fr'] = "Confidentiel Neurowaste";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_textcopy2_298203";
    item['fr'] = "Connecté en tant que";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_textcopy4_283512";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_textcopy_857282";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_text3_718061";
    item['fr'] = "Seuil critique";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_textcopy3_42693";
    item['fr'] = "%";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_field_801030";
    item['fr'] = "seuil";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_checkboxcopy_976241";
    item['fr'] = "Pleines";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_text_770241";
    item['fr'] = "Confidentiel Neurowaste";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_sup_seuil_199815";
    item['fr'] = "90";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f7_futurecopy_1008696";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f8_futurecopy_447599";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f9_futurecopy_151885";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f10_futurecopy_992163";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f6_futurecopy_1016054";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f6_future_409611";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f10_future_450967";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f9_future_641658";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f8_future_264407";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future2_f7_future_932286";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_button2_298091";
    item['fr'] = "Dernier relevé";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain_text_728181";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain_f6_futurecopy2_732794";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain_f6_futurecopy3_342735";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain_f6_futurecopy4_504608";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain_f6_futurecopy5_696332";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain_f6_futurecopy6_880620";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain_f6_futurecopy7_758001";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain_f6_futurecopy8_412273";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain_f6_futurecopy9_781917";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain_f6_futurecopy10_1030525";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen13_text_324628";
    item['fr'] = "Confidentiel Neurowaste";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen13_checkbox_466455";
    item['fr'] = "Prévisions";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen13_checkboxcopy_395240";
    item['fr'] = "Pleines";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen13_field_892873";
    item['fr'] = "seuil";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen13_textcopy3_386867";
    item['fr'] = "%";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen13_text3_131452";
    item['fr'] = "Seuil critique";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen13_textcopy_301572";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen13_textcopy4_947374";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "screen13_textcopy2_824664";
    item['fr'] = "Connecté en tant que";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain2_text_989597";
    item['fr'] = "Confidentiel Neurowaste";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain2_textcopy_890192";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain2_textcopy4_272253";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain2_textcopy2_519500";
    item['fr'] = "Connecté en tant que";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_long_term2_text_953382";
    item['fr'] = "Confidentiel Neurowaste";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_long_term2_textcopy_431061";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_long_term2_textcopy4_194079";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_long_term2_textcopy2_348661";
    item['fr'] = "Connecté en tant que";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain_tot_vide_513126";
    item['fr'] = "false";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain_tot_pleincopy_619782";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain_tot_dechcopy_715061";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy13_717033";
    item['fr'] = "MATIERES";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy12_631854";
    item['fr'] = "VIDES";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy11_388492";
    item['fr'] = "30 m3";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy10_450151";
    item['fr'] = "10 m3";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy9_1000207";
    item['fr'] = "DIB";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy8_432701";
    item['fr'] = "VEGET";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy7_1026682";
    item['fr'] = "PLATRE";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy6_465025";
    item['fr'] = "PAPIER";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy5_399232";
    item['fr'] = "GRAV NV";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_text_274378";
    item['fr'] = "MEUBLES";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy_1004939";
    item['fr'] = "BOIS";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy2_609884";
    item['fr'] = "CARTON";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy3_911667";
    item['fr'] = "FERRAILLE";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy4_888956";
    item['fr'] = "GRAV";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy3_877725";
    item['fr'] = "%";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_field_257649";
    item['fr'] = "ratio";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_text3_839193";
    item['fr'] = "Bennes par chauffeur";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain2_chauffeurs_dech_529003";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain2_tot_dech_407908";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain2_tot_plein_484888";
    item['fr'] = "DI";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain2_text_879234";
    item['fr'] = "TOTAL";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain2_f6_futurecopy10_122170";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain2_f6_futurecopy9_698152";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain2_f6_futurecopy8_246901";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain2_f6_futurecopy7_1046560";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain2_f6_futurecopy6_721500";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain2_f6_futurecopy5_253821";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain2_f6_futurecopy4_402338";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain2_f6_futurecopy3_247506";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain2_f6_futurecopy2_796751";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "lendemain2_f6_futurecopy_415245";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "footer_previ_textcopy_997137";
    item['fr'] = "TOTAL DI et Stock";
    
    item = {};
    this.items.push(item);
    item['key'] = "footer_previ_chauffeurs_dechcopy_872235";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy3_695388";
    item['fr'] = "Commandes DI";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_fieldcopy_694154";
    item['fr'] = "DI";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy_238384";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy4_872266";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_long_term_textcopy_205837";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_long_term_textcopy4_1008537";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy3_442342";
    item['fr'] = "%";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_field_450606";
    item['fr'] = "seuil";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_text3_113121";
    item['fr'] = "Seuil critique";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy3_894942";
    item['fr'] = "Stock restant";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_fieldcopy_605658";
    item['fr'] = "stock";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_bar_title_472889";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_bar_title_172007";
    item['fr'] = "Part X";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_bar_title_856915";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_bar_title_577939";
    item['fr'] = "Lendemain";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_bar_title_670042";
    item['fr'] = "Lendemain";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_bar_title_96254";
    item['fr'] = "Part X";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_bar_titlecopy2_334728";
    item['fr'] = "Long terme";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_bar_titlecopy3_609746";
    item['fr'] = "Pilotage";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_bar_titlecopy4_855091";
    item['fr'] = "Lendemain";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_bar_titlecopy5_313580";
    item['fr'] = "Lendemain";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_bar_titlecopy6_775003";
    item['fr'] = "Long terme";
    
    item = {};
    this.items.push(item);
    item['key'] = "vides_text_313449";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "vides_text2_580094";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "vides_textcopy_68660";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "vides_textcopy2_11000";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "number_text_951361";
    item['fr'] = "test";
    
    item = {};
    this.items.push(item);
    item['key'] = "number_text_624628";
    item['fr'] = "test";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_f10_nvocopy2_659117";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "future_f10_futurecopy2_54581";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "plein_f5_pleincopy2_345270";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "plein_f5_pleincopy3_46544";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy11_594739";
    item['fr'] = "PRES VGT";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f10_nvocopy2_445333";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_tps_ecoule_163383";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f6_nvocopy2_783378";
    item['fr'] = "90";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f7_nvocopy_540080";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f8_nvocopy_695585";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f9_nvocopy_507285";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f10_nvocopy_936896";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f6_nvocopy_781245";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f6_nvo_268412";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f10_nvo_866342";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f9_nvo_150042";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f8_nvo_237889";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f7_nvo_711926";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy14_237874";
    item['fr'] = "AUTRES";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy15_876455";
    item['fr'] = "PAM";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy16_557392";
    item['fr'] = "MEUBLES";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy17_138200";
    item['fr'] = "COMPOST";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy18_373064";
    item['fr'] = "BLANC";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy19_1010719";
    item['fr'] = "AMIANTE";
    
    item = {};
    this.items.push(item);
    item['key'] = "plein2_f5_pleincopy3_819773";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "plein2_f5_pleincopy2_174041";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "plein2_tot_plein_841847";
    item['fr'] = "false";
    
    item = {};
    this.items.push(item);
    item['key'] = "plein2_site_576584";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "plein2_f2_pleincopy_711257";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "plein2_f3_pleincopy_704083";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "plein2_f4_pleincopy_237120";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "plein2_f5_pleincopy_1019169";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "plein2_f1_pleincopy_579817";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "plein2_f5_plein_913697";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "plein2_f4_plein_428828";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "plein2_f3_plein_515489";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "plein2_f2_plein_38033";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "specifiques_textcopy_765315";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "specifiques_textcopy4_744580";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "specifiques_text_361131";
    item['fr'] = "Confidentiel Neurowaste";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy19_398756";
    item['fr'] = "CAISSE\nGRILLAGE";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy18_943871";
    item['fr'] = "CAISSE\nPALETTE";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy17_533694";
    item['fr'] = "FUT";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy16_682551";
    item['fr'] = "MEUBLES";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy15_617985";
    item['fr'] = "DEEE";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy14_663910";
    item['fr'] = "VIDES";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy11_382110";
    item['fr'] = "Ampoules";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy13_804733";
    item['fr'] = "FLUX";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy12_77233";
    item['fr'] = "VIDES";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy10_601844";
    item['fr'] = "m3";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy9_162256";
    item['fr'] = "Solvants";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy8_711769";
    item['fr'] = "Phytos";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy7_846922";
    item['fr'] = "Pateux";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy6_329637";
    item['fr'] = "Medica-\nments";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy5_433775";
    item['fr'] = "Extincteurs";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_text_858687";
    item['fr'] = "Acides";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy_438087";
    item['fr'] = "Bases";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy2_377475";
    item['fr'] = "Aerosols";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy3_818974";
    item['fr'] = "Bidons d’huiles";
    
    item = {};
    this.items.push(item);
    item['key'] = "header2_textcopy4_540852";
    item['fr'] = "Comburants";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f10_nvocopy2_957203";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_tps_ecoule_391734";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f6_nvocopy2_213729";
    item['fr'] = "90";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f7_nvocopy_456091";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f8_nvocopy_804705";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f9_nvocopy_916670";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f10_nvocopy_176518";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f6_nvocopy_998172";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f6_nvo_419311";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f10_nvo_542196";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f9_nvo_343885";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f8_nvo_154584";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau2_f7_nvo_577000";
    item['fr'] = " 0";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy20_433997";
    item['fr'] = "EXTINCTEURS";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy21_918514";
    item['fr'] = "FILTRES";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy22_678836";
    item['fr'] = "PATEUX";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy20_75635";
    item['fr'] = "Neons";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy21_616349";
    item['fr'] = "Bouteilles de gaz";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy22_869785";
    item['fr'] = "Cartouches";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_tps_ecoulecopy_837617";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_tps_ecoulecopy2_828913";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_tps_ecoulecopy3_623465";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_tps_ecoulecopy4_21111";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_tps_ecoulecopy5_870671";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_tps_ecoulecopy6_839384";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_tps_ecoulecopy7_671215";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_tps_ecoulecopy8_801853";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_tps_ecoulecopy9_98453";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_tps_ecoulecopy10_313984";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy10_560056";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy9_800636";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy8_187931";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy7_378120";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy6_76678";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy5_9025";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy4_171377";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy3_896297";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy2_477640";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy_684052";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoule_947602";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoulecopy10_345899";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoulecopy9_579528";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoulecopy8_921062";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoulecopy7_56330";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoulecopy6_785713";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoulecopy5_623281";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoulecopy4_459305";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoulecopy3_927687";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoulecopy2_414181";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoulecopy_168760";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoule_979473";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy10_433675";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy11_601769";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy12_570052";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy13_267965";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy_679544";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy2_921792";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy3_159511";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy10_435051";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy9_566459";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy8_540540";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy7_424911";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy6_247802";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy5_639185";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy4_1000454";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy3_374950";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy2_664053";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy_240699";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoule_512234";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy_563460";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy2_415337";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy3_315269";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_button2_787548";
    item['fr'] = "J+2 et J+3";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_text2_793926";
    item['fr'] = "Bennes pleines sur tout le parc";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy7_673778";
    item['fr'] = "+";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy8_726052";
    item['fr'] = "Commandes de DI";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy9_220639";
    item['fr'] = "+";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy10_52860";
    item['fr'] = "Prédiction j+…";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy11_555809";
    item['fr'] = "-";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy12_491163";
    item['fr'] = "Tolérance stock restant";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy13_351121";
    item['fr'] = "Objectif pleines non vides";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy13_545773";
    item['fr'] = "Nombre de bennes par chauffeur";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy14_463890";
    item['fr'] = "=";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy15_504053";
    item['fr'] = "Nombre de chauffeurs";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy16_1026537";
    item['fr'] = "Bennes pleines sur tout le parc";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy17_435706";
    item['fr'] = "Commandes de DI";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy18_924415";
    item['fr'] = "Objectif pleines non vides";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy19_642910";
    item['fr'] = "Prédiction j+…";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy20_514358";
    item['fr'] = "+";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy21_14398";
    item['fr'] = "-";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy22_479703";
    item['fr'] = "+";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy23_818187";
    item['fr'] = "Nombre de bennes par chauffeur";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy24_1005567";
    item['fr'] = "=";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy25_265597";
    item['fr'] = "Nombre de chauffeurs";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy16_565123";
    item['fr'] = "Bennes pleines sur tout le parc";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy17_125489";
    item['fr'] = "Commandes de DI";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy18_429828";
    item['fr'] = "Objectif pleines non vides";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy19_876822";
    item['fr'] = "Prédiction j+…";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy20_876137";
    item['fr'] = "+";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy21_274884";
    item['fr'] = "-";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy22_90120";
    item['fr'] = "+";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy23_51963";
    item['fr'] = "Nombre de bennes par chauffeur";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy24_782920";
    item['fr'] = "=";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy25_1029789";
    item['fr'] = "Nombre de chauffeurs";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_field2_796664";
    item['fr'] = "Pleines sur tout le parc";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_fieldcopy4_100599";
    item['fr'] = "Commandes DI";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_fieldcopy5_707598";
    item['fr'] = "Tolérance stock restant";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_fieldcopy6_661616";
    item['fr'] = "Bennes par chauffeur";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_text4_736810";
    item['fr'] = "+";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy16_1022436";
    item['fr'] = "+";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy17_500197";
    item['fr'] = "-";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy18_862397";
    item['fr'] = "-";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy18_906400";
    item['fr'] = "=";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy19_1030879";
    item['fr'] = "Nombre de chauffeurs";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy20_834755";
    item['fr'] = "Prédiction j+…";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy21_87803";
    item['fr'] = "Prédiction j+…";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy22_13450";
    item['fr'] = "=";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy23_795472";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_fieldcopy7_69627";
    item['fr'] = "Pleines sur tout le parc";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_fieldcopy8_363594";
    item['fr'] = "Commandes DI";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_fieldcopy9_357086";
    item['fr'] = "Tolérance stock restant";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_fieldcopy10_415848";
    item['fr'] = "Bennes par chauffeur";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy24_44714";
    item['fr'] = "+";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy25_571052";
    item['fr'] = "+";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_textcopy26_273007";
    item['fr'] = "-";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_text5_747381";
    item['fr'] = "Prévisions J+2 et J+3";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_button2_570492";
    item['fr'] = "Calculer J+2";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_lendemain_buttoncopy_650350";
    item['fr'] = "Calculer J+3";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy4_964709";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy7_783543";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy10_340354";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy11_858541";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques2_tps_ecoulecopy_217560";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy10_617275";
    item['fr'] = "Autres";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy12_355291";
    item['fr'] = "Radio-\ngraphies";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy15_770233";
    item['fr'] = "Piles";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy16_355322";
    item['fr'] = "Vide 80L";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy17_492775";
    item['fr'] = "Vide 650L";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_textcopy17_503020";
    item['fr'] = "Vide rouge";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_textcopy16_370390";
    item['fr'] = "Vide Grise";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_textcopy15_615643";
    item['fr'] = "Piles";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_textcopy12_933440";
    item['fr'] = "Radiographies";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_textcopy10_931730";
    item['fr'] = "Autres";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_textcopy22_1025645";
    item['fr'] = "Cartouches";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_textcopy21_451631";
    item['fr'] = "Bouteilles de gaz";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_textcopy20_1013292";
    item['fr'] = "Neons";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_textcopy14_697025";
    item['fr'] = "VIDES";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_textcopy11_565988";
    item['fr'] = "Caisses vides";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_textcopy13_242419";
    item['fr'] = "FLUX";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_textcopy9_134139";
    item['fr'] = "Solvants";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_textcopy8_673542";
    item['fr'] = "Autres \nliquides";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_textcopy7_980115";
    item['fr'] = "Pateux";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_textcopy6_140785";
    item['fr'] = "Pateux";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_textcopy5_454172";
    item['fr'] = "Emballages";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_text_268660";
    item['fr'] = "Phyto";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_textcopy_574199";
    item['fr'] = "Flitres à huiles";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_textcopy2_471369";
    item['fr'] = "Acides";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_textcopy3_843596";
    item['fr'] = "Bases";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques2_textcopy4_136036";
    item['fr'] = "Aerosols";
    
    item = {};
    this.items.push(item);
    item['key'] = "specifiques_text2_844727";
    item['fr'] = "Spécifiques hors ECO DDS";
    
    item = {};
    this.items.push(item);
    item['key'] = "specifiques_textcopy2_378428";
    item['fr'] = "Spécifiques ECO DDS";
    
    item = {};
    this.items.push(item);
    item['key'] = "specifiques_textcopy3_126424";
    item['fr'] = "Pneus";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoulecopy10_691848";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoulecopy9_712096";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoulecopy8_299244";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoulecopy7_524033";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoulecopy6_899035";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoulecopy5_24441";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoulecopy4_161823";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoulecopy3_968867";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoulecopy2_1004298";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoulecopy_385970";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques3_tps_ecoule_1019865";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds2_textcopy11_275616";
    item['fr'] = "VIDES";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds2_textcopy13_495019";
    item['fr'] = "FLUX";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds2_textcopy8_1014316";
    item['fr'] = "Autres";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds2_textcopy7_818268";
    item['fr'] = "Pateux";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds2_textcopy6_151906";
    item['fr'] = "Pateux";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds2_textcopy5_1024740";
    item['fr'] = "Emballages";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds2_text_454272";
    item['fr'] = "Jantes";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds2_textcopy_335088";
    item['fr'] = "Pneus decret";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds2_textcopy2_522692";
    item['fr'] = "Pneus";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds2_textcopy3_347688";
    item['fr'] = "Velo";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds2_textcopy4_709500";
    item['fr'] = "Aerosols";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds2_tps_ecoulecopy10_474495";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds2_tps_ecoulecopy9_238838";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds2_tps_ecoulecopy8_27992";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds2_tps_ecoulecopy7_743862";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds2_tps_ecoulecopy6_75771";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds2_tps_ecoulecopy5_496928";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds2_tps_ecoulecopy4_903381";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds2_tps_ecoulecopy3_788303";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds2_tps_ecoulecopy2_129212";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds2_tps_ecoulecopy_540410";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds2_tps_ecoule_138060";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_pneus2_textcopy13_668850";
    item['fr'] = "FLUX";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_pneus2_text_1004619";
    item['fr'] = "Froid";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_pneus2_textcopy_701285";
    item['fr'] = "TV / DEEE";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_pneus2_textcopy2_683764";
    item['fr'] = "Ampoules";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_pneus2_textcopy3_903080";
    item['fr'] = "Neons";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_pneus2_tps_ecoulecopy10_1011538";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_pneus2_tps_ecoulecopy3_712126";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_pneus2_tps_ecoulecopy2_1039726";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_pneus2_tps_ecoulecopy_494062";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_pneus2_tps_ecoule_201900";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_deee_textcopy4_401490";
    item['fr'] = "Caisse grillage vides";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_deee_tps_ecoulecopy4_324076";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_deee2_textcopy4_945343";
    item['fr'] = "Autres";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_deee2_textcopy13_691451";
    item['fr'] = "FLUX";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_deee2_text_991329";
    item['fr'] = "Piles";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_deee2_textcopy_875404";
    item['fr'] = "Batteries";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_deee2_textcopy2_800565";
    item['fr'] = "Bouteilles de gaz";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_deee2_textcopy3_529433";
    item['fr'] = "Radio-\ngraphies";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_deee2_tps_ecoulecopy4_525870";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_deee2_tps_ecoulecopy10_244863";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_deee2_tps_ecoulecopy3_209402";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_deee2_tps_ecoulecopy2_85953";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_deee2_tps_ecoulecopy_1003927";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_deee2_tps_ecoule_388302";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_autres_textcopy5_1012482";
    item['fr'] = "Cartouches d’encres";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_autres_tps_ecoulecopy5_90317";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "specifiques_textcopy5_1030865";
    item['fr'] = "DEEE";
    
    item = {};
    this.items.push(item);
    item['key'] = "specifiques_textcopy6_899097";
    item['fr'] = "Autres";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_textcopy4_264696";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_textcopy_948030";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_text3_84145";
    item['fr'] = "Seuil critique";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_field_452147";
    item['fr'] = "seuil";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_textcopy3_330655";
    item['fr'] = "%";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_checkboxcopy_916268";
    item['fr'] = "Pleines";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard2_text_364324";
    item['fr'] = "Confidentiel Neurowaste";
    
    item = {};
    this.items.push(item);
    item['key'] = "specifiques2_textcopy4_416116";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "specifiques2_textcopy_505692";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "specifiques2_text2_492728";
    item['fr'] = "Spécifiques hors ECO DDS";
    
    item = {};
    this.items.push(item);
    item['key'] = "specifiques2_textcopy2_431728";
    item['fr'] = "Spécifiques ECO DDS";
    
    item = {};
    this.items.push(item);
    item['key'] = "specifiques2_textcopy3_25262";
    item['fr'] = "Pneus";
    
    item = {};
    this.items.push(item);
    item['key'] = "specifiques2_textcopy5_607401";
    item['fr'] = "DEEE";
    
    item = {};
    this.items.push(item);
    item['key'] = "specifiques2_textcopy6_968379";
    item['fr'] = "Autres";
    
    item = {};
    this.items.push(item);
    item['key'] = "specifiques2_text_725259";
    item['fr'] = "Confidentiel Neurowaste";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_deee_textcopy5_706121";
    item['fr'] = "Extincteur\n< 2kg";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_deee_tps_ecoulecopy5_879368";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy10_787892";
    item['fr'] = "DASRI";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_tps_ecoulecopy11_127858";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_text2_447381";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_previ_textcopy10_10317";
    item['fr'] = "PRESTATIARES";
    
    item = {};
    this.items.push(item);
    item['key'] = "vides_textcopy3_281539";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "vides_textcopy4_51890";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_tps_ecoulecopy12_733465";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_tps_ecoulecopy13_799918";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_tps_ecoulecopy12_1028957";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy11_246163";
    item['fr'] = "Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy12_685523";
    item['fr'] = "Heure";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds_textcopy11_100242";
    item['fr'] = "Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds_textcopy12_142414";
    item['fr'] = "Heure";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds_tps_ecoulecopy13_11033";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds_tps_ecoulecopy12_998192";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_pneus_textcopy9_504276";
    item['fr'] = "Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_pneus_textcopy12_877558";
    item['fr'] = "Heure";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_pneus_tps_ecoulecopy13_21766";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_pneus_tps_ecoulecopy12_409688";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_deee_textcopy9_134103";
    item['fr'] = "Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_deee_textcopy12_868566";
    item['fr'] = "Heure";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_deee_tps_ecoulecopy13_92653";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_deee_tps_ecoulecopy12_957789";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_autres_textcopy9_922433";
    item['fr'] = "Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_autres_textcopy12_209886";
    item['fr'] = "Heure";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_autres_tps_ecoulecopy13_438016";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_autres_tps_ecoulecopy12_601089";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "legende_text_896716";
    item['fr'] = "Légende";
    
    item = {};
    this.items.push(item);
    item['key'] = "legende_text2_135319";
    item['fr'] = "Bennes à quai au dessus du seuil critique";
    
    item = {};
    this.items.push(item);
    item['key'] = "legende_textcopy_182157";
    item['fr'] = "Bennes vides HORS QUAI";
    
    item = {};
    this.items.push(item);
    item['key'] = "legende_textcopy2_187745";
    item['fr'] = "Bennes pleines HORS QUAI";
    
    item = {};
    this.items.push(item);
    item['key'] = "legende_textcopy3_614303";
    item['fr'] = "Heure du dernier relevé";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_f6_nvocopy_825303";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_f7_nvocopy2_361790";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp_niveau_text_987701";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "remonte_terrain_text_348232";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_f7_nvocopy2_683165";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_f7_nvocopy_191728";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp_niveau_text_760061";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp_niveau_f6_nvocopy_408454";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_text_864557";
    item['fr'] = "Site";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_f6_nvocopy_581957";
    item['fr'] = "90";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp_niveau_textcopy_532408";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "legende_text3_241201";
    item['fr'] = "Demande packmat";
    
    item = {};
    this.items.push(item);
    item['key'] = "legende_textcopy4_632920";
    item['fr'] = "Demande 32T";
    
    item = {};
    this.items.push(item);
    item['key'] = "legende_textcopy5_353281";
    item['fr'] = "Pleines hors quai";
    
    item = {};
    this.items.push(item);
    item['key'] = "legende_textcopy6_776692";
    item['fr'] = "A quai de ce flux (vide = 1)";
    
    item = {};
    this.items.push(item);
    item['key'] = "legende_textcopy7_270251";
    item['fr'] = "Remplissage cumulé du flux (%)";
    
    item = {};
    this.items.push(item);
    item['key'] = "niveau_checkbox_772893";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_textcopy20_160323";
    item['fr'] = "VU";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_field_624129";
    item['fr'] = "Identifiant";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_fieldcopy_516794";
    item['fr'] = "Mot de passe";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_button_809563";
    item['fr'] = "Connexion";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds_textcopy7_20642";
    item['fr'] = "Comburants";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_iconbutton_1879671726";
    item['fr'] = "Pleines";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_iconbutton_1145326881";
    item['fr'] = "Pleines hq";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_iconbuttoncopy_441294271";
    item['fr'] = "Pleines hq";
    
    item = {};
    this.items.push(item);
    item['key'] = "pleines_check2_iconbuttoncopy_1326723140";
    item['fr'] = "Prévisions";
    
    item = {};
    this.items.push(item);
    item['key'] = "pleines_check2_iconbutton_985962333";
    item['fr'] = "Prévisions";
    
    item = {};
    this.items.push(item);
    item['key'] = "previsions_check2_iconbuttoncopy_1592091735";
    item['fr'] = "Prévisions";
    
    item = {};
    this.items.push(item);
    item['key'] = "previsions_check2_iconbutton_911481139";
    item['fr'] = "Prévisions";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy15_2040128122";
    item['fr'] = "Passage\nchimirec";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_2_tps_ecoulecopy2_730848157";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_lendemain_text_1512351266";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_lendemain_text2_1622512372";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_previ_textcopy11_160941081";
    item['fr'] = "PAM";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_previ_textcopy12_993304353";
    item['fr'] = "COMPOST";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_previ_textcopy14_410820618";
    item['fr'] = "AMIANTE";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_previ_textcopy15_2008838923";
    item['fr'] = "BLANC";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_previ_textcopy16_517914095";
    item['fr'] = "MEUBLES";
    
    item = {};
    this.items.push(item);
    item['key'] = "footer_previ_button_941959767";
    item['fr'] = "Export excel";
    
    item = {};
    this.items.push(item);
    item['key'] = "footer_previ_chauffeurs_dechcopy2_396861314";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "footer_previ_chauffeurs_dechcopy3_719331210";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "outildashboard_button_722986947";
    item['fr'] = "Commencer";
    
    item = {};
    this.items.push(item);
    item['key'] = "outildashboard_textcopy2_1375414412";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "outildashboard_textcopy_1639957465";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "outildashboard_text_873460681";
    item['fr'] = "Bienvenue";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_button_397447829";
    item['fr'] = "Commencer";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_textcopy2_1905495570";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_textcopy_1016018280";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_bienvenue_2129009774";
    item['fr'] = "Bienvenue";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_bienvenuecopy_575674823";
    item['fr'] = "Authentification en cours…";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_user_namecopy_1028325999";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_non_previ_user_name_171388901";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "dashboard_non_previ_entreprise_632859967";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_text2_16020052";
    item['fr'] = "Bennes pleines sur tout le parc";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy8_1074956142";
    item['fr'] = "Commandes de DI";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy12_305776182";
    item['fr'] = "Tolérance stock restant";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy10_1090449136";
    item['fr'] = "Prédiction j+…";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy9_927996986";
    item['fr'] = "+";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy11_1915177039";
    item['fr'] = "-";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy7_689574863";
    item['fr'] = "+";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy13_1420085349";
    item['fr'] = "Nombre de bennes par chauffeur";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy14_1517947394";
    item['fr'] = "=";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy15_2081399699";
    item['fr'] = "Nombre de chauffeurs";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy20_1018759506";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy18_180118168";
    item['fr'] = "=";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy19_1683238856";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_field2_1844914589";
    item['fr'] = "Pleines sur tout le parc";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_fieldcopy4_164027943";
    item['fr'] = "Commandes DI";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_fieldcopy5_223313662";
    item['fr'] = "Tolérance stock restant";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_fieldcopy6_788842960";
    item['fr'] = "Bennes par chauffeur";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_text4_464687718";
    item['fr'] = "+";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy16_164080818";
    item['fr'] = "+";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy17_815093258";
    item['fr'] = "-";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_button2_1342511280";
    item['fr'] = "Calculer J+2";
    
    item = {};
    this.items.push(item);
    item['key'] = "j3_field2_362777514";
    item['fr'] = "Pleines sur tout le parc";
    
    item = {};
    this.items.push(item);
    item['key'] = "j3_text4_787922685";
    item['fr'] = "+";
    
    item = {};
    this.items.push(item);
    item['key'] = "j3_fieldcopy4_61668733";
    item['fr'] = "Commandes DI";
    
    item = {};
    this.items.push(item);
    item['key'] = "j3_textcopy16_1645477425";
    item['fr'] = "+";
    
    item = {};
    this.items.push(item);
    item['key'] = "j3_textcopy20_1750595058";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "j3_textcopy17_1423335219";
    item['fr'] = "-";
    
    item = {};
    this.items.push(item);
    item['key'] = "j3_fieldcopy5_207372580";
    item['fr'] = "Tolérance stock restant";
    
    item = {};
    this.items.push(item);
    item['key'] = "j3_fieldcopy6_861174974";
    item['fr'] = "Bennes par chauffeur";
    
    item = {};
    this.items.push(item);
    item['key'] = "j3_textcopy18_1922334213";
    item['fr'] = "=";
    
    item = {};
    this.items.push(item);
    item['key'] = "j3_textcopy19_55985699";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "j3_button2_1246365166";
    item['fr'] = "Calculer J+3";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy20_1686341561";
    item['fr'] = "Huile \nvegetale";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy19_427316740";
    item['fr'] = "Emballages \nsales";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy18_358245029";
    item['fr'] = "Non\nIdentifiés";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy15_1152060659";
    item['fr'] = "Passage\nchimirec";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy12_386393159";
    item['fr'] = "Heure";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy11_1202811286";
    item['fr'] = "Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy10_1639163830";
    item['fr'] = "DASRI";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy17_1119390205";
    item['fr'] = "Vide 650L";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy16_1720125253";
    item['fr'] = "Vide 80L";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy14_1651934584";
    item['fr'] = "VIDES";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy13_1923923565";
    item['fr'] = "FLUX";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy9_119362228";
    item['fr'] = "Solvants";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy8_1196104008";
    item['fr'] = "Phytos";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy7_59175588";
    item['fr'] = "Pateux";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy6_877969186";
    item['fr'] = "Medica-\nments";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy5_124775719";
    item['fr'] = "Extincteurs";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_text_507203747";
    item['fr'] = "Acides";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy_1803254367";
    item['fr'] = "Bases";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy2_1165943392";
    item['fr'] = "Aerosols";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy3_2109369346";
    item['fr'] = "Bidons d’huiles";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_specifiques_textcopy4_381831549";
    item['fr'] = "Comburants";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_2_tps_ecoulecopy5_1215588778";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_2_tps_ecoulecopy4_788702946";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_2_tps_ecoulecopy3_1567439975";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_2_tps_ecoulecopy2_1797703994";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_2_tps_ecoulecopy_1330341284";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_specifiques_2_tps_ecoulecopy7_2073976681";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds_tps_ecoulecopy12_665141061";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds_tps_ecoulecopy13_1905962331";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds_tps_ecoulecopy10_648967055";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds_tps_ecoulecopy9_242263340";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds_tps_ecoulecopy8_1213364409";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds_tps_ecoulecopy7_228323772";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds_tps_ecoulecopy6_1810285137";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds_tps_ecoulecopy5_220678052";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds_tps_ecoulecopy4_167467443";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds_tps_ecoulecopy3_107087291";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds_tps_ecoulecopy2_246595985";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds_tps_ecoulecopy_848264162";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_eco_dds_tps_ecoule_202546964";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds_textcopy7_1318320609";
    item['fr'] = "Comburants";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds_textcopy12_749883014";
    item['fr'] = "Heure";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds_textcopy9_1738026276";
    item['fr'] = "Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds_textcopy11_1407128508";
    item['fr'] = "Caisses vides";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds_textcopy13_1536363329";
    item['fr'] = "FLUX";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds_textcopy8_429637179";
    item['fr'] = "Autres \nliquides";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds_textcopy6_1739170358";
    item['fr'] = "Pateux";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds_textcopy5_534968501";
    item['fr'] = "Combus-\ntibles";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds_text_403019025";
    item['fr'] = "Phyto";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds_textcopy_1052262556";
    item['fr'] = "Flitres à huiles";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds_textcopy2_1998163003";
    item['fr'] = "Acides";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds_textcopy3_838023052";
    item['fr'] = "Bases";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_eco_dds_textcopy4_1903129762";
    item['fr'] = "Aerosols";
    
    item = {};
    this.items.push(item);
    item['key'] = "vides_textcopy5_1984123110";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "vides_tot_vide_45776313";
    item['fr'] = "0";
    
    item = {};
    this.items.push(item);
    item['key'] = "vides_text_567409018";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "vides_textcopy4_785087973";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "vides_textcopy3_573069486";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "vides_text2_395814149";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "vides_textcopy_1661104960";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "vides_textcopy2_2007780252";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_textcopy5_1037720880";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_textcopy4_960834266";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_textcopy3_1830069848";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_textcopy2_788456716";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_textcopy_2062629928";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_text_1331388545";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "creation_ot_text_641736792";
    item['fr'] = "Création des feuilles de tournée";
    
    item = {};
    this.items.push(item);
    item['key'] = "creation_ot_textcopy_126508627";
    item['fr'] = "OT présélectionnés";
    
    item = {};
    this.items.push(item);
    item['key'] = "creation_ot_textcopy2_1343124750";
    item['fr'] = "Feuille de tournée sélectionnée";
    
    item = {};
    this.items.push(item);
    item['key'] = "creation_ot_textcopy3_2035429896";
    item['fr'] = "Tournée sélectionnée";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_textcopy5_795149269";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_textcopy6_1842674847";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "footer_previ_checkbox_1664954967";
    item['fr'] = "Inclure hors quai";
    
    item = {};
    this.items.push(item);
    item['key'] = "footer_previ_text2_568714334";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_textcopy5_1710572791";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "creation_ot_textcopy4_1651706641";
    item['fr'] = "OT présélectionnés";
    
    item = {};
    this.items.push(item);
    item['key'] = "creation_ot_textcopy4_2056651706";
    item['fr'] = "Site";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy_1277832216";
    item['fr'] = "OT présélectionnés";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy2_1118312604";
    item['fr'] = "Site";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy3_1939593427";
    item['fr'] = "Matière";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy4_1572732253";
    item['fr'] = "hh";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy5_1409858039";
    item['fr'] = "HQ";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_textcopy6_1666957777";
    item['fr'] = "Tournée";
    
    item = {};
    this.items.push(item);
    item['key'] = "button_tournee_button2_1540574351";
    item['fr'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "creation_ot_text2_449535278";
    item['fr'] = "Aucune";
    
    item = {};
    this.items.push(item);
    item['key'] = "creation_ot_text3_981386711";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_preselection2_textcopy6_726451278";
    item['fr'] = "Tournée";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_preselection2_textcopy5_1183971797";
    item['fr'] = "HQ";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_preselection2_textcopy4_2086351441";
    item['fr'] = "HQ";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_preselection2_textcopy3_1424572530";
    item['fr'] = "Matière";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_preselection2_textcopy2_1364499539";
    item['fr'] = "Site";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_preselection2_textcopy_1554177015";
    item['fr'] = "Feuille de tournee";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot2_textcopy5_337249324";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot2_textcopy3_1918211709";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot2_textcopy2_2062498143";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot2_textcopy_652519627";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot2_text_1109436249";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot2_textcopy6_1545298874";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "menuprincipal_text_720642711";
    item['fr'] = "Sélection chauffeur";
    
    item = {};
    this.items.push(item);
    item['key'] = "tournee_text_1956493907";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_tournee_text2_1965486916";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "tournee_j2_text_2114960831";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "menuprincipal_button_310198181";
    item['fr'] = "Tounée du jour";
    
    item = {};
    this.items.push(item);
    item['key'] = "menuprincipal_buttoncopy_589642378";
    item['fr'] = "Tounée demain";
    
    item = {};
    this.items.push(item);
    item['key'] = "menuprincipal_textcopy_281571160";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_tournee_j2_textcopy3_106301";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_tournee_j2_textcopy_359905";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_tournee_j2_text_135904";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_tournee_j2_text2_490477";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "phototicketpesee_button_667696";
    item['fr'] = "Confirmer";
    
    item = {};
    this.items.push(item);
    item['key'] = "phototicketpesee_capture_104296";
    item['fr'] = "Nouvelle photo";
    
    item = {};
    this.items.push(item);
    item['key'] = "phototicketpesee_button_277296";
    item['fr'] = "Valider";
    
    item = {};
    this.items.push(item);
    item['key'] = "informationscamion_field_612192";
    item['fr'] = "Immatriculation";
    
    item = {};
    this.items.push(item);
    item['key'] = "informationscamion_fieldcopy_1004904";
    item['fr'] = "Nom";
    
    item = {};
    this.items.push(item);
    item['key'] = "informationscamion_fieldcopy2_522804";
    item['fr'] = "Essence";
    
    item = {};
    this.items.push(item);
    item['key'] = "informationscamion_button_32207";
    item['fr'] = "Valider";
    
    item = {};
    this.items.push(item);
    item['key'] = "tourneedujour_button_168835";
    item['fr'] = "Menu principal";
    
    item = {};
    this.items.push(item);
    item['key'] = "informationscamion2_field_450745";
    item['fr'] = "Immatriculation";
    
    item = {};
    this.items.push(item);
    item['key'] = "informationscamion2_fieldcopy_578669";
    item['fr'] = "Nom";
    
    item = {};
    this.items.push(item);
    item['key'] = "informationscamion2_fieldcopy2_73108";
    item['fr'] = "Essence";
    
    item = {};
    this.items.push(item);
    item['key'] = "informationscamion2_button_697211";
    item['fr'] = "Valider";
    
    item = {};
    this.items.push(item);
    item['key'] = "phototicketpesee_buttoncopy_869397";
    item['fr'] = "Retour";
    
    item = {};
    this.items.push(item);
    item['key'] = "phototicketpesee_field_973198";
    item['fr'] = "Site";
    
    item = {};
    this.items.push(item);
    item['key'] = "phototicketpesee_fieldcopy_732727";
    item['fr'] = "Matière";
    
    item = {};
    this.items.push(item);
    item['key'] = "phototicketpesee_fieldcopy2_437330";
    item['fr'] = "Exutoire";
    
    item = {};
    this.items.push(item);
    item['key'] = "phototicketpesee_fieldcopy3_380396";
    item['fr'] = "Tonnage";
    
    item = {};
    this.items.push(item);
    item['key'] = "commentaireotchute_buttoncopy_467334";
    item['fr'] = "Retour";
    
    item = {};
    this.items.push(item);
    item['key'] = "commentaireotchute_button_397424";
    item['fr'] = "Valider";
    
    item = {};
    this.items.push(item);
    item['key'] = "commentaireotchute_field_170025";
    item['fr'] = "Commentaire";
    
    item = {};
    this.items.push(item);
    item['key'] = "commentaireotchute_textarea_27162";
    item['fr'] = "Commentaire";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_tournee_j1_textcopy2_381121";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "header_feuille_tournee_textcopy_316249";
    item['fr'] = "Exutoire";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_tournee_j_textcopy2_278564";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "informationscamionfin_fieldcopy3_321181";
    item['fr'] = "Immatriculation";
    
    item = {};
    this.items.push(item);
    item['key'] = "informationscamionfin_fieldcopy3_807904";
    item['fr'] = "km";
    
    item = {};
    this.items.push(item);
    item['key'] = "phototicketpesee_fieldcopy4_146372";
    item['fr'] = "Tonnage";
    
    item = {};
    this.items.push(item);
    item['key'] = "informationscamiondebut_fieldcopy3_338503";
    item['fr'] = "km";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_exutoire_text_795585";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "phototicketpesee_fieldcopy_31357";
    item['fr'] = "Commentaire";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_tournee_j_textcopy4_447310";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "tourneedujour_button2_82360";
    item['fr'] = "Ajouter";
    
    item = {};
    this.items.push(item);
    item['key'] = "scanner_capture_830050";
    item['fr'] = "Scanner l’étiquette";
    
    item = {};
    this.items.push(item);
    item['key'] = "scanner_capture_591034";
    item['fr'] = "Scanner le matricue";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseencharge_text_900926";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "action_action_894981";
    item['fr'] = "Sortir du quai";
    
    item = {};
    this.items.push(item);
    item['key'] = "action_action_725567";
    item['fr'] = "Vider";
    
    item = {};
    this.items.push(item);
    item['key'] = "action_action_54732";
    item['fr'] = "Compacter";
    
    item = {};
    this.items.push(item);
    item['key'] = "action_action_706013";
    item['fr'] = "Déposer vide";
    
    item = {};
    this.items.push(item);
    item['key'] = "action_action_479287";
    item['fr'] = "Vider/Remettre";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseencharge2_tonnage_447029";
    item['fr'] = "Tonnage";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseencharge2_commentaire_618738";
    item['fr'] = "Commentaire";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseencharge2_capture_787231";
    item['fr'] = "Nouvelle photo";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseencharge2_valider_705356";
    item['fr'] = "Valider";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseencharge2_retour_195821";
    item['fr'] = "Retour";
    
    item = {};
    this.items.push(item);
    item['key'] = "ajouteramatournee_text_401541";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseencharge_button2_527143";
    item['fr'] = "Scanner";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseencharge_text_499446";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "commentaireotchute_scanner_937245";
    item['fr'] = "Scanner la benne";
    
    item = {};
    this.items.push(item);
    item['key'] = "commentaireotchute_text_656288";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "exutoire_tonnage_547406";
    item['fr'] = "Tonnage";
    
    item = {};
    this.items.push(item);
    item['key'] = "exutoire_commentaire_763870";
    item['fr'] = "Commentaire";
    
    item = {};
    this.items.push(item);
    item['key'] = "exutoire_capture_644677";
    item['fr'] = "Ticket de pesée";
    
    item = {};
    this.items.push(item);
    item['key'] = "exutoire_valider_623710";
    item['fr'] = "Valider";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseencharge_valider_840627";
    item['fr'] = "Valider";
    
    item = {};
    this.items.push(item);
    item['key'] = "exutoire_retour_418340";
    item['fr'] = "Retour";
    
    item = {};
    this.items.push(item);
    item['key'] = "vidage_button_305722";
    item['fr'] = "Vidage effectué";
    
    item = {};
    this.items.push(item);
    item['key'] = "depot_valider_389258";
    item['fr'] = "Valider";
    
    item = {};
    this.items.push(item);
    item['key'] = "depot_retour_993438";
    item['fr'] = "Retour";
    
    item = {};
    this.items.push(item);
    item['key'] = "vidage_text_31989";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "exutoire_text_354322";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "depot_text_607480";
    item['fr'] = "Où avez-vous déposé la benne?";
    
    item = {};
    this.items.push(item);
    item['key'] = "vidage_retour_377206";
    item['fr'] = "Retour";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseencharge_valider_41501";
    item['fr'] = "Valider";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseencharge_text2_96199";
    item['fr'] = "Benne manipulée";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseencharge_provenancecopy_155961";
    item['fr'] = "Operation";
    
    item = {};
    this.items.push(item);
    item['key'] = "vidage_exutoirecopy_533317";
    item['fr'] = "En route pour";
    
    item = {};
    this.items.push(item);
    item['key'] = "exutoire_exutoirecopy_348149";
    item['fr'] = "Vidage effectué à";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseencharge_operation_148066";
    item['fr'] = "Operation";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseencharge_text_1018410";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseencharge_textcopy_855160";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseencharge_text2_226537";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "tourneedujour_buttoncopy_972710";
    item['fr'] = "Scanner";
    
    item = {};
    this.items.push(item);
    item['key'] = "mouvement_button_485803";
    item['fr'] = "Vider";
    
    item = {};
    this.items.push(item);
    item['key'] = "mouvement_buttoncopy_223500";
    item['fr'] = "Ne pas vider";
    
    item = {};
    this.items.push(item);
    item['key'] = "mouvement_hors_quaicopy_85190";
    item['fr'] = "Transfert de benne";
    
    item = {};
    this.items.push(item);
    item['key'] = "mouvement_transfertcopy_221583";
    item['fr'] = "Retour dépot en charge";
    
    item = {};
    this.items.push(item);
    item['key'] = "mouvement_retour_315735";
    item['fr'] = "Retour";
    
    item = {};
    this.items.push(item);
    item['key'] = "depot_transfertcopy_21170";
    item['fr'] = "Retour dépot en charge";
    
    item = {};
    this.items.push(item);
    item['key'] = "mouvement_vidagecopy_62195";
    item['fr'] = "Compacter";
    
    item = {};
    this.items.push(item);
    item['key'] = "mouvement_exutoire_478923";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "mouvement_text_59109";
    item['fr'] = "Opération prévue";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseenchargebennevide_provenance_497254";
    item['fr'] = "Benne manipulée";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseenchargebennevide_scanner_938999";
    item['fr'] = "Scanner";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseenchargebennevide_text_341074";
    item['fr'] = "Matricule";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseenchargebennevide_valider_316307";
    item['fr'] = "Valider";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseenchargebennevide_retour_81688";
    item['fr'] = "Retour";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseencharge_valider_927513";
    item['fr'] = "Valider";
    
    item = {};
    this.items.push(item);
    item['key'] = "depot_validercopy_695308";
    item['fr'] = "Autre localisation";
    
    item = {};
    this.items.push(item);
    item['key'] = "commentaireotchute_scanner_360455";
    item['fr'] = "Scanner";
    
    item = {};
    this.items.push(item);
    item['key'] = "commentaireotchute_textcopy_480554";
    item['fr'] = "Matricule";
    
    item = {};
    this.items.push(item);
    item['key'] = "commentaireotchute_scanner_484090";
    item['fr'] = "Scanner";
    
    item = {};
    this.items.push(item);
    item['key'] = "commentaireotchute_textcopy_837505";
    item['fr'] = "Matricule";
    
    item = {};
    this.items.push(item);
    item['key'] = "commentaireotchute_text_549501";
    item['fr'] = "Opération prévue";
    
    item = {};
    this.items.push(item);
    item['key'] = "commentaireotchute_capture_505087";
    item['fr'] = "Photo justificative";
    
    item = {};
    this.items.push(item);
    item['key'] = "action_action_126221";
    item['fr'] = "Retirer";
    
    item = {};
    this.items.push(item);
    item['key'] = "play_charge_234672718";
    item['fr'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseencharge_provenancecopy_632852275";
    item['fr'] = "Lieu de chargement";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseencharge_provenancecopy2_1565487437";
    item['fr'] = "Lieu de chargement";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseencharge_site_txt_1114765650";
    item['fr'] = "Site";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseencharge_flux_txt_1225648146";
    item['fr'] = "Flux";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseencharge_valider_2008391617";
    item['fr'] = "Valider";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseencharge_validercopy_1361644991";
    item['fr'] = "Modifier";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseencharge_validercopy_526387056";
    item['fr'] = "Modifier";
    
    item = {};
    this.items.push(item);
    item['key'] = "enroute_text_472514250";
    item['fr'] = "En route pour";
    
    item = {};
    this.items.push(item);
    item['key'] = "enroute_exutoire_1589388126";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "enroute_button_152735405";
    item['fr'] = "Vidage effectué";
    
    item = {};
    this.items.push(item);
    item['key'] = "lieudevidage_exutoire_2028055230";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "lieudevidage_modifier_1024561936";
    item['fr'] = "Modifier";
    
    item = {};
    this.items.push(item);
    item['key'] = "exutoire_exutoire_1563240620";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "exutoire_buttoncopy_1383573649";
    item['fr'] = "Problème exutoire";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseencharge_site_txt_1615804913";
    item['fr'] = "Site";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseencharge_flux_txt_1090502609";
    item['fr'] = "Flux";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseencharge_valider_1641527416";
    item['fr'] = "Valider";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseencharge_provenance_1526795613";
    item['fr'] = "Lieu de chargement benne vide";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseencharge_modifier_2041202051";
    item['fr'] = "Modifier";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseencharge_checkbox_626042807";
    item['fr'] = "Benne vide";
    
    item = {};
    this.items.push(item);
    item['key'] = "benne_vide_text_120236023";
    item['fr'] = "Benne vide";
    
    item = {};
    this.items.push(item);
    item['key'] = "depot_geoloc_477125545";
    item['fr'] = "Me géolocaliser";
    
    item = {};
    this.items.push(item);
    item['key'] = "vidage_modifier_1984559129";
    item['fr'] = "Modifier";
    
    item = {};
    this.items.push(item);
    item['key'] = "vidage_hors_quai_763182003";
    item['fr'] = "Vidage différé";
    
    item = {};
    this.items.push(item);
    item['key'] = "depot_site_txt_1146576690";
    item['fr'] = "Site";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseencharge_site_txtcopy_1519947935";
    item['fr'] = "Site";
    
    item = {};
    this.items.push(item);
    item['key'] = "depot_modifier_1045917304";
    item['fr'] = "Modifier";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_tournee_j_exutoirecopy_568343196";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "detailvidage_buttoncopy_1340953037";
    item['fr'] = "Problème exutoire";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseencharge_checkbox_911196450";
    item['fr'] = "Vide";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseencharge_checkbox_1969285128";
    item['fr'] = "Benne vide";
    
    item = {};
    this.items.push(item);
    item['key'] = "action_action_625806638";
    item['fr'] = "Transférer";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseencharge_valider_1360713939";
    item['fr'] = "Valider";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseencharge_text2_1523848495";
    item['fr'] = "Flux";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseencharge_legend_1929942891";
    item['fr'] = "Matériel";
    
    item = {};
    this.items.push(item);
    item['key'] = "scan_error_text_127476533";
    item['fr'] = "Matricule illisible";
    
    item = {};
    this.items.push(item);
    item['key'] = "scan_error_button_365108701";
    item['fr'] = "Réessayer";
    
    item = {};
    this.items.push(item);
    item['key'] = "scan_error_field_2071627388";
    item['fr'] = "Matricule";
    
    item = {};
    this.items.push(item);
    item['key'] = "scan_error_buttoncopy_637861270";
    item['fr'] = "Valider";
    
    item = {};
    this.items.push(item);
    item['key'] = "scanner_search_1612345990";
    item['fr'] = "Rechercher";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_immatriculation_text_102520109";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "tourneedujour_button2_1208861751";
    item['fr'] = "Anomalie";
    
    item = {};
    this.items.push(item);
    item['key'] = "anomalie_scanner_433463876";
    item['fr'] = "Scanner";
    
    item = {};
    this.items.push(item);
    item['key'] = "anomalie_text_963652956";
    item['fr'] = "Matricule";
    
    item = {};
    this.items.push(item);
    item['key'] = "anomalie_provenance_445653775";
    item['fr'] = "Déclarer une anomalie";
    
    item = {};
    this.items.push(item);
    item['key'] = "anomalie_provenance_386096843";
    item['fr'] = "Déclarer une anomalie";
    
    item = {};
    this.items.push(item);
    item['key'] = "anomalie_scanner_217926693";
    item['fr'] = "Scanner";
    
    item = {};
    this.items.push(item);
    item['key'] = "anomalie_text_108598316";
    item['fr'] = "Matricule";
    
    item = {};
    this.items.push(item);
    item['key'] = "anomalie_textarea_1239221806";
    item['fr'] = "Description";
    
    item = {};
    this.items.push(item);
    item['key'] = "anomalie_button_937116564";
    item['fr'] = "Enregistrer";
    
    item = {};
    this.items.push(item);
    item['key'] = "anomalie_button2_1574654557";
    item['fr'] = "Annuler";
    
    item = {};
    this.items.push(item);
    item['key'] = "scanneranomalie_search_80165489";
    item['fr'] = "Rechercher";
    
    item = {};
    this.items.push(item);
    item['key'] = "scanneranomalie_capture_1090561487";
    item['fr'] = "Scanner";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_immatriculation_anomalie_text_1907658819";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_connexioncopy_1681272692";
    item['fr'] = "NFC";
    
    item = {};
    this.items.push(item);
    item['key'] = "confirmationanomalie_button_734454498";
    item['fr'] = "Valider";
    
    item = {};
    this.items.push(item);
    item['key'] = "confirmationanomalie_provenance_742493820";
    item['fr'] = "L’anomalie suivante a bien été déclarée";
    
    item = {};
    this.items.push(item);
    item['key'] = "confirmationanomalie_text_1693796432";
    item['fr'] = "Matricule";
    
    item = {};
    this.items.push(item);
    item['key'] = "confirmationanomalie_textcopy_1947256794";
    item['fr'] = "Commentaire";
    
    item = {};
    this.items.push(item);
    item['key'] = "confirmationanomalie_textcopy_846881412";
    item['fr'] = "Matricule";
    
    item = {};
    this.items.push(item);
    item['key'] = "confirmationanomalie_commentairecopy_1496946182";
    item['fr'] = "Matricule";
    
    item = {};
    this.items.push(item);
    item['key'] = "confirmationanomalie_commentairecopy2_222744318";
    item['fr'] = "Photo";
    
    item = {};
    this.items.push(item);
    item['key'] = "confirmationanomalie_commentaire_txtcopy_43091366";
    item['fr'] = "Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "confirmationanomalie_commentairecopy_1790686297";
    item['fr'] = "Matricule";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseenchargev3_text_1982387939";
    item['fr'] = "Lieu de prise en charge";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseenchargev3_text_318723427";
    item['fr'] = "Site";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseenchargev3_text_sitecopy_1411476814";
    item['fr'] = "Flux";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseenchargev3_text_fluxcopy_1252431242";
    item['fr'] = "Quai";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseenchargev3_button_912557500";
    item['fr'] = "Valider";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseenchargev3_provenance_227188813";
    item['fr'] = "Lieu de chargement";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseenchargev3_scanner_1132765881";
    item['fr'] = "Scanner";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseenchargev3_text_629467874";
    item['fr'] = "Matricule";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseenchargev3_scanner_66007939";
    item['fr'] = "Scanner";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseenchargev3_text_1199209944";
    item['fr'] = "Matricule";
    
    item = {};
    this.items.push(item);
    item['key'] = "operation_button_877447292";
    item['fr'] = "Vidage";
    
    item = {};
    this.items.push(item);
    item['key'] = "operation_buttoncopy_335884456";
    item['fr'] = "Vidage différé";
    
    item = {};
    this.items.push(item);
    item['key'] = "operation_vidage_diffcopy_597470883";
    item['fr'] = "Compaction";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseenchargev3_checkbox_1712898777";
    item['fr'] = "Vide";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseenchargev3_checkbox_videcopy_743692861";
    item['fr'] = "Hors quai";
    
    item = {};
    this.items.push(item);
    item['key'] = "depot_text_site_796674688";
    item['fr'] = "Site";
    
    item = {};
    this.items.push(item);
    item['key'] = "depot_text_quai_142547439";
    item['fr'] = "Quai";
    
    item = {};
    this.items.push(item);
    item['key'] = "depotv3_text_site_1013174329";
    item['fr'] = "Site";
    
    item = {};
    this.items.push(item);
    item['key'] = "depotv3_text_quai_430377161";
    item['fr'] = "Quai";
    
    item = {};
    this.items.push(item);
    item['key'] = "depotv3_checkbox_quai_258979211";
    item['fr'] = "Hors quai";
    
    item = {};
    this.items.push(item);
    item['key'] = "depotv3_text_228132735";
    item['fr'] = "Lieu de dépot";
    
    item = {};
    this.items.push(item);
    item['key'] = "depotv3_text_2060067072";
    item['fr'] = "Lieu de dépot";
    
    item = {};
    this.items.push(item);
    item['key'] = "depotv3_valider_1919118892";
    item['fr'] = "Valider";
    
    item = {};
    this.items.push(item);
    item['key'] = "depotv3_valider_1420000367";
    item['fr'] = "Valider";
    
    item = {};
    this.items.push(item);
    item['key'] = "menuprincipal_tour_jcopy_1482932010";
    item['fr'] = "Point déchèterie";
    
    item = {};
    this.items.push(item);
    item['key'] = "menuprincipal_point_dech_1396086914";
    item['fr'] = "Point déchèterie";
    
    item = {};
    this.items.push(item);
    item['key'] = "point_dech_button_1658350909";
    item['fr'] = "Valider";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_niveau4_quai_1155560689";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_niveau4_n_packmat_1414194775";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_niveau4_state_1579075979";
    item['fr'] = "0%";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_niveau4_flux_193587803";
    item['fr'] = "BOIS";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_niveau_fluxcopy_915939357";
    item['fr'] = "BOIS";
    
    item = {};
    this.items.push(item);
    item['key'] = "quai_view_dech_text_2102704655";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_niveau_statecopy_2008411844";
    item['fr'] = "BOIS";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_niveau_id_scancopy_360017946";
    item['fr'] = "BOIS";
    
    item = {};
    this.items.push(item);
    item['key'] = "quai_view_dech_button_1435144915";
    item['fr'] = "Enregistrer";
    
    item = {};
    this.items.push(item);
    item['key'] = "quai_view_dech_modecopy_1238340258";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "quai_view_dech_buttoncopy_174801586";
    item['fr'] = "Retour";
    
    item = {};
    this.items.push(item);
    item['key'] = "quai_view_dech_cancelcopy_247658053";
    item['fr'] = "Hors quai";
    
    item = {};
    this.items.push(item);
    item['key'] = "hors_quai_view_dech_button_927771437";
    item['fr'] = "Enregistrer";
    
    item = {};
    this.items.push(item);
    item['key'] = "hors_quai_view_dech_cancel_1355427032";
    item['fr'] = "Retour";
    
    item = {};
    this.items.push(item);
    item['key'] = "hors_quai_view_dech_ts_197652432";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_niveau2_quai_780009168";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_niveau2_id_scan_1877238548";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_niveau2_state_676662552";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_niveau2_flux_1195100496";
    item['fr'] = "BOIS";
    
    item = {};
    this.items.push(item);
    item['key'] = "tail_picker_plein_checkbox_974484897";
    item['fr'] = "Vide";
    
    item = {};
    this.items.push(item);
    item['key'] = "tail_picker_plein_button_27773104";
    item['fr'] = "Ajouter";
    
    item = {};
    this.items.push(item);
    item['key'] = "tail_picker_plein_checkbox_videcopy_1298679167";
    item['fr'] = "Plein";
    
    item = {};
    this.items.push(item);
    item['key'] = "hors_quai_view_dech_mode_75415437";
    item['fr'] = "Niveau de remplissage";
    
    item = {};
    this.items.push(item);
    item['key'] = "tail_picker_niveau_button_663483613";
    item['fr'] = "Appliquer";
    
    item = {};
    this.items.push(item);
    item['key'] = "tail_picker_niveau_text_544178598";
    item['fr'] = "Modifier un quai";
    
    item = {};
    this.items.push(item);
    item['key'] = "tourneedujour_point_dech_636812117";
    item['fr'] = "Point déchèterie";
    
    item = {};
    this.items.push(item);
    item['key'] = "menuprincipal_point_dech_887932775";
    item['fr'] = "Point déchèterie";
    
    item = {};
    this.items.push(item);
    item['key'] = "tourneedujour_point_dech_1092687325";
    item['fr'] = "Point déchèterie";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_niveau2_quai_633663727";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_niveau2_id_scan_469324199";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_niveau2_state_623991930";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_niveau2_flux_1352051571";
    item['fr'] = "BOIS";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseenchargev3_checkbox_videcopy_58047283";
    item['fr'] = "Plein";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseenchargev3_checkbox_quaicopy_1048841980";
    item['fr'] = "A quai";
    
    item = {};
    this.items.push(item);
    item['key'] = "client_diff_text_1805447308";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "client_diff_text_1055309908";
    item['fr'] = "Client différé";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_niveau_statecopy_1868463402";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_niveau_id_scancopy_1176919853";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_depot_packmat_1708211039";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "point_dech_ajouter_1610152996";
    item['fr'] = "Autre lieu";
    
    item = {};
    this.items.push(item);
    item['key'] = "point_dech_title_1060444701";
    item['fr'] = "Lieu de prise en charge";
    
    item = {};
    this.items.push(item);
    item['key'] = "quai_view_dech_checkbox_942486367";
    item['fr'] = "A quai";
    
    item = {};
    this.items.push(item);
    item['key'] = "quai_view_dech_checkbox_quaicopy_454988453";
    item['fr'] = "A quai";
    
    item = {};
    this.items.push(item);
    item['key'] = "quai_view_dech_checkbox_quaicopy_1164580803";
    item['fr'] = "Hors quai";
    
    item = {};
    this.items.push(item);
    item['key'] = "quai_view_dech_buttoncopy_132851581";
    item['fr'] = "Enregistrer";
    
    item = {};
    this.items.push(item);
    item['key'] = "point_dech_ajoutercopy_1986920738";
    item['fr'] = "Annuler";
    
    item = {};
    this.items.push(item);
    item['key'] = "depotv3_checkbox_quaicopy_1336025204";
    item['fr'] = "A quai";
    
    item = {};
    this.items.push(item);
    item['key'] = "depotv3_checkbox_hors_quai_1263508927";
    item['fr'] = "Hors quai";
    
    item = {};
    this.items.push(item);
    item['key'] = "operation_cancel_522780500";
    item['fr'] = "Interrompre";
    
    item = {};
    this.items.push(item);
    item['key'] = "vidage_cancel_1103933914";
    item['fr'] = "Interrompre";
    
    item = {};
    this.items.push(item);
    item['key'] = "depotv3_cancel_2136812680";
    item['fr'] = "Interrompre";
    
    item = {};
    this.items.push(item);
    item['key'] = "operation_vidage_diffcopy_1156485146";
    item['fr'] = "Transfert";
    
    item = {};
    this.items.push(item);
    item['key'] = "done_text_663699143";
    item['fr'] = "Mise à jour effectuée";
    
    item = {};
    this.items.push(item);
    item['key'] = "done_textcopy_360309720";
    item['fr'] = "Mise à jour effectuée";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_plein_packmat_1975359749";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "detailvidage_vidage_diff_2095813579";
    item['fr'] = "Vidage différé";
    
    item = {};
    this.items.push(item);
    item['key'] = "detailvidage_cancel_1144203559";
    item['fr'] = "Interrompre";
    
    item = {};
    this.items.push(item);
    item['key'] = "echange_cancel_927656125";
    item['fr'] = "Interrompre";
    
    item = {};
    this.items.push(item);
    item['key'] = "echange_text_1420502014";
    item['fr'] = "Echange avec un contenant ?";
    
    item = {};
    this.items.push(item);
    item['key'] = "echange_checkbox_hors_quai_372079708";
    item['fr'] = "Autre";
    
    item = {};
    this.items.push(item);
    item['key'] = "echange_checkbox_quai_926642034";
    item['fr'] = "Vides";
    
    item = {};
    this.items.push(item);
    item['key'] = "echange_valider_1709042436";
    item['fr'] = "Valider";
    
    item = {};
    this.items.push(item);
    item['key'] = "echange_cancelcopy_1081381624";
    item['fr'] = "Pas d’échange";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_plein2_packmat_2044973932";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_plein2_quai_2034252963";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_plein2_id_scan_1666119085";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_plein2_state_1971106097";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_plein2_flux_1715462515";
    item['fr'] = "BOIS";
    
    item = {};
    this.items.push(item);
    item['key'] = "echange_scanner_1284762157";
    item['fr'] = "Scanner";
    
    item = {};
    this.items.push(item);
    item['key'] = "echange_textcopy_1002220335";
    item['fr'] = "Matricule";
    
    item = {};
    this.items.push(item);
    item['key'] = "scanner2_search_1930802727";
    item['fr'] = "Rechercher";
    
    item = {};
    this.items.push(item);
    item['key'] = "scanner2_capture_2123653233";
    item['fr'] = "Scanner";
    
    item = {};
    this.items.push(item);
    item['key'] = "quai_number_text_535552514";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "detailvidage_transfert_847021245";
    item['fr'] = "Transfert";
    
    item = {};
    this.items.push(item);
    item['key'] = "detailvidage_exutoirecopy_802911458";
    item['fr'] = "Transfert effectué";
    
    item = {};
    this.items.push(item);
    item['key'] = "echange_textcopy_182048768";
    item['fr'] = "Site";
    
    item = {};
    this.items.push(item);
    item['key'] = "menuprincipal_button_3272697";
    item['fr'] = "Valider";
    
    item = {};
    this.items.push(item);
    item['key'] = "anomalie_descriptioncopy_131528708";
    item['fr'] = "Description";
    
    item = {};
    this.items.push(item);
    item['key'] = "anomalie_field_539823190";
    item['fr'] = "Description de l'anomalie";
    
    item = {};
    this.items.push(item);
    item['key'] = "detailvidage_description_fg_1559017324";
    item['fr'] = "Commentaire";
    
    item = {};
    this.items.push(item);
    item['key'] = "detailvidage_commentaire_fgcopy_1147143250";
    item['fr'] = "Tonnage";
    
    item = {};
    this.items.push(item);
    item['key'] = "detailvidage_field_303983972";
    item['fr'] = "Tonnage";
    
    item = {};
    this.items.push(item);
    item['key'] = "chuterunot_commentaire_fg_84522883";
    item['fr'] = "Commentaire";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseenchargev3_text_sitecopy_323468345";
    item['fr'] = "Lieu de prise en charge";
    
    item = {};
    this.items.push(item);
    item['key'] = "echange_text_1909117945";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "echange_no_echangecopy_175663484";
    item['fr'] = "OUI, contenant sur place";
    
    item = {};
    this.items.push(item);
    item['key'] = "echange_yes_herecopy_1817760974";
    item['fr'] = "OUI, contenant d’un autre site";
    
    item = {};
    this.items.push(item);
    item['key'] = "echange_yes_herecopy2_1138960013";
    item['fr'] = "NON, pas d’échange";
    
    item = {};
    this.items.push(item);
    item['key'] = "menuprincipal_button2_1333295993";
    item['fr'] = "Anomalie";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_specifiques_text2_2142289458";
    item['fr'] = "PLATRE";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_specifiques_flux_169354074";
    item['fr'] = "BOIS";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_one_presta_text_1392268978";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "quai_view_dech_button_hors_quaicopy_1857753507";
    item['fr'] = "Enregistrer";
    
    item = {};
    this.items.push(item);
    item['key'] = "list_contenants_search_398775564";
    item['fr'] = "Rechercher n° contenant";
    
    item = {};
    this.items.push(item);
    item['key'] = "anomalie_text2_1960055732";
    item['fr'] = "Anomalie sur le contenant";
    
    item = {};
    this.items.push(item);
    item['key'] = "anomalie_id_scancopy_1818653978";
    item['fr'] = "Matricule";
    
    item = {};
    this.items.push(item);
    item['key'] = "anomalie_typecopy_1056180991";
    item['fr'] = "Matricule";
    
    item = {};
    this.items.push(item);
    item['key'] = "anomalie_typecopy_345533846";
    item['fr'] = "Matricule";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseenchargev3_text2_169659600";
    item['fr'] = "Type de prise en charge";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseenchargev3_rotationcopy_1242337585";
    item['fr'] = "Vidage";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseenchargev3_rotationcopy2_504925710";
    item['fr'] = "Transfert";
    
    item = {};
    this.items.push(item);
    item['key'] = "echange_nocopy_1288130705";
    item['fr'] = "Pas d’échange";
    
    item = {};
    this.items.push(item);
    item['key'] = "tail_picker_echange_matricule_1027436481";
    item['fr'] = "Matricule";
    
    item = {};
    this.items.push(item);
    item['key'] = "tail_picker_echange_scanner_1516111087";
    item['fr'] = "Scanner";
    
    item = {};
    this.items.push(item);
    item['key'] = "tail_picker_plein_checkbox_plein_2060894926";
    item['fr'] = "Plein";
    
    item = {};
    this.items.push(item);
    item['key'] = "tail_picker_plein_checkbox_vide_1592245136";
    item['fr'] = "Vide";
    
    item = {};
    this.items.push(item);
    item['key'] = "tail_picker_plein_button_490555739";
    item['fr'] = "Ajouter";
    
    item = {};
    this.items.push(item);
    item['key'] = "tail_picker_echange_flux_81031627";
    item['fr'] = "AUTRE";
    
    item = {};
    this.items.push(item);
    item['key'] = "tail_picker_echange_button_2004677605";
    item['fr'] = "Sélectionner";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_niveau_id_scancopy_465075294";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_niveau2_quai_815936523";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_niveau2_id_scan_291423538";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_niveau2_packmat_1369289133";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_niveau2_state_1759467838";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_niveau2_flux_405109060";
    item['fr'] = "BOIS";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_plein2_packmat_2062833790";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_plein2_quai_824781139";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_plein2_id_scan_1290069023";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_plein2_state_1720911427";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_plein2_flux_2031887562";
    item['fr'] = "BOIS";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_echange2_quai_334044827";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_echange2_id_scan_493130782";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_echange2_state_98538979";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_echange2_flux_1432520702";
    item['fr'] = "BOIS";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_depot2_packmat_428189203";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_depot2_quai_1214001274";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_depot2_id_scan_1653987709";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_depot2_state_2123831154";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_depot2_flux_45568376";
    item['fr'] = "BOIS";
    
    item = {};
    this.items.push(item);
    item['key'] = "echange_id_scan_1688723959";
    item['fr'] = "Matricule : ";
    
    item = {};
    this.items.push(item);
    item['key'] = "echange_type_385410547";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "echange_description_678532646";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "echange_scannercopy_832549652";
    item['fr'] = "Scanner";
    
    item = {};
    this.items.push(item);
    item['key'] = "tail_picker_echange_buttoncopy_335100541";
    item['fr'] = "Ajouter";
    
    item = {};
    this.items.push(item);
    item['key'] = "echange_text_444968592";
    item['fr'] = "Rotation avec quel contenant?";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_tournee_j_typecopy_2061235096";
    item['fr'] = "undefined";
    
    item = {};
    this.items.push(item);
    item['key'] = "point_dech_buttoncopy_1331270410";
    item['fr'] = "Grutage";
    
    item = {};
    this.items.push(item);
    item['key'] = "other_operation_field_719365513";
    item['fr'] = "Commentaire";
    
    item = {};
    this.items.push(item);
    item['key'] = "other_operation_commentcopy_383052278";
    item['fr'] = "Volume";
    
    item = {};
    this.items.push(item);
    item['key'] = "other_operation_button_193191006";
    item['fr'] = "Enregistrer";
    
    item = {};
    this.items.push(item);
    item['key'] = "other_operation_text_1154454502";
    item['fr'] = "Opération";
    
    item = {};
    this.items.push(item);
    item['key'] = "other_operation_operation_txtcopy_876387572";
    item['fr'] = "Flux";
    
    item = {};
    this.items.push(item);
    item['key'] = "other_operation_flux_txtcopy_1347426811";
    item['fr'] = "Commentaire (facultatif)";
    
    item = {};
    this.items.push(item);
    item['key'] = "other_operation_comment_txtcopy_1404088898";
    item['fr'] = "Volume (facultatif)";
    
    item = {};
    this.items.push(item);
    item['key'] = "other_operation_capture_1552626310";
    item['fr'] = "Ajouter une photo";
    
    item = {};
    this.items.push(item);
    item['key'] = "other_operation_flux_txtcopy_1214454262";
    item['fr'] = "Exutoire";
    
    item = {};
    this.items.push(item);
    item['key'] = "other_operation_flux_txtcopy_786796869";
    item['fr'] = "Tonnage";
    
    item = {};
    this.items.push(item);
    item['key'] = "other_operation_commentcopy_120898991";
    item['fr'] = "Tonnage";
    
    item = {};
    this.items.push(item);
    item['key'] = "point_dech_ajoutercopy_1510196229";
    item['fr'] = "Autre lieu";
    
    item = {};
    this.items.push(item);
    item['key'] = "point_dech_buttoncopy_1915416129";
    item['fr'] = "Autre lieu";
    
    item = {};
    this.items.push(item);
    item['key'] = "valider_simple_capture_507461167";
    item['fr'] = "Ajouter une photo";
    
    item = {};
    this.items.push(item);
    item['key'] = "valider_simple_capture_648936828";
    item['fr'] = "Ajouter une photo";
    
    item = {};
    this.items.push(item);
    item['key'] = "valider_simple_exutoirecopy_1664403871";
    item['fr'] = "Valider l’opération";
    
    item = {};
    this.items.push(item);
    item['key'] = "valider_simple_exutoirecopy_1159653527";
    item['fr'] = "Transfert effectué";
    
    item = {};
    this.items.push(item);
    item['key'] = "valider_simple_retour_2132217722";
    item['fr'] = "Annuler";
    
    item = {};
    this.items.push(item);
    item['key'] = "valider_simple_valider_1802256858";
    item['fr'] = "Confirmer";
    
    item = {};
    this.items.push(item);
    item['key'] = "valider_simple_tonnage_1038900653";
    item['fr'] = "Tonnage";
    
    item = {};
    this.items.push(item);
    item['key'] = "valider_simple_commentaire_1796995738";
    item['fr'] = "Commentaire";
    
    item = {};
    this.items.push(item);
    item['key'] = "valider_simple_tonnagecopy_547316294";
    item['fr'] = "Volume";
    
    item = {};
    this.items.push(item);
    item['key'] = "packmat_iconbuttoncopy_59166126";
    item['fr'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "menuprincipal_textcopy_603984111";
    item['fr'] = "Sélectionner une tournée";
    
    item = {};
    this.items.push(item);
    item['key'] = "menuprincipal_tourneecopy_1597637024";
    item['fr'] = "Sélectionner un camion";
    
    item = {};
    this.items.push(item);
    item['key'] = "menuprincipal_truckcopy_912308347";
    item['fr'] = "Sélectionner un lieu";
    
    item = {};
    this.items.push(item);
    item['key'] = "menuprincipal_tourneecopy_1452433365";
    item['fr'] = "Feuille de route";
    
    item = {};
    this.items.push(item);
    item['key'] = "menuprincipal_head_tourcopy_219308183";
    item['fr'] = "Niveaux de remplissage";
    
    item = {};
    this.items.push(item);
    item['key'] = "menuprincipal_tourneecopy_714454031";
    item['fr'] = "Sélectionner une tournée";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_text_363581090";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_text_206210314";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "chargeducamion_title_1336171830";
    item['fr'] = "Confirmer la prise en charge";
    
    item = {};
    this.items.push(item);
    item['key'] = "chargeducamion_title_1445076336";
    item['fr'] = "Charge du camion";
    
    item = {};
    this.items.push(item);
    item['key'] = "chargeducamion_button_1595619593";
    item['fr'] = "Réinitialier non chargé";
    
    item = {};
    this.items.push(item);
    item['key'] = "chargeducamion_titlecopy_1521977004";
    item['fr'] = "Modifier la charge";
    
    item = {};
    this.items.push(item);
    item['key'] = "chargeducamion_buttoncopy_1242324003";
    item['fr'] = "Scanner un contenant";
    
    item = {};
    this.items.push(item);
    item['key'] = "chargeducamion_scancopy_78958017";
    item['fr'] = "Déposer sur un site";
    
    item = {};
    this.items.push(item);
    item['key'] = "chargeducamion_id_scan_423583352";
    item['fr'] = "Matricule : ";
    
    item = {};
    this.items.push(item);
    item['key'] = "chargeducamion_type_1664932716";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "chargeducamion_description_1484097038";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee_button_1089461761";
    item['fr'] = "Démarrer";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee_scanner_1319895617";
    item['fr'] = "Scanner";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee_text_1256779882";
    item['fr'] = "Matricule";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee_text_892836714";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee_checkbox_368199281";
    item['fr'] = "Départ avec un véhicule chargé";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee_field_2062339273";
    item['fr'] = "Kilométrage";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee_title_38062735";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee_startcopy_1849006703";
    item['fr'] = "Démarrer sans contenant";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee_start_emptycopy_1413977308";
    item['fr'] = "Démarrer avec un contenant plein";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee_start_empty_containercopy_1330200148";
    item['fr'] = "Démarrer avec un contenant vide";
    
    item = {};
    this.items.push(item);
    item['key'] = "departconteneurvide_scanner_1759710449";
    item['fr'] = "Scanner";
    
    item = {};
    this.items.push(item);
    item['key'] = "departconteneurvide_matricule_1766486120";
    item['fr'] = "Matricule";
    
    item = {};
    this.items.push(item);
    item['key'] = "departconteneurvide_text_324285653";
    item['fr'] = "Scanner le matricule du contenant";
    
    item = {};
    this.items.push(item);
    item['key'] = "departconteneurvide_scan_txtcopy_2140280699";
    item['fr'] = "Ou sélectionner le type de contenant";
    
    item = {};
    this.items.push(item);
    item['key'] = "departconteneurvide_button_2117433952";
    item['fr'] = "Valider";
    
    item = {};
    this.items.push(item);
    item['key'] = "departconteneurplein_scan_txt_46910457";
    item['fr'] = "Scanner le matricule du contenant";
    
    item = {};
    this.items.push(item);
    item['key'] = "departconteneurplein_matricule_8440520";
    item['fr'] = "Matricule : ";
    
    item = {};
    this.items.push(item);
    item['key'] = "departconteneurplein_scanner_395706183";
    item['fr'] = "Scanner";
    
    item = {};
    this.items.push(item);
    item['key'] = "departconteneurplein_input_type_txt_1815926870";
    item['fr'] = "Ou sélectionner parmi les vidages différés";
    
    item = {};
    this.items.push(item);
    item['key'] = "departconteneurplein_button_767222541";
    item['fr'] = "Valider";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_v_diff_start_text_1969419115";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_v_diff_start_fluxcopy_94457041";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_v_diff_start_typecopy_162584651";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "km_txt";
    item['fr'] = "Kilométrage du camion";
    
    item = {};
    this.items.push(item);
    item['key'] = "site_txt";
    item['fr'] = "Lieu de départ";
    
    item = {};
    this.items.push(item);
    item['key'] = "done2_textcopy_1855753817";
    item['fr'] = "Données enregistrées, la tournée va commencer";
    
    item = {};
    this.items.push(item);
    item['key'] = "done2_ts_1001370906";
    item['fr'] = "8/30/2021, 3:08:46 PM";
    
    item = {};
    this.items.push(item);
    item['key'] = "done_debut_button_1366964843";
    item['fr'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee_sitecopy_114519334";
    item['fr'] = "Lieu de départ";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee_km_txtcopy_1224133415";
    item['fr'] = "Nom";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee_kmcopy_1988522579";
    item['fr'] = "Nom";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee_name_txtcopy_292716307";
    item['fr'] = "Camion";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee_namecopy_1295754009";
    item['fr'] = "Camion";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee_km_txtcopy_2060767804";
    item['fr'] = "Commentaire (facultatif)";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee_kmcopy_2133902953";
    item['fr'] = "Commentaire";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee_checkbox2_1435861695";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee_no_loadcopy_21551397";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee_emptycopy_1443873519";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "title_txt";
    item['fr'] = "Etat du camion";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee_start_emptycopy_1610636169";
    item['fr'] = "Valider";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee_note_txtcopy_626173193";
    item['fr'] = "Pas de contenant";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee_no_load_txtcopy_1928856635";
    item['fr'] = "Contenant vide";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee_empty_txtcopy_2125730210";
    item['fr'] = "Contenant plein";
    
    item = {};
    this.items.push(item);
    item['key'] = "menuprincipal_tourneecopy_1072302348";
    item['fr'] = "Sélectionner une tournée";
    
    item = {};
    this.items.push(item);
    item['key'] = "menuprincipal_text2_1917190065";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee2_title_1805513461";
    item['fr'] = "Etat du camion";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee2_name_txt_410758278";
    item['fr'] = "Nom";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee2_name_2003845229";
    item['fr'] = "Nom";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee2_site_197572163";
    item['fr'] = "Lieu d’arrivée";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee2_truck_txt_1550313380";
    item['fr'] = "Camion";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee2_truck_110875467";
    item['fr'] = "Camion";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee2_km_txt_525151564";
    item['fr'] = "Kilométrage du camion (facultatif)";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee2_km_1901807493";
    item['fr'] = "Kilométrage";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee2_note_txt_1558507924";
    item['fr'] = "Commentaire (facultatif)";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee2_note_1213164903";
    item['fr'] = "Commentaire";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee2_no_load_txt_434379868";
    item['fr'] = "Pas de contenant";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee2_empty_txt_1517751466";
    item['fr'] = "Contenant vide";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee2_full_txt_2000532588";
    item['fr'] = "Contenant plein";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee2_checkbox_390977901";
    item['fr'] = "Départ avec un véhicule chargé";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee2_id_scan_635382830";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee2_scanner_2144664147";
    item['fr'] = "Scanner";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee2_start_1775039713";
    item['fr'] = "Valider";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee2_start_empty_211083014";
    item['fr'] = "Démarrer sans contenant";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee2_start_empty_container_1934608733";
    item['fr'] = "Démarrer avec un contenant plein";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee2_start_empty_containercopy_156438919";
    item['fr'] = "Démarrer avec un contenant vide";
    
    item = {};
    this.items.push(item);
    item['key'] = "done_debut2_textcopy_1524689965";
    item['fr'] = "Données enregistrées, la tournée est terminée";
    
    item = {};
    this.items.push(item);
    item['key'] = "done_debut2_ts_2059988257";
    item['fr'] = "9/3/2021, 1:52:49 PM";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_errortextcopy_469294560";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee_name_txtcopy_133723003";
    item['fr'] = "Nom";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee_truck_txtcopy_2021290697";
    item['fr'] = "Camion";
    
    item = {};
    this.items.push(item);
    item['key'] = "client_diff_text_669710201";
    item['fr'] = "Déposé";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseenchargev3_textcopy_987865786";
    item['fr'] = "Matériel";
    
    item = {};
    this.items.push(item);
    item['key'] = "chuterunot_site_1458317781";
    item['fr'] = "<no value from script>";
    
    item = {};
    this.items.push(item);
    item['key'] = "chuterunot_flux_293686738";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "chuterunot_type_1935169779";
    item['fr'] = "undefined";
    
    item = {};
    this.items.push(item);
    item['key'] = "chuterunot_exutoire_13273194";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "chuterunot_remark_930355450";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "depotv4_text_745677553";
    item['fr'] = "Lieu de dépot";
    
    item = {};
    this.items.push(item);
    item['key'] = "depotv4_checkbox_hors_quai_288705755";
    item['fr'] = "Hors quai";
    
    item = {};
    this.items.push(item);
    item['key'] = "depotv4_checkbox_quai_1026697798";
    item['fr'] = "A quai";
    
    item = {};
    this.items.push(item);
    item['key'] = "depotv4_valider_2061139247";
    item['fr'] = "Valider";
    
    item = {};
    this.items.push(item);
    item['key'] = "depotv4_text_quai_1649438264";
    item['fr'] = "A quai";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_depot3_packmat_716996155";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_depot3_quai_111925006";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_depot3_id_scan_2119894509";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_depot3_state_302392563";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_depot3_flux_76991023";
    item['fr'] = "BOIS";
    
    item = {};
    this.items.push(item);
    item['key'] = "anomalie_text_798201784";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "anomalie_button_258447219";
    item['fr'] = "Ajouter une anomalie";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_explore_anomalie_text_1604128515";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_explore_anomalie_labelcopy_681723021";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "anomalies_explorer2_text_138757811";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "anomalies_explorer2_button_1165289118";
    item['fr'] = "Ajouter";
    
    item = {};
    this.items.push(item);
    item['key'] = "anomalie_cat_text_500697211";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "anomalie_cat_checkbox_300899973";
    item['fr'] = "New option";
    
    item = {};
    this.items.push(item);
    item['key'] = "declared_anomalie_text_1421914953";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp1_add_anomalie_686000105";
    item['fr'] = "Ajouter une anomalie";
    
    item = {};
    this.items.push(item);
    item['key'] = "declared_anomalie_label_3782776";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "vider_apres_rotation_text_126111226";
    item['fr'] = "Vider le contenant échangé?";
    
    item = {};
    this.items.push(item);
    item['key'] = "vider_apres_rotation_text_355522859";
    item['fr'] = "Rotation avec quel contenant?";
    
    item = {};
    this.items.push(item);
    item['key'] = "vider_apres_rotation_yes_here_2093310141";
    item['fr'] = "OUI";
    
    item = {};
    this.items.push(item);
    item['key'] = "vider_apres_rotation_no_467480216";
    item['fr'] = "NON, pas de rotation";
    
    item = {};
    this.items.push(item);
    item['key'] = "vider_apres_rotation_yes_here_2140954661";
    item['fr'] = "OUI";
    
    item = {};
    this.items.push(item);
    item['key'] = "vider_apres_rotation_no_1174669008";
    item['fr'] = "NON, laisser le contenant hors quai";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseenchargev3_capture_1842697037";
    item['fr'] = "Ajouter une photo";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseenchargev3_id_scan_txtcopy_40331676";
    item['fr'] = "Matricule";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_niveau2_id_scancopy_1405340191";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "point_dech_buttoncopy_1113402314";
    item['fr'] = "Valider";
    
    item = {};
    this.items.push(item);
    item['key'] = "point_dech_button_producercopy_1978776557";
    item['fr'] = "Valider";
    
    item = {};
    this.items.push(item);
    item['key'] = "point_dech_other_placecopy_1460135564";
    item['fr'] = "Autre lieu";
    
    item = {};
    this.items.push(item);
    item['key'] = "point_dech_switch_producerscopy_1269752125";
    item['fr'] = "Déchèteries";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_plein2_statecopy_1536308822";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "picker_echange2_statecopy_487160363";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseenchargev3_site_1931766558";
    item['fr'] = "<no value from script>";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseenchargev3_flux_32891491";
    item['fr'] = "undefined - Hors quai";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseenchargev3_type_1018462313";
    item['fr'] = "undefined";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseenchargev3_exutoire_1972392177";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseenchargev3_remark_1359079380";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "comp_list_anomalies2_add_anomalie_425128704";
    item['fr'] = "Ajouter une anomalie";
    
    item = {};
    this.items.push(item);
    item['key'] = "point_dech_checkbox_quai_1267581264";
    item['fr'] = "Déchèteries";
    
    item = {};
    this.items.push(item);
    item['key'] = "point_dech_checkbox_hors_quai_1357987987";
    item['fr'] = "Autres lieux";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseenchargev3_add_anomalie_582244164";
    item['fr'] = "Ajouter une anomalie";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseenchargev3_validcopy_616243894";
    item['fr'] = "Annuler";
    
    item = {};
    this.items.push(item);
    item['key'] = "depotv3_checkbox_hors_quaicopy_1501553796";
    item['fr'] = "Autres lieux";
    
    item = {};
    this.items.push(item);
    item['key'] = "depotv3_checkbox_quaicopy_892216194";
    item['fr'] = "Déchèteries";
    
    item = {};
    this.items.push(item);
    item['key'] = "depotv4_checkbox_autres_53633687";
    item['fr'] = "Autres lieux";
    
    item = {};
    this.items.push(item);
    item['key'] = "depotv4_checkbox_dech_1273758574";
    item['fr'] = "Déchèteries";
    
    item = {};
    this.items.push(item);
    item['key'] = "echange_id_scan_2061187948";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "detailvidage_id_scan_1649174831";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee_startcopy_649600436";
    item['fr'] = "Valider";
    
    item = {};
    this.items.push(item);
    item['key'] = "quai_view_dech2_mode_2062127231";
    item['fr'] = "Partez-vous avec un contenant?";
    
    item = {};
    this.items.push(item);
    item['key'] = "quai_view_dech2_checkbox_quai_1964048818";
    item['fr'] = "A quai";
    
    item = {};
    this.items.push(item);
    item['key'] = "quai_view_dech2_checkbox_hors_quai_984893721";
    item['fr'] = "Hors quai";
    
    item = {};
    this.items.push(item);
    item['key'] = "quai_view_dech2_ts_1758126734";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "quai_view_dech2_button_quai_597910571";
    item['fr'] = "Non";
    
    item = {};
    this.items.push(item);
    item['key'] = "quai_view_dech2_button_hors_quai_763514879";
    item['fr'] = "Enregistrer";
    
    item = {};
    this.items.push(item);
    item['key'] = "quai_view_dech2_button_spe_1343900760";
    item['fr'] = "Enregistrer";
    
    item = {};
    this.items.push(item);
    item['key'] = "quai_view_dech2_cancel_823350198";
    item['fr'] = "≡ Aperçu tournée";
    
    item = {};
    this.items.push(item);
    item['key'] = "quai_view_dech_start_button_quaicopy_1828966603";
    item['fr'] = "Non";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_tournee_j1_site_1401485303";
    item['fr'] = "<no value from script>";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_tournee_j1_action_420447302";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_tournee_j1_flux_1288915023";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_tournee_j1_type_1447427943";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_tournee_j1_exutoire_1566028939";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_tournee_j1_remark_295118816";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee_cancel_1181708088";
    item['fr'] = "Aperçu tournée";
    
    item = {};
    this.items.push(item);
    item['key'] = "apercu_text_803922138";
    item['fr'] = "Aperçu des tournées";
    
    item = {};
    this.items.push(item);
    item['key'] = "tail_picker_plein2_button_369382873";
    item['fr'] = "Ajouter";
    
    item = {};
    this.items.push(item);
    item['key'] = "tail_picker_echange2_flux_1561858309";
    item['fr'] = "Autre contenant";
    
    item = {};
    this.items.push(item);
    item['key'] = "tail_picker_echange2_scanner_1280135363";
    item['fr'] = "Scanner";
    
    item = {};
    this.items.push(item);
    item['key'] = "tail_picker_echange2_matricule_795107836";
    item['fr'] = "Matricule";
    
    item = {};
    this.items.push(item);
    item['key'] = "tail_picker_echange2_buttoncopy_507824068";
    item['fr'] = "Ajouter";
    
    item = {};
    this.items.push(item);
    item['key'] = "tail_picker_echange2_button_685068499";
    item['fr'] = "Autre contenant";
    
    item = {};
    this.items.push(item);
    item['key'] = "add_prise_en_charge_iconbuttoncopy_1228963962";
    item['fr'] = "Nouvelle opération";
    
    item = {};
    this.items.push(item);
    item['key'] = "apercu_search_1847032164";
    item['fr'] = "Rechercher";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_immatriculation2_text_1732052647";
    item['fr'] = "undefined - undefined";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_immatriculation_apercu_textcopy_1816293517";
    item['fr'] = "undefined - undefined";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_connexioncopy_494357507";
    item['fr'] = "Mot de passe oublié";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_text_1712722903";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "connexion_text_1980538639";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_immatriculation_apercu_textcopy2_1772980793";
    item['fr'] = "undefined - undefined";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_immatriculation_apercu_textcopy2_1244403673";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "apercu_checkbox_35203897";
    item['fr'] = "New option";
    
    item = {};
    this.items.push(item);
    item['key'] = "apercu_checkbox_84252126";
    item['fr'] = "Anomalies";
    
    item = {};
    this.items.push(item);
    item['key'] = "detailvidage_id_scan_txt_819111378";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "apercu_button_1482230800";
    item['fr'] = "Voir tournée du jour";
    
    item = {};
    this.items.push(item);
    item['key'] = "apercu_button_1532040900";
    item['fr'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "apercu_buttoncopy_2070459785";
    item['fr'] = "Voir tournée du lendemain";
    
    item = {};
    this.items.push(item);
    item['key'] = "apercu_tomorrowcopy_1948089065";
    item['fr'] = "Rechercher un contenant";
    
    item = {};
    this.items.push(item);
    item['key'] = "apercu_text_2004096439";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "apercu_text_644022210";
    item['fr'] = "Tournée du jour";
    
    item = {};
    this.items.push(item);
    item['key'] = "apercu_text_360115216";
    item['fr'] = "Tournée du lendemain";
    
    item = {};
    this.items.push(item);
    item['key'] = "apercu_text_1177386327";
    item['fr'] = "Recherche contenant";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_immatriculation_textcopy_886767990";
    item['fr'] = "undefined - undefined";
    
    item = {};
    this.items.push(item);
    item['key'] = "item_immatriculation_decriptioncopy_1395500854";
    item['fr'] = "undefined - undefined";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseenchargev3_text_334656649";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseenchargev3_sitecopy_1518838557";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseenchargev3_fluxcopy_160217645";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseenchargev3_typecopy_518073399";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "findetournee_note_txtcopy_615909756";
    item['fr'] = "Carburant en Litres (facultatif)";
    
    item = {};
    this.items.push(item);
    item['key'] = "findetournee_notecopy_962835948";
    item['fr'] = "Litres";
    
    item = {};
    this.items.push(item);
    item['key'] = "chargeducamion_dropcopy_1666620556";
    item['fr'] = "Anomalie contenant";
    
    item = {};
    this.items.push(item);
    item['key'] = "chargeducamion_id_scancopy_1194833528";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "chargeducamion_id_scancopy_488482183";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "quai_view_dech_text_879476671";
    item['fr'] = "Invalid Date";
    
    item = {};
    this.items.push(item);
    item['key'] = "quai_view_dech_start_date_1847940731";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "chargeducamion_scanner_1139945679";
    item['fr'] = "Scanner";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseenchargev3_validcopy2_1185039745";
    item['fr'] = "Ajouter à ma tournée";
    
    item = {};
    this.items.push(item);
    item['key'] = "apercu_tomorrowcopy_1919516376";
    item['fr'] = "Commandes";
    
    item = {};
    this.items.push(item);
    item['key'] = "apercu_text_85969112";
    item['fr'] = "Commandes";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_tournee_j2_site_612226909";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_tournee_j2_action_1296690769";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_tournee_j2_flux_1786638567";
    item['fr'] = "undefined";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_tournee_j2_type_283387288";
    item['fr'] = "undefined - Hors quai";
    
    item = {};
    this.items.push(item);
    item['key'] = "ot_order_add_iconbutton_2106286826";
    item['fr'] = "Ajouter";
    
    item = {};
    this.items.push(item);
    item['key'] = "apercu_textcopy_1388465478";
    item['fr'] = "Tournée du jour";
    
    item = {};
    this.items.push(item);
    item['key'] = "apercu_textcopy_654517709";
    item['fr'] = "Tournée du lendemain";
    
    item = {};
    this.items.push(item);
    item['key'] = "apercu_textcopy_821844369";
    item['fr'] = "Recherche contenant";
    
    item = {};
    this.items.push(item);
    item['key'] = "apercu_textcopy_1181171049";
    item['fr'] = "Sélectionner une commande pour l’ajouter à votre tournée";
    
    item = {};
    this.items.push(item);
    item['key'] = "apercu_iconbutton_967271303";
    item['fr'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "quai_view_dech_notif_1609333346";
    item['fr'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "quai_view_dech_text_1508733581";
    item['fr'] = "New text. Double-click to edit";
    
    item = {};
    this.items.push(item);
    item['key'] = "quai_view_dech_show_apercucopy_2047111265";
    item['fr'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "tourneedujour_notif_1151300621";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "tourneedujour_notif_apercu_906010316";
    item['fr'] = "New button";
    
    item = {};
    this.items.push(item);
    item['key'] = "quai_view_dech_notif_1306575349";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "debutdetournee_notif_1905946891";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "quai_view_dech_start_notif_747626333";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "chargeducamion_notif_1163265593";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseenchargev3_notif_1229404145";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "echange_notif_776150942";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "detailvidage_notif_815668831";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "depotv3_notif_757764617";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "point_dech_notif_841337186";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "quai_view_dech_start_notif_1054253566";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "point_dech_notif_523708343";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "quai_view_dech_notif_2069306670";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "tourneedujour_notif_1099937282";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "chargeducamion_notif_806632324";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "priseenchargev3_notif_1663597485";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "valider_simple_notif_2139402059";
    item['fr'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "apercu_notif_533013223";
    item['fr'] = " ";
    
    let storedItems = localStorage.getItem(this.id);
    if (storedItems != null) {
      this.items = JSON.parse(storedItems);
    }
  }

  addItem(item, options) {
    super.addItem(item, options);
    
    localStorage.setItem(this.id, JSON.stringify(this.items));
  }

  removeItem(item, options) {
    super.removeItem(item, options);
    
    localStorage.setItem(this.id, JSON.stringify(this.items));
  }

  replaceItemByRowIndex(idx, newItem, options) {
    super.replaceItemByRowIndex(idx, newItem, options);
    
    localStorage.setItem(this.id, JSON.stringify(this.items));
  }

  getStringsByLanguage = () => {
    let stringsByLang = {};
    for (let row of this.items) {
      const locKey = row.key;
      for (let key in row) {
        if (key === 'key')
          continue;
        let langObj = stringsByLang[key] || {};
        langObj[locKey] = row[key];
        stringsByLang[key] = langObj;
      }
    }
    return stringsByLang;
  }

}
