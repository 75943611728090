import React, { Component } from 'react';
import './App.css';
import btn_icon_1653133102 from './images/btn_icon_1653133102.png';
import btn_icon_453056240 from './images/btn_icon_453056240.png';
import btn_icon_1404204177 from './images/btn_icon_1404204177.png';
import btn_icon_1039976365 from './images/btn_icon_1039976365.png';
import btn_icon_146909145 from './images/btn_icon_146909145.png';

export default class Check_command extends Component {

  // Properties used by this component:
  // visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  // --- Functions for component state index 0 (1 of 6) --- 
  
  renderState0() {
    
    return (
      <div className="Check_command">
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 6) --- 
  
  renderState1() {
    
    const style_state1_elIconButton = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_1653133102+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
     };
    
    return (
      <div className="Check_command">
        <div className="foreground">
          <button className="actionFont containerMinHeight state1_elIconButton" style={style_state1_elIconButton} />
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 2 (3 of 6) --- 
  
  renderState2() {
    
    const style_state2_elIconButton = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_453056240+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
     };
    
    return (
      <div className="Check_command">
        <div className="foreground">
          <button className="actionFont containerMinHeight state2_elIconButton" style={style_state2_elIconButton} />
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 3 (4 of 6) --- 
  
  renderState3() {
    
    const style_state3_elIconButton = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_1404204177+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
     };
    
    return (
      <div className="Check_command">
        <div className="foreground">
          <button className="actionFont containerMinHeight state3_elIconButton" style={style_state3_elIconButton} />
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 4 (5 of 6) --- 
  
  renderState4() {
    
    const style_state4_elIconButton = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_1039976365+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
     };
    
    return (
      <div className="Check_command">
        <div className="foreground">
          <button className="actionFont containerMinHeight state4_elIconButton" style={style_state4_elIconButton} />
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 5 (6 of 6) --- 
  
  renderState5() {
    
    const style_state5_elIconButton = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_146909145+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
     };
    
    return (
      <div className="Check_command">
        <div className="foreground">
          <button className="actionFont containerMinHeight state5_elIconButton" style={style_state5_elIconButton} />
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
      case 2:
        return this.renderState2();
      case 3:
        return this.renderState3();
      case 4:
        return this.renderState4();
      case 5:
        return this.renderState5();
    }
  }
  
}
