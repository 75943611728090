import React, { Component } from 'react';
import './App.css';
import Picker_niveau2 from './Picker_niveau2';
import Tail_picker_echange_start from './Tail_picker_echange_start';
import Picker_plein2 from './Picker_plein2';
import btn_icon_823350198 from './images/btn_icon_823350198.png';
import btn_icon_842251783 from './images/btn_icon_842251783.png';
import Apercu from './Apercu';
import btn_icon_back_quai_view_dech_start from './images/btn_icon_back_quai_view_dech_start.png';

// UI framework component imports
import Checkbox from 'muicss/lib/react/checkbox';
import Appbar from 'muicss/lib/react/appbar';

export default class Quai_view_dech_startScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);

    this.state = {    
      random_key: 0,
      elButton_hors_quai_visible: this.props.appActions.dataSlots['ds_q_hq_view'] == 0 ? false : true,
      elButton_quai_visible: this.props.appActions.dataSlots['ds_q_hq_view'] == 0 ? true : false,
      elButton_spe_visible: false,
      elList_hors_quai_visible: this.props.appActions.dataSlots['ds_q_hq_view'] == 0 ? false : true,
      elList_quai_visible: this.props.appActions.dataSlots['ds_q_hq_view'] == 0 ? true : false,
      elList_spe_visible: false,
      checkbox_hors_quai: this.props.appActions.dataSlots['ds_q_hq_view'] == 0 ? 'false' : 'true',
      checkbox_quai: this.props.appActions.dataSlots['ds_q_hq_view'] == 0 ? 'true' : 'false',
      elCheckbox_hors_quai_visible: true,
      elCheckbox_quai_visible: true,
      elHors_quai_visible: true,
      mode: (<div>Le camion est-il en charge?</div>),
      mode_plainText: "Le camion est-il en charge?",
      elDone_visible: false,
      elOther_operation_visible: false
    };
  }

  componentDidMount() {
    {
      let dataSheet = this.props.appActions.dataSheets['reference_containers'];
      let serviceOptions = this.props.appActions.serviceOptions_reference_containers;
      if ( !this.props.appActions.dataSheetLoaded['reference_containers']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/all_containers", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['reference_containers'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['containers'];
      let serviceOptions = this.props.appActions.serviceOptions_containers;
      if ( !this.props.appActions.dataSheetLoaded['containers']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/clients/$slot('ds_client_id')/contenants", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['containers'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['quaiView'];
      let serviceOptions = this.props.appActions.serviceOptions_quaiView;
      if ( !this.props.appActions.dataSheetLoaded['quaiView']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/quaiView", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['quaiView'] = true;
      }
    }
 
    this._refresh = () => {
      try {
        console.log('refresh')

        let dech_quai_view = this.props.appActions.dataSheets['quaiView'].items.filter(it => {
          return it.id_client === this.props.appActions.dataSlots['ds_client_id']
        })[0];

        let dech_quai_view_simplified = {
          quais: dech_quai_view.quais,
          hors_quais: dech_quai_view.hors_quais,
          spe: dech_quai_view.spe,
          ts: dech_quai_view.ts,
          id_client: dech_quai_view.id_client,
          document_key: dech_quai_view.document_key,
        }

        this.props.appActions.updateDataSlot('ds_selected_dech_quai_view', JSON.parse(JSON.stringify(dech_quai_view_simplified)));
        this.props.appActions.updateDataSlot('ds_selected_dech_site', dech_quai_view.site);

        function getRandomInt(max) {
          return Math.floor(Math.random() * max);
        }

        this.setState({
          //elList_quai_visible: false,
          elButton_quai_visible: false,
          //elList_hors_quai_visible: false,
          elButton_hors_quai_visible: false,
          elList_spe_visible: false,
          elButton_spe_visible: false,
          random_key: getRandomInt(1000000)
        });
      } catch (e) {
        console.log("ERROR: refresh");
      }
    }
    this.intervalRefresh = setInterval(this._refresh, 15000)
    
    let client = this.props.appActions.dataSheets['clients'].items.find(it => it.document_key == this.props.appActions.dataSlots['ds_selected_dech_quai_view'].id_client)||{};
    let quaiActive = [0, 1].includes(client.place_type);
    let hQuaiActive = [0, 1, 3].includes(client.place_type);
    
    if (!quaiActive && this.props.appActions.dataSlots['ds_q_hq_view'] == 0) {
      this.props.appActions.updateDataSlot('ds_q_hq_view', 1); 
      this.setState({
        checkbox_quai: false,
        checkbox_hors_quai: true,
      })
    }
    if (!hQuaiActive && this.props.appActions.dataSlots['ds_q_hq_view'] == 1) {
      this.props.appActions.updateDataSlot('ds_q_hq_view', 0);
      this.setState({
        checkbox_quai: true,
        checkbox_hors_quai: false,
      })
    };
    
    if (["0", 0, 1, 2, 3, 5, 7].includes(this.props.appActions.dataSlots['ds_mode_point'])) {
      if (this.props.appActions.dataSlots['ds_q_hq_view'] == 0) {
        let new_state = {
          elList_quai_visible: true,
          elButton_quai_visible: true,
          elList_hors_quai_visible: false,
          elButton_hors_quai_visible: false,
          elList_spe_visible: false,
          elButton_spe_visible: false,
          elCheckbox_quai_visible: quaiActive,
          elCheckbox_hors_quai_visible: hQuaiActive,
          elHors_quai_visible: hQuaiActive,
          elOther_operation_visible: false
        };
        if (Object.keys(new_state).some(k => this.state[k] !== new_state[k])) {
          this.setState(new_state);
        };
      } else if (this.props.appActions.dataSlots['ds_q_hq_view'] == 1) {
        let new_state = {
          elList_quai_visible: false,
          elButton_quai_visible: false,
          elList_hors_quai_visible: true,
          elButton_hors_quai_visible: true,
          elList_spe_visible: false,
          elButton_spe_visible: false,
          elCheckbox_quai_visible: quaiActive,
          elCheckbox_hors_quai_visible: hQuaiActive,
          elHors_quai_visible: hQuaiActive,
          elOther_operation_visible: false
        };
        if (Object.keys(new_state).some(k => this.state[k] !== new_state[k])) {
          this.setState(new_state);
        };
      }
    } else if ([4,9].includes(this.props.appActions.dataSlots['ds_mode_point'])) {
      // Prise en charge
      if (this.props.appActions.dataSlots['ds_q_hq_view'] == 0) {
        let new_state = {
          elList_quai_visible: true,
          elButton_quai_visible: false,
          elList_hors_quai_visible: false,
          elButton_hors_quai_visible: false,
          elList_spe_visible: false,
          elButton_spe_visible: false,
          elCheckbox_quai_visible: quaiActive,
          elCheckbox_hors_quai_visible: hQuaiActive,
          elHors_quai_visible: hQuaiActive,
          elOther_operation_visible: false
        };
        if (Object.keys(new_state).some(k => this.state[k] !== new_state[k])) {
          this.setState(new_state);
        };
      } else if (this.props.appActions.dataSlots['ds_q_hq_view'] == 1) {
        let new_state = {
          elList_quai_visible: false,
          elButton_quai_visible: false,
          elList_hors_quai_visible: true,
          elButton_hors_quai_visible: false,
          elList_spe_visible: false,
          elButton_spe_visible: false,
          elCheckbox_quai_visible: quaiActive,
          elCheckbox_hors_quai_visible: hQuaiActive,
          elHors_quai_visible: hQuaiActive,
          elOther_operation_visible: false
        };
        if (Object.keys(new_state).some(k => this.state[k] !== new_state[k])) {
          this.setState(new_state);
        };
      }
    } else if (this.props.appActions.dataSlots['ds_mode_point'] === 6) {
      // Speciaux
      let new_state = {
        elList_quai_visible: false,
        elButton_quai_visible: false,
        elList_hors_quai_visible: false,
        elButton_hors_quai_visible: false,
        elList_spe_visible: true,
        elButton_spe_visible: true,
        elCheckbox_quai_visible: false,
        elCheckbox_hors_quai_visible: false,
        elHors_quai_visible: false,
        elOther_operation_visible: false
      };
      if (Object.keys(new_state).some(k => this.state[k] !== new_state[k])) {
        this.setState(new_state);
      };
    } else if (this.props.appActions.dataSlots['ds_mode_point'] === 8) {
      // Autres
      let new_state = {
        elList_quai_visible: false,
        elButton_quai_visible: false,
        elList_hors_quai_visible: false,
        elButton_hors_quai_visible: false,
        elList_spe_visible: false,
        elButton_spe_visible: false,
        elCheckbox_quai_visible: false,
        elCheckbox_hors_quai_visible: false,
        elHors_quai_visible: false,
        elOther_operation_visible: true
      };
      if (Object.keys(new_state).some(k => this.state[k] !== new_state[k])) {
        this.setState(new_state);
      };
    }

    // this.props.appActions.updateDataSlot('ds_scanned_id', "");
    
    // id_client
    try{
      let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key === this.props.appActions.dataSlots['ds_selected_tournee']);

      let id_client = this.props.appActions.dataSheets['sectors'].items.find(it => it.id == parseInt(tournee.sector));
      
      if (id_client) {
        this.props.appActions.updateDataSlot('ds_client_id', id_client.id_client_reference);

        let dech_quai_view = this.props.appActions.dataSheets['quaiView'].items.filter(it => {
          return it.id_client === id_client.id_client_reference
        })[0];

        let dech_quai_view_simplified = {
          quais: dech_quai_view.quais,
          hors_quais: dech_quai_view.hors_quais,
          spe: dech_quai_view.spe,
          ts: dech_quai_view.ts,
          id_client: dech_quai_view.id_client,
          document_key: dech_quai_view.document_key,
        }

        this.props.appActions.updateDataSlot('ds_selected_dech_quai_view', JSON.parse(JSON.stringify(dech_quai_view_simplified)));
        this.props.appActions.updateDataSlot('ds_selected_dech_site', dech_quai_view.site);

      }
    } catch (error) {
      console.log(error);
    }
  }
  
  componentWillUnmount() {
    clearInterval(this.intervalRefresh);
  }

  componentDidUpdate() {
    let client = this.props.appActions.dataSheets['clients'].items.find(it => it.document_key == this.props.appActions.dataSlots['ds_selected_dech_quai_view'].id_client)||{};
    let quaiActive = [0, 1].includes(client.place_type);
    let hQuaiActive = [0, 1, 3].includes(client.place_type);
    
    if (!quaiActive && this.props.appActions.dataSlots['ds_q_hq_view'] == 0) {
      this.props.appActions.updateDataSlot('ds_q_hq_view', 1); 
      this.setState({
        checkbox_quai: false,
        checkbox_hors_quai: true,
      })
    }
    if (!hQuaiActive && this.props.appActions.dataSlots['ds_q_hq_view'] == 1) {
      this.props.appActions.updateDataSlot('ds_q_hq_view', 0);
      this.setState({
        checkbox_quai: true,
        checkbox_hors_quai: false,
      })
    };
    
    if (["0", 0, 1, 2, 3, 5, 7].includes(this.props.appActions.dataSlots['ds_mode_point'])) {
      if (this.props.appActions.dataSlots['ds_q_hq_view'] == 0) {
        let new_state = {
          elList_quai_visible: true,
          elButton_quai_visible: true,
          elList_hors_quai_visible: false,
          elButton_hors_quai_visible: false,
          elList_spe_visible: false,
          elButton_spe_visible: false,
          elCheckbox_quai_visible: quaiActive,
          elCheckbox_hors_quai_visible: hQuaiActive,
          elHors_quai_visible: hQuaiActive,
          elOther_operation_visible: false
        };
        if (Object.keys(new_state).some(k => this.state[k] !== new_state[k])) {
          this.setState(new_state);
        };
      } else if (this.props.appActions.dataSlots['ds_q_hq_view'] == 1) {
        let new_state = {
          elList_quai_visible: false,
          elButton_quai_visible: false,
          elList_hors_quai_visible: true,
          elButton_hors_quai_visible: true,
          elList_spe_visible: false,
          elButton_spe_visible: false,
          elCheckbox_quai_visible: quaiActive,
          elCheckbox_hors_quai_visible: hQuaiActive,
          elHors_quai_visible: hQuaiActive,
          elOther_operation_visible: false
        };
        if (Object.keys(new_state).some(k => this.state[k] !== new_state[k])) {
          this.setState(new_state);
        };
      }
    } else if ([4,9].includes(this.props.appActions.dataSlots['ds_mode_point'])) {
      // Prise en charge
      if (this.props.appActions.dataSlots['ds_q_hq_view'] == 0) {
        let new_state = {
          elList_quai_visible: true,
          elButton_quai_visible: false,
          elList_hors_quai_visible: false,
          elButton_hors_quai_visible: false,
          elList_spe_visible: false,
          elButton_spe_visible: false,
          elCheckbox_quai_visible: quaiActive,
          elCheckbox_hors_quai_visible: hQuaiActive,
          elHors_quai_visible: hQuaiActive,
          elOther_operation_visible: false
        };
        if (Object.keys(new_state).some(k => this.state[k] !== new_state[k])) {
          this.setState(new_state);
        };
      } else if (this.props.appActions.dataSlots['ds_q_hq_view'] == 1) {
        let new_state = {
          elList_quai_visible: false,
          elButton_quai_visible: false,
          elList_hors_quai_visible: true,
          elButton_hors_quai_visible: false,
          elList_spe_visible: false,
          elButton_spe_visible: false,
          elCheckbox_quai_visible: quaiActive,
          elCheckbox_hors_quai_visible: hQuaiActive,
          elHors_quai_visible: hQuaiActive,
          elOther_operation_visible: false
        };
        if (Object.keys(new_state).some(k => this.state[k] !== new_state[k])) {
          this.setState(new_state);
        };
      }
    } else if (this.props.appActions.dataSlots['ds_mode_point'] === 6) {
      // Speciaux
      let new_state = {
        elList_quai_visible: false,
        elButton_quai_visible: false,
        elList_hors_quai_visible: false,
        elButton_hors_quai_visible: false,
        elList_spe_visible: true,
        elButton_spe_visible: true,
        elCheckbox_quai_visible: false,
        elCheckbox_hors_quai_visible: false,
        elHors_quai_visible: false,
        elOther_operation_visible: false
      };
      if (Object.keys(new_state).some(k => this.state[k] !== new_state[k])) {
        this.setState(new_state);
      };
    } else if (this.props.appActions.dataSlots['ds_mode_point'] === 8) {
      // Autres
      let new_state = {
        elList_quai_visible: false,
        elButton_quai_visible: false,
        elList_hors_quai_visible: false,
        elButton_hors_quai_visible: false,
        elList_spe_visible: false,
        elButton_spe_visible: false,
        elCheckbox_quai_visible: false,
        elCheckbox_hors_quai_visible: false,
        elHors_quai_visible: false,
        elOther_operation_visible: true
      };
      if (Object.keys(new_state).some(k => this.state[k] !== new_state[k])) {
        this.setState(new_state);
      };
    }
  }

  onClick_elList_quai = (ev) => {
    window.clearInterval(this.intervalRefresh)
    window.clearTimeout(this.timeoutRefresh)
    console.log('locked for 30s')
    this.timeoutRefresh = setTimeout(() => {
      this.intervalRefresh = setInterval(this._refresh, 15000)
    }, 30000)
    
  
  }
  
  
  onClick_elList_hors_quai = (ev) => {
    window.clearInterval(this.intervalRefresh)
    window.clearTimeout(this.timeoutRefresh)
    console.log('locked for 30s')
    this.timeoutRefresh = setTimeout(() => {
      this.intervalRefresh = setInterval(this._refresh, 15000)
    }, 30000)
    
  
  }
  
  
  onClick_elNon = (ev) => {
    // Go to screen 'Tournée du jour'
    this.props.appActions.goToScreen('tournéeDuJour', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elShow_apercu_1 = (ev) => {
    let newVal = "1";
    this.props.appActions.updateDataSlot('ds_apercu_visible', newVal);
  
  }
  
  
  checkboxChanged_elCheckbox_hors_quai = (event) => {
    this.setState({checkbox_hors_quai: (event.target.checked ? 'true' : 'false')});
    
    this.setState({
      elList_quai_visible: false,
      elList_hors_quai_visible: true,
      elButton_quai_visible: false,
      elButton_hors_quai_visible: true,
      
      checkbox_quai: false,
      checkbox_hors_quai: true,
    });
    
    this.props.appActions.updateDataSlot('ds_q_hq_view', 1);  
  }
  
  checkboxChanged_elCheckbox_quai = (event) => {
    this.setState({checkbox_quai: (event.target.checked ? 'true' : 'false')});
    
    this.setState({
      elList_quai_visible: true,
      elList_hors_quai_visible: false,
      elButton_quai_visible: true,
      elButton_hors_quai_visible: false,
      
      checkbox_quai: true,
      checkbox_hors_quai: false,
    });
    
    this.props.appActions.updateDataSlot('ds_q_hq_view', 0);  
  }
  
  onClick_elShow_apercu = (ev) => {
    let newVal = "1";
    this.props.appActions.updateDataSlot('ds_apercu_visible', newVal);
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };
    let transformPropValue_list_quai = (input) => {
      try {
        let dech_quai_view = this.props.appActions.dataSheets['quaiView'].items.find(it => {
          return it.document_key === this.props.appActions.dataSlots['ds_selected_dech_quai_view'].document_key
        });
      
        let mode_point = this.props.appActions.dataSlots['ds_mode_point'];
        let actions;
        /**
            if (mode_point == 5) {
              actions = [2];
            } else {
              actions = [0, 1, 4];
            }
            **/
        actions = [0, 1, 2, 4];
      
        let commands = this.props.appActions.dataSheets['tournees_j'].items
        .concat(this.props.appActions.dataSheets['tournees_j1'].items)
        .filter(it => it.container_id !== "" && it.site === input.id_client && it.statut === 0 && actions.includes(it.action))
        .sort((a,b) => a.date-b.date);
      
        let simplified_commands = commands.map(it => {return {
          action: it.action,
          container_id: it.container_id,
          date: it.date,
          document_key: it.document_key,
          flux: it.flux,
          hh: it.hh,
          id_client: it.id_client,
          id_scan: it.id_scan,
          quai: it.quai,
          site: it.site,
          statut: it.statut,
          tournee: it.tournee,
          type: it.type,
        }});
      
        let output = [];
        for (let i=1; i < dech_quai_view.quais.length; i++) {
          let item = dech_quai_view.quais[i];
          let newItem = {...item, id_site: dech_quai_view.id_client, quai: i, key: this.state.random_key + i};
      
          // check stuff vider
          let index = simplified_commands.findIndex(c => c.quai === i && c.container_id === newItem.id_contenant && c.site === newItem.id_site && c.hh === "PLEIN" && [0, 1, 4].includes(c.action));
          if (index >=0) {
            let command_index = simplified_commands[index];
            if (command_index.tournee) {
              if (command_index.date === this.props.appActions.dataSlots['ds_today_minuit_ts']) {
                newItem.checked = 3;
                newItem.reference_ot = command_index.document_key;
              } else {
                newItem.checked = 2;
                newItem.reference_ot = command_index.document_key;
              }
            } else {
              newItem.checked = 1;
              newItem.reference_ot = command_index.document_key;
            }
            simplified_commands.splice(index, 1);
          } else {
            newItem.checked = 0;
          }
          // check stuff pkt
          index = simplified_commands.findIndex(c => c.quai === i && c.container_id === newItem.id_contenant && c.site === newItem.id_site && c.hh === "PLEIN" && [2].includes(c.action));
          if (index >=0) {
            let command_index = simplified_commands[index];
            if (command_index.tournee) {
              if (command_index.date === this.props.appActions.dataSlots['ds_today_minuit_ts']) {
                newItem.checked_pkt = 3;
                newItem.reference_ot_pkt = command_index.document_key;
              } else {
                newItem.checked_pkt = 2;
                newItem.reference_ot_pkt = command_index.document_key;
              }
            } else {
              newItem.checked_pkt = 1;
              newItem.reference_ot_pkt = command_index.document_key;
            }
            simplified_commands.splice(index, 1);
          } else {
            newItem.checked_pkt = 0;
          }
      
          output.push(newItem);
        }
        return output;
      } catch (e) {
        console.log(e);
        return [];
      }
    }
    
    // Source items and any special components used for list/grid element 'list_quai'.
    let items_list_quai = [];
    let listComps_list_quai = {};
    if (Array.isArray(transformPropValue_list_quai((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_dech_quai_view'] : '')))) {
        items_list_quai = items_list_quai.concat(transformPropValue_list_quai((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_dech_quai_view'] : '')));
    } else if (transformPropValue_list_quai((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_dech_quai_view'] : ''))) {
        items_list_quai.push(transformPropValue_list_quai((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_dech_quai_view'] : '')));
    }
    this._elList_quai_items = [];
    
    const style_elList_quai = {
      height: 'auto',  // This element is in scroll flow
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elList_quai = this.state.elList_quai_visible ? (
      <div className="hasNestedComps elList_quai">
        <div style={style_elList_quai} onClick={this.onClick_elList_quai} >
          {items_list_quai.map((row, index) => {
            let itemComp = (row._componentId)
                ? listComps_list_quai[row._componentId]
                : <Picker_niveau2 appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} {...{ 'flux': row['flux'], 'state': row['state'], 'id_scan': row['id_scan'], 'quai': row['quai'], 'id_client': row['id_client'], 'id_contenant': row['id_contenant'], 'exutoire': row['exutoire'], 'packmat': row['packmat'], 'checked': row['checked'], 'reference_ot': row['reference_ot'], 'type': row['type'], 'color': row['color'], 'reserve': row['reserve'], 'checked_pkt': row['checked_pkt'], 'reference_ot_pkt': row['reference_ot_pkt'], }} ref={(el) => {if (el) this._elList_quai_items.push(el)}} />;
            return (<div key={row.key}>
                {itemComp}
              </div>);
          })}
          <div className="marker" ref={(el)=> this._elList_quai_endMarker = el} />
        </div>
      </div>
      
     ) : null;
    let transformPropValue_list_hors_quai = (input) => {
      try {
        let dech_quai_view = this.props.appActions.dataSheets['quaiView'].items.find(it => {
          return it.document_key === this.props.appActions.dataSlots['ds_selected_dech_quai_view'].document_key
        });
      
        let mode_point = this.props.appActions.dataSlots['ds_mode_point'];
        let actions;
        if (mode_point == 5) {
          actions = [2];
        } else {
          actions = [0, 1, 4];
        }
      
      
      
      
        let commands = this.props.appActions.dataSheets['tournees_j'].items
        .concat(this.props.appActions.dataSheets['tournees_j1'].items)
        .filter(it => it.container_id !== "" && it.site === input.id_client && it.statut === 0 && actions.includes(it.action))
        .sort((a,b) => a.date-b.date);
      
        let simplified_commands = commands.map(it => {return {
          container_id: it.container_id,
          date: it.date,
          document_key: it.document_key,
          flux: it.flux,
          hh: it.hh,
          id_client: it.id_client,
          id_scan: it.id_scan,
          quai: it.quai,
          site: it.site,
          statut: it.statut,
          tournee: it.tournee,
          type: it.type,
        }});
      
      
        let output = [];
        (dech_quai_view.hors_quais||[]).sort((a,b) => {b.id_scan.localeCompare(a.id_scan)});
        simplified_commands.sort((a,b) => {b.id_scan.localeCompare(a.id_scan)});
        for (let i=0; i < (dech_quai_view.hors_quais||[]).length; i++) {
          let item = dech_quai_view.hors_quais[i];
          let newItem = {...item, id_site: dech_quai_view.id_client, quai: i, key: this.state.random_key + i};
      
          // check stuff
          let index = -1;
          if (newItem.id_contenant.includes('VIDE')) {
            index = simplified_commands.findIndex(c => c.id_scan === newItem.id_scan && c.container_id === newItem.id_contenant && c.site === newItem.id_site && c.hh === "VIDE" && c.type == newItem.flux);
            if (index < 0) {
              index = simplified_commands.findIndex(c => c.id_scan === "" && c.container_id === newItem.id_contenant && c.site === newItem.id_site && c.hh === "VIDE" && c.type == newItem.flux);
            }
          } else {
            index = simplified_commands.findIndex(c => c.id_scan === newItem.id_scan && c.container_id === newItem.id_contenant && c.site === newItem.id_site && c.hh === "HQ");
            if (index < 0) {
              index = simplified_commands.findIndex(c => c.id_scan === "" && c.container_id === newItem.id_contenant && c.site === newItem.id_site && c.hh === "HQ");
            }
          }
          if (index >=0) {
            let command_index = simplified_commands[index];
            if (command_index.tournee) {
              if (command_index.date === this.props.appActions.dataSlots['ds_today_minuit_ts']) {
                newItem.checked = 3;
                newItem.reference_ot = command_index.document_key;
              } else {
                newItem.checked = 2;
                newItem.reference_ot = command_index.document_key;
              }
            } else {
              newItem.checked = 1;
              newItem.reference_ot = command_index.document_key;
            }
            simplified_commands.splice(index, 1);
          } else {
            newItem.checked = 0;
          }
      
          output.push(newItem);
        }
        return output;
      } catch (e) {
        console.log(e);
        return [];
      }
    }
    
    // Source items and any special components used for list/grid element 'list_hors_quai'.
    let items_list_hors_quai = [];
    let listComps_list_hors_quai = {};
    listComps_list_hors_quai['_tail'] = <Tail_picker_echange_start appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />;  // Tail item for this list
    
    if (Array.isArray(transformPropValue_list_hors_quai((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_dech_quai_view'] : '')))) {
        items_list_hors_quai = items_list_hors_quai.concat(transformPropValue_list_hors_quai((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_dech_quai_view'] : '')));
    } else if (transformPropValue_list_hors_quai((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_dech_quai_view'] : ''))) {
        items_list_hors_quai.push(transformPropValue_list_hors_quai((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_dech_quai_view'] : '')));
    }
    items_list_hors_quai.push({key: '_tail', _componentId: '_tail'});  // A data item to indicate the tail item
    this._elList_hors_quai_items = [];
    
    const style_elList_hors_quai = {
      height: 'auto',  // This element is in scroll flow
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elList_hors_quai = this.state.elList_hors_quai_visible ? (
      <div className="hasNestedComps elList_hors_quai">
        <div style={style_elList_hors_quai} onClick={this.onClick_elList_hors_quai} >
          {items_list_hors_quai.map((row, index) => {
            let itemComp = (row._componentId)
                ? listComps_list_hors_quai[row._componentId]
                : <Picker_plein2 appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} {...{ 'flux': row['flux'], 'state': row['state'], 'id_scan': row['id_scan'], 'quai': row['quai'], 'id_client': row['id_client'], 'id_contenant': row['id_contenant'], 'exutoire': row['exutoire'], 'checked': row['checked'], 'reference_ot': row['reference_ot'], 'type': row['type'], 'packmat': row['packmat'], 'color': row['color'], 'key': row['key'], }} ref={(el) => {if (el) this._elList_hors_quai_items.push(el)}} />;
            return (<div key={row.key}>
                {itemComp}
              </div>);
          })}
          <div className="marker" ref={(el)=> this._elList_hors_quai_endMarker = el} />
        </div>
      </div>
      
     ) : null;
    
    const style_elNon = {
      display: 'block',
      color: '#00e5d9',
      textAlign: 'center',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elShow_apercu_1 = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_823350198+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '17.500%',
      paddingLeft: '19%',
      backgroundPosition: '1% 50%',
      color: '#feffff',
      textAlign: 'left',
      backgroundColor: '#00e5d9',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elBackgroundCopy = {
      width: '100%',
      height: '100%',
     };
    const style_elBackgroundCopy_outer = {
      backgroundColor: '#f6f6f6',
     };
    let transformTextPropValue_hors_quai = (input) => {
      return "(" + input.hors_quais.filter(it => !it.id_contenant.includes("VIDE")).length + ")";
    }
    
    const textValue_hors_quai = transformTextPropValue_hors_quai((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_dech_quai_view'] : ''));
    
    const style_elHors_quai = {
      display: 'block',
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      backgroundColor: 'transparent',
     };
    const elHors_quai = this.state.elHors_quai_visible ? (
      <button className="actionFont elHors_quai" style={style_elHors_quai}>
        {textValue_hors_quai}
      </button>
     ) : null;
    
    let checked_checkbox_hors_quai = this.state.checkbox_hors_quai;
    
    const style_elCheckbox_hors_quai = {
      pointerEvents: 'auto',
     };
    const elCheckbox_hors_quai = this.state.elCheckbox_hors_quai_visible ? (
      <Checkbox className="baseFont elCheckbox_hors_quai" style={style_elCheckbox_hors_quai}  label="Hors quai" onChange={this.checkboxChanged_elCheckbox_hors_quai} checked={checked_checkbox_hors_quai === 'true' || checked_checkbox_hors_quai === true || ''+checked_checkbox_hors_quai === '1'}  />
     ) : null;
    
    let checked_checkbox_quai = this.state.checkbox_quai;
    
    const style_elCheckbox_quai = {
      pointerEvents: 'auto',
     };
    const elCheckbox_quai = this.state.elCheckbox_quai_visible ? (
      <Checkbox className="baseFont elCheckbox_quai" style={style_elCheckbox_quai}  defaultChecked label="A quai" onChange={this.checkboxChanged_elCheckbox_quai} checked={checked_checkbox_quai === 'true' || checked_checkbox_quai === true || ''+checked_checkbox_quai === '1'}  />
     ) : null;
    
    const style_elRectangle = {
      background: 'rgba(213, 213, 213, 1.000)',
     };
    const style_elMode = {
      fontSize: 19.0,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const style_elShow_apercu = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_842251783+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_notif = (input) => {
      let entreprise = this.props.appActions.dataSheets['entreprise'].items[0]||{};
      let auth_user = this.props.appActions.dataSheets['auth_user'].items[0] || {};
      let profile = auth_user.profile || {}; 
      
      if (entreprise.pvd_can_add_to_tour && (profile.hdq === true ? !entreprise.hdq_cannot_add_to_tour : true)) {
        let commands = this.props.appActions.dataSheets['tournees_j'].items
        .filter(it => it.tournee == "" && it.statut === 0)
        .sort((a,b) => a.display_name.localeCompare(b.display_name));
        if (commands.length > 0) {
          return "<p style='color:white;border-radius:100%;background-color:#ff7e79;width:18px;height:18px;'>"+commands.length+"</p>";
        } else {
          return '';
        }
      } else {
        return '';
      }
    }
    
    const value_notif = transformPropValue_notif((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_today_minuit_ts'] : ''));
    
    const style_elNotif = {
      fontSize: 12.3,
      color: '#feffff',
      textAlign: 'center',
      pointerEvents: 'auto',
     };
    const elApercu = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_apercu_visible'] : '')) ? (
      <div className="hasNestedComps containerMinHeight elApercu">
        <Apercu visualStateIndex={(this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_mode_apercu'] : '')} ref={(el)=> this._elApercu = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    let transformPropValue_date = (input) => {
      let dech_quai_view = this.props.appActions.dataSheets['quaiView'].items.find(it => {
        return it.document_key === this.props.appActions.dataSlots['ds_selected_dech_quai_view'].document_key
      });
      if (dech_quai_view) {
        let date = new Date(dech_quai_view.ts);
        return date.toLocaleString('fr-FR', { month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' });
      } else {
        return ''
      }
    }
    
    const value_date = transformPropValue_date((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_dech_quai_view'] : ''));
    
    const style_elDate = {
      fontSize: 14.1,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'right',
     };
    
    return (
      <div className="AppScreen Quai_view_dech_startScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elSpacer">
            <div />
          </div>
          
          { elList_quai }
          { elList_hors_quai }
          <div className="elNon">
            <button className="actionFont" style={style_elNon} onClick={this.onClick_elNon} >
              NON, départ sans caisson
            </button>
          </div>
          
          <div className="elShow_apercu_1">
            <button className="actionFont" style={style_elShow_apercu_1} onClick={this.onClick_elShow_apercu_1} >
              Voir tournée
            </button>
          </div>
          
          <div className="elSpacer2">
            <div />
          </div>
        </div>
        <Appbar className="navBar">
          <div className="title">{this.props.ds_selected_dech_site}</div>  <div className="backBtn" onClick={ (ev)=>{ this.props.appActions.goBack() } }><img src={btn_icon_back_quai_view_dech_start} alt="" style={{width: '50%'}} /></div>
        </Appbar>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="elBackgroundCopy" style={style_elBackgroundCopy_outer}>
              <div className="appBg" style={style_elBackgroundCopy} />
            </div>
            
            { elHors_quai }
            { elCheckbox_hors_quai }
            { elCheckbox_quai }
            <div className="elRectangle" style={style_elRectangle} />
            <div className="systemFontBold  elMode" style={style_elMode}>
              <div>{this.state.mode}</div>
            </div>
            <button className="actionFont elShow_apercu" style={style_elShow_apercu} onClick={this.onClick_elShow_apercu}  />
            <div className="systemFontRegular  elNotif" style={style_elNotif}>
              <div><div dangerouslySetInnerHTML={{__html: value_notif}}></div></div>
            </div>
            { elApercu }
            <div className="systemFontItalic  elDate" style={style_elDate}>
              <div>{value_date !== undefined ? value_date : (<span className="propValueMissing">{this.state.date}</span>)}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  
}
