import React, { Component } from 'react';
import './App.css';
import img_elImage from './images/Image_fill_elImage_back_1322448678.png';
import btn_icon_648936828 from './images/btn_icon_648936828.png';
import btn_icon_1056333384 from './images/btn_icon_1056333384.png';
import img_elBig_image from './images/Image_fill_elImage_back_1322448678.png';
import btn_icon_1476246082 from './images/btn_icon_1476246082.png';
import Apercu from './Apercu';
import btn_icon_back_valider_simple from './images/btn_icon_back_valider_simple.png';

// UI framework component imports
import Input from 'muicss/lib/react/input';
import Button from 'muicss/lib/react/button';
import Appbar from 'muicss/lib/react/appbar';

export default class Valider_simpleScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);
    
    this.state = {
      exutoirecopy: (<div> </div>),
      exutoirecopy_plainText: " ",
      commentaire: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_commentaire'] : '') || '',
      tonnage: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_tonnage'] : '') || '',
      volume: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_volume'] : '') || '',
      elCapture_visible: true,
      elErase_visible: false,
      elBig_image_visible: false,
      notif: (<div> </div>),
      notif_plainText: " ",
    };
  }

  componentDidMount() {
    if (this.state.elCapture_visible && this.props.appActions.dataSlots['ds_saved_picture']) {
      this.setState({
        elCapture_visible: false,
        elErase_visible: true,
      })
    } else if (!this.state.elCapture_visible && !this.props.appActions.dataSlots['ds_saved_picture']) {
      this.setState({
        elCapture_visible: true,
        elErase_visible: false,
      })
    }
    {
      let dataSheet = this.props.appActions.dataSheets['reference_containers'];
      let serviceOptions = this.props.appActions.serviceOptions_reference_containers;
      if ( !this.props.appActions.dataSheetLoaded['reference_containers']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/all_containers", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['reference_containers'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['quaiView'];
      let serviceOptions = this.props.appActions.serviceOptions_quaiView;
      if ( !this.props.appActions.dataSheetLoaded['quaiView']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/quaiView", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['quaiView'] = true;
      }
    }
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
    if (this.state.elCapture_visible && this.props.appActions.dataSlots['ds_saved_picture']) {
      this.setState({
        elCapture_visible: false,
        elErase_visible: true,
      })
    } else if (!this.state.elCapture_visible && !this.props.appActions.dataSlots['ds_saved_picture']) {
      this.setState({
        elCapture_visible: true,
        elErase_visible: false,
      })
    }
  }

  textInputChanged_elCommentaire = (event) => {
    this.setState({commentaire: event.target.value});
    
    this.props.appActions.updateDataSlot("ds_commentaire", event.target.value);
  }
  
  textInputChanged_elTonnage = (event) => {
    this.setState({tonnage: event.target.value});
    
    this.props.appActions.updateDataSlot("ds_tonnage", event.target.value);
  }
  
  textInputChanged_elVolume = (event) => {
    this.setState({volume: event.target.value});
    
    this.props.appActions.updateDataSlot("ds_volume", event.target.value);
  }
  
  onClick_elImage = (ev) => {
    this.setState({elBig_image_visible: true});
  
  }
  
  
  onClick_elCapture = (ev) => {
    // Go to screen 'Appareil photo'
    this.props.appActions.goToScreen('appareilPhoto', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elErase = (ev) => {
    let newVal = "";
    this.props.appActions.updateDataSlot('ds_saved_picture', newVal);
  
  }
  
  
  onClick_elRetour = (ev) => {
    // Go to screen 'Tournée du jour'
    this.props.appActions.goToScreen('tournéeDuJour', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elValider = (ev) => {
    let a = window.confirm("Valider cette opération?");
    
    if (!a) {
      return;
    }
    
    let update_ot = {
      document_ref: this.props.appActions.dataSlots['ds_selected_command'].document_ref,
      document_key: this.props.appActions.dataSlots['ds_selected_command'].document_key,
      statut: 1,
      photo: this.props.appActions.dataSlots['ds_saved_picture'],
      commentaire: this.props.appActions.dataSlots['ds_commentaire'] || "",
      volume: parseFloat(this.props.appActions.dataSlots['ds_volume']) || 0,
      tonnage: parseFloat(this.props.appActions.dataSlots['ds_tonnage']) || 0,
      ts_charge: new Date().getTime(),
      coordinates_charge: {
        lat: window.previousPos.coords.latitude, 
        lng: window.previousPos.coords.longitude,
      },
      user_id_charge: this.props.appActions.dataSlots['ds_userid'],
      user_name_charge: this.props.appActions.dataSlots['ds_username'],
    }
    this.props.appActions.dataSheets['tournees_j'].replaceItemByRowIndex(update_ot.document_key, update_ot, this.props.appActions.serviceOptions_tournees_j);
  
    // Go to screen 'Tournée du jour'
    this.props.appActions.goToScreen('tournéeDuJour', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elBig_image = (ev) => {
    this.setState({elBig_image_visible: false});
  
  }
  
  
  onClick_elShow_apercu = (ev) => {
    let newVal = "1";
    this.props.appActions.updateDataSlot('ds_apercu_visible', newVal);
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };
    let transformPropValue_exutoireCopy = (input) => {
      let action = this.props.appActions.dataSheets['operations'].items.find(it => it.id == input.action).label;
      return action + ", " + input.display_name + ", " + input.flux;
    }
    
    const value_exutoireCopy = transformPropValue_exutoireCopy((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_command'] : ''));
    
    const style_elExutoireCopy = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const style_elRectangle = {
      background: 'rgba(213, 213, 213, 1.000)',
     };
    const style_elCard1_outer = {
      backgroundColor: 'white',
      filter: 'drop-shadow(0.0px 2.3px 18px rgba(0, 0, 0, 0.1600))',
      overflow: 'visible',
     };
    
    const value_commentaire = this.state.commentaire;
    
    const style_elCommentaire = {
      display: 'block',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const value_tonnage = this.state.tonnage;
    
    const style_elTonnage = {
      display: 'block',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const value_volume = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_volume'] : '');
    
    const style_elVolume = {
      display: 'block',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elCard3_outer = {
      backgroundColor: 'white',
      filter: 'drop-shadow(0.0px 2.3px 18px rgba(0, 0, 0, 0.1600))',
      overflow: 'visible',
     };
    const style_elImage = {
      backgroundImage: 'url('+((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_saved_picture'] : '') || img_elImage)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elCapture = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_648936828+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '62.857%',
      paddingTop: '30%',
      backgroundPosition: '50% 0%',
      color: '#00e5d9',
      textAlign: 'center',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elCapture = this.state.elCapture_visible ? (
      <div className="elCapture">
        <button className="actionFont" style={style_elCapture} onClick={this.onClick_elCapture} >
          Ajouter une photo
        </button>
      </div>
      
     ) : null;
    
    const style_elErase = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_1056333384+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elErase = this.state.elErase_visible ? (
      <div className="elErase">
        <button className="actionFont" style={style_elErase} onClick={this.onClick_elErase}  />
      </div>
      
     ) : null;
    
    const style_elRetour = {
      display: 'block',
      textTransform: 'none',
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#ff7d78',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elValider = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elBig_image = {
      backgroundImage: 'url('+((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_saved_picture'] : '') || img_elBig_image)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elBig_image = this.state.elBig_image_visible ? (
      <div className="elBig_image" style={style_elBig_image} onClick={this.onClick_elBig_image}  />
     ) : null;
    
    const style_elShow_apercu = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_1476246082+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_notif = (input) => {
      let entreprise = this.props.appActions.dataSheets['entreprise'].items[0]||{};
      let auth_user = this.props.appActions.dataSheets['auth_user'].items[0] || {};
      let profile = auth_user.profile || {}; 
      
      if (entreprise.pvd_can_add_to_tour && (profile.hdq === true ? !entreprise.hdq_cannot_add_to_tour : true)) {
        let commands = this.props.appActions.dataSheets['tournees_j'].items
        .filter(it => it.tournee == "" && it.statut === 0)
        .sort((a,b) => a.display_name.localeCompare(b.display_name));
        if (commands.length > 0) {
          return "<p style='color:white;border-radius:100%;background-color:#ff7e79;width:18px;height:18px;'>"+commands.length+"</p>";
        } else {
          return '';
        }
      } else {
        return '';
      }
    }
    
    const value_notif = transformPropValue_notif((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_today_minuit_ts'] : ''));
    
    const style_elNotif = {
      fontSize: 12.3,
      color: '#feffff',
      textAlign: 'center',
      pointerEvents: 'auto',
     };
    const elApercu = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_apercu_visible'] : '')) ? (
      <div className="hasNestedComps containerMinHeight elApercu">
        <Apercu visualStateIndex={(this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_mode_apercu'] : '')} ref={(el)=> this._elApercu = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    return (
      <div className="AppScreen Valider_simpleScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elSpacer">
            <div />
          </div>
          
          <div className="elExutoireCopy">
            <div className="headlineFont" style={style_elExutoireCopy}>
              <div>{value_exutoireCopy !== undefined ? value_exutoireCopy : (<span className="propValueMissing">{this.state.exutoirecopy}</span>)}</div>
            </div>
          </div>
          
          <div className="elRectangle">
            <div style={style_elRectangle} />
          </div>
          
          <div className="flowRow flowRow_Valider_simpleScreen_elCard1_273695898">
          <div className="elCard1" style={style_elCard1_outer}>
            <div className="cardBg" />
          </div>
          
          <div className="elCommentaire">
            <Input className="baseFont" style={style_elCommentaire} type="text" hint="Commentaire" onChange={this.textInputChanged_elCommentaire} value={value_commentaire !== undefined ? value_commentaire : ''}  />
          </div>
          
          <div className="elTonnage">
            <Input className="baseFont" style={style_elTonnage} type="number" hint="Tonnage" onChange={this.textInputChanged_elTonnage} value={value_tonnage !== undefined ? value_tonnage : ''}  />
          </div>
          
          <div className="elVolume">
            <Input className="baseFont" style={style_elVolume} type="number" hint="Volume" onChange={this.textInputChanged_elVolume} value={value_volume !== undefined ? value_volume : ''}  />
          </div>
          
          </div>
          <div className="elSpacer3">
            <div />
          </div>
          
          <div className="flowRow flowRow_Valider_simpleScreen_elCard3_39283406">
          <div className="elCard3" style={style_elCard3_outer}>
            <div className="cardBg" />
          </div>
          
          <div className="elImage">
            <div style={style_elImage} onClick={this.onClick_elImage}  />
          </div>
          
          { elCapture }
          { elErase }
          </div>
          <div className="elSpacerCopy2">
            <div />
          </div>
          
          <div className="flowRow flowRow_Valider_simpleScreen_elRetour_2132217722">
          <div className="elRetour">
            <Button className="actionFont" style={style_elRetour} onClick={this.onClick_elRetour} >
              Annuler
            </Button>
          </div>
          
          </div>
          <div className="elValider">
            <Button className="actionFont" style={style_elValider}  color="accent" onClick={this.onClick_elValider} >
              Confirmer
            </Button>
          </div>
        </div>
        <Appbar className="navBar">
          <div className="title">Valider l'opération</div>  <div className="backBtn" onClick={ (ev)=>{ this.props.appActions.goBack() } }><img src={btn_icon_back_valider_simple} alt="" style={{width: '50%'}} /></div>
        </Appbar>
        
        <div className="screenFgContainer">
          <div className="foreground">
            { elBig_image }
            <button className="actionFont elShow_apercu" style={style_elShow_apercu} onClick={this.onClick_elShow_apercu}  />
            <div className="systemFontRegular  elNotif" style={style_elNotif}>
              <div><div dangerouslySetInnerHTML={{__html: value_notif}}></div></div>
            </div>
            { elApercu }
          </div>
        </div>
      </div>
    )
  }
  
}
