import React, { Component } from 'react';
import './App.css';
import img_elVide from './images/Picker_niveau2_elVide_1373600465.png';
import Image_fill from './Image_fill';

export default class Picker_depot2 extends Component {

  // Properties used by this component:
  // flux, state, id_scan, quai, id_client, id_contenant, packmat, type, color, reserve

  constructor(props) {
    super(props);
    
    this.state = {
      flux: (<div>BOIS</div>),
      flux_plainText: "BOIS",
      state: (<div> </div>),
      state_plainText: " ",
      id_scan: (<div> </div>),
      id_scan_plainText: " ",
      quai: (<div> </div>),
      quai_plainText: " ",
  		state: this.props.state,
        id_scan: this.props.id_scan,
        packmat: this.props.packmat || 0
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elImage_fill = (ev) => {
    if (
      (this.props.state == 0 || this.props.state === 1) &&
      (this.props.appActions.dataSlots['ds_selected_command'].flux === "VIDE" || this.props.flux === "RESERVE" ||
       (this.props.flux === this.props.appActions.dataSlots['ds_selected_command'].flux) ||
       (this.props.flux !== this.props.appActions.dataSlots['ds_selected_command'].flux && this.props.appActions.dataSlots['ds_selected_command'].ts_vidage)
      )
    ) {
      let a = window.confirm("Déposer sur le quai " + this.props.quai + " ?")
      if (!a) {
        return;
      }
    } else {
      alert("Impossible de déposer le contenant, quai occupé");
      return;
    }
    
    let checkOperation = (command) => {
      if (!command.ts_vidage && (command.action === 1 || command.action === 4 || command.action === 5)) {
        if (command.hh=='VIDE' || this.props.appActions.dataSlots['ds_selected_command'].id_client !== this.props.appActions.dataSlots['ds_client_id']) {
          return 6;
        } else {
          return 0;
        }
      } else {
        return command.action;
      }
    }
    
    let ts = new Date();
    ts =  ts.getTime();
    
    let client_depot = this.props.appActions.dataSheets['clients'].items.filter(it => it.document_key == this.props.appActions.dataSlots['ds_client_id'])[0] || {};
    
    let successDepot = () => {
      let update_ot = {
        document_ref: this.props.appActions.dataSlots['ds_selected_command'].document_ref,
        document_key: this.props.appActions.dataSlots['ds_selected_command'].document_key,
        ts_depot: ts,
        coordinates_depot: {
          lat: window.previousPos.coords.latitude, 
          lng: window.previousPos.coords.longitude,
        },
        user_id_depot: this.props.appActions.dataSlots['ds_userid'],
        user_name_depot: this.props.appActions.dataSlots['ds_username'],
        statut: 1, // ot validé et en attente de validation
        site_depot_name: client_depot.name,
        site_depot: client_depot.document_key,
        site_depot_quai: this.props.quai,
        site_depot_hq: false,
        action_realise: checkOperation(this.props.appActions.dataSlots['ds_selected_command']),
      }
      this.props.appActions.updateDataSlot('ds_selected_command', {...this.props.appActions.dataSlots['ds_selected_command'], ...update_ot});
      this.props.appActions.dataSheets['tournees_j'].replaceItemByRowIndex(update_ot.document_key, update_ot, this.props.appActions.serviceOptions_tournees_j);
    }
    
    let logHistoryMove = () => {
      let otRow = this.props.appActions.dataSlots['ds_selected_command'];
      let update = {
        coordinates: {
          lat: window.previousPos.coords.latitude, 
          lng: window.previousPos.coords.longitude,
        },
        flux: otRow.flux_realise,
        hq: false,
        id_scan: otRow.id_scan,
        id_client: this.props.appActions.dataSlots['ds_client_id'],
        id_contenant: otRow.container_id || "",
        move_type: "DEPOT",
        quai: this.props.quai,
        reference_ot: otRow.document_key || "",
        site: client_depot.name,
        tournee: otRow.tournee||"",
        ts: ts,
        user_id: this.props.appActions.dataSlots['ds_userid'],
        user_name: this.props.appActions.dataSlots['ds_username'],
        vide: otRow.ts_vidage > 0 || otRow.flux_realise.includes("VIDE") ? true : false,
      };
      this.props.appActions.dataSheets['history_moves'].addItem(update, this.props.appActions.serviceOptions_history_moves);
    }
    
    successDepot();
    logHistoryMove();
    
    /////////////////////////// UPDATE TRUCK LOAD /////////////////
    try {
      let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(t => t.document_key == this.props.appActions.dataSlots['ds_selected_command'].tournee);
      let update = {
        document_key: tournee.document_key,
        document_ref: tournee.document_ref,
        loaded: false,
        loaded_is_full: false,
        load_detail: {},
      };
      this.props.appActions.dataSheets['id_tournees_j'].replaceItemByRowIndex(tournee.document_key, update, this.props.appActions.serviceOptions_id_tournees_j);
    } catch (e) {
      console.log(e);
    }
    
    // UPDATE QUAI VIEW
    let dech_quai_view = this.props.appActions.dataSheets['quaiView'].items.filter(it => {
      return it.id_client === this.props.appActions.dataSlots['ds_client_id'];
    })[0];
    
    // commander le vdiff hq ?
    let placeNewOrder = false;
    
    try {
      if (dech_quai_view) {
    
        let contenant;
        if (this.props.reserve && this.props.id_scan) {
          contenant = this.props.appActions.dataSheets['reference_containers'].items.find(it => it.id_scan === this.props.id_scan)||{};
        } else {
          contenant = this.props.appActions.dataSheets['group_contenants'].items.find(it => it.document_key === this.props.id_contenant)||{};
        }
    
        if (contenant && contenant.type && this.props.state === 1) {
          dech_quai_view.hors_quais.push({
            id_scan: this.props.id_scan || "",
            id_client: this.props.appActions.dataSlots['ds_client_id'],
            id_contenant: "VIDE",
            flux: contenant.type,
            packmat: 0,
            state: 1,
            type: contenant.type,
          })
        }
    
        if (this.props.appActions.dataSlots['ds_selected_command'].ts_vidage) {
          dech_quai_view.quais[this.props.quai].id_scan = this.props.appActions.dataSlots['ds_selected_command'].id_scan;
          dech_quai_view.quais[this.props.quai].state = 1;
          dech_quai_view.quais[this.props.quai].packmat = 0;
        } else if (this.props.appActions.dataSlots['ds_selected_command'].flux_realise.includes("VIDE")) {
          dech_quai_view.quais[this.props.quai].id_scan = this.props.appActions.dataSlots['ds_selected_command'].id_scan;
          dech_quai_view.quais[this.props.quai].state = 1;
          dech_quai_view.quais[this.props.quai].packmat = 0;
        } else {
          dech_quai_view.quais[this.props.quai].id_scan = this.props.appActions.dataSlots['ds_selected_command'].id_scan;
          dech_quai_view.quais[this.props.quai].packmat = this.props.appActions.dataSlots['ds_selected_command'].packmat || 0;
          dech_quai_view.quais[this.props.quai].state = this.props.appActions.dataSlots['ds_selected_command'].state|| 1;
          if (this.props.reserve) {
            let corresponding_contenant = this.props.appActions.dataSheets['group_contenants'].items
            .find(it => it.flux === this.props.appActions.dataSlots['ds_selected_command'].flux && it.id_client === dech_quai_view.id_client);
            if (corresponding_contenant) {
              dech_quai_view.quais[this.props.quai].flux = corresponding_contenant.flux;
              dech_quai_view.quais[this.props.quai].id_contenant = corresponding_contenant.document_key;
            }
          }
          placeNewOrder = true;
        }
    
        let update = {
          document_key: dech_quai_view.document_key,
          document_ref: dech_quai_view.document_ref,
          quais: dech_quai_view.quais,
          hors_quais: dech_quai_view.hors_quais,
          ts: new Date().getTime()
        };
        this.props.appActions.dataSheets['quaiView'].replaceItemByRowIndex(update.document_key, update, this.props.appActions.serviceOptions_quaiView);
    
      }
    } catch (error) {
      console.log(error);
    }
    
    // PLACE ORDER si pose benne plein AQ (vidage différé)
    ///////////// CASE REPORTER //////////////
    let entreprise = this.props.appActions.dataSheets['entreprise'].items[0]||{};
    
    if (placeNewOrder && entreprise.order_on_vdiff) {
      let ot_row = this.props.appActions.dataSlots['ds_selected_command'];
      let date_ref = new Date(ot_row.date);
    
      let keys_to_keep = [
        'abilities',
        'action',
        'adr',
        'client',
        'container_id',
        'coordinates',
        'default_action',
        'display_name',
        'duration',
        'exutoire',
        'flux',
        'heavy',
        'heure',
        'hh',
        'id_client',
        'id_scan',
        'ouverture',
        'packmat',
        'remark',
        'sector',
        'site',
        'sort_key',
        'state',
        'type',
        'zfe',      
      ];
      let modifications = {
        action: ot_row.default_action,
        default_action: ot_row.default_action,
        date: this.props.appActions.dataSlots['ds_today_minuit_ts'],
        display_name: dech_quai_view.site,
        driver: "",
        driver_id: "",
        etat: 'differe',
        hh: "PLEIN",
        photo: "",
        position: 0,
        quai: this.props.quai,
        report: ot_row.report,
        reference_diff: ot_row.document_key + " (" + date_ref.toLocaleDateString("FR-fr") + ")",
        site: dech_quai_view.id_client,
        statut : 0,
        tonnage: 0,
        tournee: "",
        truck: "",
        truck_id: "",
        ts: ts,
        user_id: this.props.appActions.dataSlots['ds_userid'],
        user_name: this.props.appActions.dataSlots['ds_username'],
      };
    
      let newOtPlanning = {...ot_row};
      Object.keys(newOtPlanning).forEach(k => {
        if (!keys_to_keep.includes(k)) {
          delete newOtPlanning[k];
        }
      })
      newOtPlanning = {...newOtPlanning, ...modifications};
    
      let tournees_collection = this.props.appActions.dataSheets['tournees_j'].firebase.firestore().collection("entreprises/"+this.props.appActions.dataSlots['ds_entreprise_id']+"/tournees/");
      tournees_collection.add(newOtPlanning).then(docRef => {
        console.log('Order success');
      })
        .catch(e => {
        console.log('ERROR : ', e);
      })
    }
    
    this.props.appActions.goToScreen('tournéeDuJour', { transitionId: 'fadeIn' });
    
  
  }
  
  
  render() {
    let transformStrokeColorPropValue_rectangle = (input) => {
      if (input.quai == this.props.quai) {
        return "black";
      } else {
        return "transparent";
      }
    }
    
    const strokeColorValue_rectangle = transformStrokeColorPropValue_rectangle((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_command'] : ''));
    
    const style_elRectangle = {
      background: 'rgba(254, 255, 255, 1.000)',
      border: '0.9px solid '+(strokeColorValue_rectangle && strokeColorValue_rectangle.length > 0 ? strokeColorValue_rectangle : 'rgba(254, 255, 255, 0.9000)'),
     };
    const style_elVide = {
      backgroundImage: 'url('+img_elVide+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    
    const value_flux = this.props.flux;
    
    const style_elFlux = {
      fontSize: 14.1,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    let transformPropValue_state = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      if (this.props.state == 0) {
        return "-";
      } else {
        return (this.props.state-1)*25 + "%";
      }
    }
    
    const value_state = transformPropValue_state(this.props.state);
    
    const style_elState = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'center',
     };
    
    let transformValue_type_elImage_fill = (input) => {
      let getContenantById = (id) => {
        return this.props.appActions.dataSheets['group_contenants'].items.find(it => it.document_key == id);
      }
      
      let getFluxByMatiere = (matiere) => {
        return this.props.appActions.dataSheets['matieres'].items.find(it => it.matiere == matiere);
      }
      
      let getMaterialByType = (type) => {
        return this.props.appActions.dataSheets['materiels'].items.find(it => it.type == type);
      }
      
      if (this.props.id_contenant == "VIDE") {
        let type = (getMaterialByType(this.props.flux)||{}).code_shape||"30";
        return type;
      } else {
        let contenant = getContenantById(this.props.id_contenant) || {};
        let type = (getMaterialByType(contenant.type)||{}).code_shape||"30";
        return type;
      }
    }
    
    let transformValue_color_elImage_fill = (input) => {
      let getContenantById = (id) => {
        return this.props.appActions.dataSheets['group_contenants'].items.find(it => it.document_key == id);
      }
      
      let getFluxByMatiere = (matiere) => {
        return this.props.appActions.dataSheets['matieres'].items.find(it => it.matiere == matiere);
      }
      
      let getMaterialByType = (type) => {
        return this.props.appActions.dataSheets['materiels'].items.find(it => it.type == type);
      }
      
      return (getFluxByMatiere(this.props.flux)||{}).color || "#000000";
    }
    const style_elImage_fill = {
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_id_scan = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return this.props.id_scan;
    }
    
    const value_id_scan = transformPropValue_id_scan(this.props.id_scan);
    
    const style_elId_scan = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'right',
     };
    let transformPropValue_quai = (input) => {
      return input;
    }
    
    const value_quai = transformPropValue_quai(this.props.quai);
    
    const style_elQuai = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    
    return (
      <div className="Picker_depot2">
        <div className="background">
          <div className="containerMinHeight elRectangle" style={style_elRectangle} />
        </div>
        
        <div className="layoutFlow">
          <div className="flowRow flowRow_Picker_depot2_elVide_2078006252">
          <div className="elVide">
            <div style={style_elVide} />
          </div>
          
          </div>
        </div>
        
        <div className="foreground">
          <div className="systemFontBold  elFlux" style={style_elFlux}>
            <div>{value_flux !== undefined ? value_flux : (<span className="propValueMissing">{this.state.flux}</span>)}</div>
          </div>
          <div className="baseFont elState" style={style_elState}>
            <div>{value_state !== undefined ? value_state : (<span className="propValueMissing">{this.state.state}</span>)}</div>
          </div>
          <div className="hasNestedComps elImage_fill" style={style_elImage_fill} onClick={this.onClick_elImage_fill} >
            <Image_fill state={this.props.state} type={transformValue_type_elImage_fill(this.props.id_contenant)} color={transformValue_color_elImage_fill(this.props.flux) || "#000000"} ref={(el)=> this._elImage_fill = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
          </div>
          <div className="baseFont elId_scan" style={style_elId_scan}>
            <div>{value_id_scan !== undefined ? value_id_scan : (<span className="propValueMissing">{this.state.id_scan}</span>)}</div>
          </div>
          <div className="headlineFont elQuai" style={style_elQuai}>
            <div>{value_quai !== undefined ? value_quai : (<span className="propValueMissing">{this.state.quai}</span>)}</div>
          </div>
        </div>
      </div>
    )
  }
  
}
