import React, { Component } from 'react';
import './App.css';
import Declared_anomalie from './Declared_anomalie';
import btn_icon_686000105 from './images/btn_icon_686000105.png';

export default class Comp_list_anomalies extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elAdd_anomalie = (ev) => {
    let newVal = "1";
    this.props.appActions.updateDataSlot('ds_select_anomalies_visible', newVal);
  
  }
  
  
  render() {
    let transformPropValue_list = (input) => {
      return input.sort((a, b) => a.localeCompare(b)).map(a => {return {label: a}});
    }
    
    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    if (Array.isArray(transformPropValue_list((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_current_anomalies'] : '')))) {
        items_list = items_list.concat(transformPropValue_list((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_current_anomalies'] : '')));
    } else if (transformPropValue_list((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_current_anomalies'] : ''))) {
        items_list.push(transformPropValue_list((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_current_anomalies'] : '')));
    }
    this._elList_items = [];
    
    const style_elList = {
      height: 'auto',  // This element is in scroll flow
     };
    
    const style_elAdd_anomalie = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_686000105+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '16.632%',
      paddingLeft: '18%',
      backgroundPosition: '1% 50%',
      color: '#00e5d9',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Comp_list_anomalies">
        <div className="layoutFlow">
          <div className="hasNestedComps elList">
            <ul style={style_elList}>
              {items_list.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list[row._componentId]
                    : <Declared_anomalie appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} {...{ 'label': row['label'], }} ref={(el) => {if (el) this._elList_items.push(el)}} />;
                return (<li key={row.key}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._elList_endMarker = el} />
            </ul>
          </div>
          
          <div className="elAdd_anomalie">
            <button className="actionFont" style={style_elAdd_anomalie} onClick={this.onClick_elAdd_anomalie} >
              Ajouter une anomalie
            </button>
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
        </div>
        
      </div>
    )
  }
  
}
