import React, { Component } from 'react';
import './App.css';
import Image_fill from './Image_fill';
import btn_icon_360761546 from './images/btn_icon_360761546.png';
import Apercu from './Apercu';
import btn_icon_1000263803 from './images/btn_icon_1000263803.png';

// UI framework component imports
import Button from 'muicss/lib/react/button';
import Appbar from 'muicss/lib/react/appbar';

export default class ChargeDuCamionScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo, state, type, color

  constructor(props) {
    super(props);
    
    this.state = {
      title: (<div> </div>),
      title_plainText: " ",
      id_scancopy: (<div> </div>),
      id_scancopy_plainText: " ",
      id_scan: (<div> </div>),
      id_scan_plainText: " ",
      type: (<div> </div>),
      type_plainText: " ",
      description: (<div> </div>),
      description_plainText: " ",
      notif: (<div> </div>),
      notif_plainText: " ",
    };
  }

  componentDidMount() {
    if (this.props.appActions.dataSlots['ds_scanned_id']) {
      let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key == this.props.appActions.dataSlots['ds_selected_tournee']) || {};
      if (tournee.loaded == true) {
        // update tournee
        try {
          let loadDetail = tournee.load_detail || {};
          loadDetail.id_scan = this.props.appActions.dataSlots['ds_scanned_id'];
          let update = {
            document_key: tournee.document_key,
            document_ref: tournee.document_ref,
            load_detail: loadDetail,
          };
          this.props.appActions.dataSheets['id_tournees_j'].replaceItemByRowIndex(tournee.document_key, update, this.props.appActions.serviceOptions_id_tournees_j);
        } catch (e) {
          console.log(e);
        }
        // SANITIZE id_scans
        try {
          this.props.appActions.dataSheets['quaiView'].items.forEach(qv => {
            let flag_q = false;
            let flag_hq = false;
            let new_q = [];
            let new_hq = [];
            qv.quais.forEach(q => {
              if (q.id_scan == this.props.appActions.dataSlots['ds_scanned_id']) {
                q.id_scan = "";
                flag_q = true;
              }
              new_q.push(q);
            })
            qv.hors_quais.forEach(hq => {
              if (hq.id_scan === this.props.appActions.dataSlots['ds_scanned_id'] && hq.id_contenant === "VIDE") {
                // skip
                flag_hq = true;
              } else if (hq.id_scan === this.props.appActions.dataSlots['ds_scanned_id']) {
                hq.id_scan = "";
                flag_hq = true;
                new_hq.push(hq);
              } else {
                new_hq.push(hq);
              }
            })
            if (flag_q) {qv.quais = new_q}
            if (flag_hq) {qv.hors_quais = new_hq}
            if (flag_q || flag_hq) {this.props.appActions.dataSheets['quaiView'].replaceItemByRowIndex(qv.document_key, qv, this.props.appActions.serviceOptions_quaiView)}
          })
        } catch (e) {
          console.log(e);
        }
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['reference_containers'];
      let serviceOptions = this.props.appActions.serviceOptions_reference_containers;
      if ( !this.props.appActions.dataSheetLoaded['reference_containers']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/all_containers", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['reference_containers'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['quaiView'];
      let serviceOptions = this.props.appActions.serviceOptions_quaiView;
      if ( !this.props.appActions.dataSheetLoaded['quaiView']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/quaiView", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['quaiView'] = true;
      }
    }
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elId_scan = (ev) => {
  
  
  }
  
  
  onClick_elDrop = (ev) => {
    // Go to screen 'depot v4'
    this.props.appActions.goToScreen('depotV4', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elAnomalie = (ev) => {
    let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key == this.props.appActions.dataSlots['ds_selected_tournee']);
    if (tournee.loaded == true && tournee.load_detail && tournee.load_detail.id_scan) {
      this.props.appActions.updateDataSlot('ds_scanned_id', tournee.load_detail.id_scan);
    } else {
      return;
    }
    
  
    let newVal = "truck";
    this.props.appActions.updateDataSlot('ds_source_anomalie', newVal);
  
    // Go to screen 'Anomalie'
    this.props.appActions.goToScreen('anomalie', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elScanner = (ev) => {
    // Go to screen 'Scanner'
    this.props.appActions.goToScreen('scanner', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elShow_apercu = (ev) => {
    let newVal = "1";
    this.props.appActions.updateDataSlot('ds_apercu_visible', newVal);
  
  }
  
  
  onClick_elMenu = (ev) => {
    // Go to screen 'Tournée du jour'
    this.props.appActions.goToScreen('tournéeDuJour', { transitionId: 'fadeIn' });
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };
    let transformPropValue_title = (input) => {
      let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key == input);
      
      if (tournee.loaded == true) {
        return tournee.loaded_is_full==true ? "Chargé, contenant plein" : "Chargé, contenant vide";
      } else {
        return "Non chargé"
      }
    }
    
    const value_title = transformPropValue_title((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_tournee'] : ''));
    
    const style_elTitle = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const style_elCard_outer = {
      backgroundColor: 'white',
      filter: 'drop-shadow(0.0px 2.3px 18px rgba(0, 0, 0, 0.1600))',
      overflow: 'visible',
     };
    let transformPropValue_id_scanCopy = (input) => {
      let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key == input);
      if (tournee.loaded == true && tournee.load_detail && tournee.load_detail.id_scan) {
        return tournee.load_detail.id_scan;
      } else {
        return ;
      }
    }
    let transformCustomCSSPropValue_id_scanCopy = (input) => {
      let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key == input);
      let id_scan = '';
      if (tournee.loaded == true && tournee.load_detail && tournee.load_detail.id_scan) {
        id_scan = tournee.load_detail.id_scan;
      }
      
      let style = '"borderRadius": "5px"';
      try {
        let container = this.props.appActions.dataSheets['reference_containers'].items.find(it => it.id_scan === id_scan);
        if (container.unresolved_anomalies_labels.length) {
          style += ',"backgroundColor": "#FFD479"';
        } else {
          if (this.state.id_scan) {
            style += ',"backgroundColor": "#EBEBEB"';
          }
        } 
      } catch (e) {
        if (this.state.id_scan) {
          style += ',"backgroundColor": "#EBEBEB"';
        }
      }
      
      return style;
    }
    
    const value_id_scanCopy = transformPropValue_id_scanCopy((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_tournee'] : ''));
    
    const style_elId_scanCopy = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'center',
      ...(() => {  // append customCSS property of 'id_scanCopy'
        let val = transformCustomCSSPropValue_id_scanCopy((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_tournee'] : ''));
        if (val == null || val.length == 0) return null;
        try {
          return JSON.parse('{'+val+'}');
        } catch (error) {
          console.log(error);
        }
        return null;
      })(),
     };
    let transformPropValue_id_scan = (input) => {
      let getContenantById = (id) => {
        return this.props.appActions.dataSheets['group_contenants'].items.find(it => it.document_key == id);
      }
      
      let getFluxByMatiere = (matiere) => {
        return this.props.appActions.dataSheets['matieres'].items.find(it => it.matiere == matiere);
      }
      
      let getMaterialByType = (type) => {
        return this.props.appActions.dataSheets['materiels'].items.find(it => it.type == type);
      }
      
      let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key == input);
      if (tournee.loaded == true && tournee.load_detail && tournee.load_detail.id_scan) {
        return "Matricule : " + tournee.load_detail.id_scan;
      } else {
        return "Matricule : ";
      }
    }
    
    const value_id_scan = transformPropValue_id_scan((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_tournee'] : ''));
    
    const style_elId_scan = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_type = (input) => {
      let getContenantById = (id) => {
        return this.props.appActions.dataSheets['group_contenants'].items.find(it => it.document_key == id);
      }
      
      let getFluxByMatiere = (matiere) => {
        return this.props.appActions.dataSheets['matieres'].items.find(it => it.matiere == matiere);
      }
      
      let getMaterialByType = (type) => {
        return this.props.appActions.dataSheets['materiels'].items.find(it => it.type == type);
      }
      
      let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key == input);
      if (tournee.loaded == true && tournee.load_detail && tournee.load_detail.type) {
        return tournee.load_detail.type;
      } else {
        return "";
      }
    }
    
    const value_type = transformPropValue_type((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_tournee'] : ''));
    
    const style_elType = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_description = (input) => {
      let getContenantById = (id) => {
        return this.props.appActions.dataSheets['group_contenants'].items.find(it => it.document_key == id);
      }
      
      let getFluxByMatiere = (matiere) => {
        return this.props.appActions.dataSheets['matieres'].items.find(it => it.matiere == matiere);
      }
      
      let getMaterialByType = (type) => {
        return this.props.appActions.dataSheets['materiels'].items.find(it => it.type == type);
      }
      
      let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key == input);
      if (tournee.loaded == true && tournee.loaded_is_full==true && tournee.load_detail && tournee.load_detail.flux) {
        return tournee.load_detail.flux;
      } else {
        return "";
      }
    }
    
    const value_description = transformPropValue_description((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_tournee'] : ''));
    
    const style_elDescription = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let transformValue_state_elImage_fill_full = (input) => {
      let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key == input);
      
      if (tournee.loaded == true) {
        return tournee.loaded_is_full==true ? 5 : 1;
      } else {
        return 0
      }
    }
    
    let transformValue_type_elImage_fill_full = (input) => {
      let getContenantById = (id) => {
        return this.props.appActions.dataSheets['group_contenants'].items.find(it => it.document_key == id);
      }
      
      let getFluxByMatiere = (matiere) => {
        return this.props.appActions.dataSheets['matieres'].items.find(it => it.matiere == matiere);
      }
      
      let getMaterialByType = (type) => {
        return this.props.appActions.dataSheets['materiels'].items.find(it => it.type == type);
      }
      
      let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key == input);
      
      if (tournee.load_detail) {
        let type = (getMaterialByType(tournee.load_detail.type)||{}).code_shape||"30";
        return type;
      } else {
        return "30";
      }
    }
    
    let transformValue_color_elImage_fill_full = (input) => {
      let getContenantById = (id) => {
        return this.props.appActions.dataSheets['group_contenants'].items.find(it => it.document_key == id);
      }
      
      let getFluxByMatiere = (matiere) => {
        return this.props.appActions.dataSheets['matieres'].items.find(it => it.matiere == matiere);
      }
      
      let getMaterialByType = (type) => {
        return this.props.appActions.dataSheets['materiels'].items.find(it => it.type == type);
      }
      
      let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key == input);
      
      if (tournee.load_detail) {
        return (getFluxByMatiere(tournee.load_detail.flux)||{}).color || "#000000";
      } else {
        return "#000000";
      }
    }
    let transformStateValue_elImage_fill_full = (input) => {
      let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key == input);
      
      if (tournee.loaded == true) {
        return 0;
      } else {
        return 1
      }
    }
    let transformPropValue_drop = (input) => {
      let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key == input);
      if (tournee.loaded == true && tournee.loaded_is_full==false && tournee.load_detail) {
        return true;
      } else {
        return false;
      }
    }
    
    const enabledValue_drop = transformPropValue_drop((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_tournee'] : ''));
    
    const style_elDrop = {
      display: 'block',
      textTransform: 'none',
      color: '#fff',
      textAlign: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.5000)',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_anomalie = (input) => {
      let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key == input);
      if (tournee.loaded == true && tournee.load_detail && tournee.load_detail.id_scan) {
        return true;
      } else {
        return false;
      }
    }
    
    const enabledValue_anomalie = transformPropValue_anomalie((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_tournee'] : ''));
    
    const style_elAnomalie = {
      display: 'block',
      textTransform: 'none',
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#ffd478',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_scanner = (input) => {
      let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key == input);
      if (tournee.loaded == true) {
        return true;
      } else {
        return false;
      }
    }
    
    const enabledValue_scanner = transformPropValue_scanner((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_tournee'] : ''));
    
    const style_elScanner = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elShow_apercu = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_360761546+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_notif = (input) => {
      let entreprise = this.props.appActions.dataSheets['entreprise'].items[0]||{};
      let auth_user = this.props.appActions.dataSheets['auth_user'].items[0] || {};
      let profile = auth_user.profile || {}; 
      
      if (entreprise.pvd_can_add_to_tour && (profile.hdq === true ? !entreprise.hdq_cannot_add_to_tour : true)) {
        let commands = this.props.appActions.dataSheets['tournees_j'].items
        .filter(it => it.tournee == "" && it.statut === 0)
        .sort((a,b) => a.display_name.localeCompare(b.display_name));
        if (commands.length > 0) {
          return "<p style='color:white;border-radius:100%;background-color:#ff7e79;width:18px;height:18px;'>"+commands.length+"</p>";
        } else {
          return '';
        }
      } else {
        return '';
      }
    }
    
    const value_notif = transformPropValue_notif((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_today_minuit_ts'] : ''));
    
    const style_elNotif = {
      fontSize: 12.3,
      color: '#feffff',
      textAlign: 'center',
      pointerEvents: 'auto',
     };
    const elApercu = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_apercu_visible'] : '')) ? (
      <div className="hasNestedComps containerMinHeight elApercu">
        <Apercu visualStateIndex={(this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_mode_apercu'] : '')} ref={(el)=> this._elApercu = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    const style_elMenu = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_1000263803+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen ChargeDuCamionScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elSpacer">
            <div />
          </div>
          
          <div className="elTitle">
            <div className="headlineFont" style={style_elTitle}>
              <div>{value_title !== undefined ? value_title : (<span className="propValueMissing">{this.state.title}</span>)}</div>
            </div>
          </div>
          
          <div className="flowRow flowRow_ChargeDuCamionScreen_elCard_727190444">
          <div className="elCard" style={style_elCard_outer}>
            <div className="cardBg" />
          </div>
          
          <div className="elId_scanCopy">
            <div className="baseFont" style={style_elId_scanCopy}>
              <div>{value_id_scanCopy !== undefined ? value_id_scanCopy : (<span className="propValueMissing">{this.state.id_scancopy}</span>)}</div>
            </div>
          </div>
          
          <div className="elId_scan">
            <div className="baseFont" style={style_elId_scan} onClick={this.onClick_elId_scan} >
              <div>{value_id_scan !== undefined ? value_id_scan : (<span className="propValueMissing">{this.state.id_scan}</span>)}</div>
            </div>
          </div>
          
          <div className="elType">
            <div className="baseFont" style={style_elType}>
              <div>{value_type !== undefined ? value_type : (<span className="propValueMissing">{this.state.type}</span>)}</div>
            </div>
          </div>
          
          <div className="elDescription">
            <div className="baseFont" style={style_elDescription}>
              <div>{value_description !== undefined ? value_description : (<span className="propValueMissing">{this.state.description}</span>)}</div>
            </div>
          </div>
          
          <div className="hasNestedComps elImage_fill_full">
            <div>
              <Image_fill state={transformValue_state_elImage_fill_full((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_tournee'] : ''))} type={transformValue_type_elImage_fill_full((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_tournee'] : ''))} color={transformValue_color_elImage_fill_full((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_tournee'] : ''))} visualStateIndex={transformStateValue_elImage_fill_full((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_tournee'] : ''))} ref={(el)=> this._elImage_fill_full = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
          
          </div>
          <div className="elSpacerCopy">
            <div />
          </div>
          
          <div className="elDrop">
            <Button className="actionFont" style={style_elDrop}  disabled={''+enabledValue_drop !== 'true'} onClick={this.onClick_elDrop} >
              Déposer sur un site
            </Button>
          </div>
          
          <div className="elAnomalie">
            <Button className="actionFont" style={style_elAnomalie}  disabled={''+enabledValue_anomalie !== 'true'} onClick={this.onClick_elAnomalie} >
              Anomalie contenant
            </Button>
          </div>
          
          <div className="elScanner">
            <Button className="actionFont" style={style_elScanner}  color="accent" disabled={''+enabledValue_scanner !== 'true'} onClick={this.onClick_elScanner} >
              Scanner
            </Button>
          </div>
        </div>
        <Appbar className="navBar">
          <div className="title">Charge du camion</div></Appbar>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <button className="actionFont elShow_apercu" style={style_elShow_apercu} onClick={this.onClick_elShow_apercu}  />
            <div className="systemFontRegular  elNotif" style={style_elNotif}>
              <div><div dangerouslySetInnerHTML={{__html: value_notif}}></div></div>
            </div>
            { elApercu }
            <button className="actionFont elMenu" style={style_elMenu} onClick={this.onClick_elMenu}  />
          </div>
        </div>
      </div>
    )
  }
  
}
