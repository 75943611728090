import React, { Component } from 'react';
import './App.css';
import Camera from './Camera';
import btn_icon_642611 from './images/btn_icon_642611.png';
import btn_icon_674753942 from './images/btn_icon_674753942.png';
import btn_icon_back_appareilphoto from './images/btn_icon_back_appareilphoto.png';

// UI framework component imports
import Appbar from 'muicss/lib/react/appbar';

export default class AppareilPhotoScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);
    
    this.state = {
      elCamera_visible: false,
    };
    this.props.appActions.updateDataSlot('ds_facing_mode', 'environment');
    setTimeout(() => {
      this.setState({ elCamera_visible : true })
    }, 500)
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elRectangle = (ev) => {
    this.setState({elCamera_visible: !this.state.elCamera_visible});
  
  }
  
  
  onClick_elCapture = (ev) => {
    // Perform action 'Shoot' on element 'camera'
    this._elCamera.savePicture()
    this.props.appActions.updateDataSlot('ds_saved_picture', this.props.appActions.dataSlots['ds_camera']);
  
    // Go back in screen navigation history
    this.props.appActions.goBack();
  
  }
  
  
  onClick_elIcon_back = (ev) => {
    this.setState({ elCamera_visible : false })
    if (this.props.appActions.dataSlots['ds_facing_mode'] == 'environment') {
      this.props.appActions.updateDataSlot('ds_facing_mode', 'user');
    } else {
      this.props.appActions.updateDataSlot('ds_facing_mode', 'environment');
    }
    setTimeout(() => {
      this.setState({ elCamera_visible : true })
    }, 500)
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    
    const style_elRectangle = {
      background: 'rgba(217, 243, 243, 1.000)',
      cursor: 'pointer',
      pointerEvents: 'auto',
      display: 'none',
     };
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: 'black',
     };
    const style_elCamera = {
      pointerEvents: 'auto',
     };
    const elCamera = this.state.elCamera_visible ? (
      <div className="elCamera" style={style_elCamera}>
        <Camera ref={(el)=> this._elCamera = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    const style_elCapture = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_642611+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '36.500%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elIcon_back = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_674753942+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '98.150%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen AppareilPhotoScreen" style={baseStyle}>
        <div className="background">
          <div className="elRectangle" style={style_elRectangle} onClick={this.onClick_elRectangle}  />
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div style={style_elBackground} />
          </div>
        </div>
        <Appbar className="navBar">
          <div className="title">Appareil photo</div>  <div className="backBtn" onClick={ (ev)=>{ this.props.appActions.goBack() } }><img src={btn_icon_back_appareilphoto} alt="" style={{width: '50%'}} /></div>
        </Appbar>
        
        <div className="screenFgContainer">
          <div className="foreground">
            { elCamera }
            <button className="actionFont elCapture" style={style_elCapture} onClick={this.onClick_elCapture}  />
            <button className="actionFont elIcon_back" style={style_elIcon_back} onClick={this.onClick_elIcon_back}  />
          </div>
        </div>
      </div>
    )
  }
  
}
