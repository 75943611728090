import React, { Component } from 'react';
import './App.css';
import btn_icon_639506549 from './images/btn_icon_639506549.png';
import Prevent_interraction from './Prevent_interraction';

export default class Add_prise_en_charge extends Component {

  // Properties used by this component:
  // appActions

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elHotspot = (ev) => {
    // Go to screen 'point_dech'
    this.props.appActions.goToScreen('point_dech', { transitionId: 'fadeIn' });
  
  }
  
  
  render() {
    
    const style_elIconButton = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_639506549+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
     };
    
    const style_elTxt_button = {
      display: 'block',
      color: '#d5d5d5',
      textAlign: 'left',
      backgroundColor: 'transparent',
     };
    
    const style_elHotspot = {
      display: 'block',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformVisiblePropValue_prevent_interraction = (input) => {
      let filtered_items = this.props.appActions.dataSheets['tournees_j'].items.filter(it => it.tournee == this.props.appActions.dataSlots['ds_selected_tournee'])
      
      if (filtered_items.some(it => it.statut == 9 || it.statut == 10 || it.statut == 11)) {
        return true;
      } else {
        return false
      }
    }
    const elPrevent_interraction = ((val) => { return val === "true" || val == true || val == 1 })(transformVisiblePropValue_prevent_interraction(this.props.appActions)) ? (
      <div className="hasNestedComps containerMinHeight elPrevent_interraction">
        <Prevent_interraction ref={(el)=> this._elPrevent_interraction = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    return (
      <div className="Add_prise_en_charge cardBg">
        <div className="foreground">
          <button className="actionFont containerMinHeight elIconButton" style={style_elIconButton} />
          <button className="actionFont containerMinHeight elTxt_button" style={style_elTxt_button}>
            Nouvelle opération
          </button>
          <div className="actionFont containerMinHeight elHotspot" style={style_elHotspot} onClick={this.onClick_elHotspot}  />
          { elPrevent_interraction }
        </div>
      </div>
    )
  }
  
}
