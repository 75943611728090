import React, { Component } from 'react';
import './App.css';
import Item_immatriculation_anomalie from './Item_immatriculation_anomalie';
import btn_icon_back_list_contenants from './images/btn_icon_back_list_contenants.png';

// UI framework component imports
import Input from 'muicss/lib/react/input';
import Appbar from 'muicss/lib/react/appbar';

export default class List_contenantsScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);
    
    this.state = {
      search: '',
    };
  }

  componentDidMount() {
    {
      let dataSheet = this.props.appActions.dataSheets['reference_containers'];
      let serviceOptions = this.props.appActions.serviceOptions_reference_containers;
      if ( !this.props.appActions.dataSheetLoaded['reference_containers']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/all_containers", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['reference_containers'] = true;
      }
    }
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  textInputChanged_elSearch = (event) => {
    this.setState({search: event.target.value});
  }
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    // eslint-disable-next-line
    const dataSheet_reference_containers = this.props.dataSheets['reference_containers'];
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };
    
    // Source items and any special components used for list/grid element 'list_immatriculations'.
    let items_list_immatriculations = [];
    let listComps_list_immatriculations = {};
    let filterItems_list_immatriculations = items => {
      let search_array = this.state.search.trim().replace(/ +(?= )/g,'').split(/\s/);
      let exp = search_array.map(e => RegExp(e, 'gi'));
      const compare = (exp, adr) => adr.search(exp) >= 0;
      let output = items.filter(it => exp.every(e => compare(e, it.id_scan)));
      return output
    }
    items_list_immatriculations = items_list_immatriculations.concat(filterItems_list_immatriculations(this.props.appActions.getDataSheet('reference_containers').items));
    this._elList_immatriculations_items = [];
    
    
    const style_elSearch = {
      display: 'block',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen List_contenantsScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        <Appbar className="navBar">
          <div className="title">Anomalie</div>  <div className="backBtn" onClick={ (ev)=>{ this.props.appActions.goBack() } }><img src={btn_icon_back_list_contenants} alt="" style={{width: '50%'}} /></div>
        </Appbar>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="hasNestedComps elList_immatriculations">
              <ul>
                {items_list_immatriculations.map((row, index) => {
                  let itemComp = (row._componentId)
                      ? listComps_list_immatriculations[row._componentId]
                      : <Item_immatriculation_anomalie appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'reference_containers'} dataSheetRow={row} ref={(el) => {if (el) this._elList_immatriculations_items.push(el)}} />;
                  return (<li key={row.key}>
                      {itemComp}
                    </li>);
                })}
                <div className="marker" ref={(el)=> this._elList_immatriculations_endMarker = el} />
              </ul>
            </div>
            
            <Input className="baseFont elSearch" style={style_elSearch} type="text" hint="Rechercher n° contenant" onChange={this.textInputChanged_elSearch} value={this.state.search}  />
          </div>
        </div>
      </div>
    )
  }
  
}
