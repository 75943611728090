import React, { Component } from 'react';
import './App.css';

export default class Ot_order_add extends Component {

  // Properties used by this component:
  // dataSheetRow

  constructor(props) {
    super(props);
    
    this.state = {
      site: (<div> </div>),
      site_plainText: " ",
      action: (<div> </div>),
      action_plainText: " ",
      flux: (<div>undefined</div>),
      flux_plainText: "undefined",
      type: (<div>undefined - Hors quai</div>),
      type_plainText: "undefined - Hors quai",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elHotspot = (ev) => {
    const a = window.confirm('Ajouter cette commande à votre tournée?');
    if (!a) {
      return;
    }
    
    try {
    
      let getDriverById = (id) => {
        return this.props.appActions.dataSheets['drivers'].items.filter(it => it.document_key == id)[0];
      }
    
      let getTruckById = (id) => {
        return this.props.appActions.dataSheets['trucks'].items.filter(it => it.document_key == id)[0];
      }
    
      let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key == this.props.appActions.dataSlots['ds_selected_tournee']);
      let destination_tour = this.props.appActions.dataSheets['tournees_j'].items.filter(it => it.tournee === tournee.document_key);
      destination_tour.sort((a, b) => b.position - a.position);
      let max_position = (destination_tour[0]||{}).position||0;
    
      let truck = getTruckById(tournee.truck_id) || {};
      let driver = getDriverById(tournee.driver_id);
    
      let update_ot = {
        document_ref: this.props.dataSheetRow.document_ref,
        document_key: this.props.dataSheetRow.document_key,
        driver: driver.name,
        driver_id: driver.document_key,
        position: (max_position+1) || 1,
        tournee: tournee.document_key,
        truck: truck.imat || "",
        truck_id: truck.document_key || "",
      }
    
      this.props.appActions.dataSheets['tournees_j'].replaceItemByRowIndex(update_ot.document_key, update_ot, this.props.appActions.serviceOptions_tournees_j);
      
      window.alert('Commande ajoutée à la tournée');
    } catch (error) {
      console.log(error);
    }
  
  }
  
  
  render() {
    let transformFillColorPropValue_background = (input) => {
      if (input.statut == 1 || input.statut == 3 || input.statut == 5) {
        return "#00e2d033";
      } else if (input.statut == 2 || input.statut == 4  || input.statut == 6) {
        return "#FF7E79";
      } else if (input.statut == 7 || input.statut == 8 || input.statut >= 9) {
        return "#ebebeb";
      } else {
        if (input.date === this.props.appActions.dataSlots['ds_today_minuit_ts']) {
          return "#FFFFFF";
        } else {
          return "#ebebeb";
        }
      }
    }
    const fillColorValue_background = transformFillColorPropValue_background(this.props.dataSheetRow);
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: (fillColorValue_background && fillColorValue_background.length > 0) ? fillColorValue_background : 'white',
     };
    
    const style_elRectangle = {
      background: 'rgba(213, 213, 213, 1.000)',
     };
    let transformPropValue_site = (input) => {
      if (input.date === this.props.appActions.dataSlots['ds_today_minuit_ts']) {
        return input.display_name;
      } else {
        return input.display_name + ' (demain)';
      }
    }
    
    const value_site = transformPropValue_site(this.props.dataSheetRow);
    
    const style_elSite = {
      fontSize: 14.1,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_action = (input) => {
      let action = input.action_realise != undefined ? input.action_realise : input.action;
      return (this.props.appActions.dataSheets['operations'].items.find(it => it.id == action)||{}).label || "";
    }
    
    const value_action = transformPropValue_action(this.props.dataSheetRow);
    
    const style_elAction = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };
    let transformPropValue_flux = (input) => {
      return input.flux + (input.id_scan ? (" - " + input.id_scan) : "");
    }
    
    const value_flux = transformPropValue_flux(this.props.dataSheetRow);
    
    const style_elFlux = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_type = (input) => {
      return input.type + (input.quai ? (" - quai " + input.quai) : " - Hors quai");
    }
    
    const value_type = transformPropValue_type(this.props.dataSheetRow);
    
    const style_elType = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const style_elHotspot = {
      display: 'block',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Ot_order_add">
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div style={style_elBackground} />
          </div>
          
          <div className="elRectangle" style={style_elRectangle} />
        </div>
        
        <div className="layoutFlow">
          <div className="elSite">
            <div className="systemFontBold" style={style_elSite}>
              <div>{value_site !== undefined ? value_site : (<span className="propValueMissing">{this.state.site}</span>)}</div>
            </div>
          </div>
          
          <div className="flowRow flowRow_Ot_order_add_elAction_1296690769">
          <div className="elAction">
            <div className="baseFont" style={style_elAction}>
              <div>{value_action !== undefined ? value_action : (<span className="propValueMissing">{this.state.action}</span>)}</div>
            </div>
          </div>
          
          </div>
          <div className="elFlux">
            <div className="baseFont" style={style_elFlux}>
              <div>{value_flux !== undefined ? value_flux : (<span className="propValueMissing">{this.state.flux}</span>)}</div>
            </div>
          </div>
          
          <div className="elType">
            <div className="baseFont" style={style_elType}>
              <div>{value_type !== undefined ? value_type : (<span className="propValueMissing">{this.state.type}</span>)}</div>
            </div>
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
        </div>
        
        <div className="foreground">
          <div className="actionFont containerMinHeight elHotspot" style={style_elHotspot} onClick={this.onClick_elHotspot}  />
        </div>
      </div>
    )
  }
  
}
