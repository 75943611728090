import React, { Component } from 'react';
import './App.css';
import btn_icon_1125613808 from './images/btn_icon_1125613808.png';
import firebase from 'firebase';
import firestore from 'firebase/firestore';
import performance from 'firebase/performance';

// UI framework component imports
import Select from 'muicss/lib/react/select';
import Option from 'muicss/lib/react/option';
import Button from 'muicss/lib/react/button';
import Appbar from 'muicss/lib/react/appbar';

export default class MenuPrincipalScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);
    
    this.state = {
      text: (<div>Invalid Date</div>),
      text_plainText: "Invalid Date",
      elHead_tour_visible: true,
      head_tour: (<div>Feuille de route</div>),
      head_tour_plainText: "Feuille de route",
      elTournee_visible: true,
      tournee: (<div>Sélectionner une tournée</div>),
      tournee_plainText: "Sélectionner une tournée",
      elPicker_j_visible: true,
      picker_j: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_tournee'] : ''),
      elTruck_visible: true,
      truck: (<div>Sélectionner un camion</div>),
      truck_plainText: "Sélectionner un camion",
      elPicker_j_truck_visible: true,
      picker_j_truck: '',
      elAlert_visible: true,
      alert: (<div> </div>),
      alert_plainText: " ",
      elTour_j_visible: true,
      elHead_dech_visible: true,
      head_dech: (<div>Niveaux de remplissage</div>),
      head_dech_plainText: "Niveaux de remplissage",
      elDech_visible: true,
      dech: (<div>Sélectionner un lieu</div>),
      dech_plainText: "Sélectionner un lieu",
      elPicker_dech_visible: true,
      picker_dech: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_client_id'] : ''),
      elButton_visible: true,
    };
  }

  componentDidMount() {
    // initialize window.tournee
    window.tournee = "";
    // Android interface if available
    try {
      window.Android.refreshData([
        this.props.appActions.dataSlots['ds_entreprise_id'],
        this.props.appActions.dataSlots['ds_userid'],
        this.props.appActions.dataSlots['ds_username'],
        null
      ])
    } catch (error) {
      console.log("Android interface not available");
    }
    
    // initialize dates!!!
    let execution = new Date();
    execution.setHours(0,0,0,0); 
    execution = execution.getTime();
    if (this.props.appActions.dataSlots['ds_today_minuit_ts'] != execution) {
      this.props.appActions.updateDataSlot('ds_selected_tournee', "");
      this.props.appActions.updateDataSlot('ds_today_minuit_ts', execution);
      this.props.appActions.updateDataSlot('ds_demain_minuit_ts', execution+86400000);
    }
    ///////
    
    let auth_user = this.props.appActions.dataSheets['auth_user'].items[0] || {};
    let profile = auth_user.profile || {};
    if (profile.driver === true) {
      this.setState({
        elPicker_j_visible : true,
        elPicker_j_truck_visible : true,
        elTour_j_visible : true,
        elTournee_visible : true,
        elTruck_visible : true,
        elHead_tour_visible : true,
        elAlert_visible: true
      })
    } else {
      this.setState({
        elPicker_j_visible : false,
        elPicker_j_truck_visible : false,
        elTour_j_visible : false,
        elTournee_visible : false,
        elTruck_visible : false,
        elHead_tour_visible : false,
        elAlert_visible: false
      })
    }
    if (profile.hdq === true) {
      this.setState({
        elPicker_dech_visible : true,
        elButton_visible : true,
        // elAnomalie_visible : true,
        elDech_visible : true,
        elHead_dech_visible : true,
      })
    } else {
      this.setState({
        elPicker_dech_visible : false,
        elButton_visible : false,
        // elAnomalie_visible : false,
        elDech_visible : false,
        elHead_dech_visible : false
      })
    }
    
    // current tour initialization
    let tourConnectedUser = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key === this.props.appActions.dataSlots['ds_selected_tournee']);
    if (tourConnectedUser) {
      this.setState({
        picker_j_truck: tourConnectedUser.truck_id
      });
    }
    
    {
      let dataSheet = this.props.appActions.dataSheets['trucks'];
      let serviceOptions = this.props.appActions.serviceOptions_trucks;
      if ( !this.props.appActions.dataSheetLoaded['trucks']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/trucks", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['trucks'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['quaiViewRights'];
      let serviceOptions = this.props.appActions.serviceOptions_quaiViewRights;
      if ( !this.props.appActions.dataSheetLoaded['quaiViewRights']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/quaiView", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['quaiViewRights'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['id_tournees_j'];
      let serviceOptions = this.props.appActions.serviceOptions_id_tournees_j;
      if ( !this.props.appActions.dataSheetLoaded['id_tournees_j']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/id_tournees", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['id_tournees_j'] = true;
      }
    }
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
    if (!this.props.appActions.dataSlots['ds_selected_tournee']) {
      let connectedUser = this.props.appActions.dataSheets['drivers'].items.filter(it => it.uid === this.props.appActions.dataSlots['ds_userid'])[0];
      //console.log('connectedUser', connectedUser);
      if (connectedUser) {
        let tourConnectedUser = this.props.appActions.dataSheets['id_tournees_j'].items.filter(it => it.driver_id === connectedUser.document_key)[0];
        //console.log('tour j', this.props.appActions.dataSheets['id_tournees_j']);
        //console.log('tourConnectedUser', tourConnectedUser);
        if (tourConnectedUser) {
          this.props.appActions.updateDataSlot('ds_selected_tournee', tourConnectedUser.document_key);
          this.setState({
            picker_j: tourConnectedUser.document_key,
            picker_j_truck: tourConnectedUser.truck_id
          });
        }
      }
    }
    
  }

  pickerValueChanged_elPicker_j = (event) => {
    this.setState({picker_j: event.target.value});
    
    let tourPickerJ = this.props.appActions.dataSheets['id_tournees_j'].items.filter(it => it.document_key === event.target.value)[0] || {};
    if (this.state.picker_j_truck !== tourPickerJ.truck_id) {
      this.setState({picker_j_truck: tourPickerJ.truck_id});
    }  
    
    this.props.appActions.updateDataSlot("ds_selected_tournee", event.target.value);
  }
  
  pickerValueChanged_elPicker_j_truck = (event) => {
    this.setState({picker_j_truck: event.target.value});
  }
  
  onClick_elTour_j = (ev) => {
    if (this.props.appActions.dataSlots['ds_selected_tournee'] && this.state.picker_j_truck) {
      window.tournee = this.props.appActions.dataSlots['ds_selected_tournee'];
      // Android interface if available
      try {
        window.Android.refreshData([
          this.props.appActions.dataSlots['ds_entreprise_id'],
          this.props.appActions.dataSlots['ds_userid'],
          this.props.appActions.dataSlots['ds_username'],
          this.props.appActions.dataSlots['ds_selected_tournee']
        ])
      } catch (error) {
        console.log("Android interface not available");
      }
    
      let driver_id = this.props.appActions.dataSheets['id_tournees_j'].items.filter(it => it.document_key === this.props.appActions.dataSlots['ds_selected_tournee'])[0].driver_id;
      let driver = this.props.appActions.dataSheets['drivers'].items.find(it => it.document_key === driver_id);
      this.props.appActions.updateDataSlot('ds_selected_tournee_driver', driver.name);
    
      // update truck if needed
      let truck = this.props.appActions.dataSheets['trucks'].items.find(it => it.document_key === this.state.picker_j_truck);
      if (this.props.appActions.dataSlots['ds_selected_tournee'] && truck) {
        let tourPickerJ = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key === this.props.appActions.dataSlots['ds_selected_tournee']);
        if (tourPickerJ) {
          tourPickerJ.truck = truck.imat;
          tourPickerJ.truck_id = truck.document_key;
          
          // union
          // tourPickerJ.abilities = [...new Set([...(driver.abilities||[]), ...(truck.abilities||[])])];
          // intersection
          let a = new Set([...(driver.abilities||[])]);
          let b = new Set([...(truck.abilities||[])]);
          let intersection = new Set(
            [...a].filter(x => b.has(x)));
          if ((truck.abilities||[]).includes('grue')) {
            intersection.add('grue');
          }
          tourPickerJ.abilities = [...intersection];
          
          this.props.appActions.dataSheets['id_tournees_j'].replaceItemByRowIndex(tourPickerJ.document_key, tourPickerJ, this.props.appActions.serviceOptions_id_tournees_j);
        }
      }
    } else {
      window.alert("Sélectionner un chauffeur et un camion.");
      return;
    }
  
    // Go to screen based on value in data slot 'ds_selected_tournee'
    this.props.appActions.goToScreen(this.targetTable_switch_driver_started(this.transformTargetScreenValue_switch_driver_started((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_tournee'] : ''))), { transitionId: 'fadeIn' });
  
  }
  
  
  pickerValueChanged_elPicker_dech = (event) => {
    this.setState({picker_dech: event.target.value});
    
    this.props.appActions.updateDataSlot("ds_client_id", event.target.value);
  }
  
  onClick_elButton = (ev) => {
    let dech_quai_view = this.props.appActions.dataSheets['quaiView'].items.filter(it => {
      return it.id_client === this.props.appActions.dataSlots['ds_client_id']
    })[0];
    
    let dech_quai_view_simplified = {
      quais: dech_quai_view.quais,
      hors_quais: dech_quai_view.hors_quais,
      spe: dech_quai_view.spe,
      ts: dech_quai_view.ts,
      id_client: dech_quai_view.id_client,
      document_key: dech_quai_view.document_key,
    }
    
    this.props.appActions.updateDataSlot('ds_selected_dech_quai_view', JSON.parse(JSON.stringify(dech_quai_view_simplified)));
    this.props.appActions.updateDataSlot('ds_selected_dech_site', dech_quai_view.site);
    
    
    /// DEFAULT MODE POINT
    let rights_app = this.props.appActions.dataSheets['auth_user'].items[0].rights_app || {
      "READ": true,
      "STATES": true,
      "SCAN": true,
      "ORDER": true,
      "EMPTY": true,
      "PACKMAT": true,
      "SPE": true,
      "SHED": false,
      "OTHER": false,
      "ISSUE": false,
    };
    
    let id_right = ["READ", "STATES", "SCAN", "ORDER", "LOCKED", "LOCKED", "SPE", "LOCKED", "LOCKED", "ISSUE"];
    let sequence = [0,1,2,3,4,5,6,7,8,9]
    
    let next_mode = sequence[0];
    let cont = 0;
    while (rights_app[id_right[next_mode]] !== true && cont < 10) {
      next_mode = sequence[(sequence.findIndex(s => s==next_mode)+1)%10];
      //next_mode = (parseInt(next_mode)+1)%9;
      cont += 1;
    }
    if (cont < 10) {
      this.props.appActions.updateDataSlot('ds_mode_point', next_mode);
    } else {
      window.alert("Utilisateur non autorisé.");
      return;
    }
  
    let newVal = "0";
    this.props.appActions.updateDataSlot('ds_selected_quai', newVal);
  
    newVal = "";
    this.props.appActions.updateDataSlot('ds_scanned_id', newVal);
  
    newVal = "0";
    this.props.appActions.updateDataSlot('ds_q_hq_view', newVal);
  
    newVal = "menu";
    this.props.appActions.updateDataSlot('ds_source_anomalie', newVal);
  
    // Go to screen 'quai_view_dech'
    this.props.appActions.goToScreen('quai_view_dech', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elLogout = (ev) => {
    // 'Sign out (lock gate)' action.
    this.clearSavedLogin();
    sessionStorage.setItem('loginStatus_connexion', 'inactive');
    this.props.appActions.goToScreen('connexion');
  
    window.currentNFCTag = "";
    this.props.appActions.updateDataSlot('ds_selected_tournee', '');
  
    // Go to screen 'Connexion'
    this.props.appActions.goToScreen('connexion', { transitionId: 'fadeIn' });
  
  }
  
  
  targetTable_switch_driver_started = (targetId) => {
    if (targetId === 'STARTED')
      return 'tournéeDuJour';
    else if (targetId === 'NOT_STARTED')
      return 'débutDeTournée';
  }
  
  clearSavedLogin() {
    // Logout
    firebase.auth().signOut().then(() => {
      window.location.reload();
    });
  }
  
  
  transformTargetScreenValue_switch_driver_started = (input) => {
    let driver_started = (this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key === input)||{}).driver_started;
    return driver_started ? "STARTED" : "NOT_STARTED";
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };
    let transformPropValue_text = (input) => {
      let date = new Date(input);
      return date.toLocaleDateString('fr-FR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
    }
    
    const value_text = transformPropValue_text((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_today_minuit_ts'] : ''));
    
    const style_elText = {
      fontSize: 14.1,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'right',
     };
    
    const style_elRectangle = {
      background: 'rgba(213, 213, 213, 1.000)',
     };
    const style_elRectangle_outer = {
      display: 'none',
     };
    const style_elHead_tour = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const elHead_tour = this.state.elHead_tour_visible ? (
      <div className="elHead_tour">
        <div className="headlineFont" style={style_elHead_tour}>
          <div>{this.state.head_tour}</div>
        </div>
      </div>
      
     ) : null;
    const style_elTournee = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    const elTournee = this.state.elTournee_visible ? (
      <div className="elTournee">
        <div className="baseFont" style={style_elTournee}>
          <div>{this.state.tournee}</div>
        </div>
      </div>
      
     ) : null;
    
    let selection_picker_j = this.state.picker_j;
    // Source datasheet and selected data column for picker element 'picker_j'
    const dataSource_picker_j = this.props.appActions.getDataSheet('id_tournees_j');
    const valueColumnName_picker_j = 'document_key';
    const labelColumnName_picker_j = 'statut_display';
    
    const style_elPicker_j = {
      pointerEvents: 'auto',
     };
    const elPicker_j = this.state.elPicker_j_visible ? (
      <div className="elPicker_j">
        <Select className="baseFont" style={style_elPicker_j}  onChange={this.pickerValueChanged_elPicker_j} value={selection_picker_j} >
          {dataSource_picker_j.items.every(item => {
            return item[valueColumnName_picker_j] !== selection_picker_j;
          }) ? <Option value=''/> : null}
          {dataSource_picker_j.items.map(item => {
            const colValue = item[valueColumnName_picker_j];
            const labelColValue = item[labelColumnName_picker_j];
            return <Option key={item.key} value={colValue} label={labelColValue} />
          })}
        </Select>
      </div>
      
     ) : null;
    const style_elTruck = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    const elTruck = this.state.elTruck_visible ? (
      <div className="elTruck">
        <div className="baseFont" style={style_elTruck}>
          <div>{this.state.truck}</div>
        </div>
      </div>
      
     ) : null;
    
    let selection_picker_j_truck = this.state.picker_j_truck;
    // Source datasheet and selected data column for picker element 'picker_j_truck'
    const dataSource_picker_j_truck = this.props.appActions.getDataSheet('trucks');
    const valueColumnName_picker_j_truck = 'document_key';
    const labelColumnName_picker_j_truck = 'statut_display';
    
    const style_elPicker_j_truck = {
      pointerEvents: 'auto',
     };
    const elPicker_j_truck = this.state.elPicker_j_truck_visible ? (
      <div className="elPicker_j_truck">
        <Select className="baseFont" style={style_elPicker_j_truck}  onChange={this.pickerValueChanged_elPicker_j_truck} value={selection_picker_j_truck} >
          {dataSource_picker_j_truck.items.every(item => {
            return item[valueColumnName_picker_j_truck] !== selection_picker_j_truck;
          }) ? <Option value=''/> : null}
          {dataSource_picker_j_truck.items.map(item => {
            const colValue = item[valueColumnName_picker_j_truck];
            const labelColValue = item[labelColumnName_picker_j_truck];
            return <Option key={item.key} value={colValue} label={labelColValue} />
          })}
        </Select>
      </div>
      
     ) : null;
    let transformPropValue_alert = (input) => {
      if (this.props.appActions.dataSlots['ds_selected_tournee']) {
        let connectedUser = this.props.appActions.dataSheets['drivers'].items.filter(it => it.uid === this.props.appActions.dataSlots['ds_userid'])[0];
        if (connectedUser) {
          let tourConnectedUser = this.props.appActions.dataSheets['id_tournees_j'].items.filter(it => it.driver_id === connectedUser.document_key)[0];
          if (tourConnectedUser) {
            if (this.props.appActions.dataSlots['ds_selected_tournee'] != tourConnectedUser.document_key) {
              return "Attention, vous avez sélectionné une tournée qui ne correspond pas à votre profil utilisateur. Continuer?";
            }
          }
        }
      }
      return "";
    }
    
    const value_alert = transformPropValue_alert((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_tournee'] : ''));
    
    const style_elAlert = {
      color: '#ff7d78',
      textAlign: 'left',
     };
    const elAlert = this.state.elAlert_visible ? (
      <div className="elAlert">
        <div className="baseFont" style={style_elAlert}>
          <div>{value_alert !== undefined ? value_alert : (<span className="propValueMissing">{this.state.alert}</span>)}</div>
        </div>
      </div>
      
     ) : null;
    let transformTextPropValue_tour_j = (input) => {
      let driver_started = (this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key === this.props.appActions.dataSlots['ds_selected_tournee'])||{}).driver_started;
      return driver_started ? "Reprendre" : "Commencer";
    }
    
    const textValue_tour_j = transformTextPropValue_tour_j((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_tournee'] : ''));
    
    const style_elTour_j = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elTour_j = this.state.elTour_j_visible ? (
      <div className="elTour_j">
        <Button className="actionFont" style={style_elTour_j}  color="accent" onClick={this.onClick_elTour_j} >
          {textValue_tour_j}
        </Button>
      </div>
      
     ) : null;
    const style_elHead_dech = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const elHead_dech = this.state.elHead_dech_visible ? (
      <div className="elHead_dech">
        <div className="headlineFont" style={style_elHead_dech}>
          <div>{this.state.head_dech}</div>
        </div>
      </div>
      
     ) : null;
    const style_elDech = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    const elDech = this.state.elDech_visible ? (
      <div className="elDech">
        <div className="baseFont" style={style_elDech}>
          <div>{this.state.dech}</div>
        </div>
      </div>
      
     ) : null;
    
    let selection_picker_dech = this.state.picker_dech;
    // Source datasheet and selected data column for picker element 'picker_dech'
    const dataSource_picker_dech = this.props.appActions.getDataSheet('quaiViewRights');
    const valueColumnName_picker_dech = 'id_client';
    const labelColumnName_picker_dech = 'site';
    
    const style_elPicker_dech = {
      pointerEvents: 'auto',
     };
    const elPicker_dech = this.state.elPicker_dech_visible ? (
      <div className="elPicker_dech">
        <Select className="baseFont" style={style_elPicker_dech}  onChange={this.pickerValueChanged_elPicker_dech} value={selection_picker_dech} >
          {dataSource_picker_dech.items.every(item => {
            return item[valueColumnName_picker_dech] !== selection_picker_dech;
          }) ? <Option value=''/> : null}
          {dataSource_picker_dech.items.map(item => {
            const colValue = item[valueColumnName_picker_dech];
            const labelColValue = item[labelColumnName_picker_dech];
            return <Option key={item.key} value={colValue} label={labelColValue} />
          })}
        </Select>
      </div>
      
     ) : null;
    
    const style_elButton = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elButton = this.state.elButton_visible ? (
      <div className="elButton">
        <Button className="actionFont" style={style_elButton}  color="accent" onClick={this.onClick_elButton} >
          Valider
        </Button>
      </div>
      
     ) : null;
    
    let cssClass_progress = 'circularProgressIndicator';
    if (this.props.appActions.isLoading()) 
      cssClass_progress += ' circularProgressIndicator-active';
    
    
    const style_elLogout = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_1125613808+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '122.500%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen MenuPrincipalScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elSpacer">
            <div />
          </div>
          
          <div className="elText">
            <div className="systemFontItalic" style={style_elText}>
              <div>{value_text !== undefined ? value_text : (<span className="propValueMissing">{this.state.text}</span>)}</div>
            </div>
          </div>
          
          <div className="elRectangle" style={style_elRectangle_outer}>
            <div style={style_elRectangle} />
          </div>
          
          { elHead_tour }
          { elTournee }
          { elPicker_j }
          { elTruck }
          { elPicker_j_truck }
          { elAlert }
          { elTour_j }
          { elHead_dech }
          { elDech }
          { elPicker_dech }
          { elButton }
        </div>
        <Appbar className="navBar">
          <div className="title">Menu principal</div></Appbar>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="elProgress">
              <svg className={cssClass_progress}>
                <circle className="circularProgress-animatedPath" style={{stroke: '#00e5d9'}} cx="25" cy="25" r="17" fill="none" strokeWidth="3" strokeMiterlimit="10" />
              </svg>
            </div>
            <button className="actionFont elLogout" style={style_elLogout} onClick={this.onClick_elLogout}  />
          </div>
        </div>
      </div>
    )
  }
  
}
