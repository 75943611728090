import React, { Component } from 'react';
import './App.css';
import img_elN0_0 from './images/Picker_specifiques_elImage_140921196.png';
import img_elN0_2 from './images/Picker_niveau2_elN0_2_732410671.png';
import img_elN0_3 from './images/Picker_niveau2_elN0_3_997716689.png';
import img_elN0_4 from './images/Picker_niveau2_elN0_4_1296709412.png';
import Client_diff from './Client_diff';
import Image_fill from './Image_fill';
import btn_icon_1661811837 from './images/btn_icon_1661811837.png';
import Check_command from './Check_command';
import Packmat from './Packmat';
import Shedding from './Shedding';

export default class Picker_plein2 extends Component {

  // Properties used by this component:
  // flux, state, id_scan, quai, id_client, id_contenant, exutoire, checked, reference_ot, type, packmat, color, key

  constructor(props) {
    super(props);
    
    this.state = {
      flux: (<div>BOIS</div>),
      flux_plainText: "BOIS",
      state: (<div> </div>),
      state_plainText: " ",
      id_scan: (<div> </div>),
      id_scan_plainText: " ",
      quai: (<div> </div>),
      quai_plainText: " ",
      elDelete_visible: true,
  		state: this.props.state,
        id_scan: this.props.id_scan,
        order: false,
        to_delete: false,
        packmat_increment: false,
        shedding_increment: false,
    };
  }

  componentDidMount() {
    if (this.props.appActions.dataSlots['ds_selected_quai'] === this.props.quai && this.props.appActions.dataSlots['ds_scanned_id']) {
      //let newDs_quai = {...this.props.appActions.dataSlots['ds_selected_dech_quai_view']};
      //newDs_quai.hors_quais[this.props.quai].id_scan = this.props.appActions.dataSlots['ds_scanned_id'];
      //this.props.appActions.updateDataSlot('ds_selected_dech_quai_view', newDs_quai);
      this.setState({id_scan: this.props.appActions.dataSlots['ds_scanned_id']});
      //this.props.appActions.updateDataSlot('ds_scanned_id', "");
    } else if (this.props.appActions.dataSlots['ds_selected_quai'] !== this.props.quai && this.props.appActions.dataSlots['ds_scanned_id'] == this.state.id_scan){
      this.setState({id_scan: ''});
    }
    
    if (this.props.appActions.dataSlots['ds_mode_point'] === 1 && this.state.elDelete_visible == false) {
      this.setState({elDelete_visible: true});
    } else if (this.props.appActions.dataSlots['ds_mode_point'] != 1 && this.state.elDelete_visible == true) {
      this.setState({elDelete_visible: false});
    }
  }

  componentWillUnmount() {
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.state != prevProps.state || this.props.id_scan != prevProps.id_scan || this.props.order != prevProps.order) {
      this.setState({
        state: this.props.state,
        id_scan: this.props.id_scan,
        order: false,
      })
    }
    
    if (this.props.appActions.dataSlots['ds_mode_point'] === 1 && this.state.elDelete_visible == false) {
      this.setState({elDelete_visible: true});
    } else if (this.props.appActions.dataSlots['ds_mode_point'] != 1 && this.state.elDelete_visible == true) {
      this.setState({elDelete_visible: false});
    }
  }

  onClick_elImage_fill = (ev) => {
    let mode = parseInt(this.props.appActions.dataSlots['ds_mode_point']);
    
    if (mode === 0) {
    } else if (mode === 1) {
      // NIVEAUX
      let newState = this.props.id_contenant.includes('VIDE') ? 1 : ((parseInt(this.state.state) + 1)%6||2);
      this.setState({state: newState});
    } else if (mode === 2) {
      this.props.appActions.goToScreen('scanner', { transitionId: 'fadeIn' });
    } else if (mode === 3) {
      this.setState({order: !this.state.order});
    } else if (mode === 4) {
      // Prise en charge
      let getExutoire = (exut) => {
        let output = {};
        let exutoire = this.props.appActions.dataSheets['exutoires'].items.find(it => it.document_key === exut) || false;
        if (exutoire) {
          output['label'] = exutoire.label;
          output['document_key'] = exutoire.document_key;
          output['location_key'] = exutoire.location_key;
        }
        return output;
      }
    
      let getClientById = (id) => {
        return this.props.appActions.dataSheets['clients'].items.find(it => it.document_key == id);
      }
    
      let getContenantById = (id) => {
        return this.props.appActions.dataSheets['group_contenants'].items.find(it => it.document_key == id);
      }
    
      let getDriverById = (id) => {
        return this.props.appActions.dataSheets['drivers'].items.find(it => it.document_key == id);
      }
    
      let getTruckById = (id) => {
        return this.props.appActions.dataSheets['trucks'].items.find(it => it.document_key == id);
      }
    
      let getIdTourneeByDriverId = (driver_id) => {
        try {
          return this.props.appActions.dataSheets['id_tournees_history'].items.find(it => it.driver_id == driver_id).document_key;
        } catch (e) {
          return false;
        }
      }
    
      let item = {};
      let placeOrder = (pickerNiveau, dech_quai_view) => {
        try {
          let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key == this.props.appActions.dataSlots['ds_selected_tournee']);
    
          let date = new Date().setHours(0,0,0,0);
          let now = new Date();
    
          let client = getClientById(pickerNiveau.props.id_client);
          let contenant = getContenantById(pickerNiveau.props.id_contenant) || {};
          let truck = getTruckById(tournee.truck_id) || {};
          let driver = getDriverById(tournee.driver_id);
          let exutoire = contenant.exutoire;
    
          item = {
            action: contenant.default_action || 1,
            adr: contenant.adr || false,
            client: client.name,
            container_id: contenant.document_key || pickerNiveau.props.id_contenant,
            coordinates: contenant.coordinates || {
              lat: window.previousPos.coords.latitude, 
              lng: window.previousPos.coords.longitude,
            },
            date: date,
            default_action: contenant.default_action || 1,
            display_name: dech_quai_view.site,
            driver: driver.name,
            driver_id: driver.document_key,
            duration: contenant.duration || null,
            etat: 'chauffeur',
            exutoire: exutoire || pickerNiveau.props.exutoire || {'label': '', 'document_key': '', 'location_key': ''},
            flux: contenant.flux || (pickerNiveau.props.id_contenant.includes('VIDE') ? "VIDE" : pickerNiveau.props.flux),
            heavy: contenant.heavy || false,
            heure: "",
            hh: pickerNiveau.props.id_contenant.includes('VIDE') ? "VIDE" : "HQ",
            id_client: client.document_key,
            id_scan: pickerNiveau.state.id_scan || "",
            ouverture: contenant.ouverture || {},
            packmat: pickerNiveau.props.packmat || 0,
            photo: "",
            position: 0,
            quai: 0,
            remark: "",
            report: date,
            site: dech_quai_view.document_key,
            sort_key: client.name + "_" + date,
            state: pickerNiveau.props.state || 0,
            statut : 9,
            tournee: tournee.document_key,
            truck: truck.imat || "",
            truck_id: truck.document_key || "",
            ts: now.getTime(),
            type: contenant.type || (pickerNiveau.props.id_contenant.includes('VIDE') ? pickerNiveau.props.flux : pickerNiveau.props.type || ""),
            user_id: this.props.appActions.dataSlots['ds_userid'],
            user_name: this.props.appActions.dataSlots['ds_username'],
            zfe: contenant.zfe || false,      
          }
    
          console.log(item);
          return item;
    
        } catch (error) {
          console.log(error);
          window.alert("Impossible de créer cette commande.");
          return;
        }
      }
    
      let dech_quai_view = this.props.appActions.dataSheets['quaiView'].items.filter(it => {
        return it.document_key === this.props.appActions.dataSlots['ds_selected_dech_quai_view'].document_key
      })[0];
    
      let commands = this.props.appActions.dataSheets['tournees_j'].items
      .concat(this.props.appActions.dataSheets['tournees_j1'].items);
      let command = commands.filter(it => it.document_key === this.props.reference_ot)[0];
    
      if (command) {
        if (command.tournee) {
          // commande déja affectée
          let conf = window.confirm('Attention cette commande est déjà attribuée à ' + command.driver + '. Continuer?');
          if (conf) {
            command.id_scan = this.state.id_scan||"";
            command.state = this.state.state||1;
            command.packmat = this.props.packmat||0;
            this.props.appActions.updateDataSlot('ds_selected_command', command);
            this.props.appActions.updateDataSlot('ds_client_id', command.id_client);
            this.props.appActions.goToScreen('priseEnChargeV3', { transitionId: 'fadeIn' });
          } else {
            return;
          }
        } else {
          // commande existante non affectée
          command.id_scan = this.state.id_scan||"";
          command.state = this.state.state||1;
          command.packmat = this.props.packmat||0;
          this.props.appActions.updateDataSlot('ds_selected_command', command);
          this.props.appActions.updateDataSlot('ds_client_id', command.id_client);
          this.props.appActions.goToScreen('priseEnChargeV3', { transitionId: 'fadeIn' });
        } 
      } else {
        //pas de commande
        let pre_command = placeOrder(this, dech_quai_view);
        pre_command.id_scan = this.state.id_scan||"";
        pre_command.state = this.state.state||0;
        pre_command.packmat = this.props.packmat||0;
        this.props.appActions.updateDataSlot('ds_selected_command', pre_command);
        this.props.appActions.updateDataSlot('ds_client_id', pre_command.id_client);
        this.props.appActions.goToScreen('priseEnChargeV3', { transitionId: 'fadeIn' });
      }
    } else if (mode === 5 && !this.props.id_contenant.includes('VIDE')) {
      // packmat
      if (this.state.packmat_increment == false) {
        this.setState({packmat_increment: true});
      } else if (this.state.packmat_increment == true) {
        if (this.state.state > 2) {
          let newState = this.state.state-1;
          this.setState({
            packmat_increment: true,
            state: newState,
          });
        } else {
          let newState = this.props.state;
          this.setState({
            packmat_increment: 2,
            state: this.props.state,
          });
        }
      } else if (this.state.packmat_increment == 2) {
        let newState = this.props.state;
        this.setState({
          packmat_increment: false,
          state: this.props.state,
        });
      }  
    } else if (mode === 7) {
      // delester
      if (this.state.shedding_increment == false) {
        this.setState({shedding_increment: true});
      } else if (this.state.shedding_increment == true) {
        if (this.state.state > 1) {
          let newState = this.state.state-1;
          this.setState({
            shedding_increment: true,
            state: newState,
          });
        } else {
          let newState = this.props.state;
          this.setState({
            shedding_increment: false,
            state: this.props.state,
          });
        }
      }
    } else if (mode === 9 && this.state.id_scan) {
      this.props.appActions.updateDataSlot('ds_selected_command', null);
      this.props.appActions.updateDataSlot('ds_scanned_id', this.state.id_scan);
      this.props.appActions.goToScreen('anomalie', { transitionId: 'fadeIn' });
      //this.props.appActions.goToScreen('scannerAnomalie', { transitionId: 'fadeIn' });
    }
    
  
    let newVal = this.props.quai;
    this.props.appActions.updateDataSlot('ds_selected_quai', newVal);
  
  }
  
  
  onClick_elDelete = (ev) => {
    this.setState({to_delete: !this.state.to_delete});
    
  
  }
  
  
  onClick_elPackmat_increment = (ev) => {
    let rights_app = this.props.appActions.dataSheets['auth_user'].items[0].rights_app;
    
    if (this.props.appActions.dataSlots['ds_mode_point']==1 && rights_app.ORDER_FLASH) {
      if (this.state.state >= 4) {
        this.setState({packmat_increment: !this.state.packmat_increment});
      } else {
        this.setState({packmat_increment: false});
      }
    }
  
  }
  
  
  render() {
    let transformFillColorPropValue_card = (input) => {
      return input===1 ? "#FFFB00": "white";
    }
    const fillColorValue_card = transformFillColorPropValue_card(this.props.checked);
    const style_elCard = {
      width: '100%',
      height: '100%',
     };
    const style_elCard_outer = {
      backgroundColor: (fillColorValue_card && fillColorValue_card.length > 0) ? fillColorValue_card : 'white',
     };
    const style_elN0_0 = {
      backgroundImage: 'url('+img_elN0_0+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN0_2 = {
      backgroundImage: 'url('+img_elN0_2+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN0_3 = {
      backgroundImage: 'url('+img_elN0_3+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elN0_4 = {
      backgroundImage: 'url('+img_elN0_4+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    
    const value_flux = this.props.flux;
    
    const style_elFlux = {
      fontSize: 14.1,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    let transformPropValue_state = (input) => {
      let state = "";
      if (this.state.state == 0 || this.props.id_contenant === 'VIDE') {
        state = "-";
      } else {
        state = (this.state.state-1)*25 + "%";
      }
      if (this.props.reserve) {state += " (R)"};
      return state;
    }
    
    const value_state = transformPropValue_state(this.props.state);
    
    const style_elState = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'center',
     };
    let transformStateValue_elClient_diff = (input) => {
      if (input.id_client === this.props.id_client || !this.props.id_client) {
        return 0;
      } else {
        return 1;
      }
    
    }
    
    let transformValue_state_elImage_fill = (input) => {
      if (this.state.to_delete) {
        return 0;
      } else if (this.props.id_contenant == "VIDE") {
        return 1;
      } else {
        return this.state.state || 0;
        //return 5;
      }
    }
    
    let transformValue_type_elImage_fill = (input) => {
      let getContenantById = (id) => {
        return this.props.appActions.dataSheets['group_contenants'].items.find(it => it.document_key == id);
      }
      
      let getFluxByMatiere = (matiere) => {
        return this.props.appActions.dataSheets['matieres'].items.find(it => it.matiere == matiere);
      }
      
      let getMaterialByType = (type) => {
        return this.props.appActions.dataSheets['materiels'].items.find(it => it.type == type);
      }
      
      
      if (this.props.id_contenant == "VIDE") {
        let type = (getMaterialByType(this.props.flux)||{}).code_shape||"30";
        return type;
      } else {
        let contenant = getContenantById(this.props.id_contenant) || {};
        let type = (getMaterialByType(contenant.type)||{}).code_shape||"30";
        return type;
      }
    }
    
    let transformValue_color_elImage_fill = (input) => {
      let getContenantById = (id) => {
        return this.props.appActions.dataSheets['group_contenants'].items.find(it => it.document_key == id);
      }
      
      let getFluxByMatiere = (matiere) => {
        return this.props.appActions.dataSheets['matieres'].items.find(it => it.matiere == matiere);
      }
      
      let getMaterialByType = (type) => {
        return this.props.appActions.dataSheets['materiels'].items.find(it => it.type == type);
      }
      
      if (this.state.to_delete) {
        return "#0433FF";
      } else {
        return (getFluxByMatiere(this.props.flux)||{}).color || "#000000";
      }
    }
    const style_elImage_fill = {
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_id_scan = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return this.state.id_scan;
    }
    let transformCustomCSSPropValue_id_scan = (input) => {
      let style = '"borderRadius": "5px"';
      try {
        let container = this.props.appActions.dataSheets['reference_containers'].items.find(it => it.id_scan === this.state.id_scan);
        if (container.unresolved_anomalies_labels.length) {
          style += ',"backgroundColor": "#FFD479"';
        } else {
          if (this.state.id_scan) {
            style += ',"backgroundColor": "#EBEBEB"';
          }
        } 
      } catch (e) {
        if (this.state.id_scan) {
          style += ',"backgroundColor": "#EBEBEB"';
        }
      }
      
      return style;
    }
    
    const value_id_scan = transformPropValue_id_scan(this.props.id_scan);
    
    const style_elId_scan = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'center',
      ...(() => {  // append customCSS property of 'id_scan'
        let val = transformCustomCSSPropValue_id_scan(this.props.id_scan);
        if (val == null || val.length == 0) return null;
        try {
          return JSON.parse('{'+val+'}');
        } catch (error) {
          console.log(error);
        }
        return null;
      })(),
     };
    let transformPropValue_quai = (input) => {
      return input;
    }
    
    const value_quai = transformPropValue_quai(this.props.quai);
    
    const style_elQuai = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
      display: 'none',
     };
    let transformPropValue_delete = (input) => {
      return input === 1 ? true : false;
    }
    
    const enabledValue_delete = transformPropValue_delete((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_mode_point'] : ''));
    
    const style_elDelete = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_1661811837+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elDelete = this.state.elDelete_visible ? (
      <button className="actionFont elDelete" style={style_elDelete}  disabled={''+enabledValue_delete !== 'true'} onClick={this.onClick_elDelete}  />
     ) : null;
    let transformStateValue_elCheck_command = (input) => {
      if (this.state.order) {
        return 4;
      } else {
        return input;
      }
    }
    
    let transformValue_packmat_elPackmat_display = (input) => {
      // This function modifies the value for property 'packmat'.
      // There is a variable named 'input' that provides the property value.
      //
      return input;
    
    }
    let transformVisiblePropValue_packmat_display = (input) => {
      return (input > 0 && this.state.packmat_increment != true) ? true : false;
    }
    const elPackmat_display = ((val) => { return val === "true" || val == true || val == 1 })(transformVisiblePropValue_packmat_display(this.props.packmat)) ? (
      <div className="hasNestedComps elPackmat_display">
        <Packmat packmat={transformValue_packmat_elPackmat_display(this.props.packmat)} visualStateIndex={2} ref={(el)=> this._elPackmat_display = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    let transformValue_packmat_elPackmat_increment = (input) => {
      // This function modifies the value for property 'packmat'.
      // There is a variable named 'input' that provides the property value.
      //
      return this.state.packmat;
    
    }
    let transformStateValue_elPackmat_increment = (input) => {
      let rights_app = this.props.appActions.dataSheets['auth_user'].items[0].rights_app;
      
      if (this.state.packmat_increment === 2) {
        return 6;
      } else if (this.state.packmat_increment) {
        return 1;
      } else {
        return 0;
      }
    }
    const style_elPackmat_increment = {
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformVisiblePropValue_packmat_increment = (input) => {
      let rights_app = this.props.appActions.dataSheets['auth_user'].items[0].rights_app;
      
      if (this.state.packmat_increment) {
        return true;
      } else {
        return false;
      }
    }
    const elPackmat_increment = ((val) => { return val === "true" || val == true || val == 1 })(transformVisiblePropValue_packmat_increment(this.props.packmat)) ? (
      <div className="hasNestedComps elPackmat_increment" style={style_elPackmat_increment} onClick={this.onClick_elPackmat_increment} >
        <Packmat packmat={transformValue_packmat_elPackmat_increment(this.props.packmat)} visualStateIndex={transformStateValue_elPackmat_increment(this.props.packmat)} ref={(el)=> this._elPackmat_increment = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    let transformStateValue_elShedding = (input) => {
      return this.state.shedding_increment ? 1 : 0;
    }
    let transformVisiblePropValue_shedding = (input) => {
      return this.state.shedding_increment ? true : false;
    }
    const elShedding = ((val) => { return val === "true" || val == true || val == 1 })(transformVisiblePropValue_shedding(this.props.exutoire)) ? (
      <div className="hasNestedComps elShedding">
        <Shedding visualStateIndex={transformStateValue_elShedding(this.props.quai)} ref={(el)=> this._elShedding = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    return (
      <div className="Picker_plein2">
        <div className="background">
          <div className="containerMinHeight elCard" style={style_elCard_outer}>
            <div style={style_elCard} />
          </div>
        </div>
        
        <div className="layoutFlow">
          <div className="flowRow flowRow_Picker_plein2_elN0_0_1628670179">
          <div className="elN0_0">
            <div style={style_elN0_0} />
          </div>
          
          <div className="elN0_2">
            <div style={style_elN0_2} />
          </div>
          
          <div className="elN0_3">
            <div style={style_elN0_3} />
          </div>
          
          <div className="elN0_4">
            <div style={style_elN0_4} />
          </div>
          
          </div>
        </div>
        
        <div className="foreground">
          <div className="systemFontBold  elFlux" style={style_elFlux}>
            <div>{value_flux !== undefined ? value_flux : (<span className="propValueMissing">{this.state.flux}</span>)}</div>
          </div>
          <div className="baseFont elState" style={style_elState}>
            <div>{value_state !== undefined ? value_state : (<span className="propValueMissing">{this.state.state}</span>)}</div>
          </div>
          <div className="hasNestedComps elClient_diff">
            <Client_diff visualStateIndex={transformStateValue_elClient_diff((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_dech_quai_view'] : ''))} ref={(el)=> this._elClient_diff = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
          </div>
          <div className="hasNestedComps elImage_fill" style={style_elImage_fill} onClick={this.onClick_elImage_fill} >
            <Image_fill state={transformValue_state_elImage_fill(this.props.state)} type={transformValue_type_elImage_fill(this.props.id_contenant)} color={transformValue_color_elImage_fill(this.props.flux) || "#000000"} ref={(el)=> this._elImage_fill = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
          </div>
          <div className="baseFont elId_scan" style={style_elId_scan}>
            <div>{value_id_scan !== undefined ? value_id_scan : (<span className="propValueMissing">{this.state.id_scan}</span>)}</div>
          </div>
          <div className="headlineFont elQuai" style={style_elQuai}>
            <div>{value_quai !== undefined ? value_quai : (<span className="propValueMissing">{this.state.quai}</span>)}</div>
          </div>
          { elDelete }
          <div className="hasNestedComps elCheck_command">
            <Check_command visualStateIndex={transformStateValue_elCheck_command(this.props.checked)} ref={(el)=> this._elCheck_command = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
          </div>
          { elPackmat_display }
          { elPackmat_increment }
          { elShedding }
        </div>
      </div>
    )
  }
  
}
