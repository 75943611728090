import React, { Component } from 'react';
import './App.css';
import Declared_anomalie from './Declared_anomalie';
import Anomalie_cat from './Anomalie_cat';
import btn_icon_797537300 from './images/btn_icon_797537300.png';
import btn_icon_179700239 from './images/btn_icon_179700239.png';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class Anomalies_explorer2 extends Component {

  // Properties used by this component:
  // anomalies, appActions, label

  constructor(props) {
    super(props);
    
    this.state = {
      elAdd_visible: false,
      current_path: [],
    };
  }
  
  handleClickCat = (category) => {this.state.current_path.push(category)};

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
    if (this.state.current_path.length > 2 && this.state.elAdd_visible == false) {
      this.setState({elAdd_visible: true})
    } else if (this.state.current_path.length <= 2 && this.state.elAdd_visible == true) {
      this.setState({elAdd_visible: false})
    }
  }

  onClick_elAdd = (ev) => {
    let path = this.state.current_path.slice(1, this.state.current_path.length).join(' > ');
    if (path.length) {
      //let push_path = true;
      let commentaire = [];
      this._elList_items.forEach(it => {
        if (it.props.isLeaf && it.state.checkbox=="true") {
          commentaire.push(it.props.label);
          //this.props.appActions.dataSlots['ds_current_anomalies'].push(path+' > '+it.props.label);
          //push_path = false;
        }
      })
      if (commentaire.length) {
        path += " (" + commentaire.join(', ') + ')';
      }
    }
    this.props.appActions.dataSlots['ds_current_anomalies'].push(path);
    
  
    let newVal = "0";
    this.props.appActions.updateDataSlot('ds_select_anomalies_visible', newVal);
  
  }
  
  
  onClick_elList = (ev) => {
    setTimeout(() => {this.setState(this.state)}, 100);
  
  }
  
  
  onClick_elBack = (ev) => {
    this.state.current_path.pop();
  
  }
  
  
  onClick_elRm = (ev) => {
    let newVal = "0";
    this.props.appActions.updateDataSlot('ds_select_anomalies_visible', newVal);
  
  }
  
  
  render() {
    
    const style_elAdd = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elAdd = this.state.elAdd_visible ? (
      <Button className="actionFont elAdd" style={style_elAdd}  color="accent" onClick={this.onClick_elAdd} >
        Ajouter
      </Button>
     ) : null;
    
    let transformValue_label_elDeclared_anomalie = (input) => {
      let path = this.state.current_path.slice(1, this.state.current_path.length).join(' > ');
      if (path.length) {
        let commentaire = [];
        this._elList_items.forEach(it => {
          if (it.props.isLeaf && it.state.checkbox=="true") {
            commentaire.push(it.props.label);
          }
        })
        if (commentaire.length) {
        	path += " (" + commentaire.join(', ') + ')';
        }
      }
      
      return path;
    }
    let transformVisiblePropValue_declared_anomalie = (input) => {
      return this.state.current_path.length > 2;
    }
    const elDeclared_anomalie = ((val) => { return val === "true" || val == true || val == 1 })(transformVisiblePropValue_declared_anomalie(this.props.appActions)) ? (
      <div className="hasNestedComps elDeclared_anomalie">
        <Declared_anomalie label={transformValue_label_elDeclared_anomalie(this.props.appActions)} visualStateIndex={1} ref={(el)=> this._elDeclared_anomalie = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    let transformPropValue_list = (input) => {
      let schema = this.props.appActions.dataSheets['static'].items[0].anomalies_schema;
      
      let items = schema.items;
      
      this.state.current_path.forEach(slug => {
        items = items.find(it => it.label == slug).items;
      })
      
      return items.map(it => {
        //let nb_anomalies = input.filter(a => a.includes(this.state.current_path.concat([it.label]).join(' > '))).length;
        return {...it, display: it.label, handleClickCat: this.handleClickCat, isLeaf: (it.items?false:true)}
      });
    }
    
    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    if (Array.isArray(transformPropValue_list(this.props.anomalies))) {
        items_list = items_list.concat(transformPropValue_list(this.props.anomalies));
    } else if (transformPropValue_list(this.props.anomalies)) {
        items_list.push(transformPropValue_list(this.props.anomalies));
    }
    this._elList_items = [];
    
    const style_elList = {
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_text = (input) => {
      if (this.state.current_path.length == 0) {
        return "Nouvelle anomalie";
      } else if (this.state.current_path.length == 1) {
        return this.state.current_path[0];
      } else if (this.state.current_path.length == 2) {
        return this.state.current_path[1];
      } else if (this.state.current_path.length == 3) {
        return "Commentaire (facultatif)";
      } else {
        return this.state.current_path.slice(1, this.state.current_path.length).join(' > ');
      }
    }
    let transformCustomCSSPropValue_text = (input) => {
      return '"display": "flex", "alignItems": "center"';
    }
    
    const value_text = transformPropValue_text(this.props.appActions);
    
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
      ...(() => {  // append customCSS property of 'text'
        let val = transformCustomCSSPropValue_text(this.props.appActions);
        if (val == null || val.length == 0) return null;
        try {
          return JSON.parse('{'+val+'}');
        } catch (error) {
          console.log(error);
        }
        return null;
      })(),
     };
    
    const style_elBack = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_797537300+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '62.500%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elRm = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_179700239+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Anomalies_explorer2">
        <div className="foreground">
          { elAdd }
          { elDeclared_anomalie }
          <div className="hasNestedComps elList">
            <ul style={style_elList} onClick={this.onClick_elList} >
              {items_list.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list[row._componentId]
                    : <Anomalie_cat appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} {...{ 'label': row['label'], 'checked': row['checked'], 'handleClickCat': row['handleClickCat'], 'isLeaf': row['isLeaf'], 'display': row['display'], }} ref={(el) => {if (el) this._elList_items.push(el)}} />;
                return (<li key={row.key}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._elList_endMarker = el} />
            </ul>
          </div>
          
          <div className="baseFont elText" style={style_elText}>
            <div>{value_text !== undefined ? value_text : (<span className="propValueMissing">{this.state.text}</span>)}</div>
          </div>
          <button className="actionFont elBack" style={style_elBack} onClick={this.onClick_elBack}  />
          <button className="actionFont elRm" style={style_elRm} onClick={this.onClick_elRm}  />
        </div>
      </div>
    )
  }
  
}
