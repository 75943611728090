import React, { Component } from 'react';
import './App.css';
import img_elCheck from './images/ConfirmationAnomalieScreen_elCheck_1739166588.png';
import img_elImage from './images/Image_fill_elImage_back_1322448678.png';

// UI framework component imports
import Button from 'muicss/lib/react/button';
import Appbar from 'muicss/lib/react/appbar';

export default class ConfirmationAnomalieScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);
    
    this.state = {
      provenance: (<div>L’anomalie suivante a bien été déclarée</div>),
      provenance_plainText: "L’anomalie suivante a bien été déclarée",
      matricule_txt: (<div>Matricule</div>),
      matricule_txt_plainText: "Matricule",
      matricule: (<div>Matricule</div>),
      matricule_plainText: "Matricule",
      commentaire_txt: (<div>Commentaire</div>),
      commentaire_txt_plainText: "Commentaire",
      commentaire: (<div> </div>),
      commentaire_plainText: " ",
      date_txt: (<div>Date</div>),
      date_txt_plainText: "Date",
      date: (<div>1/11/2023, 1:51:20 PM</div>),
      date_plainText: "1/11/2023, 1:51:20 PM",
      photo_txt: (<div>Photo</div>),
      photo_txt_plainText: "Photo",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elButton = (ev) => {
    let newVal = "";
    this.props.appActions.updateDataSlot('ds_scanned_id', newVal);
  
    newVal = "";
    this.props.appActions.updateDataSlot('ds_saved_picture', newVal);
  
    newVal = "";
    this.props.appActions.updateDataSlot('ds_current_anomalies', newVal);
  
    newVal = "";
    this.props.appActions.updateDataSlot('ds_commentaire', newVal);
  
    // Go to screen based on value in data slot 'ds_source_anomalie'
    this.props.appActions.goToScreen(this.targetTable_switch_anomalie_valid((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_source_anomalie'] : '')), { transitionId: 'fadeIn' });
  
  }
  
  
  targetTable_switch_anomalie_valid = (targetId) => {
    if (targetId === 'tour')
      return 'quai_view_dech';
    else if (targetId === 'solve')
      return 'tournéeDuJour';
    else if (targetId === 'menu')
      return 'quai_view_dech';
    else if (targetId === 'truck')
      return 'chargeDuCamion';
  }
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };
    const style_elCheck = {
      backgroundImage: 'url('+img_elCheck+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    const style_elProvenance = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elMatricule_txt = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_matricule = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      // Handy example code snippets
      //
      // Update current component’s State from 0 to n
      //
      // this.setState({visualStateIndexOverride: 1})
      //
      // Playing with Data slot values
      //
      // Get data slot value
      //
      // var exampleValue="";
      // exampleValue=this.props.appActions.dataSlots['ds_Example'];
      //
      // Update data slot value
      //
      // var exampleValue="foo";
      // this.props.appActions.updateDataSlot('ds_Example', exampleValue);
      
      return input || "Matricule";
    }
    
    const value_matricule = transformPropValue_matricule((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_scanned_id'] : ''));
    
    const style_elMatricule = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elCommentaire_txt = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_commentaire = (input) => {
      return input.join('<br>');
    }
    
    const value_commentaire = transformPropValue_commentaire((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_current_anomalies'] : ''));
    
    const style_elCommentaire = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elDate_txt = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_date = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      let now = new Date();
      return now.toLocaleString();
    }
    
    const value_date = transformPropValue_date((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_commentaire'] : ''));
    
    const style_elDate = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elPhoto_txt = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elImage = {
      backgroundImage: 'url('+((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_saved_picture'] : '') || img_elImage)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    
    const style_elButton = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen ConfirmationAnomalieScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elSpacer">
            <div />
          </div>
          
          <div className="flowRow flowRow_ConfirmationAnomalieScreen_elCheck_1739166588">
          <div className="elCheck">
            <div style={style_elCheck} />
          </div>
          
          </div>
          <div className="elProvenance">
            <div className="headlineFont" style={style_elProvenance}>
              <div>{this.state.provenance}</div>
            </div>
          </div>
          
          <div className="elMatricule_txt">
            <div className="baseFont" style={style_elMatricule_txt}>
              <div>{this.state.matricule_txt}</div>
            </div>
          </div>
          
          <div className="elMatricule">
            <div className="baseFont" style={style_elMatricule}>
              <div>{value_matricule !== undefined ? value_matricule : (<span className="propValueMissing">{this.state.matricule}</span>)}</div>
            </div>
          </div>
          
          <div className="elCommentaire_txt">
            <div className="baseFont" style={style_elCommentaire_txt}>
              <div>{this.state.commentaire_txt}</div>
            </div>
          </div>
          
          <div className="elCommentaire">
            <div className="baseFont" style={style_elCommentaire}>
              <div><div dangerouslySetInnerHTML={{__html: value_commentaire}}></div></div>
            </div>
          </div>
          
          <div className="elDate_txt">
            <div className="baseFont" style={style_elDate_txt}>
              <div>{this.state.date_txt}</div>
            </div>
          </div>
          
          <div className="elDate">
            <div className="baseFont" style={style_elDate}>
              <div>{value_date !== undefined ? value_date : (<span className="propValueMissing">{this.state.date}</span>)}</div>
            </div>
          </div>
          
          <div className="elPhoto_txt">
            <div className="baseFont" style={style_elPhoto_txt}>
              <div>{this.state.photo_txt}</div>
            </div>
          </div>
          
          <div className="elImage">
            <div style={style_elImage} />
          </div>
          
          <div className="elButton">
            <Button className="actionFont" style={style_elButton}  color="accent" onClick={this.onClick_elButton} >
              Valider
            </Button>
          </div>
          
          <div className="elSpacerCopy">
            <div />
          </div>
        </div>
        <Appbar className="navBar">
          <div className="title">Anomalie</div></Appbar>
        
      </div>
    )
  }
  
}
