import React, { Component } from 'react';
import './App.css';
import Picker_depot2 from './Picker_depot2';
import btn_icon_2136812680 from './images/btn_icon_2136812680.png';
import btn_icon_1822855885 from './images/btn_icon_1822855885.png';
import Apercu from './Apercu';

// UI framework component imports
import Button from 'muicss/lib/react/button';
import Checkbox from 'muicss/lib/react/checkbox';
import Select from 'muicss/lib/react/select';
import Option from 'muicss/lib/react/option';
import Appbar from 'muicss/lib/react/appbar';

export default class DepotV3Screen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);
    
    this.state = {
      elList_visible: false,
      elValider_visible: true,
      elCheckbox_quai_visible: true,
      checkbox_quai: 'false',
      elCheckbox_hors_quai_visible: true,
      checkbox_hors_quai: 'true',
      elPicker_producer_visible: false,
      picker_producer: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_client_id'] : ''),
      elPicker_dech_visible: true,
      picker_dech: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_client_id'] : ''),
      checkbox_dech: 'true',
      checkbox_autres: 'false',
      text: (<div>Lieu de dépot</div>),
      text_plainText: "Lieu de dépot",
      notif: (<div> </div>),
      notif_plainText: " ",
    };
  }

  componentDidMount() {
    {
      let dataSheet = this.props.appActions.dataSheets['reference_containers'];
      let serviceOptions = this.props.appActions.serviceOptions_reference_containers;
      if ( !this.props.appActions.dataSheetLoaded['reference_containers']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/all_containers", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['reference_containers'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['quaiViewProducers'];
      let serviceOptions = this.props.appActions.serviceOptions_quaiViewProducers;
      if ( !this.props.appActions.dataSheetLoaded['quaiViewProducers']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/quaiView", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['quaiViewProducers'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['quaiView'];
      let serviceOptions = this.props.appActions.serviceOptions_quaiView;
      if ( !this.props.appActions.dataSheetLoaded['quaiView']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/quaiView", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['quaiView'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['quaiViewRights'];
      let serviceOptions = this.props.appActions.serviceOptions_quaiViewRights;
      if ( !this.props.appActions.dataSheetLoaded['quaiViewRights']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/quaiView", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['quaiViewRights'] = true;
      }
    }
    let new_state = {};
    
    let selected_command = this.props.appActions.dataSlots['ds_selected_command']||{};
    if (Object.keys(selected_command).length) {
      let client_is_dech = this.props.appActions.dataSheets['quaiViewRights'].items.find(it => it.id_client == (this.state.picker_dech || this.state.picker_producer));
      if (client_is_dech) {
        new_state.elPicker_dech_visible= true;
        new_state.elPicker_producer_visible= false;
        new_state.picker_dech= selected_command.id_client;
        new_state.picker_producer= null;
        new_state.checkbox_autre= 'false';
        new_state.checkbox_dech= 'true';
      } else {
        new_state.elPicker_dech_visible= false;
        new_state.elPicker_producer_visible= true;
        new_state.picker_dech= null;
        new_state.picker_producer= selected_command.id_client;
        new_state.checkbox_autre= 'true';
        new_state.checkbox_dech= 'false';
      }
    }

    // hors quai available
    let client = this.props.appActions.dataSheets['clients_depots'].items.find(it => it.document_key == (this.state.picker_dech || this.state.picker_producer))||{};
    if (client.place_type == 1 || client.place_type == 0) {
      new_state = {...new_state, 
        elCheckbox_hors_quai_visible: true,
        elCheckbox_quai_visible: true,
        checkbox_hors_quai: (selected_command.action == 4 && selected_command.ts_vidage) ? "false": "true",
        checkbox_quai: (selected_command.action == 4 && selected_command.ts_vidage) ? "true": "false",
        elList_visible: (selected_command.action == 4 && selected_command.ts_vidage) ? true: false,
        elValider_visible: (selected_command.action == 4 && selected_command.ts_vidage) ? false: true,
      }
    } else if (client.place_type == 3) {
      new_state = {...new_state, 
        elCheckbox_hors_quai_visible: true,
        checkbox_hors_quai: "true",
        elCheckbox_quai_visible: false,
        checkbox_quai: "false",
        elList_visible: false,
      	elValider_visible: true,
      }
    } else {
      new_state = {...new_state, 
        elCheckbox_hors_quai_visible: false,
        checkbox_hors_quai: selected_command.ts_vidage ? "false" : "true",
        elCheckbox_quai_visible: false,
        checkbox_quai: selected_command.ts_vidage ? "true" : "false",
        elList_visible: false,
      	elValider_visible: false,
      }
    }
    if (Object.keys(new_state).some(k => this.state[k] !== new_state[k])) {
      this.setState(new_state);
    };
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
    
    let new_state = {};
    
    let selected_command = this.props.appActions.dataSlots['ds_selected_command']||{};
    
    // hors quai available
    let client = this.props.appActions.dataSheets['clients_depots'].items.find(it => it.document_key == (this.state.picker_dech || this.state.picker_producer))||{};
    if (client.place_type == 1 || client.place_type == 0) {
      new_state = {
        elCheckbox_hors_quai_visible: true,
        elCheckbox_quai_visible: true,
      }
      if (this.state.checkbox_quai == "true" || this.state.checkbox_quai == true) {
        new_state.elList_visible = true;
        new_state.elValider_visible = false;
      } else if (this.state.checkbox_hors_quai == "true" || this.state.checkbox_hors_quai == true) {
        new_state.elList_visible = false;
        new_state.elValider_visible = true;
      } 
    } else if (client.place_type == 3) {
      new_state = {
        elCheckbox_hors_quai_visible: true,
        checkbox_hors_quai: "true",
        elCheckbox_quai_visible: false,
        checkbox_quai: "false",
        elList_visible: false,
      	elValider_visible: true,
      }
    } else {
      new_state = {
        elCheckbox_hors_quai_visible: false,
        checkbox_hors_quai: selected_command.ts_vidage ? "false" : "true",
        elCheckbox_quai_visible: false,
        checkbox_quai: selected_command.ts_vidage ? "true" : "false",
        elList_visible: false,
        elValider_visible: false,
      }
    }
    if (Object.keys(new_state).some(k => this.state[k] !== new_state[k])) {
      this.setState(new_state);
    };
  }

  onClick_elValider = (ev) => {
    let a = window.confirm("Déposer hors quai?")
    if (!a) {
      return;
    }
    
    
    let checkOperation = (command) => {
      if (!command.ts_vidage && (command.action === 1 || command.action === 4 || command.action === 5)) {
        if (command.hh=='VIDE' || this.props.appActions.dataSlots['ds_selected_command'].id_client !== this.props.appActions.dataSlots['ds_client_id']) {
          return 6;
        } else {
          return 0;
        }
      } else {
        return command.action;
      }
    }
    
    let ts = new Date();
    ts =  ts.getTime();
    
    let client_depot = this.props.appActions.dataSheets['clients'].items.filter(it => it.document_key == (this.state.picker_dech || this.state.picker_producer))[0] || {};
    
    let successDepot = () => {
      let update_ot = {
        document_ref: this.props.appActions.dataSlots['ds_selected_command'].document_ref,
        document_key: this.props.appActions.dataSlots['ds_selected_command'].document_key,
        ts_depot: ts,
        coordinates_depot: {
          lat: window.previousPos.coords.latitude, 
          lng: window.previousPos.coords.longitude,
        },
        user_id_depot: this.props.appActions.dataSlots['ds_userid'],
        user_name_depot: this.props.appActions.dataSlots['ds_username'],
        statut: 1, // ot validé et en attente de validation
        site_depot_name: client_depot.name,
        site_depot: client_depot.document_key,
        site_depot_quai: 0,
        site_depot_hq: true,
        action_realise: checkOperation(this.props.appActions.dataSlots['ds_selected_command']),
      }
      this.props.appActions.updateDataSlot('ds_selected_command', {...this.props.appActions.dataSlots['ds_selected_command'], ...update_ot});
      this.props.appActions.dataSheets['tournees_j'].replaceItemByRowIndex(update_ot.document_key, update_ot, this.props.appActions.serviceOptions_tournees_j);
    }
    
    let logHistoryMove = () => {
      let otRow = this.props.appActions.dataSlots['ds_selected_command'];
      let update = {
        coordinates: {
          lat: window.previousPos.coords.latitude, 
          lng: window.previousPos.coords.longitude,
        },
        flux: otRow.flux_realise,
        hq: true,
        id_scan: otRow.id_scan,
        id_client: (this.state.picker_dech || this.state.picker_producer),
        id_contenant: otRow.container_id || "",
        move_type: "DEPOT",
        quai: 0,
        reference_ot: otRow.document_key || "",
        state: this.props.appActions.dataSlots['ds_selected_command'].state||0,
        site: client_depot.name,
        tournee: this.props.appActions.dataSlots['ds_selected_command'].tournee||"",
        ts: ts,
        user_id: this.props.appActions.dataSlots['ds_userid'],
        user_name: this.props.appActions.dataSlots['ds_username'],
        vide: otRow.ts_vidage > 0 || otRow.flux_realise.includes("VIDE") ? true : false,
      };
      this.props.appActions.dataSheets['history_moves'].addItem(update, this.props.appActions.serviceOptions_history_moves);
    }
    
    successDepot();
    logHistoryMove();
    
    /////////////////////////// UPDATE TRUCK LOAD /////////////////
    try {
      let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(t => t.document_key == this.props.appActions.dataSlots['ds_selected_command'].tournee);
      let update = {
        document_key: tournee.document_key,
        document_ref: tournee.document_ref,
        loaded: false,
        loaded_is_full: false,
        load_detail: {},
      };
      this.props.appActions.dataSheets['id_tournees_j'].replaceItemByRowIndex(tournee.document_key, update, this.props.appActions.serviceOptions_id_tournees_j);
    } catch (e) {
      console.log(e);
    }
    
    // UPDATE QUAI VIEW (POSE HORS QUAI)
    let dech_quai_view = this.props.appActions.dataSheets['quaiView'].items.filter(it => {
      return it.id_client === this.props.appActions.dataSlots['ds_client_id'];
    })[0];
    
    // commander le vdiff hq ?
    let placeNewOrder = false;
    
    if (dech_quai_view) {
      let new_container = {
        id_scan: this.props.appActions.dataSlots['ds_selected_command'].id_scan,
        id_client: this.props.appActions.dataSlots['ds_client_id'],
        id_contenant: this.props.appActions.dataSlots['ds_selected_command'].container_id,
        id_tournee: this.props.appActions.dataSlots['ds_selected_command'].tournee,
        flux: this.props.appActions.dataSlots['ds_selected_command'].flux_realise,
        packmat: this.props.appActions.dataSlots['ds_selected_command'].packmat || 0,
        state: this.props.appActions.dataSlots['ds_selected_command'].state||1,
        type: this.props.appActions.dataSlots['ds_selected_command'].type,
      };
      
      // reset last dropped
      let index = dech_quai_view.hors_quais.findIndex(q => q.id_tournee == this.props.appActions.dataSlots['ds_selected_command'].tournee);
      if (index > -1) {
        dech_quai_view.hors_quais[index].id_tournee = "";
      }
    
      if (this.props.appActions.dataSlots['ds_selected_command'].ts_vidage) {
        new_container.id_contenant = "VIDE";
        new_container.packmat = 0;
        new_container.state = 1;
        new_container.flux = this.props.appActions.dataSlots['ds_selected_command'].type;
        new_container.id_client = this.props.appActions.dataSlots['ds_client_id'];
      } else if (this.props.appActions.dataSlots['ds_selected_command'].flux_realise.includes("VIDE")) {
        new_container.id_contenant = "VIDE";
        new_container.packmat = 0;
        new_container.state = 1;
        new_container.flux = this.props.appActions.dataSlots['ds_selected_command'].type;
        new_container.id_client = this.props.appActions.dataSlots['ds_client_id'];
      } else {
        new_container.id_contenant = this.props.appActions.dataSlots['ds_selected_command'].container_id;
        new_container.state = this.props.appActions.dataSlots['ds_selected_command'].state||1;
        new_container.flux = this.props.appActions.dataSlots['ds_selected_command'].flux_realise;
        new_container.id_client = this.props.appActions.dataSlots['ds_selected_command'].id_client;
        new_container.exutoire = this.props.appActions.dataSlots['ds_selected_command'].exutoire;
        placeNewOrder = new_container;
      }
    
      if (new_container) {
        dech_quai_view.hors_quais.push(new_container);
    
        let update = {
          document_key: dech_quai_view.document_key,
          document_ref: dech_quai_view.document_ref,
          hors_quais: dech_quai_view.hors_quais,
          ts: new Date().getTime()
        };
        this.props.appActions.dataSheets['quaiView'].replaceItemByRowIndex(update.document_key, update, this.props.appActions.serviceOptions_quaiView);
      }
    }
    
    // PLACE ORDER si pose benne plein HQ (vidage différé)
    ///////////// CASE REPORTER //////////////
    let entreprise = this.props.appActions.dataSheets['entreprise'].items[0]||{};
    
    if (placeNewOrder && entreprise.order_on_vdiff) {
      let ot_row = this.props.appActions.dataSlots['ds_selected_command'];
      let date_ref = new Date(ot_row.date);
    
      let keys_to_keep = [
        'abilities',
        'action',
        'adr',
        'client',
        'container_id',
        'coordinates',
        'default_action',
        'display_name',
        'duration',
        'exutoire',
        'flux',
        'heavy',
        'heure',
        'hh',
        'id_client',
        'id_scan',
        'ouverture',
        'packmat',
        'remark',
        'sector',
        'site',
        'sort_key',
        'state',
        'type',
        'zfe',      
      ];
      let modifications = {
        action: ot_row.default_action,
        default_action: ot_row.default_action,
        date: this.props.appActions.dataSlots['ds_today_minuit_ts'],
        display_name: dech_quai_view.site,
        driver: "",
        driver_id: "",
        etat: 'differe',
        hh: "HQ",
        photo: "",
        position: 0,
        quai: 0,
        report: ot_row.report,
        reference_diff: ot_row.document_key + " (" + date_ref.toLocaleDateString("FR-fr") + ")",
        site: dech_quai_view.id_client,
        statut : 0,
        tonnage: 0,
        tournee: "",
        truck: "",
        truck_id: "",
        ts: ts,
        user_id: this.props.appActions.dataSlots['ds_userid'],
        user_name: this.props.appActions.dataSlots['ds_username'],
      };
    
      let newOtPlanning = {...ot_row};
      Object.keys(newOtPlanning).forEach(k => {
        if (!keys_to_keep.includes(k)) {
          delete newOtPlanning[k];
        }
      })
      newOtPlanning = {...newOtPlanning, ...modifications};
    
      let tournees_collection = this.props.appActions.dataSheets['tournees_j'].firebase.firestore().collection("entreprises/"+this.props.appActions.dataSlots['ds_entreprise_id']+"/tournees/");
      tournees_collection.add(newOtPlanning).then(docRef => {
        console.log('Order success');
      })
      .catch(e => {
        console.log('ERROR : ', e);
      })
    }
    
  
    // Go to screen 'Tournée du jour'
    this.props.appActions.goToScreen('tournéeDuJour', { transitionId: 'fadeIn' });
  
  }
  
  
  checkboxChanged_elCheckbox_quai = (event) => {
    this.setState({checkbox_quai: (event.target.checked ? 'true' : 'false')});
    
    this.setState({
      //elList_visible: event.target.checked,
      //elValider_visible: !event.target.checked,
      
      checkbox_quai: "true",
      checkbox_hors_quai: "false",
    });  
  }
  
  checkboxChanged_elCheckbox_hors_quai = (event) => {
    this.setState({checkbox_hors_quai: (event.target.checked ? 'true' : 'false')});
    
    this.setState({
      //elList_visible: !event.target.checked,
      //elValider_visible: event.target.checked,
      
      checkbox_quai: "false",
      checkbox_hors_quai: "true",
    });  
  }
  
  pickerValueChanged_elPicker_producer = (event) => {
    this.setState({picker_producer: event.target.value});
    
    this.props.appActions.updateDataSlot("ds_client_id", event.target.value);
  }
  
  pickerValueChanged_elPicker_dech = (event) => {
    this.setState({picker_dech: event.target.value});
    
    this.props.appActions.updateDataSlot("ds_client_id", event.target.value);
  }
  
  checkboxChanged_elCheckbox_dech = (event) => {
    this.setState({checkbox_dech: (event.target.checked ? 'true' : 'false')});
    
    this.setState({
      elPicker_dech_visible: true,
      elPicker_producer_visible: false,
      picker_dech: null,
      picker_producer: null,
      
      checkbox_dech: 'true',
      checkbox_autres: 'false',
    });  
    
    {
      let newVal = (event.target.checked ? 'true' : 'false');
      
      let transformValue = (input) => {
        return '';
      }
      newVal = transformValue(newVal);
      
      this.props.appActions.updateDataSlot("ds_client_id", newVal);
    }
  }
  
  checkboxChanged_elCheckbox_autres = (event) => {
    this.setState({checkbox_autres: (event.target.checked ? 'true' : 'false')});
    
    this.setState({
      elPicker_dech_visible: false,
      elPicker_producer_visible: true,
      picker_dech: null,
      picker_producer: null,
      
      checkbox_dech: 'false',
      checkbox_autres: 'true',
    });  
    
    {
      let newVal = (event.target.checked ? 'true' : 'false');
      
      let transformValue = (input) => {
        return '';
      }
      newVal = transformValue(newVal);
      
      this.props.appActions.updateDataSlot("ds_client_id", newVal);
    }
  }
  
  onClick_elCancel = (ev) => {
    let a = window.confirm("Interrompre l'OT en cours?");
    if (!a) {
      return;
    }
  
    // Go to screen 'Tournée du jour'
    this.props.appActions.goToScreen('tournéeDuJour', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elShow_apercu = (ev) => {
    let newVal = "1";
    this.props.appActions.updateDataSlot('ds_apercu_visible', newVal);
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };
    let transformPropValue_list = (input) => {
      // This function modifies the value for property 'itemsArray'.
      // There is a variable named 'input' that provides the property value.
      //
      let quaiView = this.props.appActions.dataSheets['quaiView'].items.filter(it => it.id_client === input)[0] || {};
      return (quaiView.quais || []).map((it, index) => {return {...it, quai: index, key: (quaiView.document_key + index)}}).slice(1);
    }
    
    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    if (Array.isArray(transformPropValue_list((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_client_id'] : '')))) {
        items_list = items_list.concat(transformPropValue_list((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_client_id'] : '')));
    } else if (transformPropValue_list((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_client_id'] : ''))) {
        items_list.push(transformPropValue_list((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_client_id'] : '')));
    }
    this._elList_items = [];
    
    const elList = this.state.elList_visible ? (
      <div className="hasNestedComps elList">
        <div>
          {items_list.map((row, index) => {
            let itemComp = (row._componentId)
                ? listComps_list[row._componentId]
                : <Picker_depot2 appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} {...{ 'flux': row['flux'], 'state': row['state'], 'id_scan': row['id_scan'], 'quai': row['quai'], 'id_client': row['id_client'], 'id_contenant': row['id_contenant'], 'packmat': row['packmat'], 'type': row['type'], 'color': row['color'], 'reserve': row['reserve'], }} ref={(el) => {if (el) this._elList_items.push(el)}} />;
            return (<div key={row.key}>
                {itemComp}
              </div>);
          })}
          <div className="marker" ref={(el)=> this._elList_endMarker = el} />
        </div>
      </div>
      
     ) : null;
    
    const style_elValider = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elValider = this.state.elValider_visible ? (
      <Button className="actionFont elValider" style={style_elValider}  color="primary" onClick={this.onClick_elValider} >
        Valider
      </Button>
     ) : null;
    
    let checked_checkbox_quai = this.state.checkbox_quai;
    
    const style_elCheckbox_quai = {
      pointerEvents: 'auto',
     };
    const elCheckbox_quai = this.state.elCheckbox_quai_visible ? (
      <Checkbox className="baseFont elCheckbox_quai" style={style_elCheckbox_quai}  label="A quai" onChange={this.checkboxChanged_elCheckbox_quai} checked={checked_checkbox_quai === 'true' || checked_checkbox_quai === true || ''+checked_checkbox_quai === '1'}  />
     ) : null;
    
    let checked_checkbox_hors_quai = this.state.checkbox_hors_quai;
    
    const style_elCheckbox_hors_quai = {
      pointerEvents: 'auto',
     };
    const elCheckbox_hors_quai = this.state.elCheckbox_hors_quai_visible ? (
      <Checkbox className="baseFont elCheckbox_hors_quai" style={style_elCheckbox_hors_quai}  defaultChecked label="Hors quai" onChange={this.checkboxChanged_elCheckbox_hors_quai} checked={checked_checkbox_hors_quai === 'true' || checked_checkbox_hors_quai === true || ''+checked_checkbox_hors_quai === '1'}  />
     ) : null;
    
    let selection_picker_producer = this.state.picker_producer;
    // Source datasheet and selected data column for picker element 'picker_producer'
    const dataSource_picker_producer = this.props.appActions.getDataSheet('quaiViewProducers');
    const valueColumnName_picker_producer = 'id_client';
    const labelColumnName_picker_producer = 'site';
    
    const style_elPicker_producer = {
      pointerEvents: 'auto',
     };
    const elPicker_producer = this.state.elPicker_producer_visible ? (
      <Select className="baseFont elPicker_producer" style={style_elPicker_producer}  onChange={this.pickerValueChanged_elPicker_producer} value={selection_picker_producer} >
        {dataSource_picker_producer.items.every(item => {
          return item[valueColumnName_picker_producer] !== selection_picker_producer;
        }) ? <Option value=''/> : null}
        {dataSource_picker_producer.items.map(item => {
          const colValue = item[valueColumnName_picker_producer];
          const labelColValue = item[labelColumnName_picker_producer];
          return <Option key={item.key} value={colValue} label={labelColValue} />
        })}
      </Select>
     ) : null;
    
    let selection_picker_dech = this.state.picker_dech;
    // Source datasheet and selected data column for picker element 'picker_dech'
    const dataSource_picker_dech = this.props.appActions.getDataSheet('quaiViewRights');
    const valueColumnName_picker_dech = 'id_client';
    const labelColumnName_picker_dech = 'site';
    
    const style_elPicker_dech = {
      pointerEvents: 'auto',
     };
    const elPicker_dech = this.state.elPicker_dech_visible ? (
      <Select className="baseFont elPicker_dech" style={style_elPicker_dech}  onChange={this.pickerValueChanged_elPicker_dech} value={selection_picker_dech} >
        {dataSource_picker_dech.items.every(item => {
          return item[valueColumnName_picker_dech] !== selection_picker_dech;
        }) ? <Option value=''/> : null}
        {dataSource_picker_dech.items.map(item => {
          const colValue = item[valueColumnName_picker_dech];
          const labelColValue = item[labelColumnName_picker_dech];
          return <Option key={item.key} value={colValue} label={labelColValue} />
        })}
      </Select>
     ) : null;
    
    let checked_checkbox_dech = this.state.checkbox_dech;
    
    const style_elCheckbox_dech = {
      pointerEvents: 'auto',
     };
    
    let checked_checkbox_autres = this.state.checkbox_autres;
    
    const style_elCheckbox_autres = {
      pointerEvents: 'auto',
     };
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const style_elCancel = {
      display: 'block',
      textTransform: 'none',
      color: '#fff',
      textAlign: 'left',
      backgroundColor: '#ff7d78',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elRectangle = {
      background: 'rgba(213, 213, 213, 1.000)',
     };
    
    const style_elShow_apercu = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_1822855885+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_notif = (input) => {
      let entreprise = this.props.appActions.dataSheets['entreprise'].items[0]||{};
      let auth_user = this.props.appActions.dataSheets['auth_user'].items[0] || {};
      let profile = auth_user.profile || {}; 
      
      if (entreprise.pvd_can_add_to_tour && (profile.hdq === true ? !entreprise.hdq_cannot_add_to_tour : true)) {
        let commands = this.props.appActions.dataSheets['tournees_j'].items
        .filter(it => it.tournee == "" && it.statut === 0)
        .sort((a,b) => a.display_name.localeCompare(b.display_name));
        if (commands.length > 0) {
          return "<p style='color:white;border-radius:100%;background-color:#ff7e79;width:18px;height:18px;'>"+commands.length+"</p>";
        } else {
          return '';
        }
      } else {
        return '';
      }
    }
    
    const value_notif = transformPropValue_notif((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_today_minuit_ts'] : ''));
    
    const style_elNotif = {
      fontSize: 12.3,
      color: '#feffff',
      textAlign: 'center',
      pointerEvents: 'auto',
     };
    const elApercu = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_apercu_visible'] : '')) ? (
      <div className="hasNestedComps containerMinHeight elApercu">
        <Apercu visualStateIndex={(this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_mode_apercu'] : '')} ref={(el)=> this._elApercu = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    return (
      <div className="AppScreen DepotV3Screen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        <Appbar className="navBar">
          <div className="title">Dépôt (Etape 4/4)</div></Appbar>
        
        <div className="screenFgContainer">
          <div className="foreground">
            { elList }
            { elValider }
            { elCheckbox_quai }
            { elCheckbox_hors_quai }
            { elPicker_producer }
            { elPicker_dech }
            <Checkbox className="baseFont elCheckbox_dech" style={style_elCheckbox_dech}  defaultChecked label="Déchèteries" onChange={this.checkboxChanged_elCheckbox_dech} checked={checked_checkbox_dech === 'true' || checked_checkbox_dech === true || ''+checked_checkbox_dech === '1'}  />
            <Checkbox className="baseFont elCheckbox_autres" style={style_elCheckbox_autres}  label="Autres lieux" onChange={this.checkboxChanged_elCheckbox_autres} checked={checked_checkbox_autres === 'true' || checked_checkbox_autres === true || ''+checked_checkbox_autres === '1'}  />
            <div className="headlineFont elText" style={style_elText}>
              <div>{this.state.text}</div>
            </div>
            <Button className="actionFont elCancel" style={style_elCancel}  variant="fab" onClick={this.onClick_elCancel} >
              <img src={btn_icon_2136812680} alt="" style={{width: '100%', marginTop: '4%'}} />
            </Button>
            <div className="elRectangle" style={style_elRectangle} />
            <button className="actionFont elShow_apercu" style={style_elShow_apercu} onClick={this.onClick_elShow_apercu}  />
            <div className="systemFontRegular  elNotif" style={style_elNotif}>
              <div><div dangerouslySetInnerHTML={{__html: value_notif}}></div></div>
            </div>
            { elApercu }
          </div>
        </div>
      </div>
    )
  }
  
}
