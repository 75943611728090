import React, { Component } from 'react';
import './App.css';
import Ot_tournee_j1 from './Ot_tournee_j1';

// UI framework component imports
import Appbar from 'muicss/lib/react/appbar';

export default class TournéeDemainScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);
    
    this.state = {
      text: (<div> </div>),
      text_plainText: " ",
    };
  }

  componentDidMount() {
    {
      let dataSheet = this.props.appActions.dataSheets['tournees_j1'];
      let serviceOptions = this.props.appActions.serviceOptions_tournees_j1;
      if ( !this.props.appActions.dataSheetLoaded['tournees_j1']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/tournees", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['tournees_j1'] = true;
      }
    }
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    // eslint-disable-next-line
    const dataSheet_tournees_j1 = this.props.dataSheets['tournees_j1'];
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };
    
    const value_text = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_tournee_driver'] : '');
    
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    // Source items and any special components used for list/grid element 'list copy'.
    let items_listCopy = [];
    let listComps_listCopy = {};
    let filterItems_listCopy = items => {
      return items.filter(it => it.tournee == this.props.appActions.dataSlots['ds_selected_tournee']).sort((a, b) => a.position - b.position );
    }
    items_listCopy = items_listCopy.concat(filterItems_listCopy(this.props.appActions.getDataSheet('tournees_j1').items));
    this._elListCopy_items = [];
    
    const style_elListCopy = {
      height: 'auto',  // This element is in scroll flow
     };
    
    return (
      <div className="AppScreen TournéeDemainScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elText">
            <div className="headlineFont" style={style_elText}>
              <div>{value_text !== undefined ? value_text : (<span className="propValueMissing">{this.state.text}</span>)}</div>
            </div>
          </div>
          
          <div className="hasNestedComps elListCopy">
            <ul style={style_elListCopy}>
              {items_listCopy.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_listCopy[row._componentId]
                    : <Ot_tournee_j1 appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'tournees_j1'} dataSheetRow={row} ref={(el) => {if (el) this._elListCopy_items.push(el)}} />;
                return (<li key={row.key}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._elListCopy_endMarker = el} />
            </ul>
          </div>
        </div>
        <Appbar className="navBar">
          <div className="title">Tournée demain</div>  <div className="backBtn" onClick={ (ev)=>{ this.props.appActions.goBack() } }></div>
        </Appbar>
        
      </div>
    )
  }
  
}
