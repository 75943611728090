import React, { Component } from 'react';
import './App.css';
import request from 'request';
import Camera from './Camera';
import btn_icon_1262581232 from './images/btn_icon_1262581232.png';
import btn_icon_52787679 from './images/btn_icon_52787679.png';
import Item_immatriculation from './Item_immatriculation';
import btn_icon_back_scanner from './images/btn_icon_back_scanner.png';

// UI framework component imports
import Button from 'muicss/lib/react/button';
import Input from 'muicss/lib/react/input';
import Appbar from 'muicss/lib/react/appbar';

export default class ScannerScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);
    
    this.state = {
      elScanner_visible: false,
      elCapture_visible: true,
      elIcon_back_visible: true,
      elSearch_visible: false,
      search: '',
      elList_immatriculations_visible: false,
    };
    this.props.appActions.updateDataSlot('ds_facing_mode', 'environment');
    setTimeout(() => {
      this.setState({ elScanner_visible : true })
    }, 500)
  }

  componentDidMount() {
    {
      let dataSheet = this.props.appActions.dataSheets['reference_containers'];
      let serviceOptions = this.props.appActions.serviceOptions_reference_containers;
      if ( !this.props.appActions.dataSheetLoaded['reference_containers']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/all_containers", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['reference_containers'] = true;
      }
    }
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elCapture = (ev) => {
    let containers_reference = this.props.appActions.dataSheets['reference_containers'].items;
    let ids_reference = containers_reference.map(c => c.id_scan)
    .sort((a, b) => b.length - a.length);
    
    let scan_one = (depth) => {
      if (depth < 5) {
        if (window.videoElement !== null) {
          let tracks = window.videoElement.srcObject.getTracks();
          tracks.forEach((track) => {
            track.applyConstraints({
              advanced: [{torch:  depth%2 === 0 ? false : true}]
            })
              .then(() => {
              return new Promise((resolve, reject) => {
                setTimeout(() => {resolve()}, 100)
              })
            })
              .catch(() => {
              return new Promise((resolve, reject) => {
                setTimeout(() => {resolve()}, 100)
              })
            })
              .finally(() => {
              console.log(depth);
    
              this._elScanner.savePicture()
              //let data = {image: this.props.appActions.dataSlots['ds_camera']};
    
              //let app = this.props.appActions.dataSheets['reference_containers'].firebase.app();
              //let functions = app.functions("europe-west1");
              //let ocr = functions.httpsCallable('getImageText');
    
              // temp
              let request_data = 
                  {
                    "requests": [
                      {
                        "image": {
                          "content": this.props.appActions.dataSlots['ds_camera'].split(',')[1],
                        },
                        "features": [
                          {
                            "type": "TEXT_DETECTION"
                          }
                        ]
                      }
                    ]
                  } 
              let query_OCR = new Promise( (resolve, reject) => {
                request.post({
                  url: "https://vision.googleapis.com/v1/images:annotate?key=AIzaSyDl_NCywJGXrCWzAeg12LrCVS6eSo0R13c",
                  json: request_data,
                }, (error, response, body) => {
                  if (error) {
                    console.log(error)
                    reject(error);
                  } else {
                    resolve(body.responses[0].textAnnotations);
                  }
                });
              })
    
              //
    
              //console.log('recognizing', new Date().getTime())
              //ocr(data).then(textAnnotations => {
              query_OCR.then(textAnnotations => {
                //console.log('Done', textAnnotations, new Date().getTime());
    
                // ocr sequence of characters
                let sequence = '';
                //textAnnotations.data.forEach(anotation => {
                //textAnnotations.forEach(anotation => {
                //  sequence += anotation.description;
                //})
                sequence = (textAnnotations[0]||{}).description||'';
                sequence = sequence.replace(/o/gi, '0').replace(/d/gi, '0').replace(/i/gi, '1').replace(/s/gi, '5').replace(/[^a-z\d]+/ig, '');
    
                // id to recognize
                let index_found = ids_reference.findIndex(it => {
                  let exp = new RegExp(it.replace(/o/gi, '0').replace(/d/gi, '0').replace(/i/gi, '1').replace(/s/gi, '5').replace(/[^a-z\d]+/ig, ''), 'i');
                  return sequence.match(exp);
                });
                if (index_found >= 0) {
                  this.props.appActions.setState({loading: false});
                  // window.alert('Matricule reconnu : ' + ids_reference[index_found]);
                  window.navigator.vibrate(200);
    
                  let new_scan = {
                    ts: new Date().getTime(),
                    id_entreprise: this.props.appActions.dataSlots['ds_entreprise_id'],
                    image: this.props.appActions.dataSlots['ds_camera'],
                    id_scan: containers_reference[index_found]['id_scan']
                  }
                  this.props.appActions.dataSheets['dataBaseScans'].addItem(new_scan, this.props.appActions.serviceOptions_dataBaseScans);
    
                  this.props.appActions.updateDataSlot('ds_scanned_id', ids_reference[index_found]);
                  //this.props.appActions.updateDataSlot('ds_selected_command', {...this.props.appActions.dataSlots['ds_selected_command'], 'id_scan': containers_reference[index_found]['id_scan']});
                  this.props.appActions.goBack();
                  //this.props.appActions.updateDataSlot('ds_scanned_container', containers_reference[index_found]);
                  //this.props.appActions.goToScreen('informationsConteneur', { transitionId: 'fadeIn' });
                } else {
                  return scan_one(depth+1);
                  //this.props.appActions.setState({loading: false});
                  //window.alert('Matricule non reconnu : réessayer ou lancer une recherche manuelle.');
                }
              }).catch(error => {
                return scan_one(depth+1);
                //this.props.appActions.setState({loading: false});
                //window.alert("Une erreur s'est produite");
              })
            })
          })
        }
      } else {
        this.props.appActions.setState({loading: false});
        window.navigator.vibrate(200);
        this.setState({
          elList_immatriculations_visible: !this.state.elList_immatriculations_visible,
          elSearch_visible: !this.state.elSearch_visible,
          elCapture_visible: !this.state.elCapture_visible,
          elScanner_visible: !this.state.elScanner_visible
        });
        // window.alert('Matricule non reconnu : réessayer ou lancer une recherche manuelle.');
        return;
      }
    }
    
    
    try {
      this.props.appActions.setState({loading: true});
      scan_one(0);
    } catch (error) {
      this.props.appActions.setState({loading: false});
      window.navigator.vibrate(200);
      this.setState({
        elList_immatriculations_visible: !this.state.elList_immatriculations_visible,
        elSearch_visible: !this.state.elSearch_visible,
        elCapture_visible: !this.state.elCapture_visible,
        elScanner_visible: !this.state.elScanner_visible
      });
      // window.alert("Une erreur s'est produite");
    }
    
  
  }
  
  
  onClick_elIcon_back = (ev) => {
    this.setState({ elScanner_visible : false })
    if (this.props.appActions.dataSlots['ds_facing_mode'] == 'environment') {
      this.props.appActions.updateDataSlot('ds_facing_mode', 'user');
    } else {
      this.props.appActions.updateDataSlot('ds_facing_mode', 'environment');
    }
    setTimeout(() => {
      this.setState({ elScanner_visible : true })
    }, 500)
  
  }
  
  
  onClick_elIconList = (ev) => {
    this.setState({elList_immatriculations_visible: !this.state.elList_immatriculations_visible});
  
    this.setState({elSearch_visible: !this.state.elSearch_visible});
  
    this.setState({elIcon_back_visible: !this.state.elIcon_back_visible});
  
    this.setState({elCapture_visible: !this.state.elCapture_visible});
  
    this.setState({elScanner_visible: !this.state.elScanner_visible});
  
  }
  
  
  textInputChanged_elSearch = (event) => {
    this.setState({search: event.target.value});
  }
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    // eslint-disable-next-line
    const dataSheet_reference_containers = this.props.dataSheets['reference_containers'];
    let transformFillColorPropValue_background = (input) => {
      // This function modifies the value for property 'fillColor'.
      // There is a variable named 'input' that provides the property value.
      //
      return this.state.elScanner_visible ? "black" : "#f6f6f6";
    }
    const fillColorValue_background = transformFillColorPropValue_background((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_tournee_driver'] : ''));
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: (fillColorValue_background && fillColorValue_background.length > 0) ? fillColorValue_background : 'black',
     };
    const style_elScanner = {
      pointerEvents: 'auto',
     };
    const elScanner = this.state.elScanner_visible ? (
      <div className="elScanner" style={style_elScanner}>
        <Camera ref={(el)=> this._elScanner = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    const style_elCapture = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elCapture = this.state.elCapture_visible ? (
      <Button className="actionFont elCapture" style={style_elCapture}  color="primary" onClick={this.onClick_elCapture} >
        Scanner
      </Button>
     ) : null;
    
    const style_elIcon_back = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_1262581232+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '98.150%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elIcon_back = this.state.elIcon_back_visible ? (
      <button className="actionFont elIcon_back" style={style_elIcon_back} onClick={this.onClick_elIcon_back}  />
     ) : null;
    
    const style_elIconList = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_52787679+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '98.150%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    let cssClass_progress = 'circularProgressIndicator';
    if (this.props.appActions.isLoading()) 
      cssClass_progress += ' circularProgressIndicator-active';
    
    
    const style_elSearch = {
      display: 'block',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const elSearch = this.state.elSearch_visible ? (
      <Input className="baseFont elSearch" style={style_elSearch} type="text" hint="Rechercher" onChange={this.textInputChanged_elSearch} value={this.state.search}  />
     ) : null;
    
    // Source items and any special components used for list/grid element 'list_immatriculations'.
    let items_list_immatriculations = [];
    let listComps_list_immatriculations = {};
    let filterItems_list_immatriculations = items => {
      let search_array = this.state.search.trim().replace(/ +(?= )/g,'').split(/\s/);
      let exp = search_array.map(e => RegExp(e, 'gi'));
      const compare = (exp, adr) => adr.search(exp) >= 0;
      let output = items.filter(it => exp.every(e => compare(e, it.id_scan)));
      return output
    }
    items_list_immatriculations = items_list_immatriculations.concat(filterItems_list_immatriculations(this.props.appActions.getDataSheet('reference_containers').items));
    this._elList_immatriculations_items = [];
    
    const elList_immatriculations = this.state.elList_immatriculations_visible ? (
      <div className="hasNestedComps elList_immatriculations">
        <ul>
          {items_list_immatriculations.map((row, index) => {
            let itemComp = (row._componentId)
                ? listComps_list_immatriculations[row._componentId]
                : <Item_immatriculation appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'reference_containers'} dataSheetRow={row} ref={(el) => {if (el) this._elList_immatriculations_items.push(el)}} />;
            return (<li key={row.key}>
                {itemComp}
              </li>);
          })}
          <div className="marker" ref={(el)=> this._elList_immatriculations_endMarker = el} />
        </ul>
      </div>
      
     ) : null;
    
    return (
      <div className="AppScreen ScannerScreen" style={baseStyle}>
        <div className="background">
          <div className="elBackground" style={style_elBackground_outer}>
            <div style={style_elBackground} />
          </div>
        </div>
        <Appbar className="navBar">
          <div className="title">Scanner</div>  <div className="backBtn" onClick={ (ev)=>{ this.props.appActions.goBack() } }><img src={btn_icon_back_scanner} alt="" style={{width: '50%'}} /></div>
        </Appbar>
        
        <div className="screenFgContainer">
          <div className="foreground">
            { elScanner }
            { elCapture }
            { elIcon_back }
            <button className="actionFont elIconList" style={style_elIconList} onClick={this.onClick_elIconList}  />
            <div className="elProgress">
              <svg className={cssClass_progress}>
                <circle className="circularProgress-animatedPath" style={{stroke: '#00e5d9'}} cx="25" cy="25" r="17" fill="none" strokeWidth="3" strokeMiterlimit="10" />
              </svg>
            </div>
            { elSearch }
            { elList_immatriculations }
          </div>
        </div>
      </div>
    )
  }
  
}
