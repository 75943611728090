import React, { Component } from 'react';
import './App.css';

export default class Client_diff extends Component {

  // Properties used by this component:
  // visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
      state1_elText: (<div>Transit</div>),
      state1_elText_plainText: "Transit",
      state2_elText: (<div>A l’instant</div>),
      state2_elText_plainText: "A l’instant",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  // --- Functions for component state index 0 (1 of 3) --- 
  
  renderState0() {
    
    return (
      <div className="Client_diff">
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 3) --- 
  
  renderState1() {
    
    const style_state1_elRectangle = {
      background: 'rgba(117, 213, 255, 1.000)',
      borderRadius: '3.0px',
     };
    const style_state1_elText = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'center',
     };
    
    return (
      <div className="Client_diff">
        <div className="background">
          <div className="containerMinHeight state1_elRectangle" style={style_state1_elRectangle} />
        </div>
        
        <div className="layoutFlow">
          <div className="state1_elText">
            <div className="baseFont" style={style_state1_elText}>
              <div>{this.state.state1_elText}</div>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
  
  // --- Functions for component state index 2 (3 of 3) --- 
  
  renderState2() {
    
    const style_state2_elRectangle = {
      background: 'rgba(117, 213, 255, 1.000)',
      borderRadius: '3.0px',
     };
    const style_state2_elText = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'center',
     };
    
    return (
      <div className="Client_diff">
        <div className="background">
          <div className="containerMinHeight state2_elRectangle" style={style_state2_elRectangle} />
        </div>
        
        <div className="layoutFlow">
          <div className="state2_elText">
            <div className="baseFont" style={style_state2_elText}>
              <div>{this.state.state2_elText}</div>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
      case 2:
        return this.renderState2();
    }
  }
  
}
