import React, { Component } from 'react';
import './App.css';
import btn_icon_835963567 from './images/btn_icon_835963567.png';
import btn_icon_1058697756 from './images/btn_icon_1058697756.png';

export default class Declared_anomalie extends Component {

  // Properties used by this component:
  // label, visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
      state0_elLabel: (<div> </div>),
      state0_elLabel_plainText: " ",
      state1_elLabel: (<div> </div>),
      state1_elLabel_plainText: " ",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  // --- Functions for component state index 0 (1 of 2) --- 
  
  onClick_state0_elWarning835963567 = (ev) => {
    // Go back in screen navigation history
    this.props.appActions.goBack();
  
  }
  
  
  onClick_state0_elRm = (ev) => {
    let a = window.confirm("Supprimer une anomalie?");
    if (!a) {return};
    
    let new_anomalie = this.props.appActions.dataSlots['ds_current_anomalies'].filter(a => a != this.props.label);
    this.props.appActions.updateDataSlot('ds_current_anomalies', new_anomalie);
  
  }
  
  
  renderState0() {
    
    const style_state0_elRectangle_back519569028 = {
      background: 'rgba(255, 212, 120, 1.000)',
      borderRadius: '10.0px',
     };
    
    const value_label = this.props.label;
    
    const style_state0_elLabel = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const style_state0_elWarning835963567 = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_835963567+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elRm = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_1058697756+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Declared_anomalie">
        <div className="background">
          <div className="containerMinHeight state0_elRectangle_back519569028" style={style_state0_elRectangle_back519569028} />
        </div>
        
        <div className="layoutFlow">
          <div className="state0_elSpacer137789074">
            <div />
          </div>
          
          <div className="state0_elLabel">
            <div className="baseFont" style={style_state0_elLabel}>
              <div>{value_label !== undefined ? value_label : (<span className="propValueMissing">{this.state.state0_elLabel}</span>)}</div>
            </div>
          </div>
          
          <div className="state0_elSpacerCopy914152825">
            <div />
          </div>
        </div>
        
        <div className="foreground">
          <button className="actionFont state0_elWarning835963567" style={style_state0_elWarning835963567} onClick={this.onClick_state0_elWarning835963567}  />
          <button className="actionFont state0_elRm" style={style_state0_elRm} onClick={this.onClick_state0_elRm}  />
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 2) --- 
  
  onClick_state1_elWarning835963567 = (ev) => {
    // Go back in screen navigation history
    this.props.appActions.goBack();
  
  }
  
  
  renderState1() {
    
    const style_state1_elRectangle_back519569028 = {
      background: 'rgba(255, 212, 120, 1.000)',
      borderRadius: '10.0px',
     };
    
    const value_label = this.props.label;
    
    const style_state1_elLabel = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const style_state1_elWarning835963567 = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_835963567+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Declared_anomalie">
        <div className="background">
          <div className="containerMinHeight state1_elRectangle_back519569028" style={style_state1_elRectangle_back519569028} />
        </div>
        
        <div className="layoutFlow">
          <div className="state1_elSpacer137789074">
            <div />
          </div>
          
          <div className="state1_elLabel">
            <div className="baseFont" style={style_state1_elLabel}>
              <div>{value_label !== undefined ? value_label : (<span className="propValueMissing">{this.state.state1_elLabel}</span>)}</div>
            </div>
          </div>
          
          <div className="state1_elSpacerCopy914152825">
            <div />
          </div>
        </div>
        
        <div className="foreground">
          <button className="actionFont state1_elWarning835963567" style={style_state1_elWarning835963567} onClick={this.onClick_state1_elWarning835963567}  />
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
    }
  }
  
}
