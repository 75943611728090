import React, { Component } from 'react';
import './App.css';

export default class Prevent_interraction extends Component {

  // Properties used by this component:
  // visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  // --- Functions for component state index 0 (1 of 2) --- 
  
  onClick_state0_elHotspot = (ev) => {
    window.alert("Action impossible, veuillez finaliser la commande en cours.");
  
  }
  
  
  renderState0() {
    
    const style_state0_elHotspot = {
      display: 'block',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Prevent_interraction">
        <div className="foreground">
          <div className="actionFont containerMinHeight state0_elHotspot" style={style_state0_elHotspot} onClick={this.onClick_state0_elHotspot}  />
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 2) --- 
  
  renderState1() {
    
    const style_state1_elRectangle = {
      background: 'rgba(246, 246, 246, 1.000)',
     };
    
    return (
      <div className="Prevent_interraction">
        <div className="foreground">
          <div className="containerMinHeight state1_elRectangle" style={style_state1_elRectangle} />
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
    }
  }
  
}
