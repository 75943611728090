import React, { Component } from 'react';

export default class Camera extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }
  
  componentWillUnmount() {
    if (window.videoElement !== null) {
      let tracks = this.videoElement.srcObject.getTracks();
      console.log("dismonting", tracks[0].id)
      tracks.forEach((track) => {
        track.stop();
      })
      window.videoElement = null;
    }
  }
  
  componentDidMount() {
    let video = this.videoElement;
    
    let handleStream = (stream) => {
      /**
      if ('srcObject' in video) {
        video.srcObject = stream;
      } else {
        // fallback for older browsers
        video.src = window.URL.createObjectURL(stream);      
      }
      **/
      video.srcObject = stream;
      let track = stream.getVideoTracks()[0];
      video.addEventListener('loadedmetadata', (e) => {  
        window.setTimeout(() => (
          onCapabilitiesReady(track.getCapabilities())
        ), 500);
      });
      
      let onCapabilitiesReady = (capabilities) =>{  
        if (capabilities.torch) {
          track.applyConstraints({
            advanced: [{torch: false}]
          })
            .catch(e => console.log(e));
        }
        window.videoElement = this.videoElement;
      }
      let tracks = this.videoElement.srcObject.getTracks();
      console.log(tracks)
    }
    
    let handleError = (err) => {
      console.log("** video error: ", err);      
    }
  
    navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia || navigator.oGetUserMedia;
  	/**
    if (navigator.getUserMedia) {       
      navigator.getUserMedia({video: { facingMode: { ideal: "environment" } }}, handleStream, handleError);
    } else {
      setTimeout(function() {
        alert("Sorry, camera support is not available in this web browser.");
      }, 1);
    }
    **/
  	if (navigator.getUserMedia) {     
      if (window.videoElement == null) {
        console.log("a");
        navigator.getUserMedia({video: { facingMode: { ideal: this.props.appActions.dataSlots['ds_facing_mode'] } }}, handleStream, handleError);
      } else {  
        console.log("b");
        this.videoElement = window.videoElement;
      }
    } else {
      setTimeout(function() {
        alert("Sorry, camera support is not available in this web browser.");
      }, 1);
    }
  }
  
  // Interactions made in React Studio can call this component to take a picture.
  // In the plugin's Main.js, we expose this method as the 'shoot' action in 'publishedInteractActions'.
  savePicture() {
    let canvas = document.createElement('canvas');
    canvas.width = this.videoElement.videoWidth;
    canvas.height = this.videoElement.videoHeight;
    let ctx = canvas.getContext('2d');
    ctx.drawImage(this.videoElement, 0, 0, canvas.width, canvas.height);
    let imageDataUrl = canvas.toDataURL('image/jpeg');
    let saveToDataSlot = null;
    saveToDataSlot = (v) => { this.props.appActions.updateDataSlot("ds_camera", v) };
    
    if (saveToDataSlot && imageDataUrl)
      saveToDataSlot(imageDataUrl);    
  }
     
    stopFlow() {
    let tracks = this.videoElement.srcObject.getTracks();
    console.log(tracks)
    tracks.forEach((track) => {
      track.stop();
    })
    window.videoElement = null;
  }
  
  render() {
    const cameraStyle = {
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      background: 'black',
    }

    const videoElStyle = {
      width: '100%',
      height: '100%',
    }
    
    return (<div className="Camera" style={cameraStyle}>
      <video autoPlay={true} className="liveVideo" style={videoElStyle} ref={(el) => { this.videoElement = el; }}>
      </video>
    </div>)
  }
}
