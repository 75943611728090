import React, { Component } from 'react';
import './App.css';
import btn_icon_1570632781 from './images/btn_icon_1570632781.png';

export default class Shedding extends Component {

  // Properties used by this component:
  // visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  // --- Functions for component state index 0 (1 of 2) --- 
  
  renderState0() {
    
    return (
      <div className="Shedding">
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 2) --- 
  
  renderState1() {
    
    const style_state1_elIconButton = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_1570632781+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '110.000%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
     };
    
    return (
      <div className="Shedding">
        <div className="foreground">
          <button className="actionFont containerMinHeight state1_elIconButton" style={style_state1_elIconButton} />
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
    }
  }
  
}
