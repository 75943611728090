import React, { Component } from 'react';
import './App.css';

export default class Item_immatriculation_apercu extends Component {

  // Properties used by this component:
  // dataSheetRow

  constructor(props) {
    super(props);
    
    this.state = {
      textcopy: (<div>&lt;no value from script&gt;</div>),
      textcopy_plainText: "<no value from script>",
      site: (<div>-</div>),
      site_plainText: "-",
      anomalies: (<div> </div>),
      anomalies_plainText: " ",
      type: (<div>&lt;no value from script&gt;</div>),
      type_plainText: "<no value from script>",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  render() {
    let transformPropValue_textCopy = (input) => {
      return input.id_scan;
    }
    let transformCustomCSSPropValue_textCopy = (input) => {
      let style = '"borderRadius": "5px"';
      try {
        if (input.unresolved_anomalies_labels.length) {
          style += ',"backgroundColor": "#FFD479"';
        } else {
          style += ',"backgroundColor": "#EBEBEB"';
        } 
      } catch (e) {
        style += ',"backgroundColor": "#EBEBEB"';
      }
      
      return style;
    }
    
    const value_textCopy = transformPropValue_textCopy(this.props.dataSheetRow);
    
    const style_elTextCopy = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
      ...(() => {  // append customCSS property of 'textCopy'
        let val = transformCustomCSSPropValue_textCopy(this.props.dataSheetRow);
        if (val == null || val.length == 0) return null;
        try {
          return JSON.parse('{'+val+'}');
        } catch (error) {
          console.log(error);
        }
        return null;
      })(),
     };
    let transformPropValue_site = (input) => {
      try {
        let quaiView = this.props.appActions.dataSheets['quaiView'].items.find(it => it.quais.some(q => q.id_scan == input.id_scan) || it.hors_quais.some(q => q.id_scan == input.id_scan))
        if (quaiView) {
          let quai = quaiView.quais.find(q => q.id_scan == input.id_scan);
          if (quai) {
            return quaiView.site + ' - ' + (quai.id_contenant == 'VIDE' ? 'VIDE' : quai.flux)
          } else {
            let quai = quaiView.hors_quais.find(q => q.id_scan == input.id_scan);
            if (quai) {
              return quaiView.site + ' - ' + (quai.id_contenant == 'VIDE' ? 'VIDE' : quai.flux)
            }
          }
        }
        return "-";
      } catch (error) {
        return "-";
      }
    }
    
    const value_site = transformPropValue_site(this.props.dataSheetRow);
    
    const style_elSite = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_anomalies = (input) => {
      try {
        let text = input.unresolved_anomalies_labels.join('<br>')
        return text;
      } catch (error) {
        return "";
      }
    }
    let transformCustomCSSPropValue_anomalies = (input) => {
      let style = '"borderRadius": "5px"';
      try {
        if (input.unresolved_anomalies_labels.length) {
          style += ',"backgroundColor": "#FFD4791A"';
        }
      } catch (e) {
      }
      
      return style;
    }
    
    const value_anomalies = transformPropValue_anomalies(this.props.dataSheetRow);
    
    const style_elAnomalies = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
      ...(() => {  // append customCSS property of 'anomalies'
        let val = transformCustomCSSPropValue_anomalies(this.props.dataSheetRow);
        if (val == null || val.length == 0) return null;
        try {
          return JSON.parse('{'+val+'}');
        } catch (error) {
          console.log(error);
        }
        return null;
      })(),
      pointerEvents: 'auto',
     };
    let transformPropValue_type = (input) => {
      return input.type;
    }
    
    const value_type = transformPropValue_type(this.props.dataSheetRow);
    
    const style_elType = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'right',
     };
    
    const style_elRectangle = {
      background: 'rgba(213, 213, 213, 1.000)',
     };
    
    return (
      <div className="Item_immatriculation_apercu">
        <div className="layoutFlow">
          <div className="flowRow flowRow_Item_immatriculation_apercu_elTextCopy_1816293517">
          <div className="elTextCopy">
            <div className="baseFont" style={style_elTextCopy}>
              <div>{value_textCopy !== undefined ? value_textCopy : (<span className="propValueMissing">{this.state.textcopy}</span>)}</div>
            </div>
          </div>
          
          </div>
          <div className="elSite">
            <div className="baseFont" style={style_elSite}>
              <div>{value_site !== undefined ? value_site : (<span className="propValueMissing">{this.state.site}</span>)}</div>
            </div>
          </div>
          
          <div className="elAnomalies">
            <div className="baseFont" style={style_elAnomalies}>
              <div><div dangerouslySetInnerHTML={{__html: value_anomalies}}></div></div>
            </div>
          </div>
          
          <div className="elType">
            <div className="baseFont" style={style_elType}>
              <div>{value_type !== undefined ? value_type : (<span className="propValueMissing">{this.state.type}</span>)}</div>
            </div>
          </div>
          
          <div className="elRectangle">
            <div style={style_elRectangle} />
          </div>
        </div>
        
      </div>
    )
  }
  
}
