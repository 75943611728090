import React, { Component } from 'react';
import './App.css';
import btn_icon_1362507320 from './images/btn_icon_1362507320.png';
import btn_icon_375661613 from './images/btn_icon_375661613.png';
import Prevent_interraction from './Prevent_interraction';

// UI framework component imports
import Button from 'muicss/lib/react/button';
import Select from 'muicss/lib/react/select';
import Option from 'muicss/lib/react/option';
import Checkbox from 'muicss/lib/react/checkbox';

export default class Tail_picker_plein extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
      elPicker_vide_visible: false,
      picker_vide: '',
      elPicker_visible: true,
      picker: '',
      checkbox_vide: 'false',
      checkbox_plein: 'true',
      elAdd_visible: true,
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elButton = (ev) => {
    let dech_quai_view = this.props.appActions.dataSheets['quaiView'].items.find(it => {
      return it.document_key === this.props.appActions.dataSlots['ds_selected_dech_quai_view'].document_key
    });
    
    let current_hors_quais = dech_quai_view.hors_quais || [];
    
    let picked_flux = this.props.appActions.dataSheets['containers'].items.filter(it => it.document_key == this.state.picker)[0] || {};
    picked_flux = this.state.checkbox_vide == 'true' || this.state.checkbox_vide == true ? "VIDE" : picked_flux.flux;
    
    let new_container;
    if ((this.state.checkbox_vide == 'true' || this.state.checkbox_vide == true) && this.state.picker_vide) {
      new_container = {
        id_scan: "",
        id_client: this.props.appActions.dataSlots['ds_client_id'],
        id_contenant: "VIDE",
        flux: this.state.picker_vide,
        state: 1,
      };
    } else {
      if (this.state.picker) {
        new_container = {
          id_scan: "",
          id_client: this.props.appActions.dataSlots['ds_client_id'],
          id_contenant: this.state.picker,
          flux: picked_flux,
          state: 5,
        };
      } else {
        alert("Formulaire incomplet.");
        return;
      }
    }
    
    if (new_container) {
      current_hors_quais.push(new_container);
      let update = {
        document_key: dech_quai_view.document_key,
        document_ref: dech_quai_view.document_ref,
        hors_quais: current_hors_quais,
      };
    
      this.props.appActions.dataSheets['quaiView'].replaceItemByRowIndex(update.document_key, update, this.props.appActions.serviceOptions_quaiView);
    
      //this.props.appActions.updateDataSlot('ds_selected_dech_quai_view', {...this.props.appActions.dataSlots['ds_selected_dech_quai_view'], hors_quais: current_hors_quais});
    }
    
  
    this.setState({elAdd_visible: true});
  
  }
  
  
  pickerValueChanged_elPicker_vide = (event) => {
    this.setState({picker_vide: event.target.value});
  }
  
  pickerValueChanged_elPicker = (event) => {
    this.setState({picker: event.target.value});
  }
  
  checkboxChanged_elCheckbox_vide = (event) => {
    this.setState({checkbox_vide: (event.target.checked ? 'true' : 'false')});
    
    if (event.target.checked) {
    this.setState({checkbox_plein: "false", elPicker_visible: false, elPicker_vide_visible: true});
    } else {
      this.setState({checkbox_plein: "true", elPicker_visible: true, elPicker_vide_visible: false});
    }  
  }
  
  checkboxChanged_elCheckbox_plein = (event) => {
    this.setState({checkbox_plein: (event.target.checked ? 'true' : 'false')});
    
    if (event.target.checked) {
      this.setState({checkbox_vide: "false", elPicker_visible: true, elPicker_vide_visible: false});
    } else {
      this.setState({checkbox_vide: "true", elPicker_visible: false, elPicker_vide_visible: true});
    }  
  }
  
  onClick_elClose = (ev) => {
    this.setState({elAdd_visible: true});
  
  }
  
  
  onClick_elAdd = (ev) => {
    this.setState({elAdd_visible: false});
  
    this.setState({picker: "", picker_vide: "", checkbox_plein: 'true', checkbox_vide: 'false'});
  
    this.setState({elPicker_visible: true});
  
    this.setState({elPicker_vide_visible: false});
  
  }
  
  
  render() {
    
    const style_elButton = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    let selection_picker_vide = this.state.picker_vide;
    // Source datasheet and selected data column for picker element 'picker_vide'
    const dataSource_picker_vide = this.props.appActions.getDataSheet('materiels');
    const valueColumnName_picker_vide = 'type';
    const labelColumnName_picker_vide = 'type';
    
    const style_elPicker_vide = {
      pointerEvents: 'auto',
     };
    const elPicker_vide = this.state.elPicker_vide_visible ? (
      <Select className="baseFont elPicker_vide" style={style_elPicker_vide}  onChange={this.pickerValueChanged_elPicker_vide} value={selection_picker_vide} >
        {dataSource_picker_vide.items.every(item => {
          return item[valueColumnName_picker_vide] !== selection_picker_vide;
        }) ? <Option value=''/> : null}
        {dataSource_picker_vide.items.map(item => {
          const colValue = item[valueColumnName_picker_vide];
          const labelColValue = item[labelColumnName_picker_vide];
          return <Option key={item.key} value={colValue} label={labelColValue} />
        })}
      </Select>
     ) : null;
    
    let selection_picker = this.state.picker;
    // Source datasheet and selected data column for picker element 'picker'
    const dataSource_picker = this.props.appActions.getDataSheet('containers');
    const valueColumnName_picker = 'document_key';
    const labelColumnName_picker = 'flux';
    
    const style_elPicker = {
      pointerEvents: 'auto',
     };
    const elPicker = this.state.elPicker_visible ? (
      <Select className="baseFont elPicker" style={style_elPicker}  onChange={this.pickerValueChanged_elPicker} value={selection_picker} >
        {dataSource_picker.items.every(item => {
          return item[valueColumnName_picker] !== selection_picker;
        }) ? <Option value=''/> : null}
        {dataSource_picker.items.map(item => {
          const colValue = item[valueColumnName_picker];
          const labelColValue = item[labelColumnName_picker];
          return <Option key={item.key} value={colValue} label={labelColValue} />
        })}
      </Select>
     ) : null;
    
    let checked_checkbox_vide = this.state.checkbox_vide;
    
    const style_elCheckbox_vide = {
      pointerEvents: 'auto',
     };
    
    let checked_checkbox_plein = this.state.checkbox_plein;
    
    const style_elCheckbox_plein = {
      pointerEvents: 'auto',
     };
    
    const style_elClose = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_1362507320+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '89.562%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_add = (input) => {
      // This function modifies the value for property 'enabled'.
      // There is a variable named 'input' that provides the property value.
      // The function must return 'true' (with apostrophes) for enabled value.
      //
      return input === 1 ? true : false;
    }
    
    const enabledValue_add = transformPropValue_add((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_mode_point'] : ''));
    
    const style_elAdd = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_375661613+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: '#feffff',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elAdd = this.state.elAdd_visible ? (
      <button className="actionFont containerMinHeight elAdd" style={style_elAdd}  disabled={''+enabledValue_add !== 'true'} onClick={this.onClick_elAdd}  />
     ) : null;
    const style_elPrevent_interraction = {
      width: '100%',
      height: '100%',
     };
    const style_elPrevent_interraction_outer = {
      backgroundColor: '#f6f6f6',
     };
    let transformVisiblePropValue_prevent_interraction = (input) => {
      return input === 1 ? false : true;
    }
    const elPrevent_interraction = ((val) => { return val === "true" || val == true || val == 1 })(transformVisiblePropValue_prevent_interraction((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_mode_point'] : ''))) ? (
      <div className="hasNestedComps containerMinHeight elPrevent_interraction" style={style_elPrevent_interraction_outer}>
        <div style={style_elPrevent_interraction}>
          <Prevent_interraction visualStateIndex={1} ref={(el)=> this._elPrevent_interraction = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
        </div>
      </div>
      
     ) : null;
    
    return (
      <div className="Tail_picker_plein cardBg">
        <div className="foreground">
          <Button className="actionFont elButton" style={style_elButton}  color="accent" onClick={this.onClick_elButton} >
            Ajouter
          </Button>
          { elPicker_vide }
          { elPicker }
          <Checkbox className="baseFont elCheckbox_vide" style={style_elCheckbox_vide}  label="Vide" onChange={this.checkboxChanged_elCheckbox_vide} checked={checked_checkbox_vide === 'true' || checked_checkbox_vide === true || ''+checked_checkbox_vide === '1'}  />
          <Checkbox className="baseFont elCheckbox_plein" style={style_elCheckbox_plein}  defaultChecked label="Plein" onChange={this.checkboxChanged_elCheckbox_plein} checked={checked_checkbox_plein === 'true' || checked_checkbox_plein === true || ''+checked_checkbox_plein === '1'}  />
          <button className="actionFont elClose" style={style_elClose} onClick={this.onClick_elClose}  />
          { elAdd }
          { elPrevent_interraction }
        </div>
      </div>
    )
  }
  
}
