import React, { Component } from 'react';
import './App.css';

// UI framework component imports
import Button from 'muicss/lib/react/button';
import Appbar from 'muicss/lib/react/appbar';

export default class OperationScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);
    
    this.state = {
      elVidage_visible: true,
      elVidage_diff_visible: true,
      elTransfert_visible: false,
    };
  }

  componentDidMount() {
    if (this.props.appActions.dataSlots['ds_selected_command'].hh == 'VIDE') {
      this.setState({
        elVidage_visible: false,
        elVidage_diff_visible: false,
        elTransfert_visible: true,
      })
    } else {
      this.setState({
        elVidage_visible: true,
        elVidage_diff_visible: true,
        elTransfert_visible: false,
      })
    }
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elVidage_diff = (ev) => {
    // Go to screen 'depot v3'
    this.props.appActions.goToScreen('depotV3', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elTransfert = (ev) => {
    let a = window.confirm("Confirmer la prise en charge pour un TRANSFERT.");
    if (a) {
      let update = this.props.appActions.dataSlots['ds_history_to_log'];
      this.props.appActions.dataSheets['history_moves'].addItem(update, this.props.appActions.serviceOptions_history_moves);
    } else {
      return;
    }
  
    // Go to screen 'depot v3'
    this.props.appActions.goToScreen('depotV3', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elCancel = (ev) => {
    let a = window.confirm("Interrompre l'OT en cours?");
    if (!a) {
      return;
    }
  
    // Go to screen 'Tournée du jour'
    this.props.appActions.goToScreen('tournéeDuJour', { transitionId: 'fadeIn' });
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };
    
    const style_elVidage = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
     };
    const elVidage = this.state.elVidage_visible ? (
      <div className="elVidage">
        <Button className="actionFont" style={style_elVidage}  color="accent" >
          Vidage
        </Button>
      </div>
      
     ) : null;
    
    const style_elVidage_diff = {
      display: 'block',
      color: '#00e5d9',
      textAlign: 'center',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elVidage_diff = this.state.elVidage_diff_visible ? (
      <div className="elVidage_diff">
        <button className="actionFont" style={style_elVidage_diff} onClick={this.onClick_elVidage_diff} >
          Vidage différé
        </button>
      </div>
      
     ) : null;
    
    const style_elTransfert = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elTransfert = this.state.elTransfert_visible ? (
      <div className="elTransfert">
        <Button className="actionFont" style={style_elTransfert}  color="accent" onClick={this.onClick_elTransfert} >
          Transfert
        </Button>
      </div>
      
     ) : null;
    
    const style_elPackmat = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
     };
    const style_elPackmat_outer = {
      display: 'none',
     };
    
    const style_elCancel = {
      display: 'block',
      textTransform: 'none',
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#ff7d78',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen OperationScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elSpacer">
            <div />
          </div>
          
          { elVidage }
          { elVidage_diff }
          { elTransfert }
          <div className="elPackmat" style={style_elPackmat_outer}>
            <Button className="actionFont" style={style_elPackmat}  color="accent" >
              Compaction
            </Button>
          </div>
        </div>
        <Appbar className="navBar">
          <div className="title">Prise en charge 2/4</div>  <div className="backBtn" onClick={ (ev)=>{ this.props.appActions.goBack() } }></div>
        </Appbar>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <Button className="actionFont elCancel" style={style_elCancel} onClick={this.onClick_elCancel} >
              Interrompre
            </Button>
          </div>
        </div>
      </div>
    )
  }
  
}
