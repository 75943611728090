import React, { Component } from 'react';
import './App.css';
import btn_icon_2099238582 from './images/btn_icon_2099238582.png';
import Ot_tournee_j1 from './Ot_tournee_j1';
import Item_immatriculation_apercu from './Item_immatriculation_apercu';
import btn_icon_869858924 from './images/btn_icon_869858924.png';
import Ot_order_add from './Ot_order_add';

// UI framework component imports
import Button from 'muicss/lib/react/button';
import Select from 'muicss/lib/react/select';
import Option from 'muicss/lib/react/option';
import Checkbox from 'muicss/lib/react/checkbox';
import Input from 'muicss/lib/react/input';

export default class Apercu extends Component {

  // Properties used by this component:
  // visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
      state0_elAllOrders_visible: true,
      site: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_site_apercu'] : ''),
      anomalies: 'false',
      search: '',
      state3_elText: (<div>Recherche contenant</div>),
      state3_elText_plainText: "Recherche contenant",
      state4_elSub: (<div>Sélectionner une commande pour l’ajouter à votre tournée</div>),
      state4_elSub_plainText: "Sélectionner une commande pour l’ajouter à votre tournée",
      state4_elText: (<div>Commandes</div>),
      state4_elText_plainText: "Commandes",
    };
  }

  componentDidMount() {
    // add_to_tour visible
    let entreprise = this.props.appActions.dataSheets['entreprise'].items[0]||{};
    let auth_user = this.props.appActions.dataSheets['auth_user'].items[0] || {};
    let profile = auth_user.profile || {}; 
    if (this.state.state0_elAllOrders_visible && !(entreprise.pvd_can_add_to_tour && (profile.hdq === true ? !entreprise.hdq_cannot_add_to_tour : true))) {
      this.setState({
        state0_elAllOrders_visible: false,
        state0_elNotif_visible: false,
      })
    } else if (!this.state.state0_elAllOrders_visible && (entreprise.pvd_can_add_to_tour && (profile.hdq === true ? !entreprise.hdq_cannot_add_to_tour : true))) {
      this.setState({
        state0_elAllOrders_visible: true,
        state0_elNotif_visible: true,
      })
    }
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
    // add_to_tour visible
    let entreprise = this.props.appActions.dataSheets['entreprise'].items[0]||{};
    let auth_user = this.props.appActions.dataSheets['auth_user'].items[0] || {};
    let profile = auth_user.profile || {}; 
    if (this.state.state0_elAllOrders_visible && !(entreprise.pvd_can_add_to_tour && (profile.hdq === true ? !entreprise.hdq_cannot_add_to_tour : true))) {
      this.setState({
        state0_elAllOrders_visible: false,
        state0_elNotif_visible: false,
      })
    } else if (!this.state.state0_elAllOrders_visible && (entreprise.pvd_can_add_to_tour && (profile.hdq === true ? !entreprise.hdq_cannot_add_to_tour : true))) {
      this.setState({
        state0_elAllOrders_visible: true,
        state0_elNotif_visible: true,
      })
    }
  }

  // --- Functions for component state index 0 (1 of 5) --- 
  
  onClick_state0_elAllOrders = (ev) => {
    let newVal = "4";
    this.props.appActions.updateDataSlot('ds_mode_apercu', newVal);
  
  }
  
  
  onClick_state0_elFind = (ev) => {
    let newVal = "3";
    this.props.appActions.updateDataSlot('ds_mode_apercu', newVal);
  
  }
  
  
  onClick_state0_elTomorrow = (ev) => {
    let newVal = "2";
    this.props.appActions.updateDataSlot('ds_mode_apercu', newVal);
  
  }
  
  
  onClick_state0_elDay = (ev) => {
    let newVal = "1";
    this.props.appActions.updateDataSlot('ds_mode_apercu', newVal);
  
  }
  
  
  onClick_state0_elFab2099238582 = (ev) => {
    let newVal = "0";
    this.props.appActions.updateDataSlot('ds_apercu_visible', newVal);
  
    newVal = "0";
    this.props.appActions.updateDataSlot('ds_mode_apercu', newVal);
  
  }
  
  
  renderState0() {
    const style_state0_elBackground1982019444 = {
      width: '100%',
      height: '100%',
     };
    const style_state0_elBackground1982019444_outer = {
      backgroundColor: 'white',
      filter: 'drop-shadow(0.0px 2.0px 3px rgba(0, 0, 0, 0.3000))',
      overflow: 'visible',
     };
    let transformTextPropValue_allOrders = (input) => {
      return 'Commandes';
    }
    
    const textValue_allOrders = transformTextPropValue_allOrders((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_today_minuit_ts'] : ''));
    
    const style_state0_elAllOrders = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const state0_elAllOrders = this.state.state0_elAllOrders_visible ? (
      <Button className="actionFont state0_elAllOrders" style={style_state0_elAllOrders}  color="accent" onClick={this.onClick_state0_elAllOrders} >
        {textValue_allOrders}
      </Button>
     ) : null;
    let transformPropValue_notif = (input) => {
      let entreprise = this.props.appActions.dataSheets['entreprise'].items[0]||{};
      let auth_user = this.props.appActions.dataSheets['auth_user'].items[0] || {};
      let profile = auth_user.profile || {}; 
      
      if (entreprise.pvd_can_add_to_tour && (profile.hdq === true ? !entreprise.hdq_cannot_add_to_tour : true)) {
        let commands = this.props.appActions.dataSheets['tournees_j'].items
        .filter(it => it.tournee == "" && it.statut === 0)
        .sort((a,b) => a.display_name.localeCompare(b.display_name));
        if (commands.length > 0) {
          return "<p style='color:white;border-radius:100%;background-color:#ff7e79;width:18px;height:18px;'>"+commands.length+"</p>";
        } else {
          return '';
        }
      } else {
        return '';
      }
    }
    
    const value_notif = transformPropValue_notif((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_today_minuit_ts'] : ''));
    
    const style_state0_elNotif = {
      fontSize: 12.3,
      color: '#feffff',
      textAlign: 'center',
      pointerEvents: 'auto',
     };
    
    const style_state0_elFind = {
      display: 'block',
      textTransform: 'none',
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#ffd478',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elTomorrow = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elDay = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_state0_elFab2099238582 = {
      display: 'block',
      textTransform: 'none',
      color: '#fff',
      textAlign: 'left',
      backgroundColor: 'rgba(0, 0, 0, 0.5000)',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Apercu">
        <div className="background">
          <div className="state0_elBackground1982019444" style={style_state0_elBackground1982019444_outer}>
            <div className="cardBg" style={style_state0_elBackground1982019444} />
          </div>
        </div>
        
        <div className="foreground">
          { state0_elAllOrders }
          <div className="systemFontRegular  state0_elNotif" style={style_state0_elNotif}>
            <div><div dangerouslySetInnerHTML={{__html: value_notif}}></div></div>
          </div>
          <Button className="actionFont state0_elFind" style={style_state0_elFind} onClick={this.onClick_state0_elFind} >
            Rechercher un contenant
          </Button>
          <Button className="actionFont state0_elTomorrow" style={style_state0_elTomorrow}  color="accent" onClick={this.onClick_state0_elTomorrow} >
            Voir tournée du lendemain
          </Button>
          <Button className="actionFont state0_elDay" style={style_state0_elDay}  color="accent" onClick={this.onClick_state0_elDay} >
            Voir tournée du jour
          </Button>
          <Button className="actionFont state0_elFab2099238582" style={style_state0_elFab2099238582}  variant="fab" onClick={this.onClick_state0_elFab2099238582} >
            <img src={btn_icon_2099238582} alt="" style={{width: '100%', marginTop: '4%'}} />
          </Button>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 5) --- 
  
  onClick_state1_elFab2099238582 = (ev) => {
    let newVal = "0";
    this.props.appActions.updateDataSlot('ds_apercu_visible', newVal);
  
    newVal = "0";
    this.props.appActions.updateDataSlot('ds_mode_apercu', newVal);
  
  }
  
  
  renderState1() {
    const style_state1_elBackground1982019444 = {
      width: '100%',
      height: '100%',
     };
    const style_state1_elBackground1982019444_outer = {
      backgroundColor: 'white',
      filter: 'drop-shadow(0.0px 2.0px 3px rgba(0, 0, 0, 0.3000))',
      overflow: 'visible',
     };
    let transformPropValue_list = (input) => {
      try {
        let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key === this.props.appActions.dataSlots['ds_selected_tournee']);
        let commands = this.props.appActions.dataSheets['tournees_j'].items
        .filter(it => it.driver_id === tournee.driver_id)
        .sort((a,b) => a.position-b.position)
        .sort((a,b) => a.date-b.date)
      
        return commands.map(it => {return {dataSheetRow: it, key: it.document_key}})
      } catch (error) {
        console.log(error);
        return [];
      }
    }
    
    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    if (Array.isArray(transformPropValue_list((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_today_minuit_ts'] : '')))) {
        items_list = items_list.concat(transformPropValue_list((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_today_minuit_ts'] : '')));
    } else if (transformPropValue_list((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_today_minuit_ts'] : ''))) {
        items_list.push(transformPropValue_list((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_today_minuit_ts'] : '')));
    }
    this._state1_elList_items = [];
    
    const style_state1_elText = {
      fontSize: 14.1,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const style_state1_elFab2099238582 = {
      display: 'block',
      textTransform: 'none',
      color: '#fff',
      textAlign: 'left',
      backgroundColor: 'rgba(0, 0, 0, 0.5000)',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Apercu">
        <div className="background">
          <div className="state1_elBackground1982019444" style={style_state1_elBackground1982019444_outer}>
            <div className="cardBg" style={style_state1_elBackground1982019444} />
          </div>
        </div>
        
        <div className="foreground">
          <div className="hasNestedComps state1_elList">
            <ul>
              {items_list.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list[row._componentId]
                    : <Ot_tournee_j1 appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} {...{ 'dataSheetRow': row['dataSheetRow'], }} ref={(el) => {if (el) this._state1_elList_items.push(el)}} />;
                return (<li key={row.key}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._state1_elList_endMarker = el} />
            </ul>
          </div>
          
          <div className="systemFontBold  state1_elText" style={style_state1_elText}>
            <div>{this.props.locStrings.apercu_text_644022210}</div>
          </div>
          <Button className="actionFont state1_elFab2099238582" style={style_state1_elFab2099238582}  variant="fab" onClick={this.onClick_state1_elFab2099238582} >
            <img src={btn_icon_2099238582} alt="" style={{width: '100%', marginTop: '4%'}} />
          </Button>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 2 (3 of 5) --- 
  
  onClick_state2_elFab2099238582 = (ev) => {
    let newVal = "0";
    this.props.appActions.updateDataSlot('ds_apercu_visible', newVal);
  
    newVal = "0";
    this.props.appActions.updateDataSlot('ds_mode_apercu', newVal);
  
  }
  
  
  renderState2() {
    const style_state2_elBackground1982019444 = {
      width: '100%',
      height: '100%',
     };
    const style_state2_elBackground1982019444_outer = {
      backgroundColor: 'white',
      filter: 'drop-shadow(0.0px 2.0px 3px rgba(0, 0, 0, 0.3000))',
      overflow: 'visible',
     };
    let transformPropValue_list_tomorrow = (input) => {
      try {
        let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key === this.props.appActions.dataSlots['ds_selected_tournee']);
        let commands = this.props.appActions.dataSheets['tournees_apercu'].items
        .filter(it => it.driver_id === tournee.driver_id)
        .sort((a,b) => a.position-b.position)
        .sort((a,b) => a.date-b.date)
      
        return commands.map(it => {return {dataSheetRow: it, key: it.document_key}})
      } catch (error) {
        console.log(error);
        return [];
      }
    }
    
    // Source items and any special components used for list/grid element 'list_tomorrow'.
    let items_list_tomorrow = [];
    let listComps_list_tomorrow = {};
    if (Array.isArray(transformPropValue_list_tomorrow((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_today_minuit_ts'] : '')))) {
        items_list_tomorrow = items_list_tomorrow.concat(transformPropValue_list_tomorrow((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_today_minuit_ts'] : '')));
    } else if (transformPropValue_list_tomorrow((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_today_minuit_ts'] : ''))) {
        items_list_tomorrow.push(transformPropValue_list_tomorrow((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_today_minuit_ts'] : '')));
    }
    this._state2_elList_tomorrow_items = [];
    
    const style_state2_elText = {
      fontSize: 14.1,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const style_state2_elFab2099238582 = {
      display: 'block',
      textTransform: 'none',
      color: '#fff',
      textAlign: 'left',
      backgroundColor: 'rgba(0, 0, 0, 0.5000)',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Apercu">
        <div className="background">
          <div className="state2_elBackground1982019444" style={style_state2_elBackground1982019444_outer}>
            <div className="cardBg" style={style_state2_elBackground1982019444} />
          </div>
        </div>
        
        <div className="foreground">
          <div className="hasNestedComps state2_elList_tomorrow">
            <ul>
              {items_list_tomorrow.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list_tomorrow[row._componentId]
                    : <Ot_tournee_j1 appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} {...{ 'dataSheetRow': row['dataSheetRow'], }} ref={(el) => {if (el) this._state2_elList_tomorrow_items.push(el)}} />;
                return (<li key={row.key}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._state2_elList_tomorrow_endMarker = el} />
            </ul>
          </div>
          
          <div className="systemFontBold  state2_elText" style={style_state2_elText}>
            <div>{this.props.locStrings.apercu_text_360115216}</div>
          </div>
          <Button className="actionFont state2_elFab2099238582" style={style_state2_elFab2099238582}  variant="fab" onClick={this.onClick_state2_elFab2099238582} >
            <img src={btn_icon_2099238582} alt="" style={{width: '100%', marginTop: '4%'}} />
          </Button>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 3 (4 of 5) --- 
  
  onClick_state3_elReset = (ev) => {
    let newVal = "";
    this.setState({site: ''});
    this.props.appActions.updateDataSlot('ds_site_apercu', newVal);
  
  }
  
  
  pickerValueChanged_state3_elSite = (event) => {
    this.setState({site: event.target.value});
    
    this.props.appActions.updateDataSlot("ds_site_apercu", event.target.value);
  }
  
  checkboxChanged_state3_elAnomalies = (event) => {
    this.setState({anomalies: (event.target.checked ? 'true' : 'false')});
  }
  
  textInputChanged_state3_elSearch = (event) => {
    this.setState({search: event.target.value});
  }
  
  onClick_state3_elFab2099238582 = (ev) => {
    let newVal = "0";
    this.props.appActions.updateDataSlot('ds_apercu_visible', newVal);
  
    newVal = "0";
    this.props.appActions.updateDataSlot('ds_mode_apercu', newVal);
  
  }
  
  
  renderState3() {
    // eslint-disable-next-line
    const dataSheet_reference_containers = this.props.appActions.getDataSheet('reference_containers');
    const style_state3_elBackground1982019444 = {
      width: '100%',
      height: '100%',
     };
    const style_state3_elBackground1982019444_outer = {
      backgroundColor: 'white',
      filter: 'drop-shadow(0.0px 2.0px 3px rgba(0, 0, 0, 0.3000))',
      overflow: 'visible',
     };
    
    // Source items and any special components used for list/grid element 'list_contenants'.
    let items_list_contenants = [];
    let listComps_list_contenants = {};
    let filterItems_list_contenants = items => {
      let search_array = this.state.search.trim().replace(/ +(?= )/g,'').split(/\s/);
      let exp = search_array.map(e => RegExp(e, 'gi'));
      const compare = (exp, adr) => adr.search(exp) >= 0;
      
      let output = items
      .filter(it => exp.every(e => compare(e, it.id_scan)) || exp.every(e => compare(e, (it.unresolved_anomalies_labels || []).join(', '))))
      .filter(it => this.state.anomalies === 'false' || (this.state.anomalies === 'true' && (it.unresolved_anomalies_labels || []).length > 0));
      
      if (this.state.site) {
        let itemsOut = [];
        output.forEach(o => {
          try {
            let quaiView = this.props.appActions.dataSheets['quaiView'].items.find(it => it.quais.some(q => q.id_scan == o.id_scan) || it.hors_quais.some(q => q.id_scan == o.id_scan))
            if (quaiView && quaiView.site === this.state.site) {
              itemsOut.push(o);
            }
          } catch (error) {
          }
        })
        return itemsOut;
      } else {
        return output;
      }
    
    }
    items_list_contenants = items_list_contenants.concat(filterItems_list_contenants(this.props.appActions.getDataSheet('reference_containers').items));
    this._state3_elList_contenants_items = [];
    
    
    const style_state3_elRectangle = {
      background: 'rgba(0, 229, 217, 0.200)',
     };
    
    const style_state3_elReset = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_869858924+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    let selection_site = this.state.site;
    // Source datasheet and selected data column for picker element 'site'
    const dataSource_site = this.props.appActions.getDataSheet('quaiView');
    const valueColumnName_site = 'site';
    const labelColumnName_site = 'site';
    
    const style_state3_elSite = {
      pointerEvents: 'auto',
     };
    
    let checked_anomalies = this.state.anomalies;
    
    const style_state3_elAnomalies = {
      pointerEvents: 'auto',
     };
    
    const style_state3_elSearch = {
      display: 'block',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_state3_elText = {
      fontSize: 14.1,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const style_state3_elFab2099238582 = {
      display: 'block',
      textTransform: 'none',
      color: '#fff',
      textAlign: 'left',
      backgroundColor: 'rgba(0, 0, 0, 0.5000)',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Apercu">
        <div className="background">
          <div className="state3_elBackground1982019444" style={style_state3_elBackground1982019444_outer}>
            <div className="cardBg" style={style_state3_elBackground1982019444} />
          </div>
        </div>
        
        <div className="foreground">
          <div className="hasNestedComps state3_elList_contenants">
            <ul>
              {items_list_contenants.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list_contenants[row._componentId]
                    : <Item_immatriculation_apercu appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'reference_containers'} dataSheetRow={row} ref={(el) => {if (el) this._state3_elList_contenants_items.push(el)}} />;
                return (<li key={row.key}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._state3_elList_contenants_endMarker = el} />
            </ul>
          </div>
          
          <div className="state3_elRectangle" style={style_state3_elRectangle} />
          <button className="actionFont state3_elReset" style={style_state3_elReset} onClick={this.onClick_state3_elReset}  />
          <Select className="baseFont state3_elSite" style={style_state3_elSite}  onChange={this.pickerValueChanged_state3_elSite} value={selection_site} >
            {dataSource_site.items.every(item => {
              return item[valueColumnName_site] !== selection_site;
            }) ? <Option value=''/> : null}
            {dataSource_site.items.map(item => {
              const colValue = item[valueColumnName_site];
              const labelColValue = item[labelColumnName_site];
              return <Option key={item.key} value={colValue} label={labelColValue} />
            })}
          </Select>
          <Checkbox className="baseFont state3_elAnomalies" style={style_state3_elAnomalies}  label="Anomalies" onChange={this.checkboxChanged_state3_elAnomalies} checked={checked_anomalies === 'true' || checked_anomalies === true || ''+checked_anomalies === '1'}  />
          <Input className="baseFont state3_elSearch" style={style_state3_elSearch} type="text" hint="Rechercher" onChange={this.textInputChanged_state3_elSearch} value={this.state.search}  />
          <div className="systemFontBold  state3_elText" style={style_state3_elText}>
            <div>{this.state.state3_elText}</div>
          </div>
          <Button className="actionFont state3_elFab2099238582" style={style_state3_elFab2099238582}  variant="fab" onClick={this.onClick_state3_elFab2099238582} >
            <img src={btn_icon_2099238582} alt="" style={{width: '100%', marginTop: '4%'}} />
          </Button>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 4 (5 of 5) --- 
  
  onClick_state4_elFab2099238582 = (ev) => {
    let newVal = "0";
    this.props.appActions.updateDataSlot('ds_apercu_visible', newVal);
  
    newVal = "0";
    this.props.appActions.updateDataSlot('ds_mode_apercu', newVal);
  
  }
  
  
  renderState4() {
    // eslint-disable-next-line
    const dataSheet_reference_containers = this.props.appActions.getDataSheet('reference_containers');
    const style_state4_elBackground1982019444 = {
      width: '100%',
      height: '100%',
     };
    const style_state4_elBackground1982019444_outer = {
      backgroundColor: 'white',
      filter: 'drop-shadow(0.0px 2.0px 3px rgba(0, 0, 0, 0.3000))',
      overflow: 'visible',
     };
    let transformPropValue_allOrdersList = (input) => {
      try {
        let commands = this.props.appActions.dataSheets['tournees_j'].items
        .filter(it => it.tournee == "" && it.statut === 0)
        .sort((a,b) => a.display_name.localeCompare(b.display_name));
      
        return commands.map(it => {return {dataSheetRow: it, key: it.document_key}})
      } catch (error) {
        console.log(error);
        return [];
      }
    }
    
    // Source items and any special components used for list/grid element 'allOrdersList'.
    let items_allOrdersList = [];
    let listComps_allOrdersList = {};
    if (Array.isArray(transformPropValue_allOrdersList((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_today_minuit_ts'] : '')))) {
        items_allOrdersList = items_allOrdersList.concat(transformPropValue_allOrdersList((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_today_minuit_ts'] : '')));
    } else if (transformPropValue_allOrdersList((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_today_minuit_ts'] : ''))) {
        items_allOrdersList.push(transformPropValue_allOrdersList((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_today_minuit_ts'] : '')));
    }
    this._state4_elAllOrdersList_items = [];
    
    const style_state4_elSub = {
      fontSize: 11.4,
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
     };
    const style_state4_elText = {
      fontSize: 14.1,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    const style_state4_elFab2099238582 = {
      display: 'block',
      textTransform: 'none',
      color: '#fff',
      textAlign: 'left',
      backgroundColor: 'rgba(0, 0, 0, 0.5000)',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Apercu">
        <div className="background">
          <div className="state4_elBackground1982019444" style={style_state4_elBackground1982019444_outer}>
            <div className="cardBg" style={style_state4_elBackground1982019444} />
          </div>
        </div>
        
        <div className="foreground">
          <div className="hasNestedComps state4_elAllOrdersList">
            <ul>
              {items_allOrdersList.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_allOrdersList[row._componentId]
                    : <Ot_order_add appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} {...{ 'dataSheetRow': row['dataSheetRow'], }} ref={(el) => {if (el) this._state4_elAllOrdersList_items.push(el)}} />;
                return (<li key={row.key}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._state4_elAllOrdersList_endMarker = el} />
            </ul>
          </div>
          
          <div className="systemFontRegular  state4_elSub" style={style_state4_elSub}>
            <div>{this.state.state4_elSub}</div>
          </div>
          <div className="systemFontBold  state4_elText" style={style_state4_elText}>
            <div>{this.state.state4_elText}</div>
          </div>
          <Button className="actionFont state4_elFab2099238582" style={style_state4_elFab2099238582}  variant="fab" onClick={this.onClick_state4_elFab2099238582} >
            <img src={btn_icon_2099238582} alt="" style={{width: '100%', marginTop: '4%'}} />
          </Button>
        </div>
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
      case 2:
        return this.renderState2();
      case 3:
        return this.renderState3();
      case 4:
        return this.renderState4();
    }
  }
  
}
