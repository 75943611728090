import React, { Component } from 'react';
import './App.css';
import Image_fill from './Image_fill';
import Packmat from './Packmat';
import Comp_list_anomalies_simple from './Comp_list_anomalies_simple';
import img_elImage from './images/Image_fill_elImage_back_1322448678.png';
import btn_icon_42358933 from './images/btn_icon_42358933.png';
import btn_icon_1842697037 from './images/btn_icon_1842697037.png';
import img_elBig_image from './images/Image_fill_elImage_back_1322448678.png';
import Anomalies_explorer2 from './Anomalies_explorer2';
import btn_icon_1791917053 from './images/btn_icon_1791917053.png';
import Apercu from './Apercu';

// UI framework component imports
import Button from 'muicss/lib/react/button';
import Appbar from 'muicss/lib/react/appbar';

export default class PriseEnChargeV3Screen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo, state, type, color, packmat, anomalies, appActions, label

  constructor(props) {
    super(props);
    
    this.state = {
      state: 1,
      title: (<div> </div>),
      title_plainText: " ",
      client: (<div> </div>),
      client_plainText: " ",
      id_scan_txt: (<div> </div>),
      id_scan_txt_plainText: " ",
      state: (<div>-</div>),
      state_plainText: "-",
      site: (<div>&lt;no value from script&gt;</div>),
      site_plainText: "<no value from script>",
      flux: (<div>undefined - Hors quai</div>),
      flux_plainText: "undefined - Hors quai",
      type: (<div>&lt;no value from script&gt;</div>),
      type_plainText: "<no value from script>",
      euxutoire: (<div> </div>),
      euxutoire_plainText: " ",
      elErase_visible: false,
      elCapture_visible: true,
      elAdd_to_tour_visible: false,
      elBig_image_visible: false,
      notif: (<div> </div>),
      notif_plainText: " ",
    };
    this.props.appActions.updateDataSlot('ds_current_anomalies', []);
  }

  componentDidMount() {
        {
      let dataSheet = this.props.appActions.dataSheets['reference_containers'];
      let serviceOptions = this.props.appActions.serviceOptions_reference_containers;
      if ( !this.props.appActions.dataSheetLoaded['reference_containers']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/all_containers", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['reference_containers'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['quaiView'];
      let serviceOptions = this.props.appActions.serviceOptions_quaiView;
      if ( !this.props.appActions.dataSheetLoaded['quaiView']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/quaiView", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['quaiView'] = true;
      }
    }
    let selected_command = this.props.appActions.dataSlots['ds_selected_command'];
    if (Object.keys(selected_command).length) {
      this.setState({
        text_quai: "Quai",
        picker_materiel: selected_command.type || "",
        picker_id_scan: this.props.appActions.dataSlots['ds_scanned_id'] || selected_command.id_scan || "",
        picker_site: selected_command.site,
        checkbox_vide: selected_command.hh === "VIDE" ? 'true' : 'false',
        checkbox_plein: selected_command.hh === "VIDE" ? 'false' : 'true',
        elPicker_flux_visible: selected_command.hh !== 'VIDE',
        elPicker_vide_visible: selected_command.hh == 'VIDE',
        elPicker_quai_visible: (selected_command.hh === "HQ" || selected_command.hh === "VIDE")  ? false : true,
        picker_flux: selected_command.container_id,
        picker_vide: selected_command.type,
        checkbox_quai: (selected_command.hh === "HQ" || selected_command.hh === "VIDE")  ? 'true' : 'false',
        checkbox_a_quai: (selected_command.hh === "HQ" || selected_command.hh === "VIDE")  ? 'false' : 'true',
        picker_quai: selected_command.quai,
        state: selected_command.hh === "VIDE" ? 1 : (selected_command.state>1 ? selected_command.state : 2),
      })
      
      this.props.appActions.updateDataSlot('ds_scanned_id', this.props.appActions.dataSlots['ds_scanned_id'] || selected_command.id_scan || "");
      this.props.appActions.updateDataSlot('ds_selected_command', {...this.props.appActions.dataSlots['ds_selected_command'], id_scan: this.props.appActions.dataSlots['ds_scanned_id'] || selected_command.id_scan || ""});
      
      // disable form
      document.querySelectorAll("select").forEach(el => el.setAttribute("disabled", "true"));
      document.querySelectorAll("input").forEach(el => el.setAttribute("disabled", "true"));
    }
    
    let dech_quai_view = this.props.appActions.dataSheets['quaiView'].items.filter(it => {
      return it.document_key === selected_command.site;
    })[0];
    
    let output = [];
    if (dech_quai_view) {
      dech_quai_view.quais.forEach((q, index) => {
        if (q.id_contenant === selected_command.container_id) {
          let it = {quai: index, key: index};
          output.push(it);
        }
      })
    }

    if (output.length === 0) {
      this.setState({picker_quai: 0});
    } else if (output.length >= 1 && !output.map(q => q.quai).includes(selected_command.quai)) {
      this.setState({picker_quai: output[0].quai});
    }

    this.props.appActions.dataSheets['short_list_quai_number'].loadFromJson(output);

    if (this.state.elCapture_visible && this.props.appActions.dataSlots['ds_saved_picture']) {
      this.setState({
        elCapture_visible: false,
        elErase_visible: true,
      })
    } else if (!this.state.elCapture_visible && !this.props.appActions.dataSlots['ds_saved_picture']) {
      this.setState({
        elCapture_visible: true,
        elErase_visible: false,
      })
    }
    
    try {
      let container = this.props.appActions.dataSheets['reference_containers'].items.find(it => it.id_scan === this.props.appActions.dataSlots['ds_scanned_id'])||{};
      this.props.appActions.updateDataSlot('ds_current_anomalies', container.unresolved_anomalies_labels||[]);
    } catch (e) {
      this.props.appActions.updateDataSlot('ds_current_anomalies', []);
    };
    
    // add_to_tour visible
    let entreprise = this.props.appActions.dataSheets['entreprise'].items[0]||{};

    if (this.state.elAdd_to_tour_visible && !(selected_command.document_key && entreprise.pvd_can_add_to_tour)) {
      this.setState({
        elAdd_to_tour_visible: false,
      })
    } else if (!this.state.elAdd_to_tour_visible && (selected_command.document_key && entreprise.pvd_can_add_to_tour)) {
      this.setState({
        elAdd_to_tour_visible: true,
      })
    }
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
    if (this.state.elCapture_visible && this.props.appActions.dataSlots['ds_saved_picture']) {
      this.setState({
        elCapture_visible: false,
        elErase_visible: true,
      })
    } else if (!this.state.elCapture_visible && !this.props.appActions.dataSlots['ds_saved_picture']) {
      this.setState({
        elCapture_visible: true,
        elErase_visible: false,
      })
    }
    
    // add_to_tour visible
    let entreprise = this.props.appActions.dataSheets['entreprise'].items[0]||{};
    let selected_command = this.props.appActions.dataSlots['ds_selected_command'];
    if (this.state.elAdd_to_tour_visible && !(selected_command.document_key && entreprise.pvd_can_add_to_tour)) {
      this.setState({
        elAdd_to_tour_visible: false,
      })
    } else if (!this.state.elAdd_to_tour_visible && (selected_command.document_key && entreprise.pvd_can_add_to_tour)) {
      this.setState({
        elAdd_to_tour_visible: true,
      })
    }
  }

  onClick_elImage_fill_full = (ev) => {
    if (this.state.checkbox_vide == false || this.state.checkbox_vide == "false") {
      let state = ((this.state.state+1)%6)||2;
      this.setState({state: state});
      this.props.appActions.dataSlots['ds_selected_command'].state = state;
    }
  
  }
  
  
  onClick_elScanner = (ev) => {
    // Go to screen 'Scanner'
    this.props.appActions.goToScreen('scanner', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elImage = (ev) => {
    this.setState({elBig_image_visible: true});
  
  }
  
  
  onClick_elErase = (ev) => {
    let newVal = "";
    this.props.appActions.updateDataSlot('ds_saved_picture', newVal);
  
    this.setState({elCapture_visible: !this.state.elCapture_visible});
  
    this.setState({elErase_visible: !this.state.elErase_visible});
  
  }
  
  
  onClick_elCapture = (ev) => {
    try {
      let container = this.props.appActions.dataSheets['reference_containers'].items.find(it => it.id_scan === this.props.appActions.dataSlots['ds_scanned_id']);
    
      let update = {
        document_key: container.document_key,
        document_ref: container.document_ref,
        unresolved_anomalies_labels: this.props.appActions.dataSlots['ds_current_anomalies'],
      }
      this.props.appActions.dataSheets['reference_containers'].replaceItemByRowIndex(update.document_key, update, this.props.appActions.serviceOptions_reference_containers);
    } catch (e) {
      console.log(e);
    }
  
    // Go to screen 'Appareil photo'
    this.props.appActions.goToScreen('appareilPhoto', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elValid = (ev) => {
    // console.log(this.state);
    if (this.state.picker_site && ((this.state.picker_flux && this.state.checkbox_vide == 'false') || (this.state.checkbox_vide == 'true' && this.state.picker_vide))) {
    } else {
      alert("Formulaire incomplet.");
      return;
    }
    
    let anomalies = "";
    ///////////////// ANOMALIES ///////////////////
    try {
      anomalies = this.props.appActions.dataSlots['ds_current_anomalies'].join(' + ');
    
      let id_client = "";
      let site = "";
      try {
        id_client = this.props.appActions.dataSlots['ds_selected_dech_quai_view'].id_client;
        site = this.props.appActions.dataSlots['ds_selected_dech_site'];
      } catch (e) {
        id_client = "";
        site = "";
      }
    
      try {
        let container = this.props.appActions.dataSheets['reference_containers'].items.find(it => it.id_scan === this.props.appActions.dataSlots['ds_scanned_id']);
    
        let resolved = container.unresolved_anomalies_labels.filter(a => !this.props.appActions.dataSlots['ds_current_anomalies'].includes(a));
    
        let update = {
          document_key: container.document_key,
          document_ref: container.document_ref,
          unresolved_anomalies_labels: this.props.appActions.dataSlots['ds_current_anomalies'],
          photo_anomalie: this.props.appActions.dataSlots['ds_saved_picture']||"",
        }
        this.props.appActions.dataSheets['reference_containers'].replaceItemByRowIndex(update.document_key, update, this.props.appActions.serviceOptions_reference_containers);
    
        if (resolved.length > 0) {
    
          let logHistoryMove = () => {
            let ts = new Date();
            ts =  ts.getTime();
    
            let update = {
              commentaire: resolved.join(' + '),
              coordinates: {
                lat: window.previousPos.coords.latitude, 
                lng: window.previousPos.coords.longitude,
              },
              id_client: id_client,
              id_scan: this.props.appActions.dataSlots['ds_scanned_id'],
              move_type: "RESOLUTION ANOMALIE",
              photo: this.props.appActions.dataSlots['ds_saved_picture'],
              site: site,
              ts: ts,
              user_id: this.props.appActions.dataSlots['ds_userid'],
              user_name: this.props.appActions.dataSlots['ds_username']
            };
            this.props.appActions.dataSheets['history_moves'].addItem(update, this.props.appActions.serviceOptions_history_moves);
          }
    
          if (this.props.appActions.dataSlots['ds_scanned_id']) {
            logHistoryMove();
          } else {
            // alert("Formulaire incomplet.");
            // return;
          }
    
        } 
        if (this.props.appActions.dataSlots['ds_current_anomalies'].length > 0) {
    
          let logHistoryMove = () => {
            let ts = new Date();
            ts =  ts.getTime();
    
            let update = {
              commentaire: anomalies,
              coordinates: {
                lat: window.previousPos.coords.latitude, 
                lng: window.previousPos.coords.longitude,
              },
              id_client: id_client,
              id_scan: this.props.appActions.dataSlots['ds_scanned_id'],
              move_type: "ANOMALIE",
              photo: this.props.appActions.dataSlots['ds_saved_picture'],
              site: site,
              ts: ts,
              user_id: this.props.appActions.dataSlots['ds_userid'],
              user_name: this.props.appActions.dataSlots['ds_username']
            };
            this.props.appActions.dataSheets['history_moves'].addItem(update, this.props.appActions.serviceOptions_history_moves);
          }
    
          if (this.props.appActions.dataSlots['ds_scanned_id']) {
            logHistoryMove();
          } else {
            // alert("Formulaire incomplet.");
            // return;
          }
        }
      } catch (e) {
        console.log(e);
      }
    } catch (error) {
      console.log(error)
    }
    
    ////////// DEPOT SI EN CHARGE ////////////
    let getClient = (id_client) => {
      return this.props.appActions.dataSheets['clients'].items.filter(it => it.document_key == id_client)[0];
    }
    let client = getClient(this.state.picker_site);
    
    //////////////////////////////////////////
    
    this.props.appActions.updateDataSlot('ds_selected_command', {...this.props.appActions.dataSlots['ds_selected_command'], 'id_scan': this.state.picker_id_scan});
    let selected_command = this.props.appActions.dataSlots['ds_selected_command'];
    
    if (selected_command.document_key) {
    
      let getContenant = (id_contenant) => {
        return this.props.appActions.dataSheets['group_contenants'].items.filter(it => it.document_key == id_contenant)[0];
      }
    
      let getClient = (id_client) => {
        return this.props.appActions.dataSheets['clients'].items.filter(it => it.document_key == id_client)[0];
      }
    
      let getDriverById = (id) => {
        return this.props.appActions.dataSheets['drivers'].items.filter(it => it.document_key == id)[0];
      }
    
      let getTruckById = (id) => {
        return this.props.appActions.dataSheets['trucks'].items.filter(it => it.document_key == id)[0];
      }
    
      let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key == this.props.appActions.dataSlots['ds_selected_tournee']);
      let destination_tour = this.props.appActions.dataSheets['tournees_j'].items.filter(it => it.tournee === tournee.document_key);
      destination_tour.sort((a, b) => b.position - a.position);
      let max_position = (destination_tour[0]||{}).position||0;
    
      let ts = new Date();
      ts =  ts.getTime();
      let client = getClient(selected_command.id_client);
      let contenant = getContenant(selected_command.container_id) || {};
      let truck = getTruckById(tournee.truck_id) || {};
      let driver = getDriverById(tournee.driver_id);
    
      let successCharge = () => {
        let update_ot = {
          anomalies: anomalies,
          client: client.name,
          container_realise_id: contenant.document_key || "null",
          container_realise_id: selected_command.container_id,
          coordinates_charge: {
            lat: window.previousPos.coords.latitude, 
            lng: window.previousPos.coords.longitude,
          },
          document_ref: selected_command.document_ref,
          document_key: selected_command.document_key,
          driver: driver.name,
          driver_id: driver.document_key,
          flux_realise: this.state.checkbox_vide == 'true' ? "VIDE" : contenant.flux,
          id_client: client.document_key,
          id_scan: selected_command.id_scan || "",
          photo_p: this.props.appActions.dataSlots['ds_saved_picture']||"",
          position: ((selected_command.tournee !== tournee.document_key) ? (max_position+1) : selected_command.position) || 1,
          statut: ([0, 1, 3].includes(client.place_type) && (this.state.checkbox_a_quai=='true'||this.state.checkbox_a_quai==true)) ? 9 : 10, // pris en charge
          state: this.state.state,
          tournee: tournee.document_key || selected_command.tournee,
          truck: truck.imat || "",
          truck_id: truck.document_key || "",
          ts_charge: ts,
          user_id_charge: this.props.appActions.dataSlots['ds_userid'],
          user_name_charge: this.props.appActions.dataSlots['ds_username'],
        }
        this.props.appActions.updateDataSlot('ds_selected_command', {...this.props.appActions.dataSlots['ds_selected_command'], ...update_ot});
        this.props.appActions.dataSheets['tournees_j'].replaceItemByRowIndex(update_ot.document_key, update_ot, this.props.appActions.serviceOptions_tournees_j);
      }
    
      let logHistoryMove = () => {
        let update = {
          coordinates: {
            lat: window.previousPos.coords.latitude, 
            lng: window.previousPos.coords.longitude,
          },
          flux: this.state.checkbox_vide == 'true' ? this.state.picker_vide : contenant.flux,
          hq: this.state.checkbox_quai == 'true',
          id_client: selected_command.site,
          id_contenant: selected_command.container_id || "null",
          id_scan: selected_command.id_scan || "",
          move_type: "CHARGEMENT",
          packmat: selected_command.packmat||0,
          photo: this.props.appActions.dataSlots['ds_saved_picture']||"",
          quai: parseInt(this.state.picker_quai) || 0,
          reference_ot: selected_command.document_key,
          site: selected_command.display_name,
          state: this.state.state,
          tournee: selected_command.tournee || tournee.document_key,
          ts: ts,
          type: selected_command.type || "",
          user_id: this.props.appActions.dataSlots['ds_userid'],
          user_name: this.props.appActions.dataSlots['ds_username'],
          vide: this.state.checkbox_vide == 'true',
        };
        this.props.appActions.dataSheets['history_moves'].addItem(update, this.props.appActions.serviceOptions_history_moves);
        return update;
      }
    
      let logHistoryMoveDepot = (tournee) => {
        let update = {
          coordinates: {
            lat: window.previousPos.coords.latitude, 
            lng: window.previousPos.coords.longitude,
          },
          flux: tournee.load_detail.flux || "",
          hq: true,
          id_client: selected_command.site,
          id_contenant: tournee.load_detail.id_contenant || "",
          id_scan: tournee.load_detail.id_scan || "",
          move_type: "DEPOT",
          packmat: tournee.load_detail.packmat||0,
          quai: 0,
          reference_ot: tournee.load_detail.reference_ot || "",
          site: selected_command.display_name,
          state: tournee.load_detail.state||0,
          tournee: tournee.load_detail.tournee || selected_command.tournee || "",
          ts: ts,
          type: tournee.load_detail.type || "",
          user_id: this.props.appActions.dataSlots['ds_userid'],
          user_name: this.props.appActions.dataSlots['ds_username'],
          vide: tournee.load_detail.vide || !tournee.loaded_is_full || false,
          no_reset: true,
        };
        this.props.appActions.dataSheets['history_moves'].addItem(update, this.props.appActions.serviceOptions_history_moves);
      }
    
      if (this.state.checkbox_vide == "true") {
      } else {
        successCharge();
      }
    
      // UPDATE QUAI VIEW
      let current_command = this.props.appActions.dataSlots['ds_selected_command'];
      let dech_quai_view = this.props.appActions.dataSheets['quaiView'].items.filter(it => {
        return it.id_client === current_command.site;
      })[0];
    
      try {
        if (dech_quai_view && [0, 1, 3].includes(client.place_type)) {
    
          if (tournee.loaded === true) {
            if ((tournee.load_detail && tournee.load_detail.vide) || !tournee.loaded_is_full) {
              let index = dech_quai_view.hors_quais.findIndex(q => q.id_tournee == selected_command.tournee);
              if (index > -1) {
                dech_quai_view.hors_quais[index].id_tournee = "";
              }
              let new_hq = {
                id_scan: tournee.load_detail.id_scan,
                id_client: current_command.site,
                id_contenant: "VIDE",
                flux: tournee.load_detail.type,
                packmat: 0,
                state: 1,
                type: tournee.load_detail.type,
                id_tournee: selected_command.tournee || "",
              };
              dech_quai_view.hors_quais.push(new_hq);
              logHistoryMoveDepot(tournee);
            }
          }
    
          if ((this.state.checkbox_a_quai=='true'||this.state.checkbox_a_quai==true) && current_command.quai > 0) {
            dech_quai_view.quais[current_command.quai].id_scan = "";
            dech_quai_view.quais[current_command.quai].state = 0;
            dech_quai_view.quais[current_command.quai].packmat = 0;
            if (dech_quai_view.quais[current_command.quai].reserve) {
              dech_quai_view.quais[current_command.quai].id_contenant = "";
              dech_quai_view.quais[current_command.quai].flux = "RESERVE";
            }
          } else {
            if (current_command.hh === 'HQ') {
              let index = -1;
              index = dech_quai_view.hors_quais.findIndex(hq => hq.id_scan === current_command.id_scan && current_command.id_scan != "" && hq.id_contenant === current_command.container_id && hq.id_client === current_command.id_client);
              if (index === -1) {
                index = dech_quai_view.hors_quais.findIndex(hq => hq.id_contenant === current_command.container_id && hq.id_client === current_command.id_client);
              }
              if (index >= 0) {
                dech_quai_view.hors_quais.splice(index, 1);
              } 
            } else if (current_command.hh === 'VIDE') {
              let index = -1;
              index = dech_quai_view.hors_quais.findIndex(hq => hq.id_scan === current_command.id_scan && current_command.id_scan != "" && hq.id_contenant === "VIDE" && hq.flux === current_command.type);
              if (index === -1) {
                index = dech_quai_view.hors_quais.findIndex(hq => hq.id_contenant === "VIDE" && hq.flux === current_command.type);
              }
              if (index >= 0) {
                dech_quai_view.hors_quais.splice(index, 1);
              } 
            }
          }
    
          let update = {
            document_key: dech_quai_view.document_key,
            document_ref: dech_quai_view.document_ref,
            quais: dech_quai_view.quais,
            hors_quais: dech_quai_view.hors_quais,
            ts: new Date().getTime()
          };
          this.props.appActions.dataSheets['quaiView'].replaceItemByRowIndex(update.document_key, update, this.props.appActions.serviceOptions_quaiView);
        }
      } catch (error) {
        console.log(error);
      }
    
      /////////////////////////// HISTORY MOVE //////////////////////
      let effective_move_truck = logHistoryMove();
      /////////////////////////// UPDATE TRUCK LOAD /////////////////
      try {
        let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(t => t.document_key == this.props.appActions.dataSlots['ds_selected_command'].tournee);
        let update = {
          document_key: tournee.document_key,
          document_ref: tournee.document_ref,
          loaded: true,
          loaded_is_full: effective_move_truck.vide ? false : true,
          load_detail: effective_move_truck,
        };
        this.props.appActions.dataSheets['id_tournees_j'].replaceItemByRowIndex(tournee.document_key, update, this.props.appActions.serviceOptions_id_tournees_j);
      } catch (e) {
        console.log(e);
      }
    
      // SANITIZE id_scans
      try {
        if (current_command.id_scan) {
          this.props.appActions.dataSheets['quaiView'].items.forEach(qv => {
            let flag_q = false;
            let flag_hq = false;
            let new_q = [];
            let new_hq = [];
            qv.quais.forEach(q => {
              if (q.id_scan == current_command.id_scan) {
                q.id_scan = "";
                flag_q = true;
              }
              new_q.push(q);
            })
            qv.hors_quais.forEach(hq => {
              if (hq.id_scan === current_command.id_scan && hq.id_contenant === "VIDE") {
                // skip
                flag_hq = true;
              } else if (hq.id_scan === current_command.id_scan) {
                hq.id_scan = "";
                flag_hq = true;
                new_hq.push(hq);
              } else {
                new_hq.push(hq);
              }
            })
            if (flag_q) {qv.quais = new_q}
            if (flag_hq) {qv.hors_quais = new_hq}
            if (flag_q || flag_hq) {this.props.appActions.dataSheets['quaiView'].replaceItemByRowIndex(qv.document_key, qv, this.props.appActions.serviceOptions_quaiView)}
          })
        }
      } catch (e) {
        console.log(e);
      }
    
      // GOTO screen
      if (this.state.checkbox_vide=='true') {
        this.props.appActions.goToScreen('tournéeDuJour', { transitionId: 'fadeIn' });
      } else if (
        (
          [0, 1].includes(client.place_type) && 
          (this.state.checkbox_a_quai=='true'||this.state.checkbox_a_quai==true)
        ) &&
        this.props.appActions.dataSlots['ds_selected_command'].action != 4
      ) {
        this.props.appActions.goToScreen('echange', { transitionId: 'fadeIn' });
      } else {
        this.props.appActions.goToScreen('détailVidage', { transitionId: 'fadeIn' });
      }
    
    } else {
    
      let item = {};
      let placeOrder = (pre_command) => {
        try {
          let getExutoire = (exut) => {
            let output = {};
            let exutoire = this.props.appActions.dataSheets['exutoires'].items.filter(it => it.document_key === exut)[0] || false;
            if (exutoire) {
              output['label'] = exutoire.label;
              output['document_key'] = exutoire.document_key;
              output['location_key'] = exutoire.location_key;
            }
            return output;
          }
    
          let getClientById = (id) => {
            return this.props.appActions.dataSheets['clients'].items.filter(it => it.document_key == id)[0];
          }
    
          let getContenantById = (id) => {
            return this.props.appActions.dataSheets['group_contenants'].items.filter(it => it.document_key == id)[0];
          }
    
          let getDriverById = (id) => {
            return this.props.appActions.dataSheets['drivers'].items.filter(it => it.document_key == id)[0];
          }
    
          let getTruckById = (id) => {
            return this.props.appActions.dataSheets['trucks'].items.filter(it => it.document_key == id)[0];
          }
    
          let getIdTourneeByDriverId = (driver_id) => {
            try {
              return this.props.appActions.dataSheets['id_tournees_history'].items.filter(it => it.driver_id == driver_id)[0].document_key;
            } catch (e) {
              return false;
            }
          }
    
          let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key == this.props.appActions.dataSlots['ds_selected_tournee']);
          let destination_tour = this.props.appActions.dataSheets['tournees_j'].items.filter(it => it.tournee === tournee.document_key);
          destination_tour.sort((a, b) => b.position - a.position);
          let max_position = (destination_tour[0]||{}).position||0;
    
          let date = new Date().setHours(0,0,0,0);
          let now = new Date();
    
          let client = getClientById(pre_command.id_client);
          let contenant = getContenantById(pre_command.container_id) || {};
          let truck = getTruckById(tournee.truck_id) || {};
          let driver = getDriverById(tournee.driver_id);
          let exutoire = contenant.exutoire;
    
          item = {
            anomalies: anomalies,
            abilities: contenant.abilities || [],
            action: contenant.default_action || 1,
            adr: contenant.adr || false,
            client: client.name,
            container_id: contenant.document_key || "null",
            container_id_realise: contenant.document_key || "null",
            coordinates: contenant.coordinates || {
              lat: window.previousPos.coords.latitude, 
              lng: window.previousPos.coords.longitude,
            },
            coordinates_charge: {
              lat: window.previousPos.coords.latitude, 
              lng: window.previousPos.coords.longitude,
            },
            date: date,
            default_action: contenant.default_action || 1,
            display_name: pre_command.display_name || client.name,
            driver: driver.name,
            driver_id: driver.document_key,
            duration: contenant.duration || null,
            etat: 'chauffeur',
            exutoire: pre_command.exutoire || exutoire || {'label': '', 'document_key': '', 'location_key': ''},
            flux: this.state.checkbox_vide == 'true' ? "VIDE" : contenant.flux,
            flux_realise: this.state.checkbox_vide == 'true' ? "VIDE" : contenant.flux,
            heavy: contenant.heavy || false,
            heure: "",
            hh: this.state.checkbox_vide == 'true' ? "VIDE" : (this.state.checkbox_a_quai == 'true' ? "PLEIN" : "HQ"),
            id_client: client.document_key,
            id_scan: this.props.appActions.dataSlots['ds_selected_command'].id_scan || "",
            ouverture: contenant.ouverture || {},
            packmat: pre_command.packmat||0,
            photo: "",
            photo_p: this.props.appActions.dataSlots['ds_saved_picture']||"",
            position: (max_position+1),
            quai: parseInt(this.state.picker_quai) || 0,
            remark: contenant.remark || "",
            report: date,
            sector: client.sector || contenant.sector || 0,
            site: pre_command.site || client.document_key,
            sort_key: client.name + "_" + date,
            state : this.state.checkbox_vide == 'true' ? 1 : (this.state.state||0),
            statut : ([0, 1, 3].includes(client.place_type) && (this.state.checkbox_a_quai=='true'||this.state.checkbox_a_quai==true)) ? 9 : 10, // pris en charge
            tournee: tournee.document_key,
            truck: truck.imat || "",
            truck_id: truck.document_key || "",
            ts: now.getTime(),
            ts_charge: now.getTime(),
            type: this.state.checkbox_vide == 'true' ? this.state.picker_vide : contenant.type,
            user_id: this.props.appActions.dataSlots['ds_userid'],
            user_name: this.props.appActions.dataSlots['ds_username'],
            user_id_charge: this.props.appActions.dataSlots['ds_userid'],
            user_name_charge: this.props.appActions.dataSlots['ds_username'],
            zfe: contenant.zfe || false,      
          }
    
          console.log(item);
    
          if (this.state.checkbox_vide == "true") {
            return new Promise((resolve, reject) => resolve(null));
          } else {
            function snapshotPromise(ref) {
              return new Promise((resolve, reject) => {
                var unsubscribe = ref.onSnapshot((doc) => {
                  resolve(doc.ref);
                  unsubscribe();
                }, (error) => {
                  reject(error);
                });
              });
            }
            let tournees_collection = this.props.appActions.dataSheets['tournees_j'].firebase.firestore().collection("entreprises/"+this.props.appActions.dataSlots['ds_entreprise_id']+"/tournees/");
            let doc = tournees_collection.doc();
            doc.set(item)
            return snapshotPromise(doc)
          }
        } catch (error) {
          console.log(error);
          return new Error("Impossible de créer cette commande.");
        }
      }
    
      /**
          let dech_quai_view = this.props.appActions.dataSheets['quaiView'].items.filter(it => {
            return it.document_key === this.props.appActions.dataSlots['ds_selected_dech_quai_view'].document_key
          })[0];
          **/
    
      placeOrder(selected_command).then(docRef => {
    
        if (docRef) {
          item.document_ref = docRef;
          item.document_key = docRef.id;
        }
        this.props.appActions.updateDataSlot('ds_selected_command', {...this.props.appActions.dataSlots['ds_selected_command'], ...item});
    
        let logHistoryMove = () => {
          let update = {
            coordinates: item.coordinates_charge,
            flux: this.state.checkbox_vide == 'true' ? item.type : item.flux,
            hq: this.state.checkbox_quai == 'true',
            id_client: item.site,
            id_contenant: item.container_id,
            id_scan: item.id_scan || "",
            move_type: "CHARGEMENT",
            packmat: item.packmat||0,
            photo_p: this.props.appActions.dataSlots['ds_saved_picture']||"",
            quai: parseInt(item.quai) || 0,
            reference_ot: item.document_key||"",
            site: item.display_name,
            state: item.state||0,
            tournee: item.tournee,
            ts: item.ts,
            type: item.type || "",
            user_id: this.props.appActions.dataSlots['ds_userid'],
            user_name: this.props.appActions.dataSlots['ds_username'],
            vide: this.state.checkbox_vide == 'true',
          };
          this.props.appActions.dataSheets['history_moves'].addItem(update, this.props.appActions.serviceOptions_history_moves);
          return update;
        }
    
    
        // UPDATE QUAI VIEW
        let current_command = this.props.appActions.dataSlots['ds_selected_command'];
        let client = this.props.appActions.dataSheets['clients'].items.filter(it => it.document_key == item.id_client)[0];
        let dech_quai_view = this.props.appActions.dataSheets['quaiView'].items.filter(it => {
          return it.id_client === current_command.site;
        })[0];
    
        let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.filter(it => it.document_key == this.props.appActions.dataSlots['ds_selected_tournee'])[0];
    
        let logHistoryMoveDepot = (tournee) => {
          let update = {
            coordinates: {
              lat: window.previousPos.coords.latitude, 
              lng: window.previousPos.coords.longitude,
            },
            flux: tournee.load_detail.flux || "",
            hq: true,
            id_client: item.site,
            id_contenant: tournee.load_detail.id_contenant || "",
            id_scan: tournee.load_detail.id_scan || "",
            move_type: "DEPOT",
            packmat: tournee.load_detail.packmat||0,
            quai: 0,
            reference_ot: tournee.load_detail.reference_ot || "",
            site: item.display_name,
            state: tournee.load_detail.state||0,
            tournee: tournee.load_detail.tournee || selected_command.tournee || "",
            ts: item.ts,
            type: tournee.load_detail.type || "",
            user_id: this.props.appActions.dataSlots['ds_userid'],
            user_name: this.props.appActions.dataSlots['ds_username'],
            vide: tournee.load_detail.vide || !tournee.loaded_is_full || false,
            no_reset: true,
          };
          this.props.appActions.dataSheets['history_moves'].addItem(update, this.props.appActions.serviceOptions_history_moves);
        }
    
        try {
          if (dech_quai_view && [0, 1, 3].includes(client.place_type)) {
    
            if (tournee.loaded === true) {
              if ((tournee.load_detail && tournee.load_detail.vide) || !tournee.loaded_is_full) {
                let index = dech_quai_view.hors_quais.findIndex(q => q.id_tournee == selected_command.tournee);
                if (index > -1) {
                  dech_quai_view.hors_quais[index].id_tournee = "";
                }
                let new_hq = {
                  id_scan: tournee.load_detail.id_scan,
                  id_client: current_command.site,
                  id_contenant: "VIDE",
                  flux: tournee.load_detail.type,
                  packmat: 0,
                  state: 1,
                  type: tournee.load_detail.type,
                  id_tournee: selected_command.tournee || "",
                };
                dech_quai_view.hors_quais.push(new_hq);
                logHistoryMoveDepot(tournee);
              }
            }
    
            if ((this.state.checkbox_a_quai=='true'||this.state.checkbox_a_quai==true) && current_command.quai > 0) {
              dech_quai_view.quais[current_command.quai].id_scan = "";
              dech_quai_view.quais[current_command.quai].state = 0;
              dech_quai_view.quais[current_command.quai].packmat = 0;
              if (dech_quai_view.quais[current_command.quai].reserve) {
                dech_quai_view.quais[current_command.quai].id_contenant = "";
                dech_quai_view.quais[current_command.quai].flux = "RESERVE";
              }
            } else {
              if (current_command.hh === 'HQ') {
                let index = -1;
                index = dech_quai_view.hors_quais.findIndex(hq => hq.id_scan === current_command.id_scan && current_command.id_scan != "" && hq.id_contenant === current_command.container_id && hq.id_client === current_command.id_client);
                if (index === -1) {
                  index = dech_quai_view.hors_quais.findIndex(hq => hq.id_contenant === current_command.container_id && hq.id_client === current_command.id_client);
                }
                if (index >= 0) {
                  dech_quai_view.hors_quais.splice(index, 1);
                } 
              } else if (current_command.hh === 'VIDE') {
                let index = -1;
                index = dech_quai_view.hors_quais.findIndex(hq => hq.id_scan === current_command.id_scan && current_command.id_scan != "" && hq.id_contenant === "VIDE" && hq.flux === current_command.type);
                if (index === -1) {
                  index = dech_quai_view.hors_quais.findIndex(hq => hq.id_contenant === "VIDE" && hq.flux === current_command.type);
                }
                if (index >= 0) {
                  dech_quai_view.hors_quais.splice(index, 1);
                } 
              }
            }
    
            let update = {
              document_key: dech_quai_view.document_key,
              document_ref: dech_quai_view.document_ref,
              quais: dech_quai_view.quais,
              hors_quais: dech_quai_view.hors_quais,
              ts: new Date().getTime()
            };
            this.props.appActions.dataSheets['quaiView'].replaceItemByRowIndex(update.document_key, update, this.props.appActions.serviceOptions_quaiView);
          }
        } catch (error) {
          console.log(error);
        }
    
        /////////////////////////// HISTORY MOVE //////////////////////
        let effective_move_truck = logHistoryMove();
        /////////////////////////// UPDATE TRUCK LOAD /////////////////
        try {
          let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(t => t.document_key == this.props.appActions.dataSlots['ds_selected_command'].tournee);
          let update = {
            document_key: tournee.document_key,
            document_ref: tournee.document_ref,
            loaded: true,
            loaded_is_full: effective_move_truck.vide ? false : true,
            load_detail: effective_move_truck,
          };
          this.props.appActions.dataSheets['id_tournees_j'].replaceItemByRowIndex(tournee.document_key, update, this.props.appActions.serviceOptions_id_tournees_j);
        } catch (e) {
          console.log(e);
        }
    
        // SANITIZE id_scans
        try {
          if (current_command.id_scan) {
            this.props.appActions.dataSheets['quaiView'].items.forEach(qv => {
              let flag_q = false;
              let flag_hq = false;
              let new_q = [];
              let new_hq = [];
              qv.quais.forEach(q => {
                if (q.id_scan == current_command.id_scan) {
                  q.id_scan = "";
                  flag_q = true;
                }
                new_q.push(q);
              })
              qv.hors_quais.forEach(hq => {
                if (hq.id_scan === current_command.id_scan && hq.id_contenant === "VIDE") {
                  // skip
                  flag_hq = true;
                } else if (hq.id_scan === current_command.id_scan) {
                  hq.id_scan = "";
                  flag_hq = true;
                  new_hq.push(hq);
                } else {
                  new_hq.push(hq);
                }
              })
              if (flag_q) {qv.quais = new_q}
              if (flag_hq) {qv.hors_quais = new_hq}
              if (flag_q || flag_hq) {this.props.appActions.dataSheets['quaiView'].replaceItemByRowIndex(qv.document_key, qv, this.props.appActions.serviceOptions_quaiView)}
            })
          }
        } catch (e) {
          console.log(e);
        }
    
        // GOTO screen
        if (this.state.checkbox_vide=='true') {
          this.props.appActions.goToScreen('tournéeDuJour', { transitionId: 'fadeIn' });
        } else if (
          (
            [0, 1].includes(client.place_type) && 
            (this.state.checkbox_a_quai=='true'||this.state.checkbox_a_quai==true)
          ) &&
          this.props.appActions.dataSlots['ds_selected_command'].action != 4
        ) {
          this.props.appActions.goToScreen('echange', { transitionId: 'fadeIn' });
        } else {
          this.props.appActions.goToScreen('détailVidage', { transitionId: 'fadeIn' });
        }
    
      }).catch(err => { window.alert("Impossible de créer cette commande."); return});
    }
    
  
    let newVal = "0";
    this.props.appActions.updateDataSlot('ds_vide_autre_view', newVal);
  
    newVal = "";
    this.props.appActions.updateDataSlot('ds_saved_picture', newVal);
  
    newVal = "";
    this.props.appActions.updateDataSlot('ds_scanned_id', newVal);
  
    newVal = "";
    this.props.appActions.updateDataSlot('ds_current_anomalies', newVal);
  
  }
  
  
  onClick_elAdd_to_tour = (ev) => {
    // console.log(this.state);
    if (this.state.picker_site && ((this.state.picker_flux && this.state.checkbox_vide == 'false') || (this.state.checkbox_vide == 'true' && this.state.picker_vide))) {
    } else {
      alert("Formulaire incomplet.");
      return;
    }
    
    let anomalies = "";
    ///////////////// ANOMALIES ///////////////////
    try {
      anomalies = this.props.appActions.dataSlots['ds_current_anomalies'].join(' + ');
    
      let id_client = "";
      let site = "";
      try {
        id_client = this.props.appActions.dataSlots['ds_selected_dech_quai_view'].id_client;
        site = this.props.appActions.dataSlots['ds_selected_dech_site'];
      } catch (e) {
        id_client = "";
        site = "";
      }
    
      try {
        let container = this.props.appActions.dataSheets['reference_containers'].items.find(it => it.id_scan === this.props.appActions.dataSlots['ds_scanned_id']);
    
        let resolved = container.unresolved_anomalies_labels.filter(a => !this.props.appActions.dataSlots['ds_current_anomalies'].includes(a));
    
        let update = {
          document_key: container.document_key,
          document_ref: container.document_ref,
          unresolved_anomalies_labels: this.props.appActions.dataSlots['ds_current_anomalies'],
          photo_anomalie: this.props.appActions.dataSlots['ds_saved_picture']||"",
        }
        this.props.appActions.dataSheets['reference_containers'].replaceItemByRowIndex(update.document_key, update, this.props.appActions.serviceOptions_reference_containers);
    
        if (resolved.length > 0) {
    
          let logHistoryMove = () => {
            let ts = new Date();
            ts =  ts.getTime();
    
            let update = {
              commentaire: resolved.join(' + '),
              coordinates: {
                lat: window.previousPos.coords.latitude, 
                lng: window.previousPos.coords.longitude,
              },
              id_client: id_client,
              id_scan: this.props.appActions.dataSlots['ds_scanned_id'],
              move_type: "RESOLUTION ANOMALIE",
              photo: this.props.appActions.dataSlots['ds_saved_picture'],
              site: site,
              ts: ts,
              user_id: this.props.appActions.dataSlots['ds_userid'],
              user_name: this.props.appActions.dataSlots['ds_username']
            };
            this.props.appActions.dataSheets['history_moves'].addItem(update, this.props.appActions.serviceOptions_history_moves);
          }
    
          if (this.props.appActions.dataSlots['ds_scanned_id']) {
            logHistoryMove();
          } else {
            // alert("Formulaire incomplet.");
            // return;
          }
    
        } 
        if (this.props.appActions.dataSlots['ds_current_anomalies'].length > 0) {
    
          let logHistoryMove = () => {
            let ts = new Date();
            ts =  ts.getTime();
    
            let update = {
              commentaire: anomalies,
              coordinates: {
                lat: window.previousPos.coords.latitude, 
                lng: window.previousPos.coords.longitude,
              },
              id_client: id_client,
              id_scan: this.props.appActions.dataSlots['ds_scanned_id'],
              move_type: "ANOMALIE",
              photo: this.props.appActions.dataSlots['ds_saved_picture'],
              site: site,
              ts: ts,
              user_id: this.props.appActions.dataSlots['ds_userid'],
              user_name: this.props.appActions.dataSlots['ds_username']
            };
            this.props.appActions.dataSheets['history_moves'].addItem(update, this.props.appActions.serviceOptions_history_moves);
          }
    
          if (this.props.appActions.dataSlots['ds_scanned_id']) {
            logHistoryMove();
          } else {
            // alert("Formulaire incomplet.");
            // return;
          }
        }
      } catch (e) {
        console.log(e);
      }
    } catch (error) {
      console.log(error)
    }
    
    ////////// DEPOT SI EN CHARGE ////////////
    let getClient = (id_client) => {
      return this.props.appActions.dataSheets['clients'].items.filter(it => it.document_key == id_client)[0];
    }
    let client = getClient(this.state.picker_site);
    
    //////////////////////////////////////////
    
    this.props.appActions.updateDataSlot('ds_selected_command', {...this.props.appActions.dataSlots['ds_selected_command'], 'id_scan': this.state.picker_id_scan});
    let selected_command = this.props.appActions.dataSlots['ds_selected_command'];
    
    if (selected_command.document_key) {
    
      let getContenant = (id_contenant) => {
        return this.props.appActions.dataSheets['group_contenants'].items.filter(it => it.document_key == id_contenant)[0];
      }
    
      let getClient = (id_client) => {
        return this.props.appActions.dataSheets['clients'].items.filter(it => it.document_key == id_client)[0];
      }
    
      let getDriverById = (id) => {
        return this.props.appActions.dataSheets['drivers'].items.filter(it => it.document_key == id)[0];
      }
    
      let getTruckById = (id) => {
        return this.props.appActions.dataSheets['trucks'].items.filter(it => it.document_key == id)[0];
      }
    
      let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key == this.props.appActions.dataSlots['ds_selected_tournee']);
      let destination_tour = this.props.appActions.dataSheets['tournees_j'].items.filter(it => it.tournee === tournee.document_key);
      destination_tour.sort((a, b) => b.position - a.position);
      let max_position = (destination_tour[0]||{}).position||0;
    
      let ts = new Date();
      ts =  ts.getTime();
      let client = getClient(selected_command.id_client);
      let contenant = getContenant(selected_command.container_id) || {};
      let truck = getTruckById(tournee.truck_id) || {};
      let driver = getDriverById(tournee.driver_id);
    
      let successCharge = () => {
        let update_ot = {
          document_ref: selected_command.document_ref,
          document_key: selected_command.document_key,
          driver: driver.name,
          driver_id: driver.document_key,
          id_scan: selected_command.id_scan || "",
          photo_p: this.props.appActions.dataSlots['ds_saved_picture']||"",
          position: ((selected_command.tournee !== tournee.document_key) ? (max_position+1) : selected_command.position) || 1,
          state: this.state.state,
          tournee: tournee.document_key || selected_command.tournee,
          truck: truck.imat || "",
          truck_id: truck.document_key || "",
        }
        this.props.appActions.updateDataSlot('ds_selected_command', {...this.props.appActions.dataSlots['ds_selected_command'], ...update_ot});
        this.props.appActions.dataSheets['tournees_j'].replaceItemByRowIndex(update_ot.document_key, update_ot, this.props.appActions.serviceOptions_tournees_j);
      }
    
      if (this.state.checkbox_vide == "true") {
      } else {
        successCharge();
      }
    
      // GOTO screen (back)
      this.props.appActions.goBack();
    
    }
  
    let newVal = "0";
    this.props.appActions.updateDataSlot('ds_vide_autre_view', newVal);
  
    newVal = "";
    this.props.appActions.updateDataSlot('ds_saved_picture', newVal);
  
    newVal = "";
    this.props.appActions.updateDataSlot('ds_scanned_id', newVal);
  
    newVal = "";
    this.props.appActions.updateDataSlot('ds_current_anomalies', newVal);
  
  }
  
  
  onClick_elValidCopy = (ev) => {
    // Go back in screen navigation history
    this.props.appActions.goBack();
  
    let newVal = "";
    this.props.appActions.updateDataSlot('ds_saved_picture', newVal);
  
    newVal = "";
    this.props.appActions.updateDataSlot('ds_scanned_id', newVal);
  
    newVal = "";
    this.props.appActions.updateDataSlot('ds_current_anomalies', newVal);
  
  }
  
  
  onClick_elBig_image = (ev) => {
    this.setState({elBig_image_visible: false});
  
  }
  
  
  onClick_elAnomalies_explorer = (ev) => {
    this.setState(this.state);
  
  }
  
  
  onClick_elCancel = (ev) => {
    let newVal = "1";
    this.props.appActions.updateDataSlot('ds_apercu_visible', newVal);
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };
    let transformPropValue_title = (input) => {
      if (this.state.checkbox_vide == "true") {
        return "Charger le contenant vide?";
      } else {
        return "Confirmer la prise en charge?";
      }
    }
    
    const value_title = transformPropValue_title((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_command'] : ''));
    
    const style_elTitle = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const style_elRectangle = {
      background: 'rgba(213, 213, 213, 1.000)',
     };
    let transformPropValue_client = (input) => {
      if (input.id_client !== input.site) {
        return "Lieu de production : " + input.client;
      } else {
        return "";
      }
    }
    
    const value_client = transformPropValue_client((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_command'] : ''));
    
    const style_elClient = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elCard_ot_outer = {
      backgroundColor: 'white',
      filter: 'drop-shadow(0.0px 2.0px 3px rgba(0, 0, 0, 0.3000))',
      overflow: 'visible',
     };
    
    let transformValue_state_elImage_fill_full = (input) => {
      return this.state.state || 1;
    
    }
    
    let transformValue_type_elImage_fill_full = (input) => {
      let getContenantById = (id) => {
        return this.props.appActions.dataSheets['group_contenants'].items.find(it => it.document_key == id);
      }
      
      let getFluxByMatiere = (matiere) => {
        return this.props.appActions.dataSheets['matieres'].items.find(it => it.matiere == matiere);
      }
      
      let getMaterialByType = (type) => {
        return this.props.appActions.dataSheets['materiels'].items.find(it => it.type == type);
      }
      
      if (input.container_id == "null") {
        let type = (getMaterialByType(input.flux)||{}).code_shape||"30";
        return type;
      } else {
        let contenant = getContenantById(input.container_id) || {};
        let type = (getMaterialByType(contenant.type)||{}).code_shape||"30";
        return type;
      }
    }
    
    let transformValue_color_elImage_fill_full = (input) => {
      let getContenantById = (id) => {
        return this.props.appActions.dataSheets['group_contenants'].items.find(it => it.document_key == id);
      }
      
      let getFluxByMatiere = (matiere) => {
        return this.props.appActions.dataSheets['matieres'].items.find(it => it.matiere == matiere);
      }
      
      let getMaterialByType = (type) => {
        return this.props.appActions.dataSheets['materiels'].items.find(it => it.type == type);
      }
      
      return (getFluxByMatiere(input.flux)||{}).color || "#000000";
    }
    const style_elImage_fill_full = {
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elScanner = {
      display: 'block',
      color: '#00e5d9',
      textAlign: 'center',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_id_scan_txt = (input) => {
      return input || "";
    }
    let transformCustomCSSPropValue_id_scan_txt = (input) => {
      let style = '"borderRadius": "5px"';
      try {
        let container = this.props.appActions.dataSheets['reference_containers'].items.find(it => it.id_scan === input);
        if (container.unresolved_anomalies_labels.length) {
          style += ',"backgroundColor": "#FFD479"';
        } else {
          if (input) {
            style += ',"backgroundColor": "#EBEBEB"';
          }
        } 
      } catch (e) {
        if (input) {
          style += ',"backgroundColor": "#EBEBEB"';
        }
      }
      
      return style;
    }
    
    const value_id_scan_txt = transformPropValue_id_scan_txt((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_scanned_id'] : ''));
    
    const style_elId_scan_txt = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
      ...(() => {  // append customCSS property of 'id_scan_txt'
        let val = transformCustomCSSPropValue_id_scan_txt((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_scanned_id'] : ''));
        if (val == null || val.length == 0) return null;
        try {
          return JSON.parse('{'+val+'}');
        } catch (error) {
          console.log(error);
        }
        return null;
      })(),
     };
    
    let transformValue_packmat_elPackmat_display = (input) => {
      // This function modifies the value for property 'packmat'.
      // There is a variable named 'input' that provides the property value.
      //
      return input.packmat;
    
    }
    let transformVisiblePropValue_packmat_display = (input) => {
      return input.packmat > 0;
    }
    const elPackmat_display = ((val) => { return val === "true" || val == true || val == 1 })(transformVisiblePropValue_packmat_display((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_command'] : ''))) ? (
      <div className="hasNestedComps elPackmat_display">
        <div>
          <Packmat packmat={transformValue_packmat_elPackmat_display((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_command'] : ''))} visualStateIndex={2} ref={(el)=> this._elPackmat_display = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
        </div>
      </div>
      
     ) : null;
    let transformPropValue_state = (input) => {
      try {
        let state = "";
        if (this.state.state == 0) {
          state = "-";
        } else {
          state = (this.state.state-1)*25 + "%";
        }
        return state;
      } catch(e) {
        return "-"
      }
    }
    
    const value_state = transformPropValue_state((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_command'] : ''));
    
    const style_elState = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };
    let transformPropValue_site = (input) => {
      return input.display_name;
    }
    
    const value_site = transformPropValue_site((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_command'] : ''));
    
    const style_elSite = {
      fontSize: 14.1,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_flux = (input) => {
      return input.flux + (input.quai ? (" - quai " + input.quai) : " - Hors quai");
    }
    
    const value_flux = transformPropValue_flux((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_command'] : ''));
    
    const style_elFlux = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_type = (input) => {
      return input.type;
    }
    
    const value_type = transformPropValue_type((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_command'] : ''));
    
    const style_elType = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    let transformPropValue_euxutoire = (input) => {
      if (input.action == 1 || input.action == 4) {
        return input.exutoire ? input.exutoire.label : '';
      } else {
        return '';
      }
    }
    
    const value_euxutoire = transformPropValue_euxutoire((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_command'] : ''));
    
    const style_elEuxutoire = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elComp_list_anomalies_outer = {
      backgroundColor: '#feffff',
      filter: 'drop-shadow(0.0px 2.0px 3px rgba(0, 0, 0, 0.3000))',
      overflow: 'visible',
     };
    let transformVisiblePropValue_comp_list_anomalies = (input) => {
      return input != "";
    }
    const elComp_list_anomalies = ((val) => { return val === "true" || val == true || val == 1 })(transformVisiblePropValue_comp_list_anomalies((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_scanned_id'] : ''))) ? (
      <div className="hasNestedComps elComp_list_anomalies" style={style_elComp_list_anomalies_outer}>
        <div>
          <Comp_list_anomalies_simple ref={(el)=> this._elComp_list_anomalies = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
        </div>
      </div>
      
     ) : null;
    const style_elCard_photo_outer = {
      backgroundColor: 'white',
      filter: 'drop-shadow(0.0px 2.0px 3px rgba(0, 0, 0, 0.3000))',
      overflow: 'visible',
     };
    const style_elImage = {
      backgroundImage: 'url('+((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_saved_picture'] : '') || img_elImage)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elErase = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_42358933+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elErase = this.state.elErase_visible ? (
      <div className="elErase">
        <button className="actionFont" style={style_elErase} onClick={this.onClick_elErase}  />
      </div>
      
     ) : null;
    
    const style_elCapture = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_1842697037+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '17.850%',
      paddingLeft: '18%',
      backgroundPosition: '0% 50%',
      color: '#00e5d9',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elCapture = this.state.elCapture_visible ? (
      <div className="elCapture">
        <button className="actionFont" style={style_elCapture} onClick={this.onClick_elCapture} >
          Ajouter une photo
        </button>
      </div>
      
     ) : null;
    
    const style_elValid = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elAdd_to_tour = {
      display: 'block',
      color: '#00e5d9',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elAdd_to_tour = this.state.elAdd_to_tour_visible ? (
      <div className="elAdd_to_tour">
        <button className="actionFont" style={style_elAdd_to_tour} onClick={this.onClick_elAdd_to_tour} >
          Ajouter à ma tournée
        </button>
      </div>
      
     ) : null;
    
    const style_elValidCopy = {
      display: 'block',
      textTransform: 'none',
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#ff7d78',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elBig_image = {
      backgroundImage: 'url('+((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_saved_picture'] : '') || img_elBig_image)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elBig_image = this.state.elBig_image_visible ? (
      <div className="elBig_image" style={style_elBig_image} onClick={this.onClick_elBig_image}  />
     ) : null;
    
    let transformValue_anomalies_elAnomalies_explorer = (input) => {
      let container = this.props.appActions.dataSheets['reference_containers'].items.find(it => it.id_scan === input)||{};
      
      if (this.props.appActions.dataSlots['ds_source_anomalie'] == "solve") {
        return (container.unresolved_anomalies_labels||[]).filter(it => !this.props.appActions.dataSlots['ds_current_anomalies'].includes(it));
      } else {
        return (container.unresolved_anomalies_labels||[]).concat(this.props.appActions.dataSlots['ds_current_anomalies']);
      }
    }
    const style_elAnomalies_explorer = {
      width: '100%',
      height: '100%',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elAnomalies_explorer_outer = {
      backgroundColor: '#feffff',
      filter: 'drop-shadow(0.0px 2.0px 3px rgba(0, 0, 0, 0.3000))',
      overflow: 'visible',
     };
    const elAnomalies_explorer = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_select_anomalies_visible'] : '')) ? (
      <div className="hasNestedComps elAnomalies_explorer" style={style_elAnomalies_explorer_outer}>
        <div style={style_elAnomalies_explorer} onClick={this.onClick_elAnomalies_explorer} >
          <Anomalies_explorer2 anomalies={transformValue_anomalies_elAnomalies_explorer((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_scanned_id'] : ''))} ref={(el)=> this._elAnomalies_explorer = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
        </div>
      </div>
      
     ) : null;
    
    const style_elCancel = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_1791917053+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_notif = (input) => {
      let entreprise = this.props.appActions.dataSheets['entreprise'].items[0]||{};
      let auth_user = this.props.appActions.dataSheets['auth_user'].items[0] || {};
      let profile = auth_user.profile || {}; 
      
      if (entreprise.pvd_can_add_to_tour && (profile.hdq === true ? !entreprise.hdq_cannot_add_to_tour : true)) {
        let commands = this.props.appActions.dataSheets['tournees_j'].items
        .filter(it => it.tournee == "" && it.statut === 0)
        .sort((a,b) => a.display_name.localeCompare(b.display_name));
        if (commands.length > 0) {
          return "<p style='color:white;border-radius:100%;background-color:#ff7e79;width:18px;height:18px;'>"+commands.length+"</p>";
        } else {
          return '';
        }
      } else {
        return '';
      }
    }
    
    const value_notif = transformPropValue_notif((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_today_minuit_ts'] : ''));
    
    const style_elNotif = {
      fontSize: 12.3,
      color: '#feffff',
      textAlign: 'center',
      pointerEvents: 'auto',
     };
    const elApercu = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_apercu_visible'] : '')) ? (
      <div className="hasNestedComps containerMinHeight elApercu">
        <Apercu visualStateIndex={(this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_mode_apercu'] : '')} ref={(el)=> this._elApercu = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    return (
      <div className="AppScreen PriseEnChargeV3Screen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elSpacer">
            <div />
          </div>
          
          <div className="elTitle">
            <div className="headlineFont" style={style_elTitle}>
              <div>{value_title !== undefined ? value_title : (<span className="propValueMissing">{this.state.title}</span>)}</div>
            </div>
          </div>
          
          <div className="elRectangle">
            <div style={style_elRectangle} />
          </div>
          
          <div className="elClient">
            <div className="baseFont" style={style_elClient}>
              <div>{value_client !== undefined ? value_client : (<span className="propValueMissing">{this.state.client}</span>)}</div>
            </div>
          </div>
          
          <div className="flowRow flowRow_PriseEnChargeV3Screen_elCard_ot_399491667">
          <div className="elCard_ot" style={style_elCard_ot_outer}>
            <div className="cardBg" />
          </div>
          
          <div className="hasNestedComps elImage_fill_full">
            <div style={style_elImage_fill_full} onClick={this.onClick_elImage_fill_full} >
              <Image_fill state={transformValue_state_elImage_fill_full((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_command'] : ''))} type={transformValue_type_elImage_fill_full((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_command'] : ''))} color={transformValue_color_elImage_fill_full((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_command'] : ''))} ref={(el)=> this._elImage_fill_full = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
          
          <div className="elScanner">
            <button className="actionFont" style={style_elScanner} onClick={this.onClick_elScanner} >
              Scanner
            </button>
          </div>
          
          <div className="elId_scan_txt">
            <div className="actionFont" style={style_elId_scan_txt}>
              <div>{value_id_scan_txt !== undefined ? value_id_scan_txt : (<span className="propValueMissing">{this.state.id_scan_txt}</span>)}</div>
            </div>
          </div>
          
          { elPackmat_display }
          <div className="elState">
            <div className="baseFont" style={style_elState}>
              <div>{value_state !== undefined ? value_state : (<span className="propValueMissing">{this.state.state}</span>)}</div>
            </div>
          </div>
          
          </div>
          <div className="elSite">
            <div className="systemFontBold" style={style_elSite}>
              <div>{value_site !== undefined ? value_site : (<span className="propValueMissing">{this.state.site}</span>)}</div>
            </div>
          </div>
          
          <div className="elFlux">
            <div className="baseFont" style={style_elFlux}>
              <div>{value_flux !== undefined ? value_flux : (<span className="propValueMissing">{this.state.flux}</span>)}</div>
            </div>
          </div>
          
          <div className="elType">
            <div className="baseFont" style={style_elType}>
              <div>{value_type !== undefined ? value_type : (<span className="propValueMissing">{this.state.type}</span>)}</div>
            </div>
          </div>
          
          <div className="elEuxutoire">
            <div className="baseFont" style={style_elEuxutoire}>
              <div>{value_euxutoire !== undefined ? value_euxutoire : (<span className="propValueMissing">{this.state.euxutoire}</span>)}</div>
            </div>
          </div>
          
          <div className="elSpacerCopy">
            <div />
          </div>
          
          { elComp_list_anomalies }
          <div className="flowRow flowRow_PriseEnChargeV3Screen_elCard_photo_1591097439">
          <div className="elCard_photo" style={style_elCard_photo_outer}>
            <div className="cardBg" />
          </div>
          
          <div className="elImage">
            <div style={style_elImage} onClick={this.onClick_elImage}  />
          </div>
          
          { elErase }
          { elCapture }
          </div>
          <div className="elSpacerCopy2">
            <div />
          </div>
          
          <div className="elValid">
            <Button className="actionFont" style={style_elValid}  color="accent" onClick={this.onClick_elValid} >
              Valider
            </Button>
          </div>
          
          { elAdd_to_tour }
          <div className="elValidCopy">
            <Button className="actionFont" style={style_elValidCopy} onClick={this.onClick_elValidCopy} >
              Annuler
            </Button>
          </div>
          
          <div className="elSpacer2">
            <div />
          </div>
        </div>
        <Appbar className="navBar">
          <div className="title">Prise en charge (Etape 1/4)</div></Appbar>
        
        <div className="screenFgContainer">
          <div className="foreground">
            { elBig_image }
            { elAnomalies_explorer }
            <button className="actionFont elCancel" style={style_elCancel} onClick={this.onClick_elCancel}  />
            <div className="systemFontRegular  elNotif" style={style_elNotif}>
              <div><div dangerouslySetInnerHTML={{__html: value_notif}}></div></div>
            </div>
            { elApercu }
          </div>
        </div>
      </div>
    )
  }
  
}
