import React, { Component } from 'react';
import './App.css';
import btn_icon_68533342 from './images/btn_icon_68533342.png';

export default class Done extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
      ts: (<div>1/11/2023, 1:51:19 PM</div>),
      ts_plainText: "1/11/2023, 1:51:19 PM",
      textcopy: (<div>Mise à jour effectuée</div>),
      textcopy_plainText: "Mise à jour effectuée",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  render() {
    let transformPropValue_ts = (input) => {
      return new Date().toLocaleString();
    }
    
    const value_ts = transformPropValue_ts((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_dech_quai_view'] : ''));
    
    const style_elTs = {
      color: '#feffff',
      textAlign: 'center',
     };
    
    const style_elIconButton = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_68533342+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '89.562%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
     };
    const style_elTextCopy = {
      color: '#feffff',
      textAlign: 'center',
     };
    
    return (
      <div className="Done highlightBg">
        <div className="foreground">
          <div className="baseFont elTs" style={style_elTs}>
            <div>{value_ts !== undefined ? value_ts : (<span className="propValueMissing">{this.state.ts}</span>)}</div>
          </div>
          <button className="actionFont elIconButton" style={style_elIconButton} />
          <div className="baseFont elTextCopy" style={style_elTextCopy}>
            <div>{this.state.textcopy}</div>
          </div>
        </div>
      </div>
    )
  }
  
}
