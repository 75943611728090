import React, { Component } from 'react';
import './App.css';
import btn_icon_1139092073 from './images/btn_icon_1139092073.png';
import btn_icon_573832028 from './images/btn_icon_573832028.png';

// UI framework component imports
import Button from 'muicss/lib/react/button';
import Select from 'muicss/lib/react/select';
import Option from 'muicss/lib/react/option';

export default class Tail_picker_echange_start extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
      matricule: (<div>Matricule</div>),
      matricule_plainText: "Matricule",
      flux: (<div>Autre contenant</div>),
      flux_plainText: "Autre contenant",
      picker_vide: '',
      elAdd_visible: false,
    };
  }

  componentDidMount() {
    if (this.props.appActions.dataSlots['ds_scanned_id'] && this.state.elAdd_visible === true) {
      this.setState({elAdd_visible: false});
    } else if (this.props.appActions.dataSlots['ds_scanned_id']=="" && this.state.elAdd_visible === false) {
      this.setState({elAdd_visible: true});
    }
    
    if (this.props.appActions.dataSlots['ds_scanned_id']) {
      let container = this.props.appActions.dataSheets['reference_containers'].items.find(it => it.id_scan === this.props.appActions.dataSlots['ds_scanned_id'])||{};
      let material = this.props.appActions.dataSheets['materiels'].items.find(it => it.type === container.type);
      if (material) {
        this.setState({picker_vide: material.type});
      }
    } else {
      this.setState({picker_vide: this.props.appActions.dataSlots['ds_selected_command'].type});
    }
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
    if (this.props.appActions.dataSlots['ds_scanned_id']) {
      let container = this.props.appActions.dataSheets['reference_containers'].items.find(it => it.id_scan === this.props.appActions.dataSlots['ds_scanned_id'])||{};
      let material = this.props.appActions.dataSheets['materiels'].items.find(it => it.type === container.type);
      if (material && material.type != this.state.picker_vide) {
        this.setState({picker_vide: material.type});
      }
    }
  }

  onClick_elButtonCopy = (ev) => {
    let quaiView = this.props.appActions.dataSheets['quaiView'].items.find(it => it.document_key === this.props.appActions.dataSlots['ds_selected_dech_quai_view'].document_key);
    
    let current_hors_quais = quaiView.hors_quais || [];
    
    
    let new_container;
    if (this.state.picker_vide) {
      new_container = {
        id_scan: this.props.appActions.dataSlots['ds_scanned_id'],
        id_client: quaiView.id_client,
        id_contenant: "VIDE",
        flux: this.state.picker_vide,
        state: 1,
      };
    } else {
      alert("Formulaire incomplet.");
      return;
    }
    
    if (new_container) {
      let new_hq = [];
      current_hors_quais.forEach(hq => {
        if (new_container.id_scan) {
          if (new_container.id_scan == hq.id_scan && hq.id_contenant === "VIDE") {
            // skip
          } else if (new_container.id_scan == hq.id_scan) {
            hq.id_scan = "";
            new_hq.push(hq);
          } else {
            new_hq.push(hq);
          }
        } else {
          new_hq.push(hq);
        }
      })
      new_hq.push(new_container);
    
      let update = {
        document_key: quaiView.document_key,
        document_ref: quaiView.document_ref,
        hors_quais: new_hq,
      };
      this.props.appActions.dataSheets['quaiView'].replaceItemByRowIndex(update.document_key, update, this.props.appActions.serviceOptions_quaiView);
    
      // SANITIZE id_scans
      try {
        // ids to remove from other places
        let used_ids = new Set();
        if (new_container.id_scan) {used_ids.add(new_container.id_scan)};
    
        this.props.appActions.dataSheets['quaiView'].items.forEach(qv => {
          if (qv.document_key === this.props.appActions.dataSlots['ds_selected_dech_quai_view'].document_key) {
            let flag_q = false;
            let new_q = [];
            qv.quais.forEach(q => {
              if (used_ids.has(q.id_scan)) {
                q.id_scan = "";
                flag_q = true;
              }
              new_q.push(q);
            })
            if (flag_q) {
              let update = {
                document_key: qv.document_key,
                document_ref: qv.document_ref,
                quais: new_q,
              }
              this.props.appActions.dataSheets['quaiView'].replaceItemByRowIndex(qv.document_key, update, this.props.appActions.serviceOptions_quaiView)
            }
          } else {
            let flag_q = false;
            let flag_hq = false;
            let new_q = [];
            let new_hq = [];
            qv.quais.forEach(q => {
              if (used_ids.has(q.id_scan)) {
                q.id_scan = "";
                flag_q = true;
              }
              new_q.push(q);
            })
            qv.hors_quais.forEach(hq => {
              if (used_ids.has(hq.id_scan) && hq.id_contenant === "VIDE") {
                // skip
                flag_hq = true;
              } else if (used_ids.has(hq.id_scan)) {
                hq.id_scan = "";
                flag_hq = true;
                new_hq.push(hq);
              } else {
                new_hq.push(hq);
              }
            })
            if (flag_q) {qv.quais = new_q}
            if (flag_hq) {qv.hors_quais = new_hq}
            if (flag_q || flag_hq) {this.props.appActions.dataSheets['quaiView'].replaceItemByRowIndex(qv.document_key, qv, this.props.appActions.serviceOptions_quaiView)}
          }
        })
      } catch (e) {
        console.log(e);
      }
    }
  
    this.setState({elAdd_visible: true});
  
  }
  
  
  onClick_elScanner = (ev) => {
    // Go to screen 'Scanner'
    this.props.appActions.goToScreen('scanner', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elClose = (ev) => {
    this.setState({elAdd_visible: true});
  
    let newVal = "";
    this.props.appActions.updateDataSlot('ds_scanned_id', newVal);
  
  }
  
  
  pickerValueChanged_elPicker_vide = (event) => {
    this.setState({picker_vide: event.target.value});
  }
  
  onClick_elAdd = (ev) => {
    this.setState({elAdd_visible: false});
  
    let newVal = "";
    this.props.appActions.updateDataSlot('ds_scanned_id', newVal);
  
    this.props.appActions.updateDataSlot('ds_selected_quai', this.props.appActions.dataSlots['ds_selected_dech_quai_view'].hors_quais.length);
  
  }
  
  
  render() {
    
    const style_elButtonCopy = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_matricule = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return input || "Matricule";
    }
    
    const value_matricule = transformPropValue_matricule((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_scanned_id'] : ''));
    
    const style_elMatricule = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'right',
     };
    
    const style_elScanner = {
      display: 'block',
      color: '#00e5d9',
      textAlign: 'center',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elFlux = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'right',
     };
    
    const style_elClose = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_1139092073+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '89.562%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    let selection_picker_vide = this.state.picker_vide;
    // Source datasheet and selected data column for picker element 'picker_vide'
    const dataSource_picker_vide = this.props.appActions.getDataSheet('materiels');
    const valueColumnName_picker_vide = 'type';
    const labelColumnName_picker_vide = 'type';
    
    const style_elPicker_vide = {
      pointerEvents: 'auto',
     };
    
    const style_elAdd = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_573832028+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: '#feffff',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elAdd = this.state.elAdd_visible ? (
      <button className="actionFont containerMinHeight elAdd" style={style_elAdd} onClick={this.onClick_elAdd}  />
     ) : null;
    
    return (
      <div className="Tail_picker_echange_start cardBg">
        <div className="foreground">
          <Button className="actionFont elButtonCopy" style={style_elButtonCopy}  color="accent" onClick={this.onClick_elButtonCopy} >
            Ajouter
          </Button>
          <div className="baseFont elMatricule" style={style_elMatricule}>
            <div>{value_matricule !== undefined ? value_matricule : (<span className="propValueMissing">{this.state.matricule}</span>)}</div>
          </div>
          <button className="actionFont elScanner" style={style_elScanner} onClick={this.onClick_elScanner} >
            Scanner
          </button>
          <div className="baseFont elFlux" style={style_elFlux}>
            <div>{this.state.flux}</div>
          </div>
          <button className="actionFont elClose" style={style_elClose} onClick={this.onClick_elClose}  />
          <Select className="baseFont elPicker_vide" style={style_elPicker_vide}  onChange={this.pickerValueChanged_elPicker_vide} value={selection_picker_vide} >
            {dataSource_picker_vide.items.every(item => {
              return item[valueColumnName_picker_vide] !== selection_picker_vide;
            }) ? <Option value=''/> : null}
            {dataSource_picker_vide.items.map(item => {
              const colValue = item[valueColumnName_picker_vide];
              const labelColValue = item[labelColumnName_picker_vide];
              return <Option key={item.key} value={colValue} label={labelColValue} />
            })}
          </Select>
          { elAdd }
        </div>
      </div>
    )
  }
  
}
