import React, { Component } from 'react';
import './App.css';
import Picker_specifiques from './Picker_specifiques';

export default class Item_one_group_spe extends Component {

  // Properties used by this component:
  // group, items

  constructor(props) {
    super(props);
    
    this.state = {
      text: (<div> ▿</div>),
      text_plainText: " ▿",
      elList_visible: false,
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elText = (ev) => {
    this.setState({elList_visible: !this.state.elList_visible});
  
  }
  
  
  render() {
    
    const style_elRectangle = {
      background: 'rgba(213, 213, 213, 1.000)',
     };
    let transformPropValue_text = (input) => {
      return input + " ▿";
    }
    
    const value_text = transformPropValue_text(this.props.group);
    
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    if (Array.isArray(this.props.items)) {
        items_list = items_list.concat(this.props.items);
    } else if (this.props.items) {
        items_list.push(this.props.items);
    }
    this._elList_items = [];
    
    const style_elList = {
      height: 'auto',  // This element is in scroll flow
     };
    const elList = this.state.elList_visible ? (
      <div className="hasNestedComps elList">
        <div style={style_elList}>
          {items_list.map((row, index) => {
            let itemComp = (row._componentId)
                ? listComps_list[row._componentId]
                : <Picker_specifiques appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} {...{ 'label': row['label'], 'state': row['state'], 'flux': row['flux'], 'flux_id': row['flux_id'], 'id': row['id'], }} ref={(el) => {if (el) this._elList_items.push(el)}} />;
            return (<div key={row.key}>
                {itemComp}
              </div>);
          })}
          <div className="marker" ref={(el)=> this._elList_endMarker = el} />
        </div>
      </div>
      
     ) : null;
    
    return (
      <div className="Item_one_group_spe">
        <div className="background">
          <div className="elRectangle" style={style_elRectangle} />
        </div>
        
        <div className="layoutFlow">
          <div className="elText">
            <div className="baseFont" style={style_elText} onClick={this.onClick_elText} >
              <div>{value_text !== undefined ? value_text : (<span className="propValueMissing">{this.state.text}</span>)}</div>
            </div>
          </div>
          
          { elList }
        </div>
        
      </div>
    )
  }
  
}
