import React, { Component } from 'react';
import './App.css';

export default class Item_immatriculation extends Component {

  // Properties used by this component:
  // dataSheetRow

  constructor(props) {
    super(props);
    
    this.state = {
      text: (<div>&lt;no value from script&gt;</div>),
      text_plainText: "<no value from script>",
      decription: (<div> </div>),
      decription_plainText: " ",
      type: (<div> </div>),
      type_plainText: " ",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elText = (ev) => {
    /**
    let new_scan = {
      ts: new Date().getTime(),
      id_entreprise: this.props.appActions.dataSlots['ds_entreprise_id'],
      image: this.props.appActions.dataSlots['ds_camera'],
      id_scan: this.props.dataSheetRow.id_scan
    }
    this.props.appActions.dataSheets['dataBaseScans'].addItem(new_scan, this.props.appActions.serviceOptions_dataBaseScans);
    **/
    
    this.props.appActions.updateDataSlot('ds_scanned_id', this.props.dataSheetRow.id_scan);
    //this.props.appActions.updateDataSlot('ds_selected_command', {...this.props.appActions.dataSlots['ds_selected_command'], 'id_scan': this.props.dataSheetRow.id_scan});
    this.props.appActions.goBack();
  
  }
  
  
  onClick_elDecription = (ev) => {
    /**
    let new_scan = {
      ts: new Date().getTime(),
      id_entreprise: this.props.appActions.dataSlots['ds_entreprise_id'],
      image: this.props.appActions.dataSlots['ds_camera'],
      id_scan: this.props.dataSheetRow.id_scan
    }
    this.props.appActions.dataSheets['dataBaseScans'].addItem(new_scan, this.props.appActions.serviceOptions_dataBaseScans);
    **/
    
    this.props.appActions.updateDataSlot('ds_scanned_id', this.props.dataSheetRow.id_scan);
    //this.props.appActions.updateDataSlot('ds_selected_command', {...this.props.appActions.dataSlots['ds_selected_command'], 'id_scan': this.props.dataSheetRow.id_scan});
    this.props.appActions.goBack();
  
  }
  
  
  onClick_elType = (ev) => {
    /**
    let new_scan = {
      ts: new Date().getTime(),
      id_entreprise: this.props.appActions.dataSlots['ds_entreprise_id'],
      image: this.props.appActions.dataSlots['ds_camera'],
      id_scan: this.props.dataSheetRow.id_scan
    }
    this.props.appActions.dataSheets['dataBaseScans'].addItem(new_scan, this.props.appActions.serviceOptions_dataBaseScans);
    **/
    
    this.props.appActions.updateDataSlot('ds_scanned_id', this.props.dataSheetRow.id_scan);
    //this.props.appActions.updateDataSlot('ds_selected_command', {...this.props.appActions.dataSlots['ds_selected_command'], 'id_scan': this.props.dataSheetRow.id_scan});
    this.props.appActions.goBack();
  
  }
  
  
  render() {
    
    const style_elRectangle = {
      background: 'rgba(213, 213, 213, 1.000)',
     };
    let transformPropValue_text = (input) => {
      return input.id_scan;
    }
    let transformCustomCSSPropValue_text = (input) => {
      let style = '"borderRadius": "5px"';
      try {
        let container = this.props.appActions.dataSheets['reference_containers'].items.find(it => it.id_scan === input.id_scan);
        if (container.unresolved_anomalies_labels.length) {
          style += ',"backgroundColor": "#FFD479"';
        } else {
          if (input.id_scan) {
            style += ',"backgroundColor": "#EBEBEB"';
          }
        } 
      } catch (e) {
        if (input.id_scan) {
          style += ',"backgroundColor": "#EBEBEB"';
        }
      }
      
      return style;
    }
    
    const value_text = transformPropValue_text(this.props.dataSheetRow);
    
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
      ...(() => {  // append customCSS property of 'text'
        let val = transformCustomCSSPropValue_text(this.props.dataSheetRow);
        if (val == null || val.length == 0) return null;
        try {
          return JSON.parse('{'+val+'}');
        } catch (error) {
          console.log(error);
        }
        return null;
      })(),
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_decription = (input) => {
      return input.description || "";
    }
    
    const value_decription = transformPropValue_decription(this.props.dataSheetRow);
    
    const style_elDecription = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_type = (input) => {
      return input.type || "";
    }
    
    const value_type = transformPropValue_type(this.props.dataSheetRow);
    
    const style_elType = {
      color: 'rgba(0, 0, 0, 0.5000)',
      textAlign: 'right',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Item_immatriculation">
        <div className="background">
          <div className="elRectangle" style={style_elRectangle} />
        </div>
        
        <div className="layoutFlow">
          <div className="elText">
            <div className="baseFont" style={style_elText} onClick={this.onClick_elText} >
              <div>{value_text !== undefined ? value_text : (<span className="propValueMissing">{this.state.text}</span>)}</div>
            </div>
          </div>
          
          <div className="elDecription">
            <div className="baseFont" style={style_elDecription} onClick={this.onClick_elDecription} >
              <div>{value_decription !== undefined ? value_decription : (<span className="propValueMissing">{this.state.decription}</span>)}</div>
            </div>
          </div>
          
          <div className="elType">
            <div className="baseFont" style={style_elType} onClick={this.onClick_elType} >
              <div>{value_type !== undefined ? value_type : (<span className="propValueMissing">{this.state.type}</span>)}</div>
            </div>
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
        </div>
        
      </div>
    )
  }
  
}
