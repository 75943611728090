import React, { Component } from 'react';
import './App.css';
import NoSleep from 'nosleep.js';
import img_state1_elLogo from './images/Image_fill_elImage_back_1322448678.png';
import img_state2_elImage from './images/ConnexionScreen_state2_elImage_1280929045.png';
import firebase from 'firebase';
import firestore from 'firebase/firestore';
import performance from 'firebase/performance';

// UI framework component imports
import Appbar from 'muicss/lib/react/appbar';
import Button from 'muicss/lib/react/button';
import Input from 'muicss/lib/react/input';

export default class ConnexionScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo, dataSheetRow, logo_entreprise, errorText

  constructor(props) {
    super(props);
    
    this.state = {
      state0_elBienvenue2129009774: (<div>Bienvenue</div>),
      state0_elBienvenue2129009774_plainText: "Bienvenue",
      state0_elAuthentification: (<div>Authentification en cours…</div>),
      state0_elAuthentification_plainText: "Authentification en cours…",
      state1_elBienvenue2129009774: (<div>Bienvenue</div>),
      state1_elBienvenue2129009774_plainText: "Bienvenue",
      state1_elUser_name: (<div> </div>),
      state1_elUser_name_plainText: " ",
      state1_elEntreprise: (<div> </div>),
      state1_elEntreprise_plainText: " ",
      state2_elBienvenue2129009774: (<div>Bienvenue</div>),
      state2_elBienvenue2129009774_plainText: "Bienvenue",
      email: '',
      password: '',
      state2_elErrorText: (<div> </div>),
      state2_elErrorText_plainText: " ",
      state2_elReset_pwd_plainText: "<div id=\"reset_pwd\">Mot de passe oublié ?</div><style>#reset_pwd{text-decoration: underline}</style>",
      state2_elNfc_status: (<div> </div>),
      state2_elNfc_status_plainText: " ",
    };
  }

  componentDidMount() {
    
    if (this.props.appActions.dataSlots['ds_login_screen_state'] == 2 && window.reader == null) {
      console.log("Auto Start NFC SCAN");

      try {
        window.reader = new window.NDEFReader();
        window.abortController = new AbortController();
        window.abortController.signal.onabort = event => {
          // All NFC operations have been aborted.
        };
        window.reader.scan({ signal: window.abortController.signal });
        console.log("> Scan started");

        window.reader.addEventListener("error", (event) => {
          console.log('NFC ERROR');
        });

        window.reader.addEventListener("reading", ({ message, serialNumber }) => {
          /**
    // let text = "<br>" + serialNumber + "<br>";
    let text = "";
    message.records.forEach(r => {
      let textDecoder = new window.TextDecoder(r.encoding);
      // console.log(`Text: ${textDecoder.decode(record.data)} (${record.lang})`);
      text += textDecoder.decode(r.data);
    })
    let logInData = JSON.parse(text);
    this.setState({email: logInData.email, password: logInData.password});
    **/
          this.setState({'readingNFC': true});
          let getCredentials = firebase.app().functions("europe-west1").httpsCallable('logInNFC', {timeout: 540000});
          getCredentials({serialNumber: serialNumber}).then( result => { 
            console.log(result);
            firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE).then(() => {
              return firebase.auth().signInWithCustomToken(result.data[0])
            }).then(() => {
              console.log('log in success');
              this.setState({'error': "", 'readingNFC': false});
              //window.abortController.abort();
              //window.reader = null;
              this.props.appActions.updateDataSlot('ds_userid', firebase.auth().currentUser.uid);
              this.props.appActions.goToScreen(this.props.appActions.dataSlots['ds_login_success_screen']);
            }).catch((error) => {
              console.log('** login failed: ', error.message);
              this.setState({'error': error.message, 'readingNFC': false});
              this.props.appActions.goToScreen('connexion', { errorText: ''+error.message });
              //window.abortController.abort();
              //window.reader = null;
            });
          });
          //unlockGate();
          //window.alert("text");
          //this.props.appActions.updateDataSlot('ds_ids', this.props.appActions.dataSlots['ds_ids'] + text);
          //window.abortController.abort();
          //window.reader = null;
        });
      } catch (error) {
        console.log('NFC ERROR');
        //window.reader = null;
      }
    } else if (this.props.appActions.dataSlots['ds_login_screen_state'] != 2) {
      try {
        window.abortController.abort();
        window.reader = null;
      } catch(e) {
        console.log('NFC Abbort Error', e);
      }
    }
    
    var script = document.createElement("script");
    script.id = "update-nfc-tag";
    script.innerHTML = `
    function updateNFCTag (tagId) {
      window.currentNFCTag = tagId;
      console.log('Scanned tag :', tagId);
    }
    `;
    document.body.appendChild(script);
    
    window.intervalNFC = setInterval(() => {
      //console.log('interval', firebase.auth().currentUser, window.currentNFCTag)
      if (window.currentNFCTag && !firebase.auth().currentUser) {
        this.setState({'readingNFC': true});
        let getCredentials = firebase.app().functions("europe-west1").httpsCallable('logInNFC', {timeout: 540000});
        getCredentials({serialNumber: window.currentNFCTag}).then( result => { 
          console.log(result);
          firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE).then(() => {
            return firebase.auth().signInWithCustomToken(result.data[0])
          }).then(() => {
            console.log('log in success');
            this.setState({'error': "", 'readingNFC': false});
            //window.abortController.abort();
            //window.reader = null;
            this.props.appActions.updateDataSlot('ds_userid', firebase.auth().currentUser.uid);
            this.props.appActions.goToScreen(this.props.appActions.dataSlots['ds_login_success_screen']);
          }).catch((error) => {
            console.log('** login failed: ', error.message);
            this.setState({'error': error.message, 'readingNFC': false});
            this.props.appActions.goToScreen('connexion', { errorText: ''+error.message });
            //window.abortController.abort();
            //window.reader = null;
          });
        });
      }
    }, 1000)
    // Check if Firebase login has been completed.
         firebase.auth().onAuthStateChanged(user => {
         if (user && this.props.appActions.dataSlots['ds_login_screen_state'] == 0) {
           this.props.appActions.updateDataSlot('ds_userid', firebase.auth().currentUser.uid); 
           this.props.appActions.updateDataSlot('ds_login_screen_state', 1); 
         } else if (user && this.props.appActions.dataSlots['ds_login_screen_state'] == 1){
         } else if (user && this.props.appActions.dataSlots['ds_login_screen_state'] == 2){
         	this.props.appActions.updateDataSlot('ds_userid', firebase.auth().currentUser.uid); 
           this.props.appActions.updateDataSlot('ds_login_screen_state', 1); 
         } else if (!user && this.props.appActions.dataSlots['ds_login_screen_state'] == 0) {
           this.props.appActions.updateDataSlot('ds_login_screen_state', 2); 
         } else if (!user && this.props.appActions.dataSlots['ds_login_screen_state'] == 1) {
           this.props.appActions.updateDataSlot('ds_login_screen_state', 2); 
         } else if (!user && this.props.appActions.dataSlots['ds_login_screen_state'] == 2) {
         } else {
         }
         })
    {
      let dataSheet = this.props.appActions.dataSheets['auth_user'];
      let serviceOptions = this.props.appActions.serviceOptions_auth_user;
      if ( !this.props.appActions.dataSheetLoaded['auth_user']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("users/", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['auth_user'] = true;
      }
    }
  }

  componentWillUnmount() {
    clearInterval(window.intervalNFC);
    window.currentNFCTag = "";
    try {
      const scriptList = document.querySelector("#update-nfc-tag");
      console.log(scriptList);
      scriptList.parentNode.removeChild(scriptList);
    } catch (e) {
      
    }
    try {
      window.abortController.abort();
      window.reader = null;
      window.currentNFCTag = "";
    } catch(e) {
      console.log('NFC Abbort Error', e);
    }
  }

  componentDidUpdate() {
    if (this.props.appActions.dataSlots['ds_login_screen_state'] == 2 && window.reader == null) {
      console.log("Auto Start NFC SCAN");

      try {
        window.reader = new window.NDEFReader();
        window.abortController = new AbortController();
        window.abortController.signal.onabort = event => {
          // All NFC operations have been aborted.
        };
        window.reader.scan({ signal: window.abortController.signal });
        console.log("> Scan started");

        window.reader.addEventListener("error", (event) => {
          console.log('NFC ERROR');
        });

        window.reader.addEventListener("reading", ({ message, serialNumber }) => {
          /**
    // let text = "<br>" + serialNumber + "<br>";
    let text = "";
    message.records.forEach(r => {
      let textDecoder = new window.TextDecoder(r.encoding);
      // console.log(`Text: ${textDecoder.decode(record.data)} (${record.lang})`);
      text += textDecoder.decode(r.data);
    })
    let logInData = JSON.parse(text);
    this.setState({email: logInData.email, password: logInData.password});
    **/
          this.setState({'readingNFC': true});
          let getCredentials = firebase.app().functions("europe-west1").httpsCallable('logInNFC', {timeout: 540000});
          getCredentials({serialNumber: serialNumber}).then( result => { 
            console.log(result);
            firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE).then(() => {
              return firebase.auth().signInWithCustomToken(result.data[0])
            }).then(() => {
              console.log('log in success');
              this.setState({'readingNFC': false});
              //window.abortController.abort();
              //window.reader = null;
              this.props.appActions.updateDataSlot('ds_userid', firebase.auth().currentUser.uid);
              this.props.appActions.goToScreen(this.props.appActions.dataSlots['ds_login_success_screen']);
            }).catch((error) => {
              console.log('** login failed: ', error.message);
              this.setState({'readingNFC': false});
              this.props.appActions.goToScreen('connexion', { errorText: ''+error.message });
              //window.abortController.abort();
              //window.reader = null;
            });
          });
          //unlockGate();
          //window.alert("text");
          //this.props.appActions.updateDataSlot('ds_ids', this.props.appActions.dataSlots['ds_ids'] + text);
          //window.abortController.abort();
          //window.reader = null;
        });
      } catch (error) {
        console.log('NFC ERROR');
        //window.reader = null;
      }
    } else if (this.props.appActions.dataSlots['ds_login_screen_state'] != 2) {
      try {
        window.abortController.abort();
        window.reader = null;
      } catch(e) {
        console.log('NFC Abbort Error', e);
      }
    }
  }

  // --- Functions for component state index 0 (1 of 3) --- 
  
  renderState0() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_state0_elBackground825705 = {
      width: '100%',
      height: '100%',
     };
    const style_state0_elBackground825705_outer = {
      backgroundColor: '#f6f6f6',
     };
    const style_state0_elBienvenue2129009774 = {
      fontSize: 19.4,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const style_state0_elAuthentification = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    let cssClass_progress = 'circularProgressIndicator';
    if (this.props.appActions.isLoading()) 
      cssClass_progress += ' circularProgressIndicator-active';
    
    
    return (
      <div className="AppScreen ConnexionScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight state0_elBackground825705" style={style_state0_elBackground825705_outer}>
            <div className="appBg" style={style_state0_elBackground825705} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="state0_elBienvenue2129009774">
            <div className="systemFontBold" style={style_state0_elBienvenue2129009774}>
              <div>{this.state.state0_elBienvenue2129009774}</div>
            </div>
          </div>
          
          <div className="state0_elAuthentification">
            <div className="baseFont" style={style_state0_elAuthentification}>
              <div>{this.state.state0_elAuthentification}</div>
            </div>
          </div>
          
          <div className="state0_elProgress1420551409">
            <div>
              <svg className={cssClass_progress}>
                <circle className="circularProgress-animatedPath" style={{stroke: '#00e5d9'}} cx="25" cy="25" r="17" fill="none" strokeWidth="3" strokeMiterlimit="10" />
              </svg>
            </div>
          </div>
        </div>
        <Appbar className="navBar">
          <div className="title">Connexion</div></Appbar>
        
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 3) --- 
  
  onClick_state1_elCommencer = (ev) => {
    let newVal = this.props.dataSheetRow.entreprise;
    this.props.appActions.updateDataSlot('ds_entreprise_id', newVal);
    
    newVal = this.props.dataSheetRow.user_name;
    this.props.appActions.updateDataSlot('ds_username', newVal);
    
    newVal = this.props.dataSheetRow.entreprise_name;
    this.props.appActions.updateDataSlot('ds_entreprise', newVal);
    
  
    let execution = new Date();
    execution.setHours(24,0,0,0); 
    execution = execution.getTime();
    this.props.appActions.updateDataSlot('ds_demain_minuit_ts', execution);
    execution = new Date();
    execution.setHours(0,0,0,0); 
    execution = execution.getTime();
    this.props.appActions.updateDataSlot('ds_today_minuit_ts', execution);
    
  
    // single point plot
    window.collection = this.props.dataSheets['auth_user'].firebase.firestore().collection("entreprises/"+this.props.appActions.dataSlots['ds_entreprise_id']+"/locations");
    window.collection_tour = this.props.dataSheets['auth_user'].firebase.firestore().collection("entreprises/"+this.props.appActions.dataSlots['ds_entreprise_id']+"/id_tournees");
    window.firebase = this.props.dataSheets['auth_user'].firebase;
    window.uid = this.props.appActions.dataSlots['ds_userid'];
    window.user_name = this.props.appActions.dataSlots['ds_username'];
    window.tournee = "";
    var script = document.createElement("script");
    script.innerHTML = `
    function success(pos) {
      if (window.previousRefresh.timestamp==0) {
        window.previousRefresh = pos;
      }
      if (window.previousPos.timestamp==0) {
        window.previousPos = pos;
      }
    
      let delta_t = pos.timestamp - window.previousRefresh.timestamp;
      let delta_t_local = pos.timestamp - window.previousPos.timestamp;
      if (window.tournee && delta_t_local > 5000) {
        let distanceDeg = Math.pow(Math.pow(pos.coords.latitude - window.previousPos.coords.latitude, 2) + Math.pow(pos.coords.longitude - window.previousPos.coords.longitude, 2), 0.5);
        let distanceM = 6371008 * Math.PI/180 * distanceDeg;
        window.previousRefresh.distance = (window.previousRefresh.distance||0) + distanceM;
        window.previousRefresh.positions = (window.previousRefresh.positions||[]).concat(
          {
            distance: distanceM,
            location: {lat: pos.coords.latitude, lng: pos.coords.longitude},
            ts: pos.timestamp,
          }
        );
        window.previousPos = pos;
      }
      if (window.tournee && delta_t > 60000) {
        let position_update = {
          distance: window.previousRefresh.distance||0,
          previous: {lat: window.previousRefresh.coords.latitude, lng: window.previousRefresh.coords.longitude, ts: window.previousRefresh.timestamp},
          location: {lat: pos.coords.latitude, lng: pos.coords.longitude},
          positions: window.previousRefresh.positions||[],
          ts: pos.timestamp,
          uid: window.uid,
          tournee: window.tournee,
          user_name: window.user_name,
        }
        window.previousRefresh = pos;
        window.collection_tour.doc(window.tournee).update({
          last_position: position_update,
          distance_measured_m: window.firebase.firestore.FieldValue.increment(position_update.distance)
        })
        window.collection.add(position_update)
          .then(function() {
          //console.log("Position successfully written!");
        })
          .catch(function(error) {
          //console.error("Error writing position: ", error);
        });
      }
    }
    
    function error(err) {
      console.warn('ERROR(' + err.code + '): ' + err.message);
    }
    
    if (navigator.geolocation) {
      window.previousPos = {coords : {latitude: 0, longitude: 0}, timestamp: 0};
      window.previousRefresh = {coords : {latitude: 0, longitude: 0}, timestamp: 0};
      window.positionTracker = navigator.geolocation.watchPosition( success, error, {timeout: Infinity, maximumAge: 0, enableHighAccuracy: true}); 
    }`;
    document.body.appendChild(script);
    
    // noSleep
    var noSleep = new NoSleep();
    // Enable wake lock.
    // (must be wrapped in a user input event handler e.g. a mouse or touch handler)
    document.addEventListener('click', function enableNoSleep() {
      document.removeEventListener('click', enableNoSleep, false);
      noSleep.enable();
      console.log('NoSleep enabled');
    }, false);
    
  
    // Android interface if available
    
    try {
      window.Android.refreshData([
        this.props.appActions.dataSlots['ds_entreprise_id'],
        this.props.appActions.dataSlots['ds_userid'],
        this.props.appActions.dataSlots['ds_username'],
        null
      ])
    } catch (error) {
      console.log("Android interface not available");
    }
  
    // Go to screen 'Menu principal'
    this.props.appActions.goToScreen('menuPrincipal', { transitionId: 'fadeIn' });
  
  }
  
  
  renderState1() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_state1_elBackground825705 = {
      width: '100%',
      height: '100%',
     };
    const style_state1_elBackground825705_outer = {
      backgroundColor: '#f6f6f6',
     };
    const style_state1_elBienvenue2129009774 = {
      fontSize: 19.4,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const value_user_name = this.props.dataSheetRow ? this.props.dataSheetRow.user_name : '';
    
    const style_state1_elUser_name = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const value_entreprise = this.props.dataSheetRow ? this.props.dataSheetRow.entreprise_name : '';
    
    const style_state1_elEntreprise = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const style_state1_elCommencer = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    let cssClass_progress = 'circularProgressIndicator';
    if (this.props.appActions.isLoading()) 
      cssClass_progress += ' circularProgressIndicator-active';
    
    let transformPropValue_logo = (input) => {
      if (this.props.appActions.dataSheets['static'].items.length) {
        return this.props.appActions.dataSheets['static'].items[0]['logo_entreprise'];
      } else {
        return
      }
    }
    const style_state1_elLogo = {
      backgroundImage: 'url('+(transformPropValue_logo(this.props.logo_entreprise) || img_state1_elLogo)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    
    return (
      <div className="AppScreen ConnexionScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight state1_elBackground825705" style={style_state1_elBackground825705_outer}>
            <div className="appBg" style={style_state1_elBackground825705} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="state1_elBienvenue2129009774">
            <div className="systemFontBold" style={style_state1_elBienvenue2129009774}>
              <div>{this.state.state1_elBienvenue2129009774}</div>
            </div>
          </div>
          
          <div className="state1_elUser_name">
            <div className="baseFont" style={style_state1_elUser_name}>
              <div>{value_user_name !== undefined ? value_user_name : (<span className="propValueMissing">{this.state.state1_elUser_name}</span>)}</div>
            </div>
          </div>
          
          <div className="state1_elEntreprise">
            <div className="baseFont" style={style_state1_elEntreprise}>
              <div>{value_entreprise !== undefined ? value_entreprise : (<span className="propValueMissing">{this.state.state1_elEntreprise}</span>)}</div>
            </div>
          </div>
          
          <div className="state1_elCommencer">
            <Button className="actionFont" style={style_state1_elCommencer}  color="accent" onClick={this.onClick_state1_elCommencer} >
              Commencer
            </Button>
          </div>
          
          <div className="state1_elProgress1420551409">
            <div>
              <svg className={cssClass_progress}>
                <circle className="circularProgress-animatedPath" style={{stroke: '#00e5d9'}} cx="25" cy="25" r="17" fill="none" strokeWidth="3" strokeMiterlimit="10" />
              </svg>
            </div>
          </div>
        </div>
        <Appbar className="navBar">
          <div className="title">Connexion</div></Appbar>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="state1_elLogo" style={style_state1_elLogo} />
          </div>
        </div>
      </div>
    )
  }
  
  // --- Functions for component state index 2 (3 of 3) --- 
  
  textInputChanged_state2_elEmail = (event) => {
    this.setState({email: event.target.value});
  }
  
  textInputChanged_state2_elPassword = (event) => {
    this.setState({password: event.target.value});
  }
  
  onClick_state2_elConnexion = (ev) => {
    // 'Unlock gate' action.
    const dataFromElements = {  // This object contains the data collected from the UI
      bienvenue: this.state.bienvenue_plainText,
      authentification: this.state.authentification_plainText,
      user_name: this.state.user_name_plainText,
      entreprise: this.state.entreprise_plainText,
      email: this.state.email,
      password: this.state.password,
      errorText: this.state.errortext_plainText,
      reset_pwd: this.state.reset_pwd_plainText,
      nfc_status: this.state.nfc_status_plainText,
    };
    
    this.sendLogin(dataFromElements, (err) => {
      if (err) {
        console.log('** login failed: ', err);
        sessionStorage.setItem('loginStatus_connexion', 'failed');
        this.props.appActions.goToScreen('connexion', { errorText: ''+err });
      } else {
      }
    });
  
  }
  
  
  onClick_state2_elReset_pwd = (ev) => {
    if (!this.state.email) {
      window.alert("Renseigner une adresse email.")
    } else {
      firebase.auth().sendPasswordResetEmail(this.state.email);
      window.alert("Un email de réinitialisation du mot de passe a été envoyé à l'adresse email renseignée.")
    }
  
  }
  
  
  onClick_state2_elImage = (ev) => {
    console.log("User clicked scan button");
    
    let unlockGate = () => {
      // 'Unlock gate' action.
      const dataFromElements = {  // This object contains the data collected from the UI
        bienvenue: this.state.bienvenue_plainText,
        authentification: this.state.authentification_plainText,
        user_name: this.state.user_name_plainText,
        entreprise: this.state.entreprise_plainText,
        email: this.state.email,
        password: this.state.password,
      };
    
      this.sendLogin(dataFromElements, (err) => {
        if (err) {
          console.log('** login failed: ', err);
          sessionStorage.setItem('loginStatus_connexion', 'failed');
          this.props.appActions.goToScreen('connexion', { errorText: ''+err });
        } else {
        }
      });
    }
    
    try {
      window.reader = new window.NDEFReader();
      window.abortController = new AbortController();
      window.abortController.signal.onabort = event => {
        // All NFC operations have been aborted.
      };
      window.reader.scan({ signal: window.abortController.signal });
      console.log("> Scan started");
    
      window.reader.addEventListener("error", (event) => {
        console.log('NFC ERROR');
      });
    
      window.reader.addEventListener("reading", ({ message, serialNumber }) => {
        /**
        // let text = "<br>" + serialNumber + "<br>";
        let text = "";
        message.records.forEach(r => {
          let textDecoder = new window.TextDecoder(r.encoding);
          // console.log(`Text: ${textDecoder.decode(record.data)} (${record.lang})`);
          text += textDecoder.decode(r.data);
        })
        let logInData = JSON.parse(text);
        this.setState({email: logInData.email, password: logInData.password});
        **/
        let getCredentials = firebase.app().functions("europe-west1").httpsCallable('logInNFC', {timeout: 540000});
        getCredentials({serialNumber: serialNumber}).then( result => { 
          console.log(result);
          firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE).then(() => {
            return firebase.auth().signInWithCustomToken(result.data[0])
          }).then(() => {
            console.log('log in success');
            window.abortController.abort();
          	window.reader = null;
            this.props.appActions.updateDataSlot('ds_userid', firebase.auth().currentUser.uid);
            this.props.appActions.goToScreen(this.props.appActions.dataSlots['ds_login_success_screen']);
          }).catch((error) => {
            console.log('** login failed: ', error);
            this.props.appActions.goToScreen('connexion', { errorText: ''+error });
            window.abortController.abort();
          	window.reader = null;
          });
        });
        //unlockGate();
        //window.alert("text");
        //this.props.appActions.updateDataSlot('ds_ids', this.props.appActions.dataSlots['ds_ids'] + text);
        window.abortController.abort();
        window.reader = null;
      });
    } catch (error) {
      console.log('NFC ERROR');
      window.reader = null;
    }
  
  }
  
  
  sendLogin() {
    let err;
    if (firebase.auth().currentUser) {
      this.props.appActions.updateDataSlot('ds_userid', firebase.auth().currentUser.uid);
      this.props.appActions.goToScreen(this.props.appActions.dataSlots['ds_login_success_screen']);
    } else {
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
        firebase.auth().signInWithEmailAndPassword(this.state.email.includes('@')? this.state.email : this.state.email + "@smnd.fr", this.state.password).then(() => {
          console.log('log in success');
          this.setState({'error': ''});
          this.props.appActions.updateDataSlot('ds_userid', firebase.auth().currentUser.uid);
          this.props.appActions.goToScreen(this.props.appActions.dataSlots['ds_login_success_screen']);
        }).catch((error) => {
          console.log('** login failed: ', error.message);
          this.setState({'error': error.message});
          this.props.appActions.goToScreen('connexion', { errorText: ''+error.message });
        });
      });
    }
  }
  
  
  renderState2() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_state2_elBackground825705 = {
      width: '100%',
      height: '100%',
     };
    const style_state2_elBackground825705_outer = {
      backgroundColor: '#f6f6f6',
     };
    const style_state2_elBienvenue2129009774 = {
      fontSize: 19.4,
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    
    const style_state2_elEmail = {
      display: 'block',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_state2_elPassword = {
      display: 'block',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const value_errorText = this.props.errorText;
    
    const style_state2_elErrorText = {
      color: '#ff7d78',
      textAlign: 'left',
     };
    
    const style_state2_elConnexion = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_reset_pwd = (input) => {
      return '<div id="reset_pwd">Mot de passe oublié ?</div><style>#reset_pwd{text-decoration: underline}</style>';
    }
    
    const value_reset_pwd = transformPropValue_reset_pwd((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_email'] : ''));
    
    const style_state2_elReset_pwd = {
      color: '#00e5d9',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_state2_elImage = {
      backgroundImage: 'url('+img_state2_elImage+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_nfc_status = (input) => {
      return this.state.readingNFC ? "Tag NFC reconnu, authentification en cours..." : "";
    }
    
    const value_nfc_status = transformPropValue_nfc_status(this.props.errorText);
    
    const style_state2_elNfc_status = {
      color: '#8df900',
      textAlign: 'center',
     };
    
    let cssClass_progress = 'circularProgressIndicator';
    if (this.props.appActions.isLoading()) 
      cssClass_progress += ' circularProgressIndicator-active';
    
    
    return (
      <div className="AppScreen ConnexionScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight state2_elBackground825705" style={style_state2_elBackground825705_outer}>
            <div className="appBg" style={style_state2_elBackground825705} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="state2_elBienvenue2129009774">
            <div className="systemFontBold" style={style_state2_elBienvenue2129009774}>
              <div>{this.state.state2_elBienvenue2129009774}</div>
            </div>
          </div>
          
          <div className="state2_elEmail">
            <Input className="baseFont" style={style_state2_elEmail} type="text" hint="Identifiant" onChange={this.textInputChanged_state2_elEmail} value={this.state.email}  />
          </div>
          
          <div className="state2_elPassword">
            <Input className="baseFont" style={style_state2_elPassword} type="password" hint="Mot de passe" onChange={this.textInputChanged_state2_elPassword} value={this.state.password}  />
          </div>
          
          <div className="state2_elErrorText">
            <div className="baseFont" style={style_state2_elErrorText}>
              <div>{value_errorText !== undefined ? value_errorText : (<span className="propValueMissing">{this.state.state2_elErrorText}</span>)}</div>
            </div>
          </div>
          
          <div className="state2_elConnexion">
            <Button className="actionFont" style={style_state2_elConnexion}  color="accent" onClick={this.onClick_state2_elConnexion} >
              Connexion
            </Button>
          </div>
          
          <div className="state2_elReset_pwd">
            <div className="baseFont" style={style_state2_elReset_pwd} onClick={this.onClick_state2_elReset_pwd} >
              <div><div dangerouslySetInnerHTML={{__html: value_reset_pwd}}></div></div>
            </div>
          </div>
          
          <div className="state2_elImage">
            <div style={style_state2_elImage} onClick={this.onClick_state2_elImage}  />
          </div>
          
          <div className="state2_elNfc_status">
            <div className="baseFont" style={style_state2_elNfc_status}>
              <div>{value_nfc_status !== undefined ? value_nfc_status : (<span className="propValueMissing">{this.state.state2_elNfc_status}</span>)}</div>
            </div>
          </div>
          
          <div className="state2_elProgress1420551409">
            <div>
              <svg className={cssClass_progress}>
                <circle className="circularProgress-animatedPath" style={{stroke: '#00e5d9'}} cx="25" cy="25" r="17" fill="none" strokeWidth="3" strokeMiterlimit="10" />
              </svg>
            </div>
          </div>
        </div>
        <Appbar className="navBar">
          <div className="title">Connexion</div></Appbar>
        
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.props.ds_login_screen_state !== undefined) ? this.props.ds_login_screen_state : 0, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
      case 2:
        return this.renderState2();
    }
  }
  
}
