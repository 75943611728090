import React, { Component } from 'react';
import './App.css';
import img_elImage from './images/Image_fill_elImage_back_1322448678.png';
import btn_icon_1552626310 from './images/btn_icon_1552626310.png';
import btn_icon_1761859123 from './images/btn_icon_1761859123.png';
import img_elBig_image from './images/Other_operation_elBig_image_937076410.png';

// UI framework component imports
import Select from 'muicss/lib/react/select';
import Option from 'muicss/lib/react/option';
import Input from 'muicss/lib/react/input';
import Button from 'muicss/lib/react/button';

export default class Other_operation extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
      operation_txt: (<div>Opération</div>),
      operation_txt_plainText: "Opération",
      operation: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_action_enlevement'] : ''),
      flux_txt: (<div>Flux</div>),
      flux_txt_plainText: "Flux",
      flux: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_flux'] : ''),
      comment: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_commentaire'] : '') || '',
      volume: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_volume'] : '') || '',
      elCapture_visible: true,
      elErase_visible: false,
      elCard4_visible: true,
      elExutoire_txt_visible: true,
      exutoire_txt: (<div>Exutoire</div>),
      exutoire_txt_plainText: "Exutoire",
      elExutoire_visible: true,
      exutoire: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_exutoire_enlevement'] : ''),
      elTonnage_visible: true,
      tonnage: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_tonnage'] : '') || '',
      elSpacerCopy3_visible: true,
      elBig_image_visible: false,
    };
  }

  componentDidMount() {
    document.querySelectorAll('.Other_operation').forEach(el => {el.style['pointer-events']='auto'; el.style['overflow']='scroll'});
    if (this.state.elCapture_visible && this.props.appActions.dataSlots['ds_saved_picture']) {
      this.setState({
        elCapture_visible: false,
        elErase_visible: true,
      })
    } else if (!this.state.elCapture_visible && !this.props.appActions.dataSlots['ds_saved_picture']) {
      this.setState({
        elCapture_visible: true,
        elErase_visible: false,
      })
    }
    
    // vidage?
    let operation = this.props.appActions.dataSheets['operations'].items.find(it => it.id == this.props.appActions.dataSlots['ds_action_enlevement'])||{};
    if (operation.action == 1 && !this.state.elExutoire_visible) {
      this.setState({
        elCard4_visible: true,
        elExutoire_txt_visible: true,
        elExutoire_visible: true,
        elTonnage_visible: true,
        elSpacerCopy3_visible: true,
      })
    } else if (operation.action != 1 && this.state.elExutoire_visible) {
      this.setState({
        elCard4_visible: false,
        elExutoire_txt_visible: false,
        elExutoire_visible: false,
        elTonnage_visible: false,
        elSpacerCopy3_visible: false,
      })
    } 
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
    if (this.state.elCapture_visible && this.props.appActions.dataSlots['ds_saved_picture']) {
      this.setState({
        elCapture_visible: false,
        elErase_visible: true,
      })
    } else if (!this.state.elCapture_visible && !this.props.appActions.dataSlots['ds_saved_picture']) {
      this.setState({
        elCapture_visible: true,
        elErase_visible: false,
      })
    }
    
    // vidage?
    let operation = this.props.appActions.dataSheets['operations'].items.find(it => it.id == this.props.appActions.dataSlots['ds_action_enlevement'])||{};
    if (operation.action == 1 && !this.state.elExutoire_visible) {
      this.setState({
        elCard4_visible: true,
        elExutoire_txt_visible: true,
        elExutoire_visible: true,
        elTonnage_visible: true,
        elSpacerCopy3_visible: true,
      })
    } else if (operation.action != 1 && this.state.elExutoire_visible) {
      this.setState({
        elCard4_visible: false,
        elExutoire_txt_visible: false,
        elExutoire_visible: false,
        elTonnage_visible: false,
        elSpacerCopy3_visible: false,
      })
    } 
  }

  pickerValueChanged_elOperation = (event) => {
    this.setState({operation: event.target.value});
    
    this.props.appActions.updateDataSlot("ds_action_enlevement", event.target.value);
  }
  
  pickerValueChanged_elFlux = (event) => {
    this.setState({flux: event.target.value});
    
    this.props.appActions.updateDataSlot("ds_flux", event.target.value);
  }
  
  textInputChanged_elComment = (event) => {
    this.setState({comment: event.target.value});
    
    this.props.appActions.updateDataSlot("ds_commentaire", event.target.value);
  }
  
  textInputChanged_elVolume = (event) => {
    this.setState({volume: event.target.value});
    
    this.props.appActions.updateDataSlot("ds_volume", event.target.value);
  }
  
  onClick_elImage = (ev) => {
    this.setState({elBig_image_visible: true});
  
  }
  
  
  onClick_elCapture = (ev) => {
    // Go to screen 'Appareil photo'
    this.props.appActions.goToScreen('appareilPhoto', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elErase = (ev) => {
    let newVal = "";
    this.props.appActions.updateDataSlot('ds_saved_picture', newVal);
  
  }
  
  
  pickerValueChanged_elExutoire = (event) => {
    this.setState({exutoire: event.target.value});
    
    this.props.appActions.updateDataSlot("ds_exutoire_enlevement", event.target.value);
  }
  
  textInputChanged_elTonnage = (event) => {
    this.setState({tonnage: event.target.value});
    
    this.props.appActions.updateDataSlot("ds_tonnage", event.target.value);
  }
  
  onClick_elValider = (ev) => {
    let placeOrder = (dataSlots) => {
      try {
        let getExutoire = (exut) => {
          let output = {};
          let exutoire = this.props.appActions.dataSheets['exutoires'].items.filter(it => it.document_key === exut)[0] || false;
          if (exutoire) {
            output['label'] = exutoire.label;
            output['document_key'] = exutoire.document_key;
            output['location_key'] = exutoire.location_key;
          }
          return output;
        }
    
        let getClientById = (id) => {
          return this.props.appActions.dataSheets['clients'].items.filter(it => it.document_key == id)[0];
        }
    
        let getContenantById = (id) => {
          return this.props.appActions.dataSheets['group_contenants'].items.filter(it => it.document_key == id)[0];
        }
    
        let getDriverById = (id) => {
          return this.props.appActions.dataSheets['drivers'].items.filter(it => it.document_key == id)[0];
        }
    
        let getTruckById = (id) => {
          return this.props.appActions.dataSheets['trucks'].items.filter(it => it.document_key == id)[0];
        }
    
        let getIdTourneeByDriverId = (driver_id) => {
          try {
            return this.props.appActions.dataSheets['id_tournees_history'].items.filter(it => it.driver_id == driver_id)[0].document_key;
          } catch (e) {
            return false;
          }
        }
    
        let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key == this.props.appActions.dataSlots['ds_selected_tournee']);
        let destination_tour = this.props.appActions.dataSheets['tournees_j'].items.filter(it => it.tournee === tournee.document_key);
        destination_tour.sort((a, b) => b.position - a.position);
        let max_position = (destination_tour[0]||{}).position||0;
        
        let date = new Date().setHours(0,0,0,0);
        let now = new Date();
    
        let client = getClientById(dataSlots['ds_client_id']);
        let contenant = getContenantById(dataSlots['ds_flux']) || {};
        let truck = getTruckById(tournee.truck_id) || {};
        let driver = getDriverById(tournee.driver_id);
        let exutoire = getExutoire(dataSlots['ds_exutoire_enlevement']);
    
        let item = {
          action: dataSlots['ds_action_enlevement'],
          adr: false,
          client: client.name,
          commentaire: dataSlots['ds_commentaire'] || "",
          container_id: contenant.document_key || "",
          coordinates: contenant.coordinates || {
            lat: window.previousPos.coords.latitude, 
            lng: window.previousPos.coords.longitude,
          },
          date: date,
          default_action: dataSlots['ds_action_enlevement'],
          display_name: client.name,
          driver: driver.name || "",
          driver_id: driver.document_key || "",
          duration: 0,
          etat: 'ajoute',
          exutoire: exutoire || contenant.exutoire || {'label': '', 'document_key': '', 'location_key': ''},
          flux: contenant.flux || "",
          heavy: false,
          heure: "",
          hh: dataSlots['ds_action_enlevement'] == 1 ? "PLEIN" : "AUTRE",
          id_client: client.document_key,
          id_scan: "",
          ouverture: contenant.ouverture || {},
          packmat: 0,
          photo: dataSlots['ds_saved_picture'] || "",
          position: max_position+1,
          quai: 0,
          remark: "",
          report: date,
          site: client.document_key,
          sort_key: client.name + "_" + date,
          state: dataSlots['ds_action_enlevement'] == 1 ? 5 : 0,
          statut : 1,
          tournee: tournee.document_key || "",
          truck: truck.imat || "",
          truck_id: truck.document_key || "",
          ts: now.getTime(),
          type: contenant.type || "",
          user_id: this.props.appActions.dataSlots['ds_userid'],
          user_name: this.props.appActions.dataSlots['ds_username'],
          volume: parseFloat(dataSlots['ds_volume']) || 0,
          zfe: false,      
        }
    
        // validate prise en charge
        if (true) {
          item.action_realise = dataSlots['ds_action_enlevement'];
          item.container_realise_id = item.container_id;
          item.coordinates_charge = {
            lat: window.previousPos.coords.latitude, 
            lng: window.previousPos.coords.longitude,
          };
          item.flux_realise = item.flux;
          item.ts_charge = item.ts;
          item.user_id_charge = this.props.appActions.dataSlots['ds_userid'];
          item.user_name_charge = this.props.appActions.dataSlots['ds_username'];
        }
    
        // validate vidage
        if (dataSlots['ds_action_enlevement'] == 1) {
          item.coordinates_vidage = {
            lat: window.previousPos.coords.latitude, 
            lng: window.previousPos.coords.longitude,
          };
          item.exutoire_realise = item.exutoire;
          item.tonnage = parseFloat(dataSlots['ds_tonnage']) || 0;
          item.ts_vidage = item.ts;
          item.user_id_vidage = this.props.appActions.dataSlots['ds_userid'];
          item.user_name_vidage = this.props.appActions.dataSlots['ds_username'];
        }
    
        console.log(item);
    
        let tournees_collection = this.props.appActions.dataSheets['tournees_j'].firebase.firestore().collection("entreprises/"+this.props.appActions.dataSlots['ds_entreprise_id']+"/tournees/");
        tournees_collection.add(item).then(docRef => {
          console.log('Order success');
        })
      } catch (error) {
        console.log(error);
        window.alert("Impossible de créer cette commande.");
        return;
      }
    }
    
    // validate form and place some orders
    let a  = window.confirm("Engistrer une nouvelle opération?");
    if (!a) {
      return;
    }
    let dataSlots = this.props.appActions.dataSlots;
    if (this.state.elExutoire_visible == false) {
      if (dataSlots['ds_action_enlevement'] === "" || dataSlots['ds_flux'] == "") {
        window.alert("Formulaire incomplet. Renseigner au moins une opération et un flux.");
        return;
      }
    } else {
      if (dataSlots['ds_action_enlevement'] === "" || dataSlots['ds_flux'] == "" || dataSlots['ds_exutoire_enlevement'] == "") {
        window.alert("Formulaire incomplet. Renseigner au moins une opération, un flux et un exutoire.");
        return;
      }
    }
    placeOrder(dataSlots);
    
    // confirmation
    this.setState({elDone_visible: true});
    setTimeout(() => {this.setState({elDone_visible: false})}, 2000);
  
    // clear form
    this.props.appActions.updateDataSlot('ds_action_enlevement', "");
    this.props.appActions.updateDataSlot('ds_flux', "");
    this.props.appActions.updateDataSlot('ds_commentaire', "");
    this.props.appActions.updateDataSlot('ds_volume', "");
    this.props.appActions.updateDataSlot('ds_tonnage', "");
    this.props.appActions.updateDataSlot('ds_saved_picture', "");
    this.props.appActions.updateDataSlot('ds_exutoire_enlevement', "");
  
    // Go back in screen navigation history
    this.props.appActions.goBack();
  
  }
  
  
  onClick_elBig_image = (ev) => {
    this.setState({elBig_image_visible: false});
  
  }
  
  
  render() {
    const style_elBackground_outer = {
      backgroundColor: 'white',
      filter: 'drop-shadow(0.0px 2.0px 3px rgba(0, 0, 0, 0.3000))',
      overflow: 'visible',
     };
    const style_elOperation_txt = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let selection_operation = this.state.operation;
    // Source datasheet and selected data column for picker element 'operation'
    const dataSource_operation = this.props.appActions.getDataSheet('operations_other');
    const valueColumnName_operation = 'id';
    const labelColumnName_operation = 'label';
    
    const style_elOperation = {
      pointerEvents: 'auto',
     };
    const style_elFlux_txt = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    let selection_flux = this.state.flux;
    // Source datasheet and selected data column for picker element 'flux'
    const dataSource_flux = this.props.appActions.getDataSheet('containers');
    const valueColumnName_flux = 'document_key';
    const labelColumnName_flux = 'flux';
    
    const style_elFlux = {
      pointerEvents: 'auto',
     };
    
    const value_comment = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_commentaire'] : '');
    
    const style_elComment = {
      display: 'block',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const value_volume = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_volume'] : '');
    
    const style_elVolume = {
      display: 'block',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const style_elCard3_outer = {
      backgroundColor: 'white',
      filter: 'drop-shadow(0.0px 2.0px 3px rgba(0, 0, 0, 0.3000))',
      overflow: 'visible',
     };
    const style_elImage = {
      backgroundImage: 'url('+((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_saved_picture'] : '') || img_elImage)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elCapture = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_1552626310+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '17.850%',
      paddingLeft: '18%',
      backgroundPosition: '0% 50%',
      color: '#00e5d9',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elCapture = this.state.elCapture_visible ? (
      <div className="elCapture">
        <button className="actionFont" style={style_elCapture} onClick={this.onClick_elCapture} >
          Ajouter une photo
        </button>
      </div>
      
     ) : null;
    
    const style_elErase = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_1761859123+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elErase = this.state.elErase_visible ? (
      <div className="elErase">
        <button className="actionFont" style={style_elErase} onClick={this.onClick_elErase}  />
      </div>
      
     ) : null;
    const style_elCard4_outer = {
      backgroundColor: 'white',
      filter: 'drop-shadow(0.0px 2.0px 3px rgba(0, 0, 0, 0.3000))',
      overflow: 'visible',
     };
    const elCard4 = this.state.elCard4_visible ? (
      <div className="elCard4" style={style_elCard4_outer}>
        <div className="cardBg" />
      </div>
      
     ) : null;
    const style_elExutoire_txt = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const elExutoire_txt = this.state.elExutoire_txt_visible ? (
      <div className="elExutoire_txt">
        <div className="baseFont" style={style_elExutoire_txt}>
          <div>{this.state.exutoire_txt}</div>
        </div>
      </div>
      
     ) : null;
    
    let selection_exutoire = this.state.exutoire;
    // Source datasheet and selected data column for picker element 'exutoire'
    const dataSource_exutoire = this.props.appActions.getDataSheet('exutoires');
    const valueColumnName_exutoire = 'document_key';
    const labelColumnName_exutoire = 'label';
    
    const style_elExutoire = {
      pointerEvents: 'auto',
     };
    const elExutoire = this.state.elExutoire_visible ? (
      <div className="elExutoire">
        <Select className="baseFont" style={style_elExutoire}  onChange={this.pickerValueChanged_elExutoire} value={selection_exutoire} >
          {dataSource_exutoire.items.every(item => {
            return item[valueColumnName_exutoire] !== selection_exutoire;
          }) ? <Option value=''/> : null}
          {dataSource_exutoire.items.map(item => {
            const colValue = item[valueColumnName_exutoire];
            const labelColValue = item[labelColumnName_exutoire];
            return <Option key={item.key} value={colValue} label={labelColValue} />
          })}
        </Select>
      </div>
      
     ) : null;
    
    const value_tonnage = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_tonnage'] : '');
    
    const style_elTonnage = {
      display: 'block',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    const elTonnage = this.state.elTonnage_visible ? (
      <div className="elTonnage">
        <Input className="baseFont" style={style_elTonnage} type="number" hint="Tonnage" onChange={this.textInputChanged_elTonnage} value={value_tonnage !== undefined ? value_tonnage : ''}  />
      </div>
      
     ) : null;
    const elSpacerCopy3 = this.state.elSpacerCopy3_visible ? (
      <div className="elSpacerCopy3">
        <div />
      </div>
      
     ) : null;
    
    const style_elValider = {
      display: 'block',
      textTransform: 'none',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elBig_image = {
      backgroundImage: 'url('+((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_saved_picture'] : '') || img_elBig_image)+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elBig_image = this.state.elBig_image_visible ? (
      <div className="containerMinHeight elBig_image" style={style_elBig_image} onClick={this.onClick_elBig_image}  />
     ) : null;
    
    return (
      <div className="Other_operation">
        <div className="layoutFlow">
          <div className="elSpacer">
            <div />
          </div>
          
          <div className="flowRow flowRow_Other_operation_elBackground_1552510650">
          <div className="elBackground" style={style_elBackground_outer}>
            <div className="cardBg" />
          </div>
          
          <div className="elOperation_txt">
            <div className="baseFont" style={style_elOperation_txt}>
              <div>{this.state.operation_txt}</div>
            </div>
          </div>
          
          <div className="elOperation">
            <Select className="baseFont" style={style_elOperation}  onChange={this.pickerValueChanged_elOperation} value={selection_operation} >
              {dataSource_operation.items.every(item => {
                return item[valueColumnName_operation] !== selection_operation;
              }) ? <Option value=''/> : null}
              {dataSource_operation.items.map(item => {
                const colValue = item[valueColumnName_operation];
                const labelColValue = item[labelColumnName_operation];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="elFlux_txt">
            <div className="baseFont" style={style_elFlux_txt}>
              <div>{this.state.flux_txt}</div>
            </div>
          </div>
          
          <div className="elFlux">
            <Select className="baseFont" style={style_elFlux}  onChange={this.pickerValueChanged_elFlux} value={selection_flux} >
              {dataSource_flux.items.every(item => {
                return item[valueColumnName_flux] !== selection_flux;
              }) ? <Option value=''/> : null}
              {dataSource_flux.items.map(item => {
                const colValue = item[valueColumnName_flux];
                const labelColValue = item[labelColumnName_flux];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="elComment">
            <Input className="baseFont" style={style_elComment} type="text" hint="Commentaire" onChange={this.textInputChanged_elComment} value={value_comment !== undefined ? value_comment : ''}  />
          </div>
          
          <div className="elVolume">
            <Input className="baseFont" style={style_elVolume} type="number" hint="Volume" onChange={this.textInputChanged_elVolume} value={value_volume !== undefined ? value_volume : ''}  />
          </div>
          
          </div>
          <div className="elSpacerCopy">
            <div />
          </div>
          
          <div className="flowRow flowRow_Other_operation_elCard3_1055474926">
          <div className="elCard3" style={style_elCard3_outer}>
            <div className="cardBg" />
          </div>
          
          <div className="elImage">
            <div style={style_elImage} onClick={this.onClick_elImage}  />
          </div>
          
          { elCapture }
          { elErase }
          </div>
          <div className="elSpacerCopy2">
            <div />
          </div>
          
          <div className="flowRow flowRow_Other_operation_elCard4_2094572428">
          { elCard4 }
          { elExutoire_txt }
          { elExutoire }
          { elTonnage }
          </div>
          { elSpacerCopy3 }
          <div className="elValider">
            <Button className="actionFont" style={style_elValider}  color="accent" onClick={this.onClick_elValider} >
              Enregistrer
            </Button>
          </div>
        </div>
        
        <div className="foreground">
          { elBig_image }
        </div>
      </div>
    )
  }
  
}
