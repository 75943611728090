import React, { Component } from 'react';
import './App.css';
import Image_fill from './Image_fill';
import Add_prise_en_charge from './Add_prise_en_charge';
import Ot_tournee_j from './Ot_tournee_j';
import btn_icon_2014759988 from './images/btn_icon_2014759988.png';
import btn_icon_329243493 from './images/btn_icon_329243493.png';
import Apercu from './Apercu';

// UI framework component imports
import Button from 'muicss/lib/react/button';
import Appbar from 'muicss/lib/react/appbar';

export default class TournéeDuJourScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo, state, type, color

  constructor(props) {
    super(props);
    
    this.state = {
      notif: (<div> </div>),
      notif_plainText: " ",
    };
  }

  componentDidMount() {
    this.props.appActions.updateDataSlot('ds_saved_picture', "");
    this.props.appActions.updateDataSlot('ds_scanned_id', "");
    this.props.appActions.updateDataSlot('ds_selected_command', {});
    this.props.appActions.updateDataSlot('ds_commentaire', "");
    this.props.appActions.updateDataSlot('ds_tonnage', "");
    {
      let dataSheet = this.props.appActions.dataSheets['reference_containers'];
      let serviceOptions = this.props.appActions.serviceOptions_reference_containers;
      if ( !this.props.appActions.dataSheetLoaded['reference_containers']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/all_containers", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['reference_containers'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['tournees_j'];
      let serviceOptions = this.props.appActions.serviceOptions_tournees_j;
      if ( !this.props.appActions.dataSheetLoaded['tournees_j']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/tournees", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['tournees_j'] = true;
      }
    }
    {
      let dataSheet = this.props.appActions.dataSheets['quaiView'];
      let serviceOptions = this.props.appActions.serviceOptions_quaiView;
      if ( !this.props.appActions.dataSheetLoaded['quaiView']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("entreprises/$slot('ds_entreprise_id')/quaiView", this.props.appActions.dataSlots);
        this.props.appActions.loadData_pointapp2(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['quaiView'] = true;
      }
    }
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elImage_fill_full = (ev) => {
    let newVal = "";
    this.props.appActions.updateDataSlot('ds_scanned_id', newVal);
  
    // Go to screen 'Charge du camion'
    this.props.appActions.goToScreen('chargeDuCamion', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elButton = (ev) => {
    // Go to screen 'Fin de tournée'
    this.props.appActions.goToScreen('finDeTournée', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elMenu = (ev) => {
    let a = window.confirm("Retourner au menu principal?")
    if (!a) {
      return;
    }
  
    // Go to screen 'Menu principal'
    this.props.appActions.goToScreen('menuPrincipal', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elShow_apercu = (ev) => {
    let newVal = "1";
    this.props.appActions.updateDataSlot('ds_apercu_visible', newVal);
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    // eslint-disable-next-line
    const dataSheet_tournees_j = this.props.dataSheets['tournees_j'];
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };
    
    let transformValue_state_elImage_fill_full = (input) => {
      let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key == input);
      
      if (tournee.loaded == true) {
        return tournee.loaded_is_full==true ? 5 : 1;
      } else {
        return 0
      }
    }
    
    let transformValue_type_elImage_fill_full = (input) => {
      let getContenantById = (id) => {
        return this.props.appActions.dataSheets['group_contenants'].items.find(it => it.document_key == id);
      }
      
      let getFluxByMatiere = (matiere) => {
        return this.props.appActions.dataSheets['matieres'].items.find(it => it.matiere == matiere);
      }
      
      let getMaterialByType = (type) => {
        return this.props.appActions.dataSheets['materiels'].items.find(it => it.type == type);
      }
      
      let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key == input);
      
      if (tournee.load_detail) {
        let type = (getMaterialByType(tournee.load_detail.type)||{}).code_shape||"30";
        return type;
      } else {
        return "30";
      }
    }
    
    let transformValue_color_elImage_fill_full = (input) => {
      let getContenantById = (id) => {
        return this.props.appActions.dataSheets['group_contenants'].items.find(it => it.document_key == id);
      }
      
      let getFluxByMatiere = (matiere) => {
        return this.props.appActions.dataSheets['matieres'].items.find(it => it.matiere == matiere);
      }
      
      let getMaterialByType = (type) => {
        return this.props.appActions.dataSheets['materiels'].items.find(it => it.type == type);
      }
      
      let tournee = this.props.appActions.dataSheets['id_tournees_j'].items.find(it => it.document_key == input);
      
      if (tournee.load_detail) {
        return (getFluxByMatiere(tournee.load_detail.flux)||{}).color || "#000000";
      } else {
        return "#000000";
      }
    }
    const style_elImage_fill_full = {
      width: '100%',
      height: '100%',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elImage_fill_full_outer = {
      backgroundColor: '#38d3d5',
      borderRadius: '3.0px',
     };
    
    let cssClass_progress = 'circularProgressIndicator';
    if (this.props.appActions.isLoading()) 
      cssClass_progress += ' circularProgressIndicator-active';
    
    
    const style_elButton = {
      display: 'block',
      textTransform: 'none',
      color: '#fff',
      textAlign: 'center',
      backgroundColor: '#ff7d78',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    // Source items and any special components used for list/grid element 'list copy'.
    let items_listCopy = [];
    let listComps_listCopy = {};
    listComps_listCopy['_tail'] = <Add_prise_en_charge appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />;  // Tail item for this list
    
    let filterItems_listCopy = items => {
      let filtered_items = items.filter(it => it.tournee == this.props.appActions.dataSlots['ds_selected_tournee']).sort((a, b) => a.position - b.position );
      
      let output_items = filtered_items.filter(it => !it.reference_diff);
      filtered_items.filter(it => it.reference_diff).forEach(it => {
        let foud_index = output_items.findIndex(ot => it.reference_diff.includes(ot.document_key));
        output_items.splice(foud_index+1, 0, it);
      })
      return output_items;
    }
    items_listCopy = items_listCopy.concat(filterItems_listCopy(this.props.appActions.getDataSheet('tournees_j').items));
    items_listCopy.push({key: '_tail', _componentId: '_tail'});  // A data item to indicate the tail item
    this._elListCopy_items = [];
    
    
    const style_elMenu = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_2014759988+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elShow_apercu = {
      display: 'block',
      backgroundImage: 'url('+btn_icon_329243493+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    let transformPropValue_notif = (input) => {
      let entreprise = this.props.appActions.dataSheets['entreprise'].items[0]||{};
      let auth_user = this.props.appActions.dataSheets['auth_user'].items[0] || {};
      let profile = auth_user.profile || {}; 
      
      if (entreprise.pvd_can_add_to_tour && (profile.hdq === true ? !entreprise.hdq_cannot_add_to_tour : true)) {
        let commands = this.props.appActions.dataSheets['tournees_j'].items
        .filter(it => it.tournee == "" && it.statut === 0)
        .sort((a,b) => a.display_name.localeCompare(b.display_name));
        if (commands.length > 0) {
          return "<p style='color:white;border-radius:100%;background-color:#ff7e79;width:18px;height:18px;'>"+commands.length+"</p>";
        } else {
          return '';
        }
      } else {
        return '';
      }
    }
    
    const value_notif = transformPropValue_notif((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_today_minuit_ts'] : ''));
    
    const style_elNotif = {
      fontSize: 12.3,
      color: '#feffff',
      textAlign: 'center',
      pointerEvents: 'auto',
     };
    const elApercu = ((val) => { return val === "true" || val == true || val == 1 })((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_apercu_visible'] : '')) ? (
      <div className="hasNestedComps containerMinHeight elApercu">
        <Apercu visualStateIndex={(this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_mode_apercu'] : '')} ref={(el)=> this._elApercu = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    return (
      <div className="AppScreen TournéeDuJourScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        <Appbar className="navBar">
          <div className="title">{this.props.ds_selected_tournee_driver}</div></Appbar>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="hasNestedComps elImage_fill_full" style={style_elImage_fill_full_outer}>
              <div style={style_elImage_fill_full} onClick={this.onClick_elImage_fill_full} >
                <Image_fill state={transformValue_state_elImage_fill_full((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_tournee'] : ''))} type={transformValue_type_elImage_fill_full((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_tournee'] : ''))} color={transformValue_color_elImage_fill_full((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_selected_tournee'] : ''))} ref={(el)=> this._elImage_fill_full = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
              </div>
            </div>
            
            <div className="elProgress">
              <svg className={cssClass_progress}>
                <circle className="circularProgress-animatedPath" style={{stroke: '#00e5d9'}} cx="25" cy="25" r="17" fill="none" strokeWidth="3" strokeMiterlimit="10" />
              </svg>
            </div>
            <Button className="actionFont elButton" style={style_elButton} onClick={this.onClick_elButton} >
              Fin de la tournée
            </Button>
            <div className="hasNestedComps elListCopy">
              <ul>
                {items_listCopy.map((row, index) => {
                  let itemComp = (row._componentId)
                      ? listComps_listCopy[row._componentId]
                      : <Ot_tournee_j appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'tournees_j'} dataSheetRow={row} {...{ 'flux': row['flux'], 'document_key': row['document_key'], 'statut': row['statut'], 'action': row['action'], 'exutoire': row['exutoire'], 'remark': row['remark'], 'hh': row['hh'], }} ref={(el) => {if (el) this._elListCopy_items.push(el)}} />;
                  return (<li key={row.key}>
                      {itemComp}
                    </li>);
                })}
                <div className="marker" ref={(el)=> this._elListCopy_endMarker = el} />
              </ul>
            </div>
            
            <button className="actionFont elMenu" style={style_elMenu} onClick={this.onClick_elMenu}  />
            <button className="actionFont elShow_apercu" style={style_elShow_apercu} onClick={this.onClick_elShow_apercu}  />
            <div className="systemFontRegular  elNotif" style={style_elNotif}>
              <div><div dangerouslySetInnerHTML={{__html: value_notif}}></div></div>
            </div>
            { elApercu }
          </div>
        </div>
      </div>
    )
  }
  
}
